import React from 'react'
import { BAcceptBody } from '../betslipComponents/BetslipAccept/BAcceptBody/BAcceptBody'
import BAcceptHeader from '../betslipComponents/BetslipAccept/BAcceptHeader'

export const BetslipAccept = ({
  stakes,
  multistakes,
  possibleWin,
  betTotal,
  removeAllBetsHandler,
}) => {
  return (
    <div className="b-accept">
      <div className="b-accept__wrapper">
        <BAcceptHeader {...{ removeAllBetsHandler }} />
        <BAcceptBody {...{ stakes }} {...{ multistakes }} {...{ possibleWin }} {...{ betTotal }} />
      </div>
    </div>
  )
}
