import React from 'react'
import Navigate from '../../common/routing/Navigate'
import { historyRouting } from '../../../routing/accountRouting/historyRouting'

const History = () => {
  return (
    <div className="container">
      <Navigate routes={historyRouting} />
    </div>
  )
}

export default History
