import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getFavorites } from 'redux/selectors/favoritesSelectors'
import FlipMove from 'react-flip-move'
import MarketGroup from './marketsGroup/MarketGroup'
import { debounce } from 'helpers/debounce'

const formatMarketsForPrematch = (data) => {
  const existInData = (id, array) => {
    let exist = false
    array.forEach((item) => {
      if (item.id === id) {
        exist = true
      }
    })

    return exist
  }

  const dat = data
  const formedData: Array<any> = []
  const usedSpecifiresIT: Array<any> = []

  dat.forEach((odd) => {
    if (!existInData(odd.id, formedData)) {
      const tempID = odd.id
      const tempIT = odd.it

      dat.forEach((tempOdd) => {
        if (
          tempOdd.id === tempID &&
          tempOdd.it !== tempIT &&
          !usedSpecifiresIT.includes(tempOdd.it)
        ) {
          tempOdd.outcomes.forEach((outcome) => {
            odd.outcomes.push(outcome)
          })
        }
      })
      formedData.push(odd)
      odd = null
      usedSpecifiresIT.push(tempIT)
    }
  })

  console.log('Formed odds: ', formedData)

  return formedData
}

const MarketsContainer = ({ data }) => {
  const favorites = useSelector(getFavorites)

  const phRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<number>(0)

  const sortOrNot = (a, b) => {
    if (favorites.markets.some(market => market === a.id.toString())) {
      if (favorites.markets.some(market => market === b.id.toString())) {
        return 1
      } else {
        return -1
      }
    } else {
      return 1
    }
  }

  useLayoutEffect(() => {
    if (!phRef.current || !ref.current) {
      return
    }
    phRef.current.style.height = `${ref.current.getBoundingClientRect().height
      }px`
  }, [data, favorites])

  useEffect(
    () =>
      document.body.addEventListener(
        'scroll',
        debounce(() => {
          scrollRef.current = document.body.scrollTop
          if (phRef.current) {
            phRef.current.scrollTop = document.body.scrollTop
          }
        }, 200)
      ),
    []
  )

  let formedData: any[] = []
  if (window.location.href.includes('sports')) {
    formedData = formatMarketsForPrematch(data).sort(sortOrNot)
  } else {
    formedData = data.sort(sortOrNot)
  }
  return (
    <div className="gll-markets-wrapper">
      <div ref={phRef} className="flipmove-placeholder" />
      <div ref={ref}>
        <FlipMove>
          {formedData?.map((item, idx) => {
            return <MarketGroup key={item.id} odd={item} {...{ idx }} marketsLn={data.length} />
          })}
        </FlipMove>
      </div>
    </div>
  )
}


export default React.memo(MarketsContainer)
