import React, { useState } from 'react'
import TableTitle from './components/TableTitle'
import TableRow from './components/TableRow'
import Replenishment from './components/Replenishment'
import skrill from '../../../assets/img/partner/skrill.svg'
import androidBozor from '../../../assets/img/partner/androidBozor.svg'
import applePay from '../../../assets/img/partner/applePay.svg'
import bitcoin from '../../../assets/img/partner/bitcoin.svg'
import webMoney from '../../../assets/img/partner/webMoney.svg'
import qiwi from '../../../assets/img/partner/qiwi.svg'
import visa from '../../../assets/img/partner/visa.svg'
import masterCard from '../../../assets/img/partner/masterCard.svg'

const DepositWrapper = () => {
	const [replenishment, setReplenishment] = useState(false)
	const positions = [
		{
			img: skrill,
			name: 'Skrill',
			min: '0$',
			max: '100 $',
			comission: '0 %',
			processing: 'instantly',
		},
		{
			img: androidBozor,
			name: 'Android bozor',
			min: '2$',
			max: '100 $',
			comission: '0 %',
			processing: 'instantly',
		},
		{
			img: applePay,
			name: 'Apple Pay',
			min: '3$',
			max: '100 $',
			comission: '0 %',
			processing: 'instantly',
		},
		{
			img: bitcoin,
			name: 'Bitcoin',
			min: '4$',
			max: '100 $',
			comission: '0 %',
			processing: 'instantly',
		},
		{
			img: webMoney,
			name: 'WebMoney',
			min: '4$',
			max: '100 $',
			comission: '0 %',
			processing: 'instantly',
		},
		{
			img: qiwi,
			name: 'QIWI',
			min: '5$',
			max: '100 $',
			comission: '0 %',
			processing: 'instantly',
		},
		{
			img: visa,
			name: 'Visa',
			min: '5$',
			max: '100 $',
			comission: '0 %',
			processing: 'instantly',
		},
		{
			img: masterCard,
			name: 'Master Card',
			min: '5$',
			max: '100 $',
			comission: '0 %',
			processing: 'instantly',
		},
		/* {
      img: 'https://via.placeholder.com/66x30/#6C90FC',
      name: 'Skrill',
      min: '5$',
      max: '100 $',
      comission: '0 %',
      processing: 'instantly'
    } */
	]
	const replenishmentHandler = () => {
		setReplenishment(!replenishment)
	}
	return (
		<div className="container">
			<div className="container__head">
				<p className="font font--semibold">Deposit</p>
			</div>
			<div className="container__wrap">
				<div className="balance">
					<table className="cb-table">
						<tbody>
							<TableTitle />
							{positions?.map(pos => {
								return (
									<TableRow
										key={pos.name}
										img={pos.img}
										name={pos.name}
										min={pos.min}
										max={pos.max}
										comission={pos.comission}
										processing={pos.processing}
										buttonName="Withdraw"
										click={replenishmentHandler}
									/>
								)
							})}
						</tbody>
					</table>
					{replenishment && <Replenishment click={replenishmentHandler} />}
				</div>
			</div>
		</div>
	)
}

export default DepositWrapper
