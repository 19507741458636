import React, { useState, useEffect } from 'react';
import { setIn } from 'formik';

const ProgressBar = ( {name, remove, setPost, setClass} ) => {

    let [percent, setPercent] = useState(0);
    const [status, setStatus] = useState('Uploading...');
    const [hide, setHide] = useState('flex');
    const [pauseClass, setPauseClass] = useState("cb-alert__button cb-alert__button--pause m-l-5");
    const [interval, setInt] = useState<number>();

    //let interval;

    const progressWorker = () => {
        if (percent == 90 || percent > 90) {
          Stop();
          setPercent(100);
          setStatus('Done');
          setHide('none');
          Done();
        } else {
          setPercent(percent+=Math.floor(Math.random() * 15) + 7);
        }
      }

    const Stop = () => {
        clearInterval(interval);
    }

    const Continue = () => {
      setPauseClass("cb-alert__button cb-alert__button--pause m-l-5");
      setInt(window.setInterval(progressWorker, 1000));
    }

    const Pause = () => {
      console.log('Pause');
      Stop();
      setPauseClass("cb-alert__button pause fal fa-play");
    }

    const PauseHandler = () => {
      if (typeof interval != 'number') {
        Continue();
      } else {
        Pause();
        setInt(window.setInterval(() => {}, 0));
      }
    }

    const Done = () => {
      setClass('cb-alert cb-alert--default');
      setPost(
        <div className="flex align-middle align-justify full-height cb-alert__wrap">
            <p className="font font--10 cb-alert__text text-ellipsis">{name}</p>
            <div className="cb-alert__times" onClick={remove}><i className="fal fa-times"></i></div>
          </div>
      );
    }

    useEffect(() => {
        if (typeof interval === 'undefined') {
          Continue();
        }
      });

    return (
        <div className="cb-alert__wrap">
          <div className="flex align-middle">
            <p className="font font--10 font--blue m-r-5 cb-alert__status">{status}</p>
            <p className="font font--10 cb-alert__text text-ellipsis">{name}</p>
          </div>
          <div className="flex align-middle">
            <p className="font font--10 cb-alert__text text-ellipsis">{percent + '%'}</p>
            <div className="cb-alert__separate"></div>
            <p className="font font--10 cb-alert__text text-ellipsis"></p>
            <div className="flex align-middle cb-alert__buttons">
            <div style={{display: hide}}>
              <button className={pauseClass} onClick={PauseHandler}><i></i>
              </button>
              <button className="cb-alert__button cb-alert__button--stop m-l-5" onClick={remove}><i></i>
              </button>
              </div>
            </div>
          </div>
          <div className="cb-alert__progress m-t-10" style={{ width: percent + '%', background: '#6c90fc'}}></div>
        </div>
    );
}

export default ProgressBar;