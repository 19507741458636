import React from 'react'
import img from '../../assets/img/team.svg'

const TopMatches = () => {
	return (
		<div className="wn-top-matches">
			<div className="wn-top-matches__title">
				<p className="font font--semibold">Top Matches</p>
			</div>
			<div className="wn-top-matches__slider">
				<div className="wn-top-matches__item">
					<div className="wn-top-matches__body">
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Chelsea</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<p className="font font--default-alpha-05 m-b-5">Today</p>
									<p className="font font--semibold">19:00</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Arsenal</p>
								</div>
							</div>
						</div>
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										0
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										x
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										2
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
						</div>
					</div>
					<div className="wn-top-matches__footer">
						<a className="font font--11 font--blue text-uppercase">More info</a>
					</div>
				</div>
				<div className="wn-top-matches__item">
					<div className="wn-top-matches__body">
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Chelsea</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<p className="font font--default-alpha-05 m-b-5">Today</p>
									<p className="font font--semibold">19:00</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Arsenal</p>
								</div>
							</div>
						</div>
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										1
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										x
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										2
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
						</div>
					</div>
					<div className="wn-top-matches__footer">
						<a className="font font--11 font--blue text-uppercase">More info</a>
					</div>
				</div>
				<div className="wn-top-matches__item">
					<div className="wn-top-matches__body">
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Chelsea</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<p className="font font--default-alpha-05 m-b-5">Today</p>
									<p className="font font--semibold">19:00</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Arsenal</p>
								</div>
							</div>
						</div>
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										2
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										x
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										2
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
						</div>
					</div>
					<div className="wn-top-matches__footer">
						<a className="font font--11 font--blue text-uppercase">More info</a>
					</div>
				</div>
				<div className="wn-top-matches__item">
					<div className="wn-top-matches__body">
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Chelsea</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<p className="font font--default-alpha-05 m-b-5">Today</p>
									<p className="font font--semibold">19:00</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Arsenal</p>
								</div>
							</div>
						</div>
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										3
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										x
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										2
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
						</div>
					</div>
					<div className="wn-top-matches__footer">
						<a className="font font--11 font--blue text-uppercase">More info</a>
					</div>
				</div>
				<div className="wn-top-matches__item">
					<div className="wn-top-matches__body">
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Chelsea</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<p className="font font--default-alpha-05 m-b-5">Today</p>
									<p className="font font--semibold">19:00</p>
								</div>
							</div>
							<div className="wn-top-matches__cell">
								<div className="text-center">
									<div className="wn-top-matches__team">
										<img src={img} alt="" />
									</div>
									<p className="font font--semibold">Arsenal</p>
								</div>
							</div>
						</div>
						<div className="wn-top-matches__row">
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										4
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										x
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
							<div className="wn-top-matches__cell">
								<button className="btn btn--h-28 btn--coff wn-top-matches__btn">
									<span className="font font--11 font--default-alpha-05">
										2
									</span>
									<span className="font font--12">2.17</span>
								</button>
							</div>
						</div>
					</div>
					<div className="wn-top-matches__footer">
						<a className="font font--11 font--blue text-uppercase">More info</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TopMatches
