import React from 'react'
import ActionsCell from './actionsCell/ActionsCell'
import ParticipantInfoCell from './participantsInfo/ParticipantInfoCell'
import EventMarkets from './markets/EventMarkets'
import EventOtherBets from './otherBets/EventOtherBets'
import EventMarketsForChangeMarket from './markets/EventMarketsForChangeMarket'

const Match = ({ changeMarket, oddName, oddId, toEvent, event }) => {
  const {
    competitors,
    additionalData,
    id,
    sportId,
    leagueId,
    odds,
    oddsCount,
    startDate,
    enrichment,
    externalIds,
    providerStatus,
    eventType,
  } = event

  return (
    <>
      <ActionsCell
        clock={additionalData?.eventClock}
        startDate={startDate}
        periodId={additionalData?.periodId}
        externalIds={externalIds}
        enrichment={enrichment}
        providerStatus={providerStatus}
        eventType={eventType}
        event={event}
      />
      <ParticipantInfoCell
        competitors={competitors}
        score={additionalData?.scoreboard?.data}
        point={additionalData?.currentServer?.data}
        sportId={sportId}
        toEvent={(e) => {
          e.stopPropagation()
          toEvent(sportId, leagueId, id)
        }}
      />
      <EventMarkets
        odds={odds}
        competitors={competitors}
        oddName={oddName}
        oddId={oddId}
        changeMarket={changeMarket}
        eventId={id}
        sportId={sportId}
      />
      <EventOtherBets oth={oddsCount} toEvent={() => toEvent(sportId, leagueId, id)} />
    </>
  )
}

export default Match
