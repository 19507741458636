import React from 'react'
import Competition from '../../competition/Competition'

const ClassificationItemBody = ({ data, liveTranslation }) => {
  const leagues = data.categories.reduce((acc, item) => [...acc, ...item.leagues], [])

  return (
    <div className="ipn-classification__body">
      <div className="ipn-classification__body-inner">
        {leagues?.map((league) => (
          <Competition key={league.id} data={league} {...{ liveTranslation }} />
        ))}
      </div>
    </div>
  )
}

export default React.memo(ClassificationItemBody)
