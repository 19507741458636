import React, { useEffect, useState } from 'react'
import Bonus from './Bonus'
import LiveTranslationWrapper from './liveTranslation/LiveTranslationWrapper'
import BetslipWrapper from './betslip/betslipWrappers/BetslipWrapper'
import MyBetsWrapper from './myBets/MyBetsWrapper'
import { useStorage } from '../../../hooks/useStorage'
import { useDispatch, useSelector } from 'react-redux'
import { initBetslip, removeAll, setQuickbet } from '../../../redux/actions/betslipActions'
import BetslipHeader from './betslip/betslipWrappers/BetslipHeader'
import { getBetslip } from 'redux/selectors/betslipSelectors'

const BetslipAsideWrapper = ({ wide, removeWideHandler }) => {
  const refContainer = React.useRef(null)

  const dispatch = useDispatch()

  const { getSession, setSession } = useStorage()

  const betslipSettings = getSession('betslipSettings') || {
    showPanel: false,
    showBetslip: '1',
    showBonus: true,
  }

  useEffect(() => {
    dispatch(setQuickbet(betslipSettings.showPanel))
  }, [])

  const betslipReduxData = useSelector(getBetslip)

  const [data, setData] = useState(betslipSettings)

  useEffect(() => {
    dispatch(initBetslip())
  }, [])

  const onToggle = (name, id) => {
    const newData = {
      ...data,
      [name]: id ? id : !data[name],
    }
    if (name === 'showPanel') {
      dispatch(setQuickbet(!data.showPanel))
    }
    setData(newData)
    setSession('betslipSettings', newData)
  }

  return (
    <div ref={refContainer}>
      {data.showBonus && <Bonus close={onToggle} />}
      <LiveTranslationWrapper {...{ wide }} {...{ removeWideHandler }} />
      <div className="betslip m-b-10">
        <BetslipHeader click={onToggle} content={data.showBetslip} />
        <BetslipWrapper
          display={data.showBetslip === '1'}
          data={betslipReduxData}
          ref={refContainer}
          quickbetPanel={data.showPanel}
          quickbetToggle={onToggle}
        />
        <MyBetsWrapper display={data.showBetslip !== '1'} data={betslipReduxData} />
      </div>
    </div>
  )
}

export default BetslipAsideWrapper
