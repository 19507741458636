import React from 'react'

const PopupSucceed = ({
	message = 'Your documents have been sent for review. It can take some time.',
	close
}) => {
	return (
		<div className="overlay overlay--additionally overlay--block" >
			<div className="cb-popup cb-popup--center cb-popup--block">
				<div className="cb-popup__close fal fa-times" onClick={close}></div>
				<div className="cb-popup__wrap">
					<div className="text-center">
						<p className="font font--19 font--green m-b-15">Excellent!</p>
						<p className="font font--15">{message}</p>
					</div>
					<div className="flex align-center-middle cb-popup__notification">
						<p className="font font--10 font--grey-300 m-r-5">The window will close
                                            automatically through…</p>
						<p className="font font--10 font--grey-300">3 sec</p>
					</div>
				</div>
			</div>
		</div >
	)
}

export default PopupSucceed