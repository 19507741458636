import React from 'react'
import Text from '../../../common/elements/Text'

const ScoreboardTeamsName = ({ competitors, name }) => {
	if (name) {
		return (
			<div className="ip-scoreboard-teams">
				<div className="flex align-middle align-left full-height">
					<Text
						className="font font--white font--17 font--semibold text-ellipsis m-r-10"
						title={name}
						style={{
							maxWidth: '260px',
							lineHeight: '20px',
						}}
					/>
				</div>
			</div>
		)
	}

	const transformedCompetitors = [...competitors]

	transformedCompetitors.splice(1, 0, { name: 'vs' })

	return (
		<div className="ip-scoreboard-teams">
			<div className="flex align-middle align-left full-height">
				{transformedCompetitors?.map(item => (
					<Text
						key={item.name}
						className="font font--white font--17 font--semibold text-ellipsis m-r-10"
						title={item.name}
						style={{
							maxWidth: '200px',
							lineHeight: '20px',
						}}
					/>
				))}
			</div>
		</div>
	)
}

export default React.memo(ScoreboardTeamsName)
