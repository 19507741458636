import { types } from '../types/types'
import {
  IMatch,
  ISport,
  ITournament,
  Obj,
  Nullable,
  IStreaming,
} from '../../helpers/commonInterfaces/interfaces'

export interface ICurrentView {
  current: Nullable<ISport>
  chosenLeagues: Nullable<Array<number>>
  currentOddName: Nullable<string>
  currentOddId: Nullable<number>
  allOddsName: Nullable<string>
  allOddsId: Nullable<number>
}

export interface IInplay {
  data: Nullable<Array<ISport>>
  currentView: ICurrentView
  leaguesEvents: Obj<Array<IMatch | ITournament>>
  eventsLoaded: boolean
  active: number
  stream: Nullable<Array<IStreaming>>
  pinnedMatch: boolean
  activeTracker: number
  activeTranslation: number
  columnMarket: Nullable<string>
}

const initialState: IInplay = {
  data: [],
  currentView: {
    current: null,
    chosenLeagues: null,
    currentOddName: null,
    currentOddId: null,
    allOddsName: null,
    allOddsId: null,
  },
  eventsLoaded: false,
  leaguesEvents: {},
  active: 1,
  pinnedMatch: false,
  activeTracker: 0,
  activeTranslation: 0,
  stream: null,
  columnMarket: null,
}

export const inplay = (state = initialState, action: any): IInplay => {
  switch (action.type) {
    case types.SET_INPLAY_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case types.SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: {
          ...state.currentView,
          current: action.payload,
          // currentOddName: null,
          // currentOddId: null,
          // allOddsName: null,
          // allOddsId: null,
        },
        active: action.payload.id,
      }
    case types.INPLAY_ALL:
      return {
        ...state,
        leaguesEvents: {
          ...action.payload,
        },
        eventsLoaded: true,
      }
    case types.LOAD_LEAGUE_EVENTS:
      return {
        ...state,
        leaguesEvents: {
          ...state.leaguesEvents,
          [action.payload.id]: action.payload.data,
        },
      }
    case types.SET_ALL_ODDS_NAME:
      return {
        ...state,
        currentView: {
          ...state.currentView,
          allOddsName: action.payload,
        },
      }
    case types.SET_ALL_ODDS_ID:
      return {
        ...state,
        currentView: {
          ...state.currentView,
          allOddsId: action.payload,
        },
      }
    case types.CURRENT_ODD_NAME:
      return {
        ...state,
        currentView: {
          ...state.currentView,
          currentOddName: action.payload,
        },
      }
    case types.CURRENT_ODD_ID:
      return {
        ...state,
        currentView: {
          ...state.currentView,
          currentOddId: action.payload,
        },
      }
    case types.SET_CHOSEN_LEAGUES:
      return {
        ...state,
        currentView: {
          ...state.currentView,
          chosenLeagues: action.payload,
        },
      }
    case types.DESTROY_LEAGUE:
      return {
        ...state,
        leaguesEvents: action.data,
      }
    case types.SET_STREAMING_EVENTS:
      return {
        ...state,
        stream: action.payload,
      }
    case types.CHANGE_COLUMN_MARKET:
      return {
        ...state,
        columnMarket: action.payload,
      }
    case types.SET_ACTIVE_TRACKER:
      return {
        ...state,
        activeTracker: action.payload,
      }
    case types.SET_ACTIVE_TRANSLATION:
      return {
        ...state,
        activeTranslation: action.payload,
      }
    case types.SET_PINNED_MATCH:
      return {
        ...state,
        pinnedMatch: action.payload,
      }
    default:
      return state
  }
}
