import React from 'react'
import { modifyBets } from '../../../../helpers/modifyBets'

const MarketValue = ({ highlight, oddValue, oddType }) => {
  return (
    <span className={'font font--grey-500 ' + highlight.className}>
      {modifyBets(oddValue, oddType)}
    </span>
  )
}

export default React.memo(MarketValue)
