import React from 'react'
import registration from '../../../assets/img/registration/cup.svg'

const RegistrationSuccessed = () => {
  return (
    <div className="mn-popup mn-popup--active congr-popup">
      <div className="flex align-center-middle mn-popup__block">
        <div className="mn-popup__center">
          <div className="text-center mn-popup__title">
            <img src={registration} alt="registration" />
            <p className="font font--16 font--green m-t-20 m-b-5">Congratulations</p>
            <p className="font font--16 m-b-5">You have been successfully registered.</p>
            <p className="font font--16">
              Please confirm your
              <a className="font font--16 font--blue m-l-5" href="#">
                mail
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationSuccessed
