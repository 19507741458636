import React, { useEffect, useRef, useState } from 'react'
import { IBet, IBetPa } from '../../../../helpers/commonInterfaces/interfaces'
import gearIcon from '../../../../assets/img/icon/gear.svg'
import CashoutRange from './CashoutRange'
import { floatToCurrency } from '../../../../helpers/floatToCurrency'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrency } from '../../../../redux/selectors/configSelectors'
import { Collapse } from 'react-collapse'
import { mybetsWatchers, mybetsWorkers } from '../../../../redux/actions/myBetsActions'
import { getMyBetsCashout } from '../../../../redux/selectors/myBetsSelectors'

enum ETabs {
  AUTO_CASHOUT = 'AUTO_CASHOUT',
  PARTIAL_CASHOUT = 'PARTIAL_CASHOUT',
}

declare global {
  interface Window {
    cashoutTimeout: number
  }
}

type ICashoutContainer = {
  bet: IBet
  pa: IBetPa[]
}
const CashoutContainer: React.FC<ICashoutContainer> = ({ bet }) => {
  const dispatch = useDispatch()
  const currencyConfig = useSelector(getCurrency)
  const cashout = useSelector(getMyBetsCashout)
  const [tab, setTab] = useState<ETabs>(ETabs.AUTO_CASHOUT)
  const [isOpenSettings, handleOpenSettings] = useState<boolean>(false)
  const [min, max] = [0.0, bet.CV] as Array<number>
  const maxRef = useRef<number>(bet.CV ?? 0)

  useEffect(() => {
    if (!maxRef.current && bet.CV) {
      return
    }
    // if (rangeAutoValues[0] <= maxRef.current) {
    //   setRangeAutoValues([bet.CV])
    // }
    if (rangePartialValues[0] <= maxRef.current) {
      setRangePartialValues([bet.CV ?? 0])
    }
    if (rangeAutoValues[0] > maxRef.current && rangeAutoValues[0] <= max) {
      setRangeAutoValues([bet.CV ?? 0])
    }
    if (rangePartialValues[0] > maxRef.current && rangePartialValues[0] <= max) {
      setRangePartialValues([bet.CV ?? 0])
    }
    maxRef.current = bet.CV ?? 0
  }, [bet.CV])

  const [isOpenAutoCashoutRange, handleOpenAutoCashoutRange] = useState<boolean>(true)

  const [loading, setLoading] = useState<boolean>(false)
  const [creatingRule, setCreatingRule] = useState<boolean>(false)
  // const [rule, setRule] = useState<boolean>(false)
  // const [message, setMessage] = useState<string>('')

  const [partial, setPartial] = useState<number>(100)
  const [ac, setAC] = useState<number>(100)
  const [rangePartialValues, setRangePartialValues] = useState<number[]>([max])
  const [rangeAutoValues, setRangeAutoValues] = useState<number[]>([max])
  const handleAutoValuesRange = (e) => {
    setAC(e[0])
    setRangeAutoValues([Number(((e[0] / 100) * maxRef.current).toFixed(2))])
  }
  const handlePartialValuesRange = (e) => {
    setPartial(e[0])
    setRangePartialValues([Number(((e[0] / 100) * maxRef.current).toFixed(2))])
  }

  useEffect(() => {
    if (cashout.status === 0) {
      setCreatingRule(false)
      setLoading(false)
      window.clearTimeout(window.cashoutTimeout)
      window.cashoutTimeout = window.setTimeout(() => {
        dispatch(
          mybetsWorkers.setCashout({
            status: 2,
            ID: 0,
            errors: { code: 0, text: '' },
          })
        )
      }, 3000)
    }
  }, [cashout])

  const handleCashout = () => {
    if (!loading) {
      setLoading(true)
    }
    try {
      dispatch(
        mybetsWatchers.cashout({
          amount: Number(((partial / 100) * max).toFixed(2)),
          id: bet.ID,
        })
      )
    } catch (e) {
      console.log('cashout data not fetched ', e)
    }
  }

  const handleCreateRule = () => {
    if (ac === 0) {
      return
    }
    if (!creatingRule) {
      setCreatingRule(true)
    }
    try {
      dispatch(
        mybetsWatchers.cashout({
          amount: Number(rangeAutoValues[0].toFixed(2)),
          id: bet.ID,
        })
      )
      console.log('cashout rule data')
    } catch (e) {
      console.log('cashout data not fetched ', e)
    }
  }

  return (
    <div className="container__wrap">
      <div className="t-block t-block--down">
        <div className="t-table">
          {cashout.ID === bet.ID && cashout.status === 0 && (
            <div
              className={
                'cashout__message enter-done' + (cashout.status === 0 ? ' cashout__error' : '')
              }
            >
              {/* <CSSTransition
            in={cashout.ID === bet.ID && cashout.errors.text !== ''}
            timeout={200}
            className={
              'cashout__message' +
              (cashout.status === 0 ? ' cashout__error' : '')
            }
            unmountOnExit
            mountOnEnter> */}
              <div className="text-center">
                <p
                  className={
                    'font font--11 text-center' + (cashout.status === 0 ? ' font--red' : '')
                  }
                >
                  {cashout.errors.text}
                </p>
              </div>
            </div>
          )}
          {cashout.ID === bet.ID && cashout.errors.text !== '' ? (
            <></>
          ) : (
            <div className="t-table__tr">
              <div className="t-table__td">
                <button
                  className={
                    'btn btn--h-40 btn--outline t-table__button' +
                    (tab === ETabs.AUTO_CASHOUT && isOpenSettings ? ' disabled' : '')
                  }
                  onClick={!isOpenSettings || tab !== ETabs.AUTO_CASHOUT ? handleCashout : () => 0}
                >
                  {loading ? (
                    <div className="spinner"></div>
                  ) : (
                    <>
                      <span className="font">Cash Out</span>
                      <span
                        className="font font--green"
                        dangerouslySetInnerHTML={{
                          __html: floatToCurrency(
                            ((partial / 100) * max).toFixed(2),
                            currencyConfig,
                            true,
                            true
                          ),
                        }}
                      >
                        {/* {(tab === ETabs.AUTO_CASHOUT
                  ? rangeAutoValues[0]
                  : rangePartialValues[0]
                ).toFixed(2)} */}
                      </span>
                    </>
                  )}
                </button>
              </div>
              {!!bet.CP && (
                <div className="t-table__td t-table__td--sm">
                  <button
                    onClick={handleOpenSettings.bind(null, !isOpenSettings)}
                    className="btn btn--h-40 t-table__option"
                  >
                    <img className="fal" src={gearIcon} alt="Gear" />
                  </button>
                </div>
              )}
            </div>
          )}
          <Collapse
            theme={{ collapse: 'ReactCollapse' }}
            isOpened={isOpenSettings && cashout.status !== 0}
            initialStyle={{ height: '0px', overflow: 'hidden' }}
            checkTimeout={350}
          >
            <div className={'t-dropdown' + (isOpenSettings ? '  t-dropdown--active' : '')}>
              <div className="t-tab">
                <div className="t-tab__header">
                  <a
                    role="button"
                    onClick={setTab.bind(null, ETabs.AUTO_CASHOUT)}
                    onKeyPress={() => null}
                    tabIndex={0}
                    className={
                      'font t-tab__link' +
                      (tab === ETabs.AUTO_CASHOUT ? ' t-tab__link--active' : '')
                    }
                  >
                    Auto Cash Out
                  </a>
                  <a
                    role="button"
                    onClick={setTab.bind(null, ETabs.PARTIAL_CASHOUT)}
                    onKeyPress={() => null}
                    tabIndex={0}
                    className={
                      'font t-tab__link' +
                      (tab === ETabs.PARTIAL_CASHOUT ? ' t-tab__link--active' : '')
                    }
                  >
                    Partial Cash Out
                  </a>
                </div>
                <div className="t-tab__body">
                  {tab === ETabs.AUTO_CASHOUT && (
                    <>
                      <div
                        role="button"
                        onClick={handleOpenAutoCashoutRange.bind(null, !isOpenAutoCashoutRange)}
                        className="flex align-middle m-b-20"
                        onKeyPress={() => null}
                        tabIndex={0}
                      >
                        <p className="font m-r-10">If the value reaches</p>
                        <h5
                          className="h5"
                          dangerouslySetInnerHTML={{
                            __html: floatToCurrency(
                              rangeAutoValues[0].toFixed(2),
                              currencyConfig,
                              true,
                              true
                            ),
                          }}
                        ></h5>
                      </div>

                      {isOpenAutoCashoutRange && (
                        <div className="text-center m-t-10 m-b-10">
                          <p className="font m-b-10">Choose how much to cash out</p>
                          <CashoutRange
                            min={0}
                            max={100}
                            rangeValues={ac}
                            setRangeValues={handleAutoValuesRange}
                          />
                          <div className="flex align-middle align-justify range-label">
                            <p
                              className="font"
                              dangerouslySetInnerHTML={{
                                __html: floatToCurrency(min.toFixed(2), currencyConfig, true, true),
                              }}
                            ></p>
                            <p
                              className="font"
                              dangerouslySetInnerHTML={{
                                __html: floatToCurrency(max.toFixed(2), currencyConfig, true, true),
                              }}
                            ></p>
                          </div>
                        </div>
                      )}
                      <button
                        className={
                          'btn btn--h-40 btn--outline' +
                          (rangeAutoValues[0] === 0 ? ' t-table__button--grey' : '')
                        }
                        style={{
                          backgroundColor: rangeAutoValues[0] > 0 ? '#007aff' : 'inherit',
                        }}
                        onClick={handleCreateRule}
                      >
                        {creatingRule ? (
                          <div className="spinner"></div>
                        ) : (
                          <>
                            <span className="font" style={{ color: '#fff', border: 0 }}>
                              Create Rule
                            </span>
                          </>
                        )}
                      </button>
                    </>
                  )}

                  {tab === ETabs.PARTIAL_CASHOUT && (
                    <div className="text-center m-t-10 m-b-10">
                      <p className="font m-b-10">Choose how much to cash out</p>
                      <CashoutRange
                        min={0}
                        max={100}
                        rangeValues={partial}
                        setRangeValues={handlePartialValuesRange}
                      />
                      <div className="flex align-middle align-justify range-label">
                        <p
                          className="font"
                          dangerouslySetInnerHTML={{
                            __html: floatToCurrency(min.toFixed(2), currencyConfig, true, true),
                          }}
                        >
                          {}
                        </p>
                        <p
                          className="font"
                          dangerouslySetInnerHTML={{
                            __html: floatToCurrency(max.toFixed(2), currencyConfig, true, true),
                          }}
                        >
                          {}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  )
}

export default CashoutContainer
