import AvailableOffers from '../../components/forAccontPageComponents/offers/AvailableOffers'
import Active from '../../components/forAccontPageComponents/offers/Active'
import Archieve from '../../components/forAccontPageComponents/offers/Archieve'
import Open from '../../components/forAccontPageComponents/offers/Open'
import OffersRedirection from 'components/Routing/accountRedirection/offersRedirection'

export const offersRouting = [
	{
		path: '/account/offers/available-offers',
		exact: true,
		component: AvailableOffers,
	},
	{
		path: '/account/offers/active',
		exact: true,
		component: Active,
	},
	{
		path: '/account/offers/archive',
		exact: true,
		component: Archieve,
	},
	{
		path: '/account/offers/open',
		exact: true,
		component: Open,
	},
	{
		component: OffersRedirection
	},
]
