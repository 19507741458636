import React from 'react'

const CbMessage = ({ type }) => {
	return (
		<>
			{type === 'error' ? (
				<div className="cb-message cb-message--error">
					<div className="text-center">
						<div className="cb-message__icon" />
						<p className="font font--16 cb-message__title m-b-5">Oops.</p>
						<p className="font cb-message__description">
							Something went wrong:(
						</p>
					</div>
				</div>
			) : (
				<div className="cb-message cb-message--success">
					<div className="text-center">
						<div className="cb-message__icon" />
						<p className="font font--16 cb-message__title m-b-5">
							Congratulations.
						</p>
						<p className="font cb-message__description">
							You have passed verification
						</p>
					</div>
				</div>
			)}
		</>
	)
}

export default CbMessage
