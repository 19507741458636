import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef } from 'react'
import FlipMove from 'react-flip-move'
import ClassificationItem from './classificationItem/ClassificationItem'
import ClassificationItemWrap from './ClassificationItemWrap'
import { useDispatch, useSelector } from 'react-redux'
import { AsideContext } from '../../../../context/asideLeftContext'
import { getInplay } from 'redux/selectors/inplaySelectors'
import { getAside } from 'redux/selectors/eventSelectors'
import { eventWorkers } from 'redux/actions/eventActions'
import { sortSportsData } from 'helpers/utils/utils'
import { getFavorites } from 'redux/selectors/favoritesSelectors'
import { debounce } from 'helpers/debounce'

const ClassificationWrap = ({ liveTranslation }) => {
  const dispatch = useDispatch()
  const phRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<number>(0)

  const favorites = useSelector(getFavorites)

  const sortOrNot = (a, b) => {
    if (favorites.sports.some(sport => sport === a.id.toString())) {
      if (favorites.sports.some(sport => sport === b.id.toString())) {
        return 1
      } else {
        return -1
      }
    } else {
      return 1
    }
  }

  const sortedData = sortSportsData(useSelector(getInplay).data ?? []).sort(sortOrNot)
  const { sportCategoryHandler, isOpenSport, addOpenedSport, small, collapse, dataForAsideSmall } = useContext(AsideContext)
  const { openedSports, openedLeagues } = useSelector(getAside)



  const toggleOpenedSport = useCallback((id: number) => {
    if (!openedSports.some((sport) => id === sport)) {
      dispatch(eventWorkers.setAside({
        openedLeagues: [...openedLeagues],
        openedSports: [
          ...openedSports,
          id
        ]
      }))
    } else {
      dispatch(eventWorkers.setAside({
        openedLeagues: [...openedLeagues],
        openedSports: [
          ...openedSports.filter((sport) => sport === id)
        ]
      }))
    }
  }, [openedLeagues, openedSports])

  useLayoutEffect(() => {
    if (!phRef.current || !ref.current) {
      return
    }
    phRef.current.style.height = `${ref.current.getBoundingClientRect().height
      }px`
  }, [sortedData])

  useEffect(
    () =>
      document.body.addEventListener(
        'scroll',
        debounce(() => {
          scrollRef.current = document.body.scrollTop
          if (phRef.current) {
            phRef.current.scrollTop = document.body.scrollTop
          }
        }, 200)
      ),
    []
  )
  // isOpenSport && isOpenSport(item.id)
  return (
    <div style={{ position: 'relative' }}>
      <div ref={phRef} className="flipmove-placeholder"></div>
      <div ref={ref}>
        <FlipMove>
          {sortedData.map((item) => (
            <ClassificationItemWrap
              key={item.id}
              sportCategoryHandler={sportCategoryHandler}
              data={item}
              open={(isOpenSport && isOpenSport(item.id)) ?? false}
            >
              <ClassificationItem
                data={item}
                {...{ liveTranslation }}
                open={isOpenSport && isOpenSport(item.id)}
                addOpenedSport={addOpenedSport}
              />
            </ClassificationItemWrap>
          ))}
        </FlipMove>
      </div>
    </div>
  )
}

export default React.memo(ClassificationWrap)
