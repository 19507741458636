import { modifyBets } from './modifyBets'
import {
  acceptChanges,
  removeStake,
  removeAll,
  disableOddsUpdates,
  placeBet,
  enableOddsUpdates,
  setQuickbet,
} from '../redux/actions/betslipActions'
import { floatToCurrency, ICurrencyConfig } from './newFloatToCurrency'

export const betslipLogic = (state, setState, dispatch, currencyConfig: ICurrencyConfig) => {
  const initialState = state
  const decimalSep = initialState.CURRENCY_DECIMAL_SEPARATOR ?? '.',
    groupSep = initialState.CURRENCY_GROUP_SEPARATOR ?? ','

  const getFloat = (val: number | string): number => {
    val = val.toString()
    const noGroupSep = val.split(groupSep).reduce((acc, cur) => {
      return (acc += cur)
    })
    if (val.includes(decimalSep)) {
      if (val.split(decimalSep)[1].length > 0) return parseFloat(noGroupSep.slice(0))
      else
        return parseFloat(noGroupSep.split(decimalSep)[0] + '.' + noGroupSep.split(decimalSep)[1])
    } else return parseFloat(noGroupSep)
  }

  // String money value from float number
  /*  const floatToCurrency = (money, wrap) => {
    const startMoneyValue = money
    money = money.split(groupSep).reduce((acc, cur) => {
      return (acc += cur)
    })
    // check if input is correct
    if (money.includes(decimalSep)) {
      // remove extra decimal separators
      if (money.split(decimalSep).length - 1 > 1) {
        return money.slice(0, -1)
      }
      // remove extra coins
      if (money.split(decimalSep)[1].length > 2) {
        money = money.slice(0, -1)
      }

      if (money.split(decimalSep)[1] == '') {
      } else {
        if (
          !(
            /^\d+$/.test(money.split(decimalSep)[0].split(groupSep).join()) &&
            /^\d+$/.test(money.split(decimalSep)[1])
          )
        ) {
          return '0'
        }
      }
    } else {
      if (!/^\d+$/.test(money)) {
        return '0'
      }
    }

    if (typeof money.split(decimalSep)[1] == 'undefined') {
      money += `${decimalSep}00`
    } else {
      if (money.split(decimalSep)[1].length === 1) {
        money += '0'
        money = money.replace('.', decimalSep)
      } else {
        if (money.split(decimalSep)[1].length === 0) {
          money += '00'
          money = money.replace('.', decimalSep)
        } else {
          money = money.replace('.', decimalSep)
        }
      }
    }

    // Add currency group and decimal separators from the user's config
    const lg = money.length
    let count = 0
    for (let i = 0; i < lg && lg > 6; i++) {
      count++
      const item = money.charAt(i)
      if (item == decimalSep) {
        break
      }
      if (count == 3) {
        count = 0
        money = money.slice(0, -(i - 1) - 5) + groupSep + money.slice(-(i - 1) - 5, money.length)
        i++
      }
      if (money.charAt(0) == groupSep) {
        money = money.slice(1)
      }
    }
    if (
      money.charAt(money.length - 1) == '0' &&
      startMoneyValue.charAt(startMoneyValue.length - 1) != '0'
    ) {
      money = money.slice(0, -1)
      if (money.charAt(money.length - 1) == '0') {
        money = money.slice(0, -2)
      }
    }

    if (startMoneyValue.includes(decimalSep)) {
      if (
        startMoneyValue.split(decimalSep)[1].length === 1 &&
        startMoneyValue.charAt(startMoneyValue.length - 1) == '0'
      ) {
        money = money.slice(0, -1)
      }
      if (startMoneyValue.split(decimalSep)[1].length === 0) {
        money += '.'
      }
    } else {
      if (startMoneyValue.charAt(startMoneyValue.length - 1) == '0') {
        money = money.slice(0, -3)
      }
    }

    // wrapping
    if (wrap) {
      if (!startMoneyValue.includes(decimalSep)) {
        money += decimalSep + '00'
      } else {
        const lengthDiff = startMoneyValue.split(decimalSep)[1].length
        if (wrap && lengthDiff > 0) {
          if (lengthDiff === 1) {
            money += '0'
          } else {
            if (lengthDiff === 0) {
              money += decimalSep + '00'
            }
          }
        }
      }
    }
    return money
  }*/

  const calcTotal = (newState?: any) => {
    const baseState = newState ? newState : initialState
    let betTotal = 0,
      possibleWin = 0
    for (const stake of baseState.stakes) {
      if (typeof stake.st !== 'undefined' && stake.st != 0 && stake.st !== '' && stake.st !== ' ') {
        betTotal += Number(stake.st)
        possibleWin += Number(stake.st) * parseFloat(modifyBets(stake.od))
      }
    }
    const multist = baseState.multistakes.dm
    if (typeof multist?.st !== 'undefined' && multist?.st !== 0 && multist?.st !== '') {
      betTotal += multist.st
      possibleWin += multist.st * parseFloat(modifyBets(multist.od, '2'))
    }
    if (baseState.multistakes?.mo) {
      for (const mo of baseState.multistakes.mo) {
        if (mo.bt !== -1 && typeof mo.st !== 'undefined' && mo.st !== 0 && mo.st !== '') {
          betTotal += multist.st * parseFloat(multist.bc)
          possibleWin += multist.st * parseFloat(multist.bc)
        }
      }
    }
    setState({
      ...baseState,
      betTotal: typeof betTotal !== 'number' ? 0 : betTotal.toFixed(2),
      possibleWin: typeof possibleWin !== 'number' ? 0 : possibleWin.toFixed(2),
    })
  }

  const onInputChangeHandler = (event, keyParams, providedVal, qb = false) => {
    const val = providedVal ? providedVal : event.target.value
    const key = keyParams ? keyParams : event?.target.dataset.key
    let idx = 0
    state = { ...initialState }
    state.rememberStake.st = isNaN(getFloat(val)) ? 0 : getFloat(val)
    state.rememberStake.stake = floatToCurrency(val, currencyConfig, false)
    // quickbet input
    if (key === 'quickbet') {
      state.quickbet.st = isNaN(getFloat(val)) ? 0 : getFloat(val)
      state.quickbet.stake = floatToCurrency(val, currencyConfig, false)
      setStakeToSessionStorage(key, isNaN(getFloat(val)) ? 0 : getFloat(val), state)
    } else {
      // stake inputs
      if (key.length > 3) {
        for (const stake of state.stakes) {
          if (stake.pt[0].pi == key) {
            state.stakes[idx].stake = floatToCurrency(val, currencyConfig, false)
            console.log(`val`, val)
            console.log(`getFloat`, getFloat(val))
            state.stakes[idx].st = isNaN(getFloat(val)) ? 0 : getFloat(val)
            setStakeToSessionStorage(key, isNaN(getFloat(val)) ? 0 : getFloat(val), state)
          }
          idx++
        }
      } else {
        // multistake dm input
        if (state.multistakes.dm.bt == key) {
          state.multistakes.dm.stake = floatToCurrency(val, currencyConfig, false)
          state.multistakes.dm.st = isNaN(getFloat(val)) ? 0 : getFloat(val)
          setStakeToSessionStorage(key, isNaN(getFloat(val)) ? 0 : getFloat(val), state)
        } else {
          // multistake mo input
          for (const multistake of state.multistakes.mo) {
            if (multistake.bt == key) {
              if (key == '-1') {
                const stake = floatToCurrency(val, currencyConfig, false),
                  st = isNaN(getFloat(val)) ? 0 : getFloat(val)
                state.stakes?.forEach((s, idx) => {
                  state.stakes[idx].stake = stake
                  state.stakes[idx].st = st
                  setStakeToSessionStorage(state.stakes[idx].pt[0].pi.toString(), st, state)
                })
                state.multistakes.mo[0].stake = stake
                state.multistakes.mo[0].st = st
              }
              state.multistakes.mo[idx].stake = floatToCurrency(val, currencyConfig, false)
              state.multistakes.mo[idx].st = isNaN(getFloat(val)) ? 0 : getFloat(val)
              setStakeToSessionStorage(key, isNaN(getFloat(val)) ? 0 : getFloat(val), state)
            }
            idx++
          }
        }
      }
    }
    setState(state)
    calcTotal()
  }

  const onFocusHandler = (event) => {
    event.currentTarget.select()
    const val = event.target.value
    const key = event.target.dataset.key
    const state = { ...initialState }
    if (val == '0' || val == '') {
      event.target.placeholder = ''
    }
    if (state.multistakes.mo[0]) {
      if (key.length > 3) {
        state.multistakes.mo[0].st = 0
        state.multistakes.mo[0].stake = ''
      } else {
        if (key === '-1') {
          state.multistakes.mo[0].st = 0
          state.multistakes.mo[0].stake = ''
        }
      }
    }
    setState(state)
  }

  const onInputBlur = (event) => {
    const val = event.target.value
    let key = event.target.dataset.key,
      idx = 0
    state = { ...initialState }
    state.rememberStake.st = isNaN(getFloat(val)) ? 0 : getFloat(val)
    state.rememberStake.stake = floatToCurrency(val, currencyConfig, false)
    if (event.target.value == '0' || event.target.value == '') {
      event.target.placeholder = '0'
    }
    // quickbet input
    if (key == 'quickbet') {
      state.quickbet.st = isNaN(getFloat(val)) ? 0 : getFloat(val)
      state.quickbet.stake = floatToCurrency(val, currencyConfig, true)
      setStakeToSessionStorage(key, isNaN(getFloat(val)) ? 0 : getFloat(val), state)
    } else {
      // stake inputs
      if (key.length > 3) {
        for (const stake of state.stakes) {
          if (stake.pt[0].pi == key) {
            state.stakes[idx].stake = floatToCurrency(val, currencyConfig, true)
            state.stakes[idx].st = isNaN(getFloat(val)) ? 0 : getFloat(val)
            setStakeToSessionStorage(key, isNaN(getFloat(val)) ? 0 : getFloat(val), state)
          }
          idx++
        }
      } else {
        // multistake dm input
        if (state.multistakes.dm.bt == key) {
          state.multistakes.dm.stake = floatToCurrency(val, currencyConfig, true)
          state.multistakes.dm.st = isNaN(getFloat(val)) ? 0 : getFloat(val)
          setStakeToSessionStorage(key, isNaN(getFloat(val)) ? 0 : getFloat(val), state)
        } else {
          // multistake mo input
          for (const multistake of state.multistakes.mo) {
            if (multistake.bt == key) {
              if (false && key == '-1') {
                const stake = floatToCurrency(val, currencyConfig, true),
                  st = isNaN(getFloat(val)) ? 0 : getFloat(val)
                state.stakes?.forEach((s, idx) => {
                  state.stakes[idx].stake = stake
                  state.stakes[idx].st = st
                  setStakeToSessionStorage(state.stakes[idx].pt[0].pi.toString(), st, state)
                })
                state.multistakes.mo[0].stake = stake
                state.multistakes.mo[0].st = st
              } else {
                state.multistakes.mo[idx].stake = floatToCurrency(val, currencyConfig, true)
                state.multistakes.mo[idx].st = isNaN(getFloat(val)) ? 0 : getFloat(val)
                setStakeToSessionStorage(key, isNaN(getFloat(val)) ? 0 : getFloat(val), state)
              }
            }
            idx++
          }
        }
      }
    }
    setState(state)
    calcTotal()
  }

  const removeBetHandler = (id) => {
    return () => {
      setState({
        ...initialState,
        loading: true,
      })
      if (initialState.stakes.length < 2) {
        setState({
          ...initialState,
          settingsMode: false,
        })
      }
      dispatch(removeStake(id))
    }
  }

  const removeAllBetsHandler = () => {
    dispatch(removeAll())
    setState({
      ...initialState,
      loading: false,
      placedSuccess: false,
      stakes: [],
      multistakes: [],
      placedBt: [],
    })
    setTimeout(() => dispatch(enableOddsUpdates()), 250)
  }

  const onQuickbetBtnHandler = (event) => {
    let target = event.target
    if (target.tagName == 'SPAN') {
      target = target.parentNode
    }
    const val = target.innerHTML
    const stakeFloat =
      typeof state.quickbet.st !== 'undefined' ? getFloat(state.quickbet.st) : false
    let quickbetST = stakeFloat || 0
    if (val.match(/\+(.*)</gi) == null) {
      setStakeToSessionStorage('quickbet', 0, initialState)
      setState({
        ...initialState,
        quickbet: {
          st: 0,
          stake: 0,
        },
      })
    } else {
      quickbetST += parseInt(val.match(/\+(.*)</gi)[0].slice(1, -1))
      setStakeToSessionStorage(
        'quickbet',
        isNaN(getFloat(quickbetST)) ? 0 : getFloat(quickbetST),
        initialState
      )
      setState({
        ...initialState,
        quickbet: {
          st: quickbetST,
          stake: floatToCurrency(quickbetST.toString(), currencyConfig, true),
        },
      })
    }
  }

  const setStakeToSessionStorage = (ID, ST, state) => {
    if (isNaN(ST)) {
      ST = 0
    }
    if (ID === 'quickbet') {
      sessionStorage.setItem('quickbet', JSON.stringify(ST))
      return
    }
    const stakes = JSON.parse(sessionStorage.getItem('ns') || '{}')
    let multistakes = sessionStorage.getItem('ms') || ''
    let i = 0
    // stake inputs
    if (ID.length > 3) {
      let newStake = stakes[ID]?.slice(0, -2)
      for (const stake of state.stakes) {
        if (stake.pt[0].pi == ID) {
          if (/tr=/i.test(newStake)) {
            newStake = newStake.replace(/st=(.*)#tr=/i, 'st=' + ST + '#tr=')
            newStake = newStake.replace(
              /tr=(.*)#ust=/i,
              //@ts-ignore
              'tr=' + (modifyBets(state.stakes[i].od, '2') * ST).toFixed(3).toString() + '#ust='
            )
            newStake = newStake.replace(/ust=(.*)/i, 'ust=' + ST + '||')
          } else {
            newStake +=
              '#st=' +
              ST.toString() +
              '#tr=' +
              //@ts-ignore
              (modifyBets(state.stakes[i].od) * ST).toFixed(3).toString() +
              '#ust=' +
              ST +
              '||'
          }
        }
        i++
      }
      stakes[ID] = newStake
    } else {
      const multi = multistakes.split('||')
      // multistake dm input
      if (state.multistakes.dm.bt == ID) {
        let dm = multi[0]
        if (/st=/i.test(multi[0])) {
          dm = dm.replace(/ust=(.*)#st/i, 'ust=' + ST + '#st')
          multistakes = dm.slice(0, dm.search(/#st=(.*)#/gi)) + '#st=' + ST + '#'
        } else {
          multistakes = dm.slice(0, 9) + '#ust=' + ST + '#st=' + ST + '#'
        }
        multi?.forEach((mult, idx) => {
          if (idx > 0) {
            multistakes += '||' + mult
          }
        })
      } else {
        // multistake mo input
        i++
        for (const stake of state.multistakes.mo) {
          if (stake.bt == ID) {
            if (multi[i].includes('st')) {
              multi[i] = multi[i].slice(0, multi[i].search(/#st=/gi)) + '#st=' + ST + '#ust=' + ST
            } else {
              multi[i] += 'st=' + ST + '#ust=' + ST
            }
            multistakes = ''
            multi?.forEach((mult) => {
              if (mult) multistakes += mult + '||'
            })
          }
          i++
        }
      }
    }
    sessionStorage.setItem('ns', JSON.stringify(stakes))
    sessionStorage.setItem('ms', multistakes)
  }

  const onAcceptHandler = (event) => {
    sessionStorage.setItem('acceptAll', event.target.checked)
    setState({
      ...initialState,
      changes: { ...initialState.changes, checkbox: event.target.checked },
    })
    if (!initialState.changes.accept) {
      dispatch(acceptChanges())
    }
  }

  const onAcceptChangesHandler = () => {
    setState({
      ...initialState,
      loading: true,
    })
    dispatch(acceptChanges())
  }

  // const placeBetHandler = () => {
  // 	if (ACCOUNT > initialState.betTotal) {
  // 		setState({
  // 			...initialState,
  // 			placedSuccess: true,
  // 		})
  // 		dispatch(removeAll())
  // 	} else {
  // 		setState({
  // 			...initialState,
  // 			notEnoughFunds: true,
  // 		})
  // 	}
  // }

  const toggleSettingsHandler = () => {
    setState({
      ...initialState,
      settingsMode: !initialState.settingsMode,
    })
  }

  const toggleRememberStakeHandler = () => {
    const rememberStake = initialState.rememberStake
    sessionStorage.setItem('rememberStake', rememberStake.enabled ? 'true' : 'false')
    sessionStorage.setItem('rememberStakeST', rememberStake.st)
    sessionStorage.setItem('rememberStakeSTAKE', rememberStake.stake)
    setState({
      ...initialState,
      rememberStake: {
        ...initialState.rememberStake,
        enabled: rememberStake.enabled ? false : true,
      },
    })
  }

  const toggleQuickbetModeHandler = (toggleQuickbet) => {
    console.log(`initialState.quickbetMode`, initialState.quickbetMode)
    sessionStorage.setItem('quickbetMode', !initialState.quickbetMode ? 'false' : 'true')
    dispatch(setQuickbet(!initialState.quickbetMode))
    dispatch(removeAll())
  }

  const toggleAcceptAllOddsChangesHandler = () => {
    const acceptAllOddsChanges = initialState.acceptAllOddsChanges
    console.log(`acceptAllOddsChanges`, acceptAllOddsChanges)
    sessionStorage.setItem('acceptAll', acceptAllOddsChanges ? 'false' : 'true')
    setState({
      ...initialState,
      acceptAllOddsChanges: !acceptAllOddsChanges,
    })
  }

  const placeBetHandler = (logged_in, account) => {
    if (parseFloat(account) >= initialState.betTotal && initialState.betTotal > 0) {
      setState({
        ...initialState,
        editMode: false,
        loading: true,
      })
      dispatch(disableOddsUpdates())
      dispatch(placeBet(false))
    } else {
      if (logged_in) {
        setState({
          ...initialState,
          notEnoughFunds: true,
        })
      } else {
        setState({
          ...initialState,
          notLoggedIn: true,
        })
      }
    }
  }

  const popupAccept = (callback) => {
    if (state.notLoggedIn) {
      if (typeof callback === 'function') {
        callback()
      }
    }
    setState({
      ...initialState,
      placedSuccess: false,
      notEnoughFunds: false,
      notLoggedIn: false,
    })
  }

  return {
    calcTotal,
    floatToCurrency,
    toggleSettingsHandler,
    toggleAcceptAllOddsChangesHandler,
    toggleQuickbetModeHandler,
    toggleRememberStakeHandler,
    onAcceptChangesHandler,
    onInputChangeHandler,
    onFocusHandler,
    onInputBlur,
    removeBetHandler,
    removeAllBetsHandler,
    onQuickbetBtnHandler,
    onAcceptHandler,
    placeBetHandler,
    popupAccept,
  }
}
