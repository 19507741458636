import { IRootState } from '../reducers/rootReducer'
import {
  ICompetitors,
  IMatch,
  ITournament,
  Nullable,
  Obj,
  Odd,
} from '../../helpers/commonInterfaces/interfaces'

export const getPrematchEvent = (state: IRootState): Nullable<IMatch | ITournament> => {
  return state.prematchEvent.event
}

// export const getFirstEventId = (
// 	events: Nullable<Array<IMatch | ITournament>>
// ): number => {
// 	return events?.[0].id || 0
// }

export const getPrematchLeagueEvents = (
  state: IRootState
): Nullable<Array<IMatch | ITournament>> => {
  return state.prematchEvent?.leagueEvents
}

export const getPrematchMarkets = (state: IRootState): Nullable<Obj<Odd>> => {
  return state.prematchEvent.odds
}

export const getPrematchCompetitors = (state: IRootState): ICompetitors[] => {
  if (state.prematchEvent.event?.eventType === 'Match') {
    return state.prematchEvent.event.competitors
  }
  return []
}
