import React from 'react'
import Main from '../../components/containers/main/Main'
import Navigate from '../../components/common/routing/Navigate'
import AsideLeftContext from '../../context/asideLeftContext'
import AsideLeft from '../../components/containers/main/AsideLeft'
import PrematchClassification from '../../components/forAsideLeft/prematchClassification/PrematchClassification'
import AsideRightContext from '../../context/asideRightContext'
import AsideRight from '../../components/containers/main/AsideRight'
import Content from '../../components/containers/main/Content'
import { sportsRoutes } from '../../routing/sportsRoutes'

const Sports = () => {
  return (
    <Navigate routes={sportsRoutes} />
  )
}

export default Sports
