import { types } from '../types/types'
import { iconsWorkersTypes } from '../actions/iconsActions'

const lS = localStorage.getItem('icons')

const initialState = {
  icons: JSON.parse(localStorage.getItem('icons') ?? '[]') ?? [],
}

export type iconsState = typeof initialState

export const icons = (state: iconsState = initialState, action: iconsWorkersTypes): iconsState => {
  switch (action.type) {
    case types.ADD_ICONS:
      localStorage.setItem('icons', JSON.stringify(action.payload))
      return {
        ...state,
        icons: [...state.icons, ...action.payload],
      }
    default:
      return state
  }
}
