import React from 'react'
import { useSelector } from 'react-redux'
import { getConfig, getOddsType } from 'redux/selectors/configSelectors'
import { floatToCurrency } from '../../../../../../../../helpers/floatToCurrency'
import { modifyBets } from '../../../../../../../../helpers/modifyBets'

export const BAcceptBet = ({ item }) => {
  const ODDS_TYPE = useSelector(getOddsType)
  const { CUSTOMER_CONFIG } = useSelector(getConfig)
  const toReturn = (parseFloat(item.st) * parseFloat(modifyBets(item.od, '2'))).toFixed(2)
  const transformBetName = (name) => {
    if (name) {
      name = name.replace(/{\$competitor1}/gi, item.fd.split(' v ')[0])
      name = name.replace(/{\$competitor2}/gi, item.fd.split(' v ')[1])
      name = name.replace(/{\+hcp}/gi, item.pt[0].ha)
      name = name.replace(/{-hcp}/gi, item.pt[0].ha)
      name = name.replace(/{total}/gi, item.pt[0].ha)
      name = name.replace(/{!goalnr}/gi, item.pt[0].ha)
    }
    return name
  }
  return (
    <div className="b-accept__bet">
      <div className="b-accept__wrap">
        <div className="b-accept__cell">
          <p className="b-accept__title">{transformBetName(item.pt[0].bd)}</p>
          <p className="b-accept__market b-accept__market--blue">
            {transformBetName(item.pt[0].md)}
          </p>
          <p className="b-accept__teams">{item.fd}</p>
        </div>
        <div className="b-accept__cell b-accept__cell--sm">
          <p className="b-accept__odds">
            {modifyBets(item.od, ODDS_TYPE) === '' || modifyBets(item.od, ODDS_TYPE) === 'NaN'
              ? ''
              : modifyBets(item.od, ODDS_TYPE)}
          </p>
          <input
            className="b-accept__field"
            type="number"
            placeholder="Stake"
            value={floatToCurrency(item.st, CUSTOMER_CONFIG.currencyConfig, true)}
            disabled
          />
        </div>
      </div>
      {Number(toReturn) > 0 && (<div className="b-accept__bottom">
        <p className="b-accept__label">Possible win:</p>
        <p
          className="b-accept__label"
          dangerouslySetInnerHTML={{
            __html: floatToCurrency(toReturn, CUSTOMER_CONFIG.currencyConfig, true, true),
          }}
        />
      </div>)}
    </div>
  )
}
