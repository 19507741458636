import React from 'react'

const LvTranslationLink = ({ classes, children, ...rest }) => {
  return (
    <a className={classes} {...rest}>
      {children}
    </a>
  )
}

export default LvTranslationLink
