import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccount } from 'redux/selectors/accountSelectors'
import { setMessages } from '../../../redux/actions/accountActions'
import Message from './Message'

const Sent = () => {
  const { sent } = useSelector(getAccount)
  const dispatch = useDispatch()
  const toggleCheckedHandler = (id) => {
    return () => {
      dispatch(
        setMessages(
          sent?.map((item, i) => {
            if (i === id) {
              item.checked = !item.checked
            }
            return item
          }),
          'sent'
        )
      )
    }
  }
  const [selectAll, setSelectAll] = React.useState(false)
  // delete selected messages
  const deleteHandler = () => {
    setSelectAll(false)
    dispatch(
      setMessages(
        sent.filter((item) => {
          return !item.checked
        }),
        'sent'
      )
    )
  }
  // set selected messages as read
  const readHandler = () => {
    setSelectAll(false)
    dispatch(
      setMessages(
        sent?.map((item) => {
          if (item.checked) {
            item.isNew = false
          }
          return item
        }),
        'sent'
      )
    )
  }
  // select and unselect all messages checkbox handler
  const toggleSelectAllHandler = () => {
    setSelectAll(!selectAll)
    dispatch(
      setMessages(
        sent?.map((item) => {
          item.checked = !selectAll
          return item
        }),
        'sent'
      )
    )
  }

  return (
    <div className="container">
      <div className="container__head">
        <p className="font font--semibold">Inbox</p>
      </div>
      <div className="container__wrap">
        <div className="container__header">
          <div className="flex align-middle action">
            <input
              className="checkbox"
              type="checkbox"
              checked={selectAll}
              onChange={toggleSelectAllHandler}
            />
            <div
              className="flex align-middle action action__item action__item--delete"
              onClick={deleteHandler}
            >
              <i className="fal fa-trash-alt m-r-5"></i>
              <p className="font font--grey-500">Delete</p>
            </div>
            <div
              className="flex align-middle action action__item action__item--delete"
              onClick={readHandler}
            >
              <i className="fal fa-envelope-open m-r-5"></i>
              <p className="font font--grey-500">Read</p>
            </div>
          </div>
        </div>
        <div className="message">
          {sent?.map((item, id) => {
            return <Message key={id} {...item} change={toggleCheckedHandler(id)} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Sent
