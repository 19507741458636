import React from 'react'

const ScoreboardTable = ({ children, className = '' }) => {
  return (
    <div className="ip-scoreboard__table">
      <div className={`table ${className}`}>{children}</div>
    </div>
  )
}

export default React.memo(ScoreboardTable)
