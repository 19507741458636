import React from 'react'

const TitleCell: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="gll-market-group__cell">
      <div className="gll-market-group__coff">
        <span className="font font--grey-300 text-ellipsis">{title}</span>
      </div>
    </div>
  )
}

export default TitleCell
