import { IRootState } from '../reducers/rootReducer'
import { ICategory, ISport } from '../../helpers/commonInterfaces/interfaces'

export const getAllSports = (state: IRootState): ISport[] | null => {
  return state?.prematch?.data || null
}

export const getCurrentSport = (state: ISport[], id: string): ISport | null => {
  return state.find((sport) => sport.id === Number(id)) || null
}

export const getCurrent = (state: IRootState): ISport | null => {
  return state.prematch.currentSport
}

export const getAllCategories = (state: ISport): ICategory[] => {
  return state.categories
}
