import React from 'react'
import Navigate from '../../common/routing/Navigate'
import { balanceRouting } from '../../../routing/accountRouting/balanceRouting'

const Balance = () => {
	return (
		<div className="container">
			<Navigate routes={balanceRouting} />
		</div>
	)
}

export default Balance
