import React, { useState } from 'react'
import { useRegistration } from '../hooks/useRegistration'

type ILostCredentials = {
  show: boolean
  type: string
}

interface RegContextTypes extends ReturnType<typeof useRegistration> {
  loginForm: boolean
  registration: boolean
  lostCredentials: ILostCredentials
  enterCode: boolean
  codeSent: boolean
  username: string
  registrationData: () => any
  setUsername: (username: string) => any
  showLostCredentialsForm: (type: string) => void
  showForm: () => void
  showRegistration: () => void
  showEnterCode: () => void
  showCodeSent: () => void
  setLostCredentials: (params: ILostCredentials) => void
}

export const RegisterContext = React.createContext<Partial<RegContextTypes>>({})

const RegistrationContext: React.FC = ({ children }) => {
  const [loginForm, setLoginForm] = useState(false)
  const [registration, setRegistration] = useState(false)
  const [username, setUsername] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const [enterCode, setEnterCode] = useState(false)
  const [lostCredentials, setLostCredentials] = useState<ILostCredentials>({
    show: false,
    type: '',
  })

  const registrationData = useRegistration()

  const showLostCredentialsForm = (type = '') => {
    setLostCredentials({
      show: !lostCredentials.show,
      type,
    })
  }

  const showForm = () => setLoginForm(!loginForm)

  const showRegistration = () => setRegistration(!registration)

  const showCodeSent = () => setCodeSent(!codeSent)

  const showEnterCode = () => setEnterCode(!enterCode)

  return (
    <RegisterContext.Provider
      value={{
        username,
        setUsername,
        showForm,
        loginForm,
        showLostCredentialsForm,
        showRegistration,
        showEnterCode,
        showCodeSent,
        enterCode,
        codeSent,
        registration,
        lostCredentials,
        setLostCredentials,
        ...registrationData,
      }}
    >
      {children}
    </RegisterContext.Provider>
  )
}

export default RegistrationContext
