import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDay, setSport } from '../../../redux/actions/scheduleActions'
import ScheduleTableWrapper from './scheduleTableWrapper'
import ClassificationBar from '../overview/clasificationBar/ClassificationBar'
import { loadScheduleData } from '../../../redux/actions/scheduleActions'
// import $ from 'jquery'
import ControlBar from './controlBar'
import { useScrollLoading } from '../../../hooks/useScrollLoading'
import { getSchedule } from 'redux/selectors/scheduleSelectors'

const ScheduleWrapper = () => {
  const dispatch = useDispatch()

  const refContainer = React.useRef(null)

  const { sports, days, currentView, activeSport, activeDay, init } = useSelector(getSchedule)

  const { data, onScrollHandler } = useScrollLoading({
    defaultLength: 50,
    defaultData: currentView,
    selector: '.ip-sports',
  })

  // useLayoutEffect(() => {
  // 	$(refContainer.current).niceScroll({
  // 		cursorcolor: '#2F3E46',
  // 		cursorborder: '0px solid #1A1EA0',
  // 		cursorborderradius: '0px',
  // 		cursorwidth: '5px',
  // 		iframeautoresize: true,
  // 		mousescrollstep: 60,
  // 		smoothscroll: true,
  // 		hwacceleration: true,
  // 		bouncescroll: true,
  // 	})
  // }, [currentView])

  useEffect(() => {
    dispatch(loadScheduleData())
  }, [])

  return (
    <div ref={refContainer} className="height-scroll" onScroll={onScrollHandler}>
      <div className="ip-sports center">
        <ClassificationBar
          active={activeSport}
          data={Object.values(sports)}
          setView={({ name, id }) => {
            dispatch(setSport(name, id))
          }}
        />
        <ControlBar
          data={days}
          setDay={(day) => {
            dispatch(setDay(day))
          }}
          active={activeDay}
        />
        <ScheduleTableWrapper {...{ init }} data={data} />
      </div>
    </div>
  )
}

export default ScheduleWrapper
