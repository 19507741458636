import React, { useEffect, useRef } from 'react'
import ClassificationBarItem from './ClassificationBarItem'
import { useSlider } from '../../../../hooks/useSlider'
import Button from '../../../common/buttons/Button'

import '../../../../assets/styles/classificationBar.css'

const ClassificationBar = ({ setView, data, active }) => {
  const sliderRef: React.RefObject<HTMLDivElement> = useRef(null)

  if (data[0]?.ID) {
    data = data?.map((sport) => {
      sport.id = sport.ID
      sport.name = sport.NA
      return sport
    })
  }

  data.forEach(sport => {
    if (sport.priority === 0) {
      sport.priority = 200
    }
  })

  data.sort((a, b) => a.priority - b.priority)

  const { setSliderData, showButtons, toNextSlide, toPrevSlide, transform, max, min } = useSlider()

  useEffect(() => {
    setSliderData(sliderRef)
  }, [data])

  return (
    <>
      <div className="ip-classification-bar-other relative">
        {showButtons && !min && (
          <Button onClick={toPrevSlide} className="fa ip-classification-prev" />
        )}
        <div
          ref={sliderRef}
          className="flex align-middle full-height ip-classification-bar__wrap"
          style={{
            transform: `translate(-${transform}px, 0px)`,
          }}
        >
          {data?.map(({ id, name }) => (
            <ClassificationBarItem
              onClick={() => setView({ id, name })}
              key={id}
              className={active === id ? 'ip-classification-bar__item--active' : ''}
              item={{
                name,
                id,
              }}
            />
          ))}
        </div>
        {showButtons && !max && (
          <Button onClick={toNextSlide} className="fa ip-classification-next" />
        )}
      </div>
    </>
  )
}

export default React.memo(ClassificationBar)
