import React from 'react';

const TableTitle = () => {
  return (
    <tr className="cb-table__tr cb-table__tr--title">
      <th className="cb-table__th text-left"></th>
      <th className="cb-table__th text-left"></th>
      <th className="cb-table__th text-left">
        <p className="font font--10 font--grey-500">Min</p>
      </th>
      <th className="cb-table__th text-left">
        <p className="font font--10 font--grey-500">Max</p>
      </th>
      <th className="cb-table__th text-left">
        <p className="font font--10 font--grey-500">Сomission</p>
      </th>
      <th className="cb-table__th text-left">
        <p className="font font--10 font--grey-500">In processing</p>
      </th>
      <th className="cb-table__th text-left"></th>
    </tr>
  );
};

export default TableTitle;