import React from 'react'
import Text from './Text'

const ErrorPopup = () => {
  return (
    <div
      className="flex align-center-middle mn-popup__block relative"
      style={{
        height: '400px',
      }}
    >
      <div
        className="mn-popup mn-popup--active flex align-center-middle"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#0c1022',
        }}
      >
        <Text
          title="Sorry, match end"
          className="font font--16 font--semibold m-b-20"
          style={{
            color: '#fff',
          }}
        />
      </div>
    </div>
  )
}

export default ErrorPopup
