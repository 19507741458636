import React, { ReactElement } from 'react'
import MarketRow from '../MarketRow'
import MarketColumn from '../MarketColumn'
import TitleCell from '../Cells/TitleCell'
import BetCellWithoutTitle from '../Cells/BetCellWithoutTitle'
import {
  IOutcome,
  OddOutcome,
} from '../../../../../../helpers/commonInterfaces/interfaces'
import { useSelector } from 'react-redux'
import { getBetslip } from '../../../../../../redux/selectors/betslipSelectors'
import { getOddsType } from '../../../../../../redux/selectors/configSelectors'
import ColumnTitle from '../ColumnTitle'
import BetButton from 'components/common/buttons/BetButton'

interface IAlternativeBody {
  outcomes: Array<OddOutcome>
  sportId: number
  eventId: number
  createBet: (
    eventId: number,
    id: number,
    od: number,
    ha: string,
    check: boolean,
    qb: boolean
  ) => void
}
const AlternativeBody: React.FC<IAlternativeBody> = ({
  outcomes,
  eventId,
  sportId,
  createBet,
}) => {
  const { quickBet, bt } = useSelector(getBetslip)
  const ODDS_TYPE = useSelector(getOddsType)

  const over: Array<ReactElement> = []
  const under: Array<ReactElement> = []
  const outcomeRows: Array<OddOutcome> = []

  outcomes.forEach(outcome => {
    const cloneOutcome = { ...outcome }
    if (cloneOutcome.outcome.includes('Over')) {
      cloneOutcome.outcome = cloneOutcome.outcome.replace('Over ', '')
      over.push(
        <BetButton
          item={cloneOutcome}
          key={cloneOutcome.it}
          heightNotFixed={true}
          onCreateBet={createBet.bind(null, eventId)}
          panel={quickBet}
          allStakes={bt}
          oddsType={ODDS_TYPE}
          isCurScore={true}
        />
      )
      outcomeRows.push(cloneOutcome)
    } else {
      cloneOutcome.outcome = outcome.outcome.replace('Under ', '')
      under.push(
        <BetButton
          item={cloneOutcome}
          key={cloneOutcome.it}
          heightNotFixed={true}
          onCreateBet={createBet.bind(null, eventId)}
          panel={quickBet}
          allStakes={bt}
          oddsType={ODDS_TYPE}
          isCurScore={true}
        />
      )
    }
  })

  return (
    <div className="gll-market-group__body">
      <MarketRow key="0" isMulti={false}>
        <ColumnTitle title={''} style={{ flex: '33.33%' }} />
        <ColumnTitle title={'Over'} style={{ flex: '33.33%' }} />
        <ColumnTitle title={'Under'} style={{ flex: '33.33%' }} />
      </MarketRow>
      {outcomeRows.map((item, idx) => (
        <MarketRow key={item.id + item.outcomeId} isMulti={false}>
          <div
            style={{
              flex: '1 1 33.33%',
            }}>
            <TitleCell
              title={item.outcome
                .replace('over', 'Over')
                .replace('under', 'Under')
                .replace('Draw', 'X')}
            />
          </div>
          <div
            style={{
              flex: '1 1 33.33%',
            }}>
            <div className="gll-market-group__cell">{over[idx]}</div>
          </div>
          <div
            style={{
              flex: '1 1 33.33%',
            }}>
            <div className="gll-market-group__cell">{under[idx]}</div>
          </div>
        </MarketRow>
      ))}
    </div>
  )
}

export default React.memo(AlternativeBody)
