import React from 'react'

const Message = ({ checked, isNew, header, short, date, change }) => {
	return (
		<span
			className={
				'message__item' +
				(checked
					? ' message__item--checked'
					: '' + (isNew ? ' message__item--new' : ''))
			}>
			<div>
				<input
					className="checkbox"
					type="checkbox"
					checked={checked}
					onChange={change}
				/>
			</div>
			<div>
				<p className="font font--grey-500 text-ellipsis">{header}</p>
			</div>
			<div>
				<p className="font font--grey-500 text-ellipsis">{short}</p>
			</div>
			<div>
				<p className="font font--h-11 font--grey-500 text-ellipsis">{date}</p>
			</div>
		</span>
	)
}

export default Message
