import React, { useState } from 'react'
import { useStorage } from '../../../../../hooks/useStorage'
import VolumeDropDown from './VolumeDropDown'

const Volume = ({ soundRangeValue, setSoundRangeValue }) => {
  const { getSession, setSession } = useStorage()

  const defaultState = {
    showBody: true,
    volume: false,
    soundRangeValue: soundRangeValue,
    soundEffectsValue: true,
  }

  const volumeStorageData = getSession('volumeSettings') || defaultState

  const [state, setState] = useState(volumeStorageData)

  const rangeHandler = (e) => {
    const value = e.currentTarget.value

    const newState = {
      ...state,
      soundRangeValue: value,
    }

    setState(newState)
    setSoundRangeValue(value)
    setSession('volumeSettings', newState)
  }

  const toggleHandler = (name) => {
    const newState = {
      ...state,
      [name]: !state[name],
    }

    setState(newState)
    setSession('volumeSettings', newState)
  }

  return (
    <a
      className={
        state.volume ? 'volume--active lv-translation__icon' : 'volume lv-translation__icon'
      }
    >
      <i
        className="fas fa-volume icon full-width full-height flex align-center-middle"
        onClick={() => toggleHandler('volume')}
      />

      {state.volume && (
        <VolumeDropDown {...{ rangeHandler }} {...{ toggleHandler }} {...{ state }} />
      )}
    </a>
  )
}

export default Volume
