import React, { useState, useContext, useEffect, useRef } from 'react'
import { Form, Formik, useFormik } from 'formik'
import Input from '../../common/elements/Input'
import * as Yup from 'yup'
import { RegisterContext } from '../../../context/registrationContext'
import { useRequest } from '../../../hooks/useRequest'
import { links } from '../../../connection/links'
import Overlay from '../../registration/overlay'
import Banner from '../../../assets/img/banner.png'
import Wrap from '../../registration/elements/wrap'
import Text from '../../common/elements/Text'
import InputWrap from '../../registration/elements/inputWrap'
import InputCheckbox from '../../registration/elements/inputCheckbox'
import { useTranslation } from 'react-i18next'
import registrationTemplate from '../../../assets/json/registration.json'
import { post } from 'helpers/asyncRequests'

type ICreateResult = {
  CREATE: {
    INFO?: string
    SESSION_ID: string
    SUCCESS: boolean
  }
}
export const asyncRegisterRequest = async (data) => {
  try {
    registrationTemplate.Details.Login = data.username
    registrationTemplate.Details.Password = data.password
    registrationTemplate.Confirmation.LegalAge = data.age
    registrationTemplate.Confirmation.newsletter = data.newsletter
    const hid = sessionStorage.getItem('fingerprint') ?? ''
    registrationTemplate.hid = hid
    return await post(links.registration, registrationTemplate)()
  } catch (e) {
    console.log(e)
    return new Error('Registration Failed')
  }
}

const LoginForm = ({ active }) => {
  const { t } = useTranslation()
  const { post } = useRequest()
  const ref = useRef(null)
  const { showForm, showRegistration, showCodeSent, showLostCredentialsForm } =
    useContext(RegisterContext)
  const [hover, setHover] = useState(false)
  const [state, setState] = useState({
    showPassword: false,
    error: {
      status: false,
      title: 'Error',
      message: 'Check your internet connection',
    },
    firstLoad: true,
  })

  const [success, setSuccess] = useState(true)

  // closing on overlay click
  const hideLogin = (e) => {
    if (e.target.className === 'overlay overlay--main overlay--block') {
      if (showForm) {
        showForm()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', hideLogin)
    return () => document.removeEventListener('click', hideLogin)
  }, [])

  useEffect(() => {
    setState({ ...state, firstLoad: true })
    const disSlide = setTimeout(() => {
      setState({ ...state, firstLoad: false })
    }, 1000)
    return () => {
      clearTimeout(disSlide)
    }
  }, [active])

  const errorToggleHandler = (message) => {
    setState({
      ...state,
      error: {
        ...state.error,
        status: !state.error.status,
        message: message ? message : state.error.message,
      },
    })
  }

  const showPasswordHandler = () => {
    setState({ ...state, showPassword: !state.showPassword })
  }

  const lowerCaseRegex = /(?=.*[a-z])/
  // const upperCaseRegex = /(?=.*[A-Z])/
  // const numericRegex = /(?=.*[0-9])/

  const LoginSchema = Yup.object().shape({
    username: Yup.string().min(6, 'Too short!').required('Required'),
    password: Yup.string()
      .min(6, 'Too short!')
      .matches(lowerCaseRegex, 'one lowercase required')
      .required('Required'),
  })

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      yearsConfirmation: '',
      newsletter: '',
      isRegistrBtn: false,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        if (hover) {
          if (showForm && showCodeSent) {
            if (!values.yearsConfirmation) {
              errors.yearsConfirmation = 'error'
              touched.yearsConfirmation = true
              errorToggleHandler('Confirm your age')
              return
            }
            const { username, password, yearsConfirmation, newsletter } = values
            // adding fingerprint
            const hid = sessionStorage.getItem('fingerprint') ?? ''
            const regData = {
              username: username.trim(),
              password: password.trim(),
              age: yearsConfirmation,
              newsletter,
              hid,
            }
            const { CREATE }: ICreateResult = await asyncRegisterRequest(regData)
            if (!CREATE.SUCCESS) {
              errorToggleHandler(CREATE.INFO || 'Registration failed :(')
            }
            showForm()
            showCodeSent()
          }
        } else {
          const { LOGIN } = await post(links.login, values)
          /* document.cookie = `SID=${LOGIN.SESSION_ID}; path=/; SameSite=None;` */
          // setCookie('SID', LOGIN.SESSION_ID, 1)
          if (LOGIN.SUCCESS) {
            window.location.pathname = './sports'
            // }
            // if (LOGIN) {
            // 	overlayClick()
            // 	setUserConfig('LOGGED_IN', true)
            // 	localStorage.removeItem('user.config')
            // 	window.location.reload()
          } else {
            console.log('Login failure')
            console.log('error', LOGIN)
            errorToggleHandler(LOGIN.INFO.toString())
            setSuccess(false)
          }
        }
      } catch (e) {
        console.log('error', e)
        errorToggleHandler(e)
      }
    },
  })

  const { values, handleChange, handleSubmit, errors, touched } = formik

  let passwordType = ''
  if (state.showPassword) {
    passwordType = 'text'
  } else passwordType = 'password'

  const [view, setView] = useState(true)
  return (
    <>
      <Overlay node={ref}>
        <div className={view ? 'mn-popup mn-popup--active login-popup' : 'mn-popup'}>
          <div
            className="betslip__close fal fa-times"
            onClick={showForm}
            role="button"
            onKeyPress={() => 0}
            tabIndex={0}
          ></div>

          <div className="flex align-center-middle mn-popup__block">
            <div className="mn-popup__left">
              <img className="mn-popup__banner" src={Banner} alt="" />
              <div className="text-center mn-popup__title">
                <div className="text-center"></div>
                <p className="font font--white">
                  Need some help?
                  <a className="font font--blue m-l-5" href="#">
                    Support
                  </a>
                </p>
              </div>
            </div>
            <div className="mn-popup__right">
              <div className="mn-popup__head">
                <div className="flex align-center-middle">
                  <a className="h-logo"></a>
                </div>
              </div>
              <div className="mn-popup__wrapper">
                <Formik
                  initialValues={formik.initialValues}
                  onSubmit={() => {
                    console.log('Submitted')
                  }}
                >
                  <Form onSubmit={handleSubmit}>
                    <Wrap
                      className={errors.username && touched.username && 'required required--error'}
                    >
                      <Input
                        name="username"
                        label="login"
                        className="field field--white"
                        value={values.username}
                        type="text"
                        placeholder={t('COMMON.LOGIN.PHONE_OR_EMAIL')}
                        onChange={handleChange}
                        autoComplete="on"
                        required
                      />
                    </Wrap>
                    <Wrap
                      className={
                        'big-height ' +
                        (errors.password && touched.password && 'required required--error')
                      }
                    >
                      <Input
                        name="password"
                        label="password"
                        className="field h-popup__field"
                        value={values.password}
                        type={passwordType}
                        placeholder={t('COMMON.LOGIN.PASSWORD_CAP')}
                        onChange={handleChange}
                        autoComplete="on"
                        style={{ color: '#b5b7c6' }}
                        required
                      />
                      <div
                        className={
                          state.showPassword
                            ? 'show-password show-password--active'
                            : 'show-password'
                        }
                        onClick={showPasswordHandler}
                        role="button"
                        onKeyPress={() => 0}
                        tabIndex={0}
                      >
                        <div className="fas fa-eye"></div>
                      </div>
                      <Text
                        title={t('Password must be at least 6 characters long')}
                        className="font font--10 font--white-alpha-05 m-t-5"
                        style={{ color: '#b5b7c6' }}
                      />
                    </Wrap>
                    <div className="mn-popup__wrap">
                      {success == false ? (
                        <p
                          className="error font--15"
                          style={{ color: '#fc3164 ', marginBottom: '10px' }}
                        >
                          Try again
                        </p>
                      ) : (
                        ''
                      )}
                      <button type="submit" className="btn btn--blue btn--h-44">
                        <span className="font font--white">{t('COMMON.LOGIN.ENTER')}</span>
                      </button>

                      <div
                        className="text-center m-t-15"
                        onClick={() => {
                          setView(false)
                          /*showLostCredentialsForm('1')*/
                        }}
                        role="button"
                        onKeyPress={() => 0}
                        tabIndex={0}
                      >
                        <p className="font font--white">
                          Lost
                          <a className="font font--blue m-l-5" onClick={() => {
                            if (showForm && showLostCredentialsForm) {
                              showLostCredentialsForm('1')
                              showForm()
                            }
                          }}>
                            password
                          </a>
                        </p>
                      </div>
                      <InputWrap
                        className={
                          'm-t-35' + (
                            errors.yearsConfirmation &&
                              touched.yearsConfirmation ?
                              ' required required--error' : '')
                        }
                      >
                        <InputCheckbox
                          name="yearsConfirmation"
                          value={values.yearsConfirmation}
                          change={handleChange}
                        />
                      </InputWrap>
                      <InputWrap
                        className={
                          errors.newsletter && touched.newsletter && 'required required--error'
                        }
                      >
                        <div className="flex align-middle">
                          <input
                            className="checkbox checkbox--blue"
                            type="checkbox"
                            id="newsletter"
                            name="newsletter"
                            value={values.newsletter}
                            onChange={handleChange}
                          />
                          <label
                            className="font font--12 m-l-5"
                            htmlFor="newsletter"
                            style={{ color: '#fff' }}
                          >
                            {t('I agree to the newsletter')}
                          </label>
                        </div>
                      </InputWrap>
                      <button
                        className="btn btn--blue btn--outline btn--h-44 m-t-5"
                        type="submit"
                        onMouseEnter={() => {
                          setHover(true)
                        }}
                        onMouseLeave={() => {
                          setHover(false)
                        }}
                        style={{ borderColor: '#6c90fc' }}
                      >
                        <span className="font font--blue">Registration</span>
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Overlay>
    </>
  )
}

export default LoginForm
