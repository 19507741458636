import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccount } from 'redux/selectors/accountSelectors'
import { setMessages } from '../../../redux/actions/accountActions'
import Message from './Message'

const Inbox = () => {
	const { inbox } = useSelector(getAccount)
	const dispatch = useDispatch()
	const toggleCheckedHandler = id => {
		return () => {
			dispatch(
				setMessages(
					inbox?.map((item, i) => {
						if (i === id) {
							item.checked = !item.checked
						}
						return item
					}),
					'inbox'
				)
			)
		}
	}
	const [selectAll, setSelectAll] = React.useState(false)
	// delete selected messages
	const deleteHandler = () => {
		setSelectAll(false)
		dispatch(
			setMessages(
				inbox.filter(item => {
					return !item.checked
				}),
				'inbox'
			)
		)
	}
	// set selected messages as read
	const readHandler = () => {
		setSelectAll(false)
		dispatch(
			setMessages(
				inbox?.map(item => {
					if (item.checked) {
						item.isNew = false
					}
					item.checked = false
					return item
				}),
				'inbox'
			)
		)
	}
	// set selected messages unread
	const unreadHandler = () => {
		setSelectAll(false)
		dispatch(
			setMessages(
				inbox?.map(item => {
					if (item.checked) {
						item.isNew = true
					}
					item.checked = false
					return item
				}),
				'inbox'
			)
		)
	}
	// select and unselect all messages checkbox handler
	const toggleSelectAllHandler = () => {
		setSelectAll(!selectAll)
		dispatch(
			setMessages(
				inbox?.map(item => {
					item.checked = !selectAll
					return item
				}),
				'inbox'
			)
		)
	}

	return (
		<div className="container">
			<div className="container__head">
				<p className="font font--semibold">Inbox</p>
			</div>
			<div className="container__wrap">
				<div className="container__header">
					<div className="flex align-middle action">
						<input
							className="checkbox"
							type="checkbox"
							checked={selectAll}
							onChange={toggleSelectAllHandler}
						/>
						<div
							className="flex align-middle action action__item action__item--delete"
							onClick={deleteHandler}>
							<i className="fal fa-trash-alt m-r-5"></i>
							<p className="font font--grey-500">Delete</p>
						</div>
						<div
							className="flex align-middle action action__item action__item--delete"
							onClick={readHandler}>
							<i className="fal fa-envelope-open m-r-5"></i>
							<p className="font font--grey-500">Read</p>
						</div>
						<div
							className="flex align-middle action action__item action__item--delete"
							onClick={unreadHandler}>
							<i className="fal fa-envelope m-r-5"></i>
							<p className="font font--grey-500">Unread</p>
						</div>
					</div>
				</div>
				<div className="message">
					{inbox?.map((item, id) => {
						return (
							<Message key={id} {...item} change={toggleCheckedHandler(id)} />
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default Inbox
