import React from 'react'

type IColumnTitle = {
  title: string
  style?: any
}

const ColumnTitle: React.FC<IColumnTitle> = ({ title, style }) => {
  return (
    <div className="gll-market-group__title" {...{ style }}>
      <span className="font font--grey-500 text-ellipsis">{title}</span>
    </div>
  )
}

export default ColumnTitle
