import { v4 as uuidv4 } from 'uuid'
import PrematchEvent from '../pages/sports/PrematchEvent'
import SportsMain from '../pages/sports/SportsMain'
import PrematchLeague from '../pages/sports/PrematchLeague'
import SportsRedirection from 'components/Routing/sportsRedirection'

export const sportsRoutes = [
  {
    key: uuidv4(),
    path: '/sports/:sportId/:leagueId',
    component: PrematchLeague,
    private: false,
    exact: true,
  },
  {
    key: uuidv4(),
    path: '/sports/:sportId/:leagueId/:id',
    component: PrematchEvent,
    private: false,
    exact: true,
  },
  {
    key: uuidv4(),
    path: '/sports/main',
    component: SportsMain,
    private: false,
    exact: true,
  },
  {
    key: uuidv4(),
    component: SportsRedirection,
  },
]
