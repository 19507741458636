import React from 'react'
import Button from '../../../common/buttons/Button'

const ScoreboardOption = ({ handler, open }) => {
	const text = open ? 'Hide scoreboard' : 'Show Scoreboard'

	return (
		<div className="ip-scoreboard__option">
			<div className="flex align-middle align-right full-height justify-self-right">
				<a className="ip-scoreboard__favourite m-r-10" />
				<Button
					className="btn btn--outline btn--26 m-r-10 ip-scoreboard__hide"
					onClick={handler}>
					<span className="font font--10 font--white-alpha-05">{text}</span>
				</Button>
				<a className="c-icon c-icon--stats ip-scoreboard__stats" />
			</div>
		</div>
	)
}

export default React.memo(ScoreboardOption)
