import React, { useEffect, useState } from 'react'
import '../../../assets/styles/search.css'
import { useSelector } from 'react-redux'
import { getConfig, getToken } from '../../../redux/selectors/configSelectors'
import { post } from '../../../helpers/asyncRequests'
import axios from 'axios'
import SearchTitle from './SearchTitle'
import SearchItem from './SearchItem'
import SearchItemMatch from './SearchItemMatch'
import SearchItemLeague from './SearchItemLeague'
import SearchScrollWrapper from './SearchScrollWrapper'
import { Nullable } from '../../../helpers/commonInterfaces/interfaces'

const dataTree = (data: Nullable<any>) => {
  if (data === null || data === [null]) {
    return [[], [], []]
  } else {
    const teams: Array<any> = []
    const matches: Array<any> = []
    const leagues: Array<any> = []

    data.forEach((item) => {
      if (item !== null) {
        if (
          item.type == 'MG' &&
          typeof item.NA !== 'undefined' &&
          item.NA != 'Tennis' &&
          item.NA != 'England' &&
          item.NA != 'Table Tennis' &&
          (!item.NA.includes(' v ') || !item.NA.includes(' vs ')) &&
          typeof item.EI === 'undefined'
        ) {
          teams.push(item)
        } else if (
          typeof item.NA !== 'undefined' &&
          (item.NA.includes(' v ') || item.NA.includes(' vs '))
        ) {
          matches.push(item)
        } else if (
          item.type == 'MG' &&
          typeof item.NA !== 'undefined' &&
          (!item.NA.includes(' v ') || !item.NA.includes(' vs ')) &&
          !item.NA.includes('Outright')
        ) {
          leagues.push(item)
        }
      }
    })

    return [teams, matches, leagues]
  }
}

const Search = () => {
  const token = useSelector(getToken)
  const config = useSelector(getConfig)

  const [data, setData] = useState<any>(null)

  const [startUpData, setStartUpData] = useState<any>(null)

  useEffect(() => {
    if (startUpData == null) {
      axios.get('https://api.absolute.bet/search/config/').then((response) => {
        setStartUpData(response)
      })
    }
  }, [])

  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
  /*const refContainer = React.useRef(null)
  const refWrapper = React.useRef(null)*/

  const [search, showSearch] = useState(false)
  const [searchHolder, setInput] = useState('')

  useEffect(() => {
    if (search) {
      document.addEventListener('mousedown', hideSearchFrom)
      inputRef?.current?.focus()
    }
  }, [search])

  const showSearchForm = () => {
    if (!search) {
      showSearch(true)
    }
  }

  const hideSearchFrom = (e) => {
    if (ref.current == null) {
      showSearch(false)
      document.removeEventListener('mousedown', hideSearchFrom)
    } else {
      if (!ref?.current?.contains(e.target)) {
        showSearch(false)
        document.removeEventListener('mousedown', hideSearchFrom)
      }
    }
  }

  const Display = () => {
    if (
      dataTree(data)[0].length >= 1 ||
      dataTree(data)[1].length >= 1 ||
      dataTree(data)[2].length >= 1
    ) {
      return true
    } else {
      return false
    }
  }

  const loadSearch = async (query: string): Promise<void> => {
    try {
      const data = await post(
        `https://api.absolute.bet/search/?token=${token}&ln=${config?.CUSTOMER_CONFIG?.LANGUAGE_ID ?? '1'
        }`,
        {
          keyword: query,
        }
      )()
      if (data.length) {
        setData(data)
      } else {
        setData(null)
      }
    } catch (e) {
      setData(null)
    }
  }

  const handleChange = (e) => {
    setInput(e.target.value)
    if (e.target.value.length >= 3) {
      loadSearch(e.target.value)
    }
  }

  if (searchHolder.length >= 3) {
    if (data !== null && dataTree(data) !== null) {
      if (
        dataTree(data)[0].length >= 1 ||
        dataTree(data)[1].length >= 1 ||
        dataTree(data)[2].length >= 1
      ) {
        return (
          <div ref={ref} className={search ? 'h-search h-search--active' : 'h-search'}>
            <div className="flex align-middle full-height" onClick={showSearchForm}>
              <input
                ref={inputRef}
                className="field h-search__field"
                type="text"
                value={searchHolder}
                placeholder="Search"
                onChange={handleChange}
              />
              <i className="fal fa-search h-search__icon" />
              <a
                className="font font--grey-300 font--12 h-search__text"
                style={{ paddingRight: '8px' }}
              >
                {/*dispatch(t('COMMON.SUBMENU.SEARCH'))*/ 'Search'}
              </a>
            </div>
            <SearchScrollWrapper
              onScroll={() => null}
              className={'h-search__dropdown'}
              display={Display()}
            >
              {dataTree(data)[1].length >= 1 ? <SearchTitle title="Matches" /> : ''}
              {dataTree(data)[1].map((item, idx) => (
                <SearchItemMatch key={idx} q={item} showSearch={showSearch} />
              ))}
              {dataTree(data)[2].length >= 1 ? <SearchTitle title="Leagues" /> : ''}
              {dataTree(data)[2].map((item, idx) => (
                <SearchItemLeague key={idx} q={item} />
              ))}
            </SearchScrollWrapper>
          </div>
        )
      } else {
        return (
          <div ref={ref} className={search ? 'h-search h-search--active' : 'h-search'}>
            <div className="flex align-middle full-height" onClick={showSearchForm}>
              <input
                ref={inputRef}
                className="field h-search__field"
                type="text"
                value={searchHolder}
                placeholder="Search"
                onChange={handleChange}
              />
              <i className="fal fa-search h-search__icon" />
              <a
                className="font font--grey-300 font--12 h-search__text"
                style={{ paddingRight: '8px' }}
              >
                {/*dispatch(t('COMMON.SUBMENU.SEARCH'))*/ 'Search'}
              </a>
            </div>
            <div className="h-search__dropdown">
              <SearchTitle title={'No results found. Please, try a different search term.'} />
            </div>
          </div>
        )
      }
    } else {
      return (
        <div ref={ref} className={search ? 'h-search h-search--active' : 'h-search'}>
          <div className="flex align-middle full-height" onClick={showSearchForm}>
            <input
              ref={inputRef}
              className="field h-search__field"
              type="text"
              value={searchHolder}
              placeholder="Search"
              onChange={handleChange}
            />
            <i className="fal fa-search h-search__icon" />
            <a
              className="font font--grey-300 font--12 h-search__text"
              style={{ paddingRight: '8px' }}
            >
              {/*dispatch(t('COMMON.SUBMENU.SEARCH'))*/ 'Search'}
            </a>
          </div>
          <div className="h-search__dropdown">
            <SearchTitle title={'No results found. Please, try a different search term.'} />
          </div>
        </div>
      )
    }
  } else if (searchHolder.length < 3 && searchHolder !== '') {
    return (
      <div ref={ref} className={search ? 'h-search h-search--active' : 'h-search'}>
        <div className="flex align-middle full-height" onClick={showSearchForm}>
          <input
            ref={inputRef}
            className="field h-search__field"
            type="text"
            value={searchHolder}
            placeholder="Search"
            onChange={handleChange}
          />
          <i className="fal fa-search h-search__icon" />
          <a
            className="font font--grey-300 font--12 h-search__text"
            style={{ paddingRight: '8px' }}
          >
            {/*dispatch(t('COMMON.SUBMENU.SEARCH'))*/ 'Search'}
          </a>
        </div>
        {/*<div className="h-search__dropdown">
          </div>*/}
      </div>
    )
  } else {
    const localStartUp = {
      history: ['Liverpool'],
      top_queries: ['Liverpool'],
    }
    return (
      <div ref={ref} className={search ? 'h-search h-search--active' : 'h-search'}>
        <div className="flex align-middle full-height" onClick={showSearchForm}>
          <input
            ref={inputRef}
            className="field h-search__field"
            type="text"
            value={searchHolder}
            placeholder="Search"
            onChange={handleChange}
          />
          <i className="fal fa-search h-search__icon" />
          <a
            className="font font--grey-300 font--12 h-search__text"
            style={{ paddingRight: '8px' }}
          >
            {/*dispatch(t('COMMON.SUBMENU.SEARCH'))*/ 'Search'}
          </a>
        </div>
        <div className="h-search__dropdown">
          <SearchTitle />
          {startUpData == null
            ? localStartUp?.history.map((item, index) => (
              <SearchItem
                q={{ NA: item, EI: '' }}
                key={index}
                setQ={setInput}
                inputRef={inputRef}
              />
            ))
            : startUpData?.data?.history.map((item, index) => (
              <SearchItem
                q={{ NA: item, EI: '' }}
                key={index}
                setQ={setInput}
                inputRef={inputRef}
              />
            ))}
          <SearchTitle title="Top queries" />
          {startUpData == null
            ? localStartUp?.top_queries.map((item, index) => (
              <SearchItem
                q={{ NA: item, EI: '' }}
                key={index}
                setQ={setInput}
                inputRef={inputRef}
              />
            ))
            : startUpData?.data?.top_queries.map((item, index) => (
              <SearchItem
                q={{ NA: item, EI: '' }}
                key={index}
                setQ={setInput}
                inputRef={inputRef}
              />
            ))}
        </div>
      </div>
    )
  }
}

export default Search
