import React from 'react'
import { modifyBets } from '../../../helpers/modifyBets'

const ButtonValue = ({ oddValue, oddsType, highlight }) => {
  return (
    <span className={'font font--12 ' + highlight.className}>{modifyBets(oddValue, oddsType)}</span>
  )
}

export default React.memo(ButtonValue)
