import { IEvent, ISport } from 'helpers/commonInterfaces/interfaces'
import { types } from '../types/types'

export interface ISchedule {
  sports: Record<string, ISport>
  events: Array<IEvent>
  days: any
  currentView: any[]
  activeSport: string
  activeDay: any
  init: boolean
}

const initialState = {
  sports: {},
  events: [],
  days: {},
  currentView: [],
  activeSport: '0',
  activeDay: null,
  init: false,
}

export const schedule = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SCHEDULE_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case types.SET_SCHEDULE_VIEW:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
