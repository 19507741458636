import React from 'react'
import { useSelector } from 'react-redux'
import { getConfig, getCurrency, getOddsType } from 'redux/selectors/configSelectors'
import { modifyBets } from 'helpers/modifyBets'
import { floatToCurrency } from '../../../../helpers/floatToCurrency'

const BetslipRow = ({ type, bet }) => {
  const { CUSTOMER_CONFIG } = useSelector(getConfig)
  const currencyConfig = useSelector(getCurrency)
  const ODDS_TYPE = useSelector(getOddsType)
  if (!bet.PA) {
    return null
  }
  const isAvailableCashout =
    (bet.CA && bet.CA === 1 && !!bet.CV && typeof bet.CP !== 'undefined') || false
  return (
    <div className={type ? 'betslip__tr' : 'betslip__tr betslip__tr--border'}>
      {type ? (
        <div className="betslip__td betslip__td--big">
          {/* <div className="flex align-middle flex-wrap">
            <p className="font font--11 font--defaul-alpha-05 m-r-5">odds</p>
            <p
              className="font font--blue font--medium"
              dangerouslySetInnerHTML={{
                __html: modifyBets(bet.PA[0].OD, ODDS_TYPE),
              }}
            ></p>
          </div> */}
          <div className="flex align-middle flex-wrap">
            <p className="font font--11 font--defaul-alpha-05 m-r-5">stake</p>
            <p
              className="font"
              dangerouslySetInnerHTML={{
                __html: floatToCurrency(bet.AM, currencyConfig, true, true),
              }}
            ></p>
          </div>
          <div className="flex align-middle flex-wrap">
            <p className="font font--11 font--defaul-alpha-05 m-r-5">
              {bet.ST === 4 ? 'returned' : bet.ST !== 0 ? 'returned' : 'possible win'}
            </p>
            <p
              className={
                'font' +
                (bet.ST === 4 ? ' font--red' : bet.ST !== 0 ? ' font--green' : '')
              }
              dangerouslySetInnerHTML={{
                __html: floatToCurrency(bet.RE, currencyConfig, true, true),
              }}
            ></p>
          </div>
        </div>
      ) : (
        <>
          <div className="betslip__td betslip__td--big">
            <div className="flex align-middle align-justify full-width m-b-10">
              <p className="font font--semibold">{bet.NA}</p>
              <p className="font font--blue font--semibold">{bet.PA[0].OD}</p>
            </div>
          </div>
          <div className="betslip__td betslip__td--big">
            <div className="flex align-middle align-justify full-width">
              <div className="flex align-middle flex-wrap">
                <p className="font font--11 font--defaul-alpha-05 m-r-5">stake</p>
                <p
                  className="font"
                  dangerouslySetInnerHTML={{
                    __html: floatToCurrency(bet.AM, currencyConfig, true, true),
                  }}
                ></p>
              </div>
              <div className="flex align-middle flex-wrap">
                <p className="font font--11 font--defaul-alpha-05 m-r-5 text-overflow">
                  {bet.ST === 4
                    ? ' '
                    : bet.ST !== 0
                      ? 'returned'
                      : 'possible win'}
                </p>
                <p
                  className={
                    'font' +
                    (bet.PA[0].ST === 4 ? ' font--red' : bet.PA[0].ST !== 0 ? ' font--green' : '')
                  }
                  dangerouslySetInnerHTML={{
                    __html: floatToCurrency(bet.RE, currencyConfig, true, true),
                  }}
                ></p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default BetslipRow
