import React from 'react'
import img from '../../../../../assets/img/inplay/league.svg'

const SelectLeagueHeaderButton = ({ onClick }) => {
	return (
		<div
			className="flex align-middle ip-classification-header__league"
			onClick={onClick}>
			<div className="ip-classification-header__icon m-r-10">
				<img src={img} alt="league" />
			</div>
			<p className="font font--12 font--white">Select Leagues</p>
		</div>
	)
}

export default React.memo(SelectLeagueHeaderButton)
