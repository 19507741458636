import React from 'react'

const TabItem = ({ item, active, handler }) => {
	return (
		<a
			onClick={() => handler(item.id)}
			className={
				item.id === active
					? 'font font--grey-300 wn-classification__link wn-classification__link--active'
					: 'font font--grey-300 wn-classification__link'
			}
		>
			{item.id === '2' && <div className="wn-classification-favourites" />}
			<span>{item.title}</span>
		</a>
	)
}

export default TabItem