import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import AsideLeftContext, { AsideContext } from '../../../context/asideLeftContext'
import CollapsedWrap from '../../forAsideLeft/eventClassification/classification/classificationCollapsed/CollapsedWrap'
import PrematchClassification from 'components/forAsideLeft/prematchClassification/PrematchClassification'
import UserCabinet from 'components/forAccontPageComponents/UserCabinet'
import UserAsideNavBar from 'components/forAccontPageComponents/UserAsideNavBar'
import EventAsideWrapper from '../../forAsideLeft/eventClassification/EventAsideWrapper'
import '../../../assets/styles/asideLeft.css'

const AsideLeft = ({ className = '' }) => {
  const location = useLocation()
  const [liveTranslation, setLiveTranslation] = useState(false)
  const { small, position, dataForAsideSmall, onMouseLeave } = useContext(AsideContext)
  const refWrapper = React.useRef(null)

  useEffect(() => {
    console.log(`dataForAsideSmall`, dataForAsideSmall)
  }, [dataForAsideSmall])

  return (
    <>
      {dataForAsideSmall && (
        <CollapsedWrap
          position={position}
          data={dataForAsideSmall}
          {...{ liveTranslation }}
          remove={onMouseLeave} />
      )}
      <SimpleBar
        ref={refWrapper}
        style={{
          display: `${!location.pathname.includes('account') &&
            !location.pathname.includes('live/overview') ? `flex` : 'none'}`,
          overflow: 'auto',
        }}
        className={
          small
            ? `aside--wrapper aside--small ${className}`
            : `aside--wrapper aside--left ${className}`
        }
      >
        <div>
          {!location.pathname.includes('account') && !location.pathname.includes('mybets') ? (
            location.pathname.includes('live/event') ? (
              <EventAsideWrapper {...{ liveTranslation }} {...{ setLiveTranslation }} />
            ) : (
              <PrematchClassification />
            )
          ) : (
            <>
              <UserCabinet />
              <UserAsideNavBar />
            </>
          )}
        </div>
      </SimpleBar>
    </>
  )
}

export default React.memo(AsideLeft)
