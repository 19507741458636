import BetLockButton from 'components/common/buttons/BetLockButton'
import React, { useEffect } from 'react'
import EventMarketsForChangeMarget from './EventMarketsForChangeMarket'

export const outoputDashes = () => (
  <div className="flex align-stretch ip-competition__info" style={{ minHeight: '28px' }}>
    <button
      key="empty 1"
      className="btn btn--coff ip-competition__coff ip-competition__coff--big
			
					"
    >
      <span className="nowrap flex flex-1-1">
        <span className="font font--11 font--default-alpha-05 flex-1-1">...</span>
        <span className="font font--12 flex-1-1 ">-</span>
      </span>
    </button>
    <button
      key="empty 2"
      className="btn btn--coff ip-competition__coff ip-competition__coff--big
			
					"
    >
      <span className="nowrap flex flex-1-1">
        <span className="font font--11 font--default-alpha-05 flex-1-1 font--semibold">...</span>
        <span className="font font--12 flex-1-1 font--semibold">-</span>
      </span>
    </button>
  </div>
)

const outputLockers = () => (
  <div className="flex align-stretch ip-competition__info" style={{ minHeight: '28px' }}>
    <BetLockButton />
    <BetLockButton />
  </div>
)
const EventMarkets = ({ odds, competitors, oddId, oddName, changeMarket, eventId, sportId }) => {
  if (!odds || odds.length === 0) {
    return (
      <td className="td">
        <div className="flex align-middle ">{outputLockers()}</div>
      </td>
    )
  }
  return (
    <>
      {
        <EventMarketsForChangeMarget
          odds={odds}
          oddId={oddId}
          oddName={oddName}
          competitors={competitors}
          eventId={eventId}
          sportId={sportId}
        />
      }
    </>
  )
}

export default EventMarkets
