import React, { useEffect } from 'react'
import { useConfigHook } from '../hooks/useConfigHook'
import Loader from '../components/common/loaders/Loader'
import { ConfigType } from '../hooks/useConfigHook'

type ContextValue = {
  connection: any
  t: (path: string) => string
  CUSTOMER_CONFIG: any
  ODDS_TYPE: string
  ln: string
  LOGGED_IN: boolean
  loaded: boolean
  config: ConfigType
  langIDs: Map<string, string>
  locales: any
  getUserConfig: (key: string) => string
  setUserConfig: (key: string, value: any) => void
  changeLanguage: (id: string) => void
  loadProjectConfigs: () => void
}

export const ConfigContext = React.createContext<Partial<ContextValue>>({})

const GlobalConfig = (props) => {
  const {
    t,
    loaded,
    config,
    connection,
    langIDs,
    locales,
    getUserConfig,
    setUserConfig,
    changeLanguage,
    loadProjectConfigs,
  } = useConfigHook()

  useEffect(() => {
    loadProjectConfigs()
  }, [])

  return (
    <ConfigContext.Provider
      value={{
        t,
        connection,
        langIDs,
        locales,
        getUserConfig,
        setUserConfig,
        changeLanguage,
        ...config,
      }}
    >
      <Loader />
      {loaded && <>{props.children}</>}
    </ConfigContext.Provider>
  )
}

export default GlobalConfig
