import { IEvent } from 'helpers/commonInterfaces/interfaces'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

type TournamentCellProps = {
  event: any
}

export const TournamentCell: FC<TournamentCellProps> = ({ event }) => {
  return (
    <>
      <td className="td td--border tournament--action-cell">
        <p className="font m-l-5">Tournament</p>
      </td>
      <td className="td m-l-15">
        <Link
          className="flex align-middle align-justify flex-grow overflow-hidden p-l-10 p-r-10"
          to={`sports/${event.sportId}/${event.leagueId}/${event.id}`}
        >
          <div className="text-left overflow-hidden m-r-5">
            <div className="flex align-middle">
              <p className="font text-ellipsis">{event.name}</p>
            </div>
          </div>
        </Link>
      </td>
    </>
  )
}
