import React from 'react'
import background from '../../../assets/img/offer.png'

const AvailableOffers = () => {
	return (
		<div className="container">
			<div className="container__head">
				<p className="font font--semibold">Available offers</p>
			</div>
			<div className="container__wrap">
				<div className="offers">
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="offer"
						style={{ backgroundImage: `url(${background})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit.
									Deserunt porro magni aspernatur quia asperiores, amet, dolores
									alias animi ipsam dolorum consequatur non consectetur eligendi
									officia praesentium sapiente, minima ea. Consequatur!
								</p>
							</div>
							<div className="offer__center">
								<fieldset>
									<legend>
										<p className="font font--white font--10">
											Before the promotion
										</p>
									</legend>
									<div className="timer timer--gold">
										<div className="flex align-center align-justify timer__top">
											<p className="font">01</p>
											<p className="font">23</p>
											<p className="font">11</p>
											<p className="font">03</p>
										</div>
										<div className="flex align-middle align-justify timer__bottom">
											<p className="font">Day</p>
											<p className="font">Hours</p>
											<p className="font">Minutes</p>
											<p className="font">Seconds</p>
										</div>
									</div>
								</fieldset>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active">
										<span className="font">Active</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AvailableOffers
