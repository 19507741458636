import { all } from 'redux-saga/effects'
import { overviewSagas } from './liveSagas/overview/overviewSagas'
import { eventSagas } from './liveSagas/event/eventSagas'
import { configSagas } from './config/configSagas'
import { prematchSagas } from './prematch/prematchSagas'
import { betslipSagas } from './betslip/betslipSagas'
import { prematchLeagueSagas } from './prematch/prematchLeagueSagas'
import { myBetsSagas } from './mybets/mybetsSagas'
import { prematchEventSagas } from './prematch/prematchEventSagas'
import { topGamesSagas } from './topGames/topGamesSagas'
import { bannersSagas } from './banners/bannerSagas'

export const rootSaga = function* root(): Iterator<any> {
  yield all([
    ...bannersSagas,
    ...overviewSagas,
    ...betslipSagas,
    ...eventSagas,
    ...configSagas,
    ...prematchSagas,
    ...prematchLeagueSagas,
    ...myBetsSagas,
    ...prematchEventSagas,
    ...topGamesSagas,
  ])
}
