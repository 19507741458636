import React from 'react'
import LvTranslationLink from './LvTranslationLink'
import LvTranslationIcon from './LvTranslationIcon'
import Volume from './Volume'
import { useTranslation } from 'react-i18next'

const renderData = [
  {
    type: 'text',
    title: 'COMMON.ASIDE_RIGHT.STREAMING',
    wideTitle: 'COMMON.ASIDE_RIGHT.LIVE_STREAMING',
    activeClass: 'font font--12 font--grey-300 lv-translation__link lv-translation__link--active',
    defaultClass: 'font font--12 font--grey-300 lv-translation__link',
    name: 'streaming',
  },
  {
    type: 'text',
    title: 'COMMON.ASIDE_RIGHT.LIVE',
    wideTitle: 'COMMON.ASIDE_RIGHT.MATCH_LIVE',
    activeClass: 'font font--12 font--grey-300 lv-translation__link lv-translation__link--active',
    defaultClass: 'font font--12 font--grey-300 lv-translation__link',
    name: 'live',
  },
  {
    type: 'icon',
    defaultClass: 'lv-translation__icon',
    activeClass: 'lv-translation__icon pinned',
    childClass: 'fal fa-thumbtack icon',
    click: 'pinToggle',
    name: 'thumbtrack',
  },
  {
    type: 'icon',
    defaultClass: 'lv-translation__icon lv-translation__icon--uncompress',
    activeClass: '',
    childClass: 'fal icon',
    click: 'removeWideHandler',
    name: 'collapse',
  },
  {
    type: 'icon',
    defaultClass: 'lv-translation__icon drop drop--active',
    activeClass: 'lv-translation__icon drop',
    childClass: 'fal fa-angle-up icon',
    click: 'activateBody',
    name: 'bodyShow',
  },
]

const LiveTranslationHeader = (props) => {
  const {
    soundRangeValue,
    setSoundRangeValue,
    pinned,
    showBody,
    wide,
    active,
    setActiveLink,
  } = props
  const { t } = useTranslation()
  return (
    <div className="lv-translation__header">
      <div className="flex align-middle full-height">
        <Volume {...{ soundRangeValue }} {...{ setSoundRangeValue }} />
        {renderData?.map(
          ({
            type,
            defaultClass,
            activeClass,
            childClass,
            title = '',
            wideTitle = '',
            click = '',
            name,
          }) => {
            return type === 'icon' ? (
              <LvTranslationLink
                classes={
                  activeClass && (name === 'thumbtrack' ? pinned : showBody)
                    ? activeClass
                    : defaultClass
                }
                onClick={() => {
                  console.log('click on ', String(props['click']))
                  if (props[click]) {
                    props[click]()
                  }
                }}
                key={name}
              >
                <LvTranslationIcon classes={childClass} />
              </LvTranslationLink>
            ) : (
              <LvTranslationLink
                key={name}
                classes={active === name ? activeClass : defaultClass}
                onClick={() => {
                  console.log('click on ', name)
                  setActiveLink(name)
                }}
              >
                {wide ? t(wideTitle) : t(title)}
              </LvTranslationLink>
            )
          }
        )}
      </div>
    </div>
  )
}

export default LiveTranslationHeader
