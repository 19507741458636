import React, { useEffect, useState } from 'react'
import ClassificationBarItem from './ClassificationBarItem'
import { useDispatch, useSelector } from 'react-redux'
import { inplayWatchers } from '../../../redux/actions/inplayActions'
import { useSlider } from '../../../hooks/useSlider'
import '../../../assets/styles/classificationBar.css'
import { IRootState } from 'redux/reducers/rootReducer'

const sliderRef: React.RefObject<HTMLDivElement> = React.createRef()

type ISport = {
  response?: any
  id: number
  inPlayMatchCount: number
  inPlayTournamentCount: number
  matchCount: number
  name: string
  priority: number
  templateId: number
  tournamentCount: number
}

const ClassificationBar = () => {
  const sortSportsData = (data) => {
    if (data) {
      data.forEach((sport) => {
        if (sport.priority === 0) {
          sport.priority = 200
        }
      })
      data.sort((a, b) => a.priority - b.priority)

      return data
    }

    return []
  }

  const { setSliderData, showButtons, toNextSlide, toPrevSlide, transform, max, min } = useSlider()

  const { data = [], active } = useSelector((state: IRootState) => state.inplay)
  const [sorted, sort] = useState(sortSportsData(data))

  const dispatch = useDispatch()

  const setView = (item) => dispatch(inplayWatchers.setCurrentView(item))

  useEffect(() => {
    setSliderData(sliderRef)
    sort(sortSportsData(data))
  }, [data])

  return (
    <>
      <div className="ip-classification-bar-sport relative">
        {showButtons && !min && (
          <button onClick={toPrevSlide} className="fa ip-classification-prev" />
        )}
        <div
          className="flex align-middle full-height ip-classification-bar__wrap"
          ref={sliderRef}
          style={{
            transform: `translate(-${transform}px, 0px)`,
          }}
        >
          {sorted?.map((item) => (
            <ClassificationBarItem
              onClick={() => setView(item)}
              key={item.id}
              className={active === item.id ? 'wn-classification-bar__item--active' : ''}
              {...{ item }}
            />
          ))}
        </div>
        {showButtons && !max && (
          <button onClick={toNextSlide} className="fa ip-classification-next" />
        )}
      </div>
    </>
  )
}

export default React.memo(ClassificationBar)
