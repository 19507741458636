import React from 'react'
import Tournament from './Tournament'
import Match from './Match'

const EventWrapper = ({ changeMarket, event, oddName, oddId, toEvent }) => {
  const { odds, oddsCount, eventType, name } = event

  if (eventType === 'Tournament') {
    return <Tournament name={name} odds={odds} oth={oddsCount} toEvent={toEvent} />
  }

  return (
    <Match
      event={event}
      changeMarket={changeMarket}
      oddName={oddName}
      oddId={oddId}
      toEvent={toEvent}
    />
  )
}

export default EventWrapper
