import React from 'react'
import Competition from '../../competition/Competition'

const CollapsedBody = ({ data, liveTranslation }) => {
  const { categories } = data

  if (categories?.length >= 1) {
    return (
      <>
        {categories?.map((category) =>
          category.leagues?.map((league) => <Competition key={league.id} data={league} {...{ liveTranslation }} />)
        )}
      </>
    )
  } else {
    return <></>
  }
}

export default CollapsedBody
