import { IMatch, ITournament, Nullable, Odd } from 'helpers/commonInterfaces/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from 'redux/reducers/rootReducer'

type IEventContext = {
  event: Nullable<IMatch | ITournament>
  leagueEvents: Nullable<IMatch[]>
  odds: Nullable<{ [key: string]: Odd }>
  markets: Nullable<{ [key: string]: Odd }>
  error: boolean
}

export const EventContext = React.createContext<IEventContext>({
  event: null,
  leagueEvents: null,
  odds: null,
  markets: null,
  error: false,
})
 
const EventContextWrapper = ({ children, type = 'inplay' }) => {
  const { prematchEvent, event } = useSelector((state: IRootState) => state)

  const data = type === 'inplay' ? event : prematchEvent
  //console.log("Context: ", data);

  return (
    <div className="event-content">
      <EventContext.Provider
        value={{
          event: data.event,
          leagueEvents: data.leagueEvents,
          odds: data.odds,
          markets: data.odds,
          error: data.event ? true : false,
        }}
      >
        {data?.event?.id && children}
      </EventContext.Provider>
    </div>
  )
}

export default EventContextWrapper
