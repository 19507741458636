import React, { useRef, useEffect, useContext } from 'react'
import Overlay from '../../../registration/overlay'
import { NavLink } from 'react-router-dom'
import { RegisterContext } from 'context/registrationContext'

const NotEnoughFunds = ({ popupAccept, notLoggedIn }) => {
  const { showForm } = useContext(RegisterContext)

  const ref = useRef()
  useEffect(() => {
    document.addEventListener('click', popupAccept)
    return () => document.removeEventListener('click', popupAccept)
  }, [ref.current])

  return (
    <Overlay>
      <div className="ui-popup">
        <div className="flex align-center m-t-10">
          <p
            dangerouslySetInnerHTML={{
              __html: notLoggedIn
                ? 'Log into the system first'
                : 'You don&apos;t have enough funds',
            }} />
          {notLoggedIn
            ? <button className="btn btn--h-32 btn--blue" onClick={() => popupAccept(showForm)}>
              Login
            </button>
            : <NavLink to={'/account/balance/deposit'}>
              <button className="btn btn--h-32 btn--blue" onClick={() => popupAccept()}>
                Deposit
              </button>
            </NavLink>
          }
          <button className="btn btn--h-32 btn--red" onClick={() => popupAccept()}>
            Cancel
          </button>
        </div>
      </div>
    </Overlay>
  )
}

export default NotEnoughFunds
