import React from 'react'
import { Link } from 'react-router-dom'

const LinkItem = ({ title, path, className, showMenu }) => {
	return (
		<Link to={path} className={className} onClick={showMenu}>
			<div className="h-a-menu__item">{title}</div>
		</Link>
	)
}

export default LinkItem
