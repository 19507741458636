import React, { useEffect, useState } from 'react'
import Content from '../../components/containers/main/Content'
import AsideRight from '../../components/containers/main/AsideRight'
import LiveMenu from '../../components/headerComponents/menus/LiveMenu'
import AsideLeft from '../../components/containers/main/AsideLeft'
import EventAsideWrapper from '../../components/forAsideLeft/eventClassification/EventAsideWrapper'
import Center from '../../components/containers/main/Center'
import Footer from '../../components/containers/footer/Footer'
import EventCenterContent from '../../components/forLivePageComponents/event/EventCenterContent'
import AsideLeftContext from '../../context/asideLeftContext'
import AsideRightContext from '../../context/asideRightContext'
import EventContextWrapper from '../../context/eventContext'
import { eventWatchers, eventWorkers } from '../../redux/actions/eventActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getConfig } from 'redux/selectors/configSelectors'
import { prematchEventWatchers, prematchEventWorkers } from 'redux/actions/prematchEventActions'
import { getAside, getEvent } from '../../redux/selectors/eventSelectors'
import _ from 'lodash'
import { inplayWatchers, inplayWorkers } from 'redux/actions/inplayActions'
import { getTrackerAndTranslation } from 'redux/selectors/inplaySelectors'

const Event = () => {
  const dispatch = useDispatch()

  const config = useSelector(getConfig)
  const event = useSelector(getEvent)
  const { pinnedMatch } = useSelector(getTrackerAndTranslation)
  const { openedSports, openedLeagues } = useSelector(getAside)

  const params: {
    eventId?: string
    leagueId?: string
  } = useParams()

  useEffect(() => {
    console.log(`params`, params)
    if (event) {
      dispatch(inplayWatchers.loadLeague(123))
      if (!openedSports.some(sport => sport === event.sportId)) {
        dispatch(eventWorkers.setAside({
          openedLeagues: [...openedLeagues],
          openedSports: [
            ...openedSports,
            event.sportId
          ]
        }))
      }
    }
    return () => {
      if (!pinnedMatch) {
        dispatch(inplayWorkers.setActiveTracker(0))
      }
    }
  }, [event])

  useEffect(() => {
    if (!_.isEmpty(params)) {
      console.log('Event params not empty')
      window.scrollTo(0, 0)
      if (event !== null && event.id.toString() != params.eventId) {
        dispatch({ type: `REMOVE_EVENT_BY_ID_${event.id}` })
        dispatch(
          eventWatchers.removeEventById({
            id: params.eventId,
            leagueId: params.leagueId,
          })
        )
        dispatch(prematchEventWatchers.removeEvent())
        dispatch(prematchEventWorkers.clearEventData())
      }
      dispatch(
        eventWatchers.getEvent({
          id: params.eventId,
          leagueId: params.leagueId,
        })
      )
      dispatch(inplayWorkers.setActiveTranslation(Number(params.eventId)))
      dispatch(inplayWorkers.setActiveTracker(Number(params.eventId)))
    } else {
      console.log('Event params empty', event)
      window.scrollTo(0, 0)
      dispatch(
        eventWatchers.getEvent({
          id: params.eventId,
          leagueId: params.leagueId,
        })
      )
      dispatch(inplayWorkers.setActiveTranslation(Number(params.eventId)))
      dispatch(inplayWorkers.setActiveTracker(Number(params.eventId)))
    }
  }, [params, config, event])

  useEffect(() => {
    if (!_.isEmpty(params)) {
      return () => {
        dispatch({ type: `REMOVE_EVENT_BY_ID_${params.eventId}` })
        dispatch(
          eventWatchers.removeEventById({
            id: params.eventId,
            leagueId: params.leagueId,
          })
        )
        dispatch(prematchEventWatchers.removeEvent())
        dispatch(prematchEventWorkers.clearEventData())
      }
    } else {
      return () => {
        dispatch({ type: `REMOVE_EVENT_BY_ID_${event?.id}` })
        dispatch(
          eventWatchers.removeEventById({
            id: params.eventId,
            leagueId: params.leagueId,
          })
        )
        dispatch(prematchEventWatchers.removeEvent())
        dispatch(prematchEventWorkers.clearEventData())
      }
    }
  }, [])

  return (
    <div className="live-wrapper">
      <LiveMenu />
      <Center className="height-scroll scroll">
        <EventContextWrapper>
          <EventCenterContent />
        </EventContextWrapper>
        <Footer />
      </Center>
    </div>
  )
}

export default Event
