import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from './sagas/rootSaga'
import { rootReducer } from './reducers/rootReducer'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
	}
}

const composeEnhancers =
	(process.env.NODE_ENV !== 'production' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose

const sagaMiddleware = createSagaMiddleware()
const middlewares: Array<any> = [sagaMiddleware]

export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(...middlewares))
)

sagaMiddleware.run(rootSaga)
