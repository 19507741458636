import React from 'react'
import SubmenuItem from './SubmenuItem'

const SeparateLine = () => {
  return (
    <SubmenuItem>
      <div className="h-submenu__separate" />
    </SubmenuItem>
  )
}

export default SeparateLine
