import React, { useContext } from 'react'
import { ConfigContext } from '../../../../../context/globalConfig'

const BetslipEmpty = ({ quickbetMode }) => {
	const { t } = useContext(ConfigContext)
	return (
		<div className="betslip__empty">
			{quickbetMode ? (
				<>
					<div className="longclick">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path
								fillRule="evenodd"
								d="M14.421 21.387L11.043 10.99l9.314 5.25-3.21.856 3.114 3.843-1.365 1.188-3.118-3.843-1.357 3.103zm-3.132-6.403a4.002 4.002 0 01-1.482-7.798 4 4 0 015.133 4.71l-1.9-1.021a2.003 2.003 0 00-2.615-1.787 2 2 0 00.202 3.859l.662 2.037z"
							/>
						</svg>
					</div>
					<div className="text-center">
						<p className="font font--11 font--default-alpha-05 font--semibold">
							Long click to the odd to place
							<br /> single ticket directly
						</p>
					</div>
				</>
			) : (
				<div className="text-center">
					<p className="font font--11 font--default-alpha-05">
						No bets selected
					</p>
					<p className="font font--blue font--semibold">
						Click a price to add a section
					</p>
				</div>
			)}
		</div>
	)
}

export default BetslipEmpty
