import { useState, useCallback } from 'react'
import { useStorage } from './useStorage'
import { useRequest } from './useRequest'
import { useDispatch } from 'react-redux'
import { start as logoStart } from '../redux/actions/preloaderActions'
import { links } from '../connection/links'
import { Connection } from '../connection/Connection'
import { Nullable } from 'helpers/commonInterfaces/interfaces'

type IDetails = {
  Host: string
  port: number
}

export type ISite = {
  CONNECTION_DETAILS: IDetails[]
  SERVER_TIME: number
  SITE_EXCLUDED_SPORTS: string[]
  SITE_EXCLUDED_VIRTUALS: string[]
  TAX_CONFIGURATION: string
}

export type ICustomer = {
  CURRENCY_DECIMAL_SEPARATOR: string
  CURRENCY_EXCHANGE_RATE: string
  CURRENCY_GROUP_SEPARATOR: string
  CURRENCY_MIN_SEP_VALUE: string
  CURRENCY_PLURAL_SYMBOL: string
  CURRENCY_PREFIX_SYMBOL: boolean
  CURRENCY_SPACE_REQUIRED: boolean
  CURRENCY_SYMBOL: string
  CUSTOMER_TYPE: number
  FULL_NAME: string
  GeoComplyId: string
  LANGUAGE_ID: number
  LAST_LOGIN_TIME: string
  LOGGED_IN: boolean
  ODDS_TYPE: string
  REGISTERED_COUNTRY: string
  SESSION_ID: string
  TOKEN: string
  TZ: string
  TZA: string
  USER_NAME: string
  USER_OFFER_STATUS: string
}
export type ConfigType = {
  SITE_CONFIG: Nullable<ISite>
  CUSTOMER_CONFIG: Nullable<ICustomer>
}
export const useConfigHook = () => {
  const { get, post } = useRequest()
  const { getLocal, setLocal } = useStorage()
  const dispatch = useDispatch()

  const [config, setConfig] = useState<ConfigType>({ CUSTOMER_CONFIG: null, SITE_CONFIG: null })
  const [locales, setLocales] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [connection, setConnection] = useState<Connection>()

  const getUserConfig = (key) => config[key]

  const setUserConfig = (key, value) => {
    setConfig({ ...config, [key]: value })
    setLocal('config', { ...config, [key]: value })
  }

  const langIDs = new Map([
    ['1', 'en'],
    ['2', 'ru'],
    ['3', 'ua'],
  ])

  // useEffect(() => {
  // 	console.log('locales setted: ', locales)
  // }, [locales])

  const t = useCallback(
    (path) => {
      const ways = path.split('.')
      if (Object.keys(locales).length < 1) {
        return path
      }
      if (locales[ways[0]] === undefined) {
        return path
      }
      return ways.reduce((acc, way, curIdx) => {
        if (acc[ways[curIdx + 1]] === undefined) {
          return typeof acc === 'string' ? acc : path
        }
        return acc[ways[curIdx + 1]]
      }, locales[ways[0]])
    },
    [locales]
  )

  const changeLanguage = async (id) => {
    setUserConfig('LANGUAGE_ID', id.toString())

    // setConfig({
    // 	...config,
    // 	LANGUAGE_ID: id.toString(),
    // })
    // setLocal('user.config', {
    // 	...getLocal('user.config'),
    // 	LANGUAGE_ID: id.toString(),
    // })
    try {
      const url = links.settings
      const data = JSON.stringify({ Language: id })
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = true
      xhr.open('POST', url, false)
      xhr.onload = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText)
            console.log('response on lang', data)
          } else {
            console.error(xhr.statusText)
          }
        }
      }
      xhr.onerror = function () {
        console.error(xhr.statusText)
      }
      xhr.send(data)
    } catch (e) {
      console.log(e)
    }
    window.location.reload()
    // const newLang = langIDs.get(id.toString())
    // try {
    // 	const lang = await get(
    // 		`https://dev.absolute.bet/config/locales/${newLang}.json`
    // 	)
    // 	console.log('lang', lang)
    // 	setLocales(lang)
    // } catch (e) {
    // 	console.log('Lang request failed:', e)
    // }
  }

  const loadOddTypes = async (config) => {
    try {
      const data = await post(links.oddTypes + config.ln, [])
      // dispatch(setOddTypes(data))
    } catch (e) {
      console.log('odd types load failed,', e.message)
    }
  }

  const loadLanguage = async (config) => {
    try {
      return await get(
        `https://dev.absolute.bet/config/locales/${
          langIDs.get(config?.CUSTOMER_CONFIG?.LANGUAGE_ID) ?? 'ru'
        }.json`,
        [],
        false
      )
    } catch (e) {
      console.log('language loading failed', e)
    }
  }

  const loadBalance = async () => {
    try {
      return await post(links.balance, [])
    } catch (e) {
      console.log('language loading failed', e)
    }
  }

  const loadConfig = async () => {
    const configFromStorage = getLocal('config')

    if (configFromStorage) {
      return configFromStorage
    }

    try {
      return await get(links.config)
    } catch (e) {
      console.log('Config loading failed', e)
    }
  }

  const setConn = (config) => {
    const connection = new Connection({
      webApi: 'https://api.absolute.bet',
      longPoll: config.SITE_CONFIG.CONNECTION_DETAILS[1].Host,
      webSocket: config.SITE_CONFIG.CONNECTION_DETAILS[0].Host,
      socketConfig: {
        privateWS: config.SITE_CONFIG.CONNECTION_PRIVATE.isUsingWS,
        feedWS: config.SITE_CONFIG.CONNECTION_DETAILS.isUsingWS,
      },
      webSocketPrivate: config.SITE_CONFIG.CONNECTION_PRIVATE[0].Host,
    })
    console.log(`{
			webSocket: ${config.SITE_CONFIG.CONNECTION_DETAILS[0].Host},
			longPoll: ${config.SITE_CONFIG.CONNECTION_DETAILS[1].Host},
			webApi: 'https://api.absolute.bet',
		}`)
    setConnection(connection)
    connection.send({
      cmd: 'Request',
      path: '/balance/get',
      data: {},
      topic: 'Balance',
      listeners: [
        (data) => {
          console.log('listened')
          console.dir(data)
        },
      ],
      loop: 30,
    })
  }

  const setData = (config, lang, balance) => {
    config.CUSTOMER_CONFIG.currencyConfig = {
      CURRENCY_SYMBOL: config.CUSTOMER_CONFIG.CURRENCY_SYMBOL,
      CURRENCY_DECIMAL_SEPARATOR: config.CUSTOMER_CONFIG.CURRENCY_DECIMAL_SEPARATOR,
      CURRENCY_GROUP_SEPARATOR: config.CUSTOMER_CONFIG.CURRENCY_GROUP_SEPARATOR,
      CURRENCY_PREFIX_SYMBOL: config.CUSTOMER_CONFIG.CURRENCY_PREFIX_SYMBOL,
      CURRENCY_MIN_SEP_VALUE: config.CUSTOMER_CONFIG.CURRENCY_MIN_SEP_VALUE,
      CURRENCY_SPACE_REQUIRED: config.CUSTOMER_CONFIG.CURRENCY_SPACE_REQUIRED,
      CURRENCY_PLURAL_SYMBOL: config.CUSTOMER_CONFIG.CURRENCY_PLURAL_SYMBOL,
      CURRENCY_EXCHANGE_RATE: config.CUSTOMER_CONFIG.CURRENCY_EXCHANGE_RATE,
    }
    config.ln = config.CUSTOMER_CONFIG.LANGUAGE_ID.toString()
    config.balance = balance.balance
    loadOddTypes(config)
    setConfig(config)
    setLocales(lang)
  }

  const loadProjectConfigs = async () => {
    dispatch(logoStart())

    try {
      const config = await loadConfig()
      if (config.CUSTOMER_CONFIG) {
        config.CUSTOMER_CONFIG.LANGUAGE_ID = '2'
      }
      const lang = await loadLanguage(config)
      const balance = await loadBalance()

      setLocal('config', config)
      setData(config, lang, balance)
      setConn(config)
      setLoaded(true)
    } catch (e) {
      console.log('Project loading failed', e)
      setLoaded(false)
    }

    // const configFromStorage = getLocal('user.config')

    // if (configFromStorage) {
    // 	try {
    // 		const lang = await get(
    // 			`https://dev.absolute.bet/config/locales/${
    // 				langIDs.get(configFromStorage['LANGUAGE_ID']) ?? 'gb'
    // 			}.json`
    // 		)
    // 		setData(configFromStorage, lang)
    // 	} catch (e) {
    // 		console.log('Error', e)
    // 	}
    // } else {
    // 	try {
    // 		const config = await get(
    // 			'https://dev.absolute.bet/config/user.config.json'
    // 		)
    // 		// console.log('config', config)
    // 		const lang = await get(
    // 			`https://dev.absolute.bet/config/locales/${
    // 				langIDs.get(config['LANGUAGE_ID']) ?? 'gb'
    // 			}.json`
    // 		)
    // 		// console.log('lang', lang)
    // 		setLocal('user.config', config)
    // 		setData(config, lang)
    // 	} catch (e) {
    // 		console.log('Error', e)
    // 	}
    // }
  }

  return {
    t,
    config,
    connection,
    loaded,
    langIDs,
    locales,
    getUserConfig,
    setUserConfig,
    changeLanguage,
    loadProjectConfigs,
  }
}
