import { types } from '../types/types'
import { InferType } from '../../helpers/commonInterfaces/interfaces'

export const bannersWatchers = {
  getSlides: () => {
    console.log(`get slides`)
    return {
      type: types.GET_SLIDES,
    }
  },
  getInlineBanners: () => {
    console.log(`get inline banners`)
    return { type: types.GET_INLINE_BANNERS }
  },
}

export const bannersWorkers = {
  setSlides: (payload) => {
    console.log(`set slides`)
    return {
      type: types.SET_SLIDES,
      payload,
    }
  },
  setInlineBanners: (payload) => ({
    type: types.SET_INLINE_BANNERS,
    payload,
  }),
}

export type BannersWatchersType = ReturnType<InferType<typeof bannersWatchers>>
export type BannersWorkersType = ReturnType<InferType<typeof bannersWorkers>>
