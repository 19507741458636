import React from 'react'
const def_message = `Dear Ivan Pupkin! Your money was
    successfully credited to your account.`
const SuccessPopup = ({ message = def_message, close }) => {
  return (
    <>
      <div className="h-popup__overlay" style={{ zIndex: 1 }} onClick={close}></div>
      <div className="cb-popup cb-popup--block">
        <div className="cb-popup__close fal fa-times" onClick={close}></div>
        <div className="cb-popup__wrap">
          <div className="text-left">
            <p className="font font--default-alpha-05">{message}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessPopup
