import React from 'react'
import ScoreboardScore from '../ScoreboardScore'

// const withoutScore = new Set(['92', '13', '91', '94'])

const ScoreboardCompetitors = ({ additionalData, competitors }) => {
  return (
    <div className="ip-scoreboard__score">
      <div className="flex align-center full-height">
        <div className="flex align-center-middle ip-scoreboard__wrap">
          <p
            className="font font--white font--17 font--semibold text-ellipsis"
            style={{
              lineHeight: '20px',
            }}
          >
            {competitors[0].name}
          </p>
        </div>
        <ScoreboardScore scoreboard={additionalData?.scoreboard} />
        <div className="flex align-center-middle ip-scoreboard__wrap">
          <p
            className="m-r-10 font font--white font--17 font--semibold text-ellipsis"
            style={{
              lineHeight: '20px',
            }}
          >
            {competitors[1].name}
          </p>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ScoreboardCompetitors)
