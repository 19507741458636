import React from 'react'
import Items from '../betslipComponents/Items'
import Multiples from '../betslipComponents/Multiples'
import Possible from '../betslipComponents/Possible'
import Message from '../betslipComponents/Message'
import Accept from '../betslipComponents/Accept'
import RemoveAll from '../betslipComponents/RemoveAll'
import BetslipSettings from '../betslipComponents/BetslipSettings/BetslipSettings'
import PlacedSuccess from '../../popups/PlacedSuccess'

const BetslipBody = ({
  settingsMode,
  quickbetMode,
  rememberStake,
  acceptAllOddsChanges,
  changes,
  stakes,
  multistakes,
  change,
  blur,
  focus,
  possibleWin,
  betTotal,
  quickbetPanel,
  quickbetToggle,
  toggleSettingsHandler,
  toggleAcceptAllOddsChangesHandler,
  toggleQuickbetModeHandler,
  toggleRememberStakeHandler,
  onAcceptHandler,
  removeBetHandler,
  removeAll,
  placedSuccess,
}) => {
  return (
    <div className="betslip__body">
      {stakes.length > 1 ? <RemoveAll {...{ removeAll }} /> : null}
      <Items
        {...{ placedSuccess }}
        {...{ removeBetHandler }}
        {...{ stakes }}
        {...{ change }}
        {...{ blur }}
        {...{ focus }}
      />
      {typeof multistakes.dm.bt !== 'undefined' && multistakes.mo.length > 0 ? (
        <Multiples
          className="betslip__multiples"
          {...{ multistakes }}
          {...{ focus }}
          {...{ change }}
          {...{ blur }}
        />
      ) : null}
      {!placedSuccess && (
        <BetslipSettings
          {...{ settingsMode }}
          {...{ quickbetMode }}
          {...{ rememberStake }}
          check={onAcceptHandler}
          {...{ changes }}
          {...{ quickbetPanel }}
          {...{ quickbetToggle }}
          {...{ acceptAllOddsChanges }}
          {...{ toggleSettingsHandler }}
          {...{ toggleAcceptAllOddsChangesHandler }}
          {...{ toggleQuickbetModeHandler }}
          {...{ toggleRememberStakeHandler }}
        />
      )}
      {/* {!quickbetPanel && <Accept check={onAcceptHandler} {...{ changes }} />} */}
      {!changes.accept ? <Message /> : null}
      <Possible title="Bet total:" amount={betTotal} />
      {false && <Possible title="Possible win:" amount={possibleWin} />}
    </div>
  )
}

export default BetslipBody
