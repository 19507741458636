import { removeByPath, updateByPath } from '../../helpers/utils/objectUpdate'

export const emitterByCmd = (
  oldState: any,
  rs: { data: any; path: string; cmd: string },
  emitter: (object) => void
) => {
  switch (rs.cmd) {
    case 'snapshot':
      {
        emitter(rs.data)
      }
      break
    case 'update':
      {
        updateByPath(oldState, rs.data, rs.path)
        emitter(oldState)
      }
      break
    case 'delete':
      {
        removeByPath(oldState, rs.path)
        emitter(oldState)
      }
      break
    default:
      emitter(rs.data)
  }
}
