import { types } from '../types/types'
import { InferType } from '../../helpers/commonInterfaces/interfaces'

export const favoritesWatchers = {
	getMybets: (id = 0) =>
		({
			type: types.GET_MYBETS_WATCHER,
			payload: { id },
		} as const),
}
export const favoritesWorkers = {
	addSport: (id: string) =>
		({
			type: types.ADD_SPORT_TO_FAV,
			payload: id,
		} as const),
	addLeague: (leagueId: string, sportId: string) =>
		({
			type: types.ADD_LEAGUE_TO_FAV,
			payload: { sportId, leagueId },
		} as const),
	addEvent: (id: string) =>
		({
			type: types.ADD_EVENT_TO_FAV,
			payload: id,
		} as const),
	addMarket: (id: string) =>
		({
			type: types.ADD_MARKET_TO_FAV,
			payload: id,
		} as const),
	removeSport: (id: string) =>
		({
			type: types.REMOVE_SPORT_FROM_FAV,
			payload: id,
		} as const),
	removeLeague: (leagueId: string, sportId: string) =>
		({
			type: types.REMOVE_LEAGUE_FROM_FAV,
			payload: { leagueId, sportId },
		} as const),
	removeEvent: (id: string) =>
		({
			type: types.REMOVE_EVENT_FROM_FAV,
			payload: id,
		} as const),
	removeMarket: (id: string) =>
		({
			type: types.REMOVE_MARKET_FROM_FAV,
			payload: id,
		} as const),
}

export type favoritesWorkersTypes = ReturnType<
	InferType<typeof favoritesWorkers>
>
