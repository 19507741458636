import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { favoritesWorkers } from 'redux/actions/favoritesActions'
import { getFavorites } from 'redux/selectors/favoritesSelectors'
import SportIcon from '../../../../common/elements/SportIcon'
import Text from '../../../../common/elements/Text'

const ClassificationItemHeader = ({ title, id }) => {
  const dispatch = useDispatch()
  const favorites = useSelector(getFavorites)

  const toggleFavSport = (e) => {
    e.stopPropagation()
    if (favorites.sports?.some(sp => sp === id.toString())) {
      dispatch(favoritesWorkers.removeSport(id.toString()))
    } else {
      dispatch(favoritesWorkers.addSport(id.toString()))
    }
  }

  return (
    <div className="flex align-middle ipn-classification__header">
      <div className="ipn-classification__icon">
        <SportIcon name={title} id={id} />
      </div>
      <Text title={title} className="font font--white text-ellipsis ipn-classification__text" />
      <div className={"ipn-classification__favourite--icon"
        + (favorites.sports.some(sport => sport === id.toString()) ? ' active' : '')} onClick={toggleFavSport} />
    </div>
  )
}

export default React.memo(ClassificationItemHeader)
