import React from 'react'

const DropDownItem = ({ item, onChange, checked }) => {
  return (
    <div className="flex align-middle ip-classification-header__checkbox m-b-10">
      <input
        className="checkbox checkbox--gold"
        type="checkbox"
        id={item.id}
        checked={checked}
        onChange={(e) => onChange(e, item.id)}
      />
      <label className="font font--white m-l-5 text-ellipsis" htmlFor={item.id}>
        {item.name}
      </label>
    </div>
  )
}

export default React.memo(DropDownItem)
