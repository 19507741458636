import { types } from '../types/types'

interface IMessage {
  checked: boolean
  isNew: boolean
  header: string
  short: string
  date: string
}

export interface IAccountReducer {
  inbox: IMessage[],
  sent: IMessage[],
  verified: boolean,
  showMoney: boolean 
}

const initialState: IAccountReducer = {
	inbox: [
		{
			checked: false,
			isNew: true,
			header: 'Страховка ставки',
			short: `Не уверен в исходе матча? Мы застрахуем бонусом
            твою ставку, а тебе останется лишь…`,
			date: '21:00',
		},
		{
			checked: false,
			isNew: false,
			header: 'Страховка ставки',
			short: `Не уверен в исходе матча? Мы застрахуем бонусом
            твою ставку, а тебе останется лишь…`,
			date: '10 Jan',
		},
		{
			checked: false,
			isNew: false,
			header: 'Страховка ставки',
			short: `Не уверен в исходе матча? Мы застрахуем бонусом
            твою ставку, а тебе останется лишь…`,
			date: '9 Jan',
		},
	],
	sent: [
		{
			checked: false,
			isNew: false,
			header: 'Страховка ставки',
			short: `Не уверен в исходе матча? Мы застрахуем бонусом
            твою ставку, а тебе останется лишь…`,
			date: '3 Jan',
		},
		{
			checked: false,
			isNew: false,
			header: 'Страховка ставки',
			short: `Не уверен в исходе матча? Мы застрахуем бонусом
            твою ставку, а тебе останется лишь…`,
			date: '1 Jan',
		},
		{
			checked: false,
			isNew: false,
			header: 'Страховка ставки',
			short: `Не уверен в исходе матча? Мы застрахуем бонусом
            твою ставку, а тебе останется лишь…`,
			date: '22 Dec',
		},
		{
			checked: false,
			isNew: false,
			header: 'Страховка ставки',
			short: `Не уверен в исходе матча? Мы застрахуем бонусом
            твою ставку, а тебе останется лишь…`,
			date: '11 Dec',
		},
		{
			checked: false,
			isNew: false,
			header: 'Страховка ставки',
			short: `Не уверен в исходе матча? Мы застрахуем бонусом
            твою ставку, а тебе останется лишь…`,
			date: '1 Dec',
		},
	],
	verified: false,
	showMoney: localStorage.getItem('showMoney') === 'false' ? false : true,
}

export const account = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_MESSAGES:
			if (action.payload === '') {
				return state
			} else {
				return {
					...state,
					[action.payload.type]: [...action.payload.messages],
				}
			}
		case types.SET_VERIFY:
			if (action.payload === '') {
				return state
			} else {
				return {
					...state,
					verified: action.payload,
				}
			}
		case types.SET_SHOW_MONEY:
			return {
				...state,
				showMoney: action.payload,
			}
		default:
			return state
	}
}
