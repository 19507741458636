import React from 'react'
import { uppercaseFirstLetter } from '../../../../../helpers/transform/transform'
import Text from '../../../../common/elements/Text'

const MarketName = ({ title }) => {
	title = uppercaseFirstLetter(title)

	return (
		<div className="gll-market-group__name">
			{/* <div className="tooltip">
				<div className="tooltip__item">i</div>
				<div className="tooltip__dropdown">
					<p className="font font--12 font--grey-300">
						Bets with a coefficient of 1.5 and higher participate in the
						promotion.
					</p>
				</div>
			</div> */}
			<Text className="font font--11" title={title} />
		</div>
	)
}

export default React.memo(MarketName)
