import React from 'react'
import { useTranslation } from 'react-i18next'

const VolumeDropDown = ({ state, rangeHandler, toggleHandler }) => {
  const { t } = useTranslation()
  const { soundRangeValue, soundEffectsValue } = state

  return (
    <>
      <div
        role="button"
        onKeyPress={() => 0}
        tabIndex={0}
        className="h-popup__overlay"
        style={{ zIndex: 1 }}
        onClick={() => toggleHandler('volume')}
      />
      <div style={{ display: 'block' }} className="volume__menu">
        <p className="font font--11 font--default-alpha-05">{t('Video Volume')}</p>
        <div className="flex align-middle m-t-10 m-b-10">
          <i className="fas fa-volume m-r-5 volume__icon" />
          <input className="range" type="range" value={soundRangeValue} onChange={rangeHandler} />
        </div>
        <div className="flex align-middle align-justify">
          <label className="font font--11 font--default-alpha-05" htmlFor="sound">
            {t('Sound Effects')}
          </label>
          <label className="toggle">
            <input
              className="toggle__input"
              type="checkbox"
              id="sound"
              checked={soundEffectsValue}
              onChange={() => toggleHandler('soundEffectsValue')}
            />
            <span className="toggle__swipe" />
            <span className="toggle__bg" />
          </label>
        </div>
      </div>
    </>
  )
}

export default VolumeDropDown
