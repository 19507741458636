import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import { v4 as uuidv4 } from 'uuid'

const Navigate = ({ routes }) => {
	return (
		<Switch>
			{routes?.map(item => {
				if (!item.id) {
					item.id = uuidv4()
				}

				return item.private ? (
					<PrivateRoute key={item.id} {...item} />
				) : (
					<Route key={item.id} {...item} />
				)
			})}
		</Switch>
	)
}

export default Navigate
