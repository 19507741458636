import { types } from '../types/types'
import { IState as ConfigState } from '../reducers/configReducer'
import { IBalance, ICustomer, InferType } from '../../helpers/commonInterfaces/interfaces'

export const configWatchers = {
  getConfig: () => ({ type: types.GET_CONFIG_WATCHER } as const),
  getBalance: () => ({ type: types.GET_BALANCE_WATCHER } as const),
  getOddTypes: () => ({ type: types.GET_ODD_TYPES } as const),
}

export const configWorkers = {
  setOddTypes: (data: any[]) =>
    ({
      type: types.SET_ODD_TYPES,
      payload: data,
    } as const),
  setBalance: (data: IBalance) =>
    ({
      type: types.SET_BALANCE,
      payload: data,
    } as const),
  changeLanguage: (languageId: string) =>
    ({
      type: types.CHANGE_LANGUAGE,
      payload: languageId,
    } as const),
  setLocales: (data: any) =>
    ({
      type: types.SET_LOCALES,
      payload: data,
    } as const),
  setConfig: (data: ConfigState) =>
    ({
      type: types.SET_CONFIG,
      payload: data,
    } as const),
  updateCustConfig: (key: keyof ICustomer, value: any) => ({
    type: types.SET_CUSTOMER_CONFIG,
    payload: { [key]: value },
  }),
}

export type ConfigWorkersType = ReturnType<InferType<typeof configWorkers>>

export type ConfigWatchersType = ReturnType<InferType<typeof configWatchers>>

// export const getConfigWatcher = () =>
// 	({ type: types.GET_CONFIG_WATCHER } as const)
//
// export const getBalanceWatcher = () =>
// 	({ type: types.GET_BALANCE_WATCHER } as const)
//
// export const setBalance = (data: IBalance) =>
// 	({
// 		type: types.SET_BALANCE,
// 		payload: data,
// 	} as const)
//
// export const setConfig = (data: ConfigState) =>
// 	({
// 		type: types.SET_CONFIG,
// 		payload: data,
// 	} as const)
//
// export type ConfigActionsTypes =
// 	| ReturnType<typeof setConfig>
// 	| ReturnType<typeof setBalance>
