import React from 'react'
import LvTranslationLive from './LvTranslationLive'
import LvTranslationStreaming from './LvTranslationStreaming'

const LiveTranslationBody = ({ active, soundRangeValue }) => {
  return (
    <div className="lv-translation__body">
      {active === 'streaming' ? (
        <LvTranslationStreaming soundRangeValue={soundRangeValue} />
      ) : (
        <LvTranslationLive />
      )}
    </div>
  )
}

export default LiveTranslationBody
