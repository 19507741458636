import { useState, useEffect } from 'react'
import * as Yup from 'yup'

export const useAccount = (): any => {
  const [done, setDone] = useState(false)
  const [formIsFulled, setFormIsFulled] = useState(false)
  const [persInfo, setPersInfo] = useState({
    initialValues: {
      firstName: 'Sergey',
      surname: 'Prokofiev',
      day: '05',
      month: '03',
      year: '1999',
      country: 'Ukraine',
      city: 'Kyiv',
      address: 'Khreshchatyk, 44',
      postcode: '01001',
      password: '',
    },
  })
  const [contact, setContact] = useState({
    initialValues: {
      number: '380930123456',
      email: 'prokofiev@gmail.com',
      password: '',
    },
  })
  const [accountSettings, setAccountSettings] = useState({
    initialValues: {
      language: 'English',
      odds: 'Decimal',
      timeZone: 'GMT+2',
      maxInactiv: '1 Hour',
    },
  })
  const [changePassword, setChangePassword] = useState({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })
  const [replenishment, setReplenishment] = useState({
    initialValues: {
      amount: '1',
    },
  })

  useEffect(() => {
    if (formIsFulled) {
      makeRequest()
      clearValues()
    }
  }, [formIsFulled])

  const submit = (formName, data) => {
    switch (formName) {
      case 'persInfo':
        setFormIsFulled(true)
        return
      case 'contact':
        setFormIsFulled(true)
        return
      case 'accountSettings':
        setFormIsFulled(true)
        return
      case 'changePassword':
        setFormIsFulled(true)
        return
      default:
        return
    }
  }

  const clearValues = () => {
    setPersInfo({
      initialValues: {
        firstName: 'Sergey',
        surname: 'Prokofiev',
        day: '05',
        month: '03',
        year: '1999',
        country: 'Ukraine',
        city: 'Kyiv',
        address: 'Khreshchatyk, 44',
        postcode: '01001',
        password: '',
      },
    })
  }

  const makeRequest = () => {
    console.log(persInfo)
    clearValues()
    setDone(true)
  }

  const numericRegex = /^[0-9]+$/

  const schemaPersInfo = Yup.object().shape({
    firstName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
    surname: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
    day: Yup.string().required('Required'),
    month: Yup.string().required('Required'),
    year: Yup.string().required('Required'),
    country: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .matches(/[a-zA-Z]/, 'Country can only contain Latin letters.')
      .required('Required'),
    city: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .matches(/[a-zA-Z]/, 'City can only contain Latin letters.')
      .required('Required'),
    address: Yup.string().required('Required'),
    postcode: Yup.string()
      .min(3, 'Too Short!')
      .max(7, 'Too Long!')
      .matches(numericRegex, 'Postcode is a number')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .required('No password provided.'),
  })
  const schemaContact = Yup.object().shape({
    number: Yup.string()
      .min(12, 'Too')
      .max(12, 'Too')
      .matches(numericRegex, 'only numbers')
      .required('Required'),
    email: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .required('No password provided.'),
  })
  const schemaAccountSettins = Yup.object().shape({
    language: Yup.string().required('Required'),
    odds: Yup.string().required('Required'),
    timeZone: Yup.string().required('Required'),
    maxInactiv: Yup.string().required('Required'),
  })
  const schemaChangePassword = Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .required('No password provided.'),
    newPassword: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .required('No password provided.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required'),
  })
  const schemaReplenishment = Yup.object().shape({
    amount: Yup.string()
      .min(2, 'Minimum amount 10 Bin')
      .matches(numericRegex, 'only numbers')
      .required('Required'),
  })

  return {
    persInfo,
    setPersInfo,
    contact,
    setContact,
    accountSettings,
    setAccountSettings,
    changePassword,
    setChangePassword,
    replenishment,
    setReplenishment,
    done,
    submit,
    setDone,
    schemaPersInfo,
    schemaContact,
    schemaAccountSettins,
    schemaChangePassword,
    schemaReplenishment,
  }
}
