import React from 'react'
import img from '../../../../assets/img/banners/BannerEventMoreInfo.png'
const EventMoreInfo = () => {
  return (
    <div className="wn-banner wn-banner--shadow" style={{ backgroundImage: `url(${img})` }}>
      <div className="wn-banner__wrap">
        <div className="text-left full-width">
          <p className="font font--gold m-b-10">England Cup</p>
          <p className="font font--white font--20 m-b-10">Liverpool - Manchester </p>
          <p className="font font--white-alpha-05 m-b-10">24 nov / 19:00</p>
        </div>
        <button className="btn btn--outline btn--h-24 wn-banner__btn">
          <span className="font font--gold font--11">More info</span>
        </button>
      </div>
    </div>
  )
}

export default EventMoreInfo
