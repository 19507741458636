import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { scrollTo } from '../../../helpers/scroll'

const BtnScroll = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  const clickHandler = () => {
    const center = document.querySelector('.content .center .simplebar-content-wrapper')
    const overview = document.querySelector('.content .simplebar-content-wrapper')
    // const asideRight = document.querySelector('.height-aside-scroll .simplebar-content-wrapper')
    if (center) {
      scrollTo(center, 0, 250)
    }
    if (overview) {
      scrollTo(overview, 0, 250)
    }
    /* if (asideRight) {
      scrollTo(asideRight, 0, 250)
    } */
  }

  setInterval(() => {
    const center = document.querySelector('.content .center .simplebar-content-wrapper')
    const overview = document.querySelector('.content .simplebar-content-wrapper')
    // const asideRight = document.querySelector('.height-aside-scroll .simplebar-content-wrapper')
    const deps = [center, overview]
    const filteredDeps = deps.filter((el) => el)
    if (isScrolled) {
      if (filteredDeps.every((el) => (el?.scrollTop ?? 0) < 100)) {
        setIsScrolled(false)
      }
    } else {
      filteredDeps?.map((el) => {
        if (el?.scrollTop) {
          setIsScrolled(true)
        }
      })
    }
  }, 100)

  return (
    <button
      className={'btn btn--scroll' + (isScrolled ? ' active' : '')}
      onClick={isScrolled ? clickHandler : () => { }}
    >
      <i className="fal fa-angle-up"></i>
    </button>
  )
}

export default BtnScroll
