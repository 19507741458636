import { IRootState } from '../reducers/rootReducer'
import { Nullable, IBet } from '../../helpers/commonInterfaces/interfaces'

export const getMyBetsData = (state: IRootState): { 1: any[]; 2: any[]; 3: any[] } => {
  return state.myBets.data
}

export const getMyBetsLoading = (state: IRootState): boolean => {
  return state.myBets.loading
}

export const getMyBetsCashout = (
  state: IRootState
): { errors: { text: string; code: number }; status: number; ID: number } => {
  return state.myBets.cashout
}
