import { call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { types } from '../../types/types'
import { links } from '../../../connection/links'
import { mybetsWorkers } from '../../actions/myBetsActions'
import { createSubscriptionChannel, unsubscribe } from '../subscriptionHandler'
import _ from 'lodash'
import { store } from '../../store'
import { emitterByCmd } from '../../utils/emitter'
import { api } from 'connection/api'
import { createRequest } from '../requestHandler'

function* myBetsCallback(data) {
  yield put(mybetsWorkers.getMybets(data))
}

function* getMyBets(inp: { type: string; payload: { id: number } }) {
  console.log('GET MY BETS getMyBets', inp)
  const type = inp.payload.id

  const config = yield select((state) => state.config)
  console.log(`id : : : : : `, inp.payload.id)
  console.log(`config`, config)
  const channel = yield call(
    createSubscriptionChannel,
    '/mybets',
    {
      ...inp.payload,
      type,
    },
    'mybets-' + type,
    [
      (emitter, rs) => {
        const oldState = _.cloneDeep(store.getState().myBets)
        emitterByCmd(oldState, rs, emitter)
      },
    ],
    3500,
    config
  )

  yield fork(function* () {
    yield take(`REMOVE_MYBETS_${type}`)
    console.log('UNSUBSCRIBE', 'mybets-' + type)
    unsubscribe(links.mybets, 'mybets-' + type, 3500, config)
  })

  try {
    while (true) {
      const data = yield take(channel)
      yield put(mybetsWorkers.getMybets({ data, type }))
      yield put(mybetsWorkers.setLoading(false))
    }
  } catch (e) {
    yield put(mybetsWorkers.setLoading(false))
    channel.close()
  }
}
function* createRule(data: { type: string; payload: { amount: number; id: number } }) {
  const state = yield select((state) => state)
  console.log(`create rule started`)
  const req = yield call(
    createRequest,
    api.createRule,
    {
      id: data.payload.id,
      type: 2,
      token: state.config.CUSTOMER_CONFIG.TOKEN,
      amount: data.payload.amount,
    },
    'create-rule',
    [
      (emitter, response) => {
        if (response.data) {
          emitter(response.data)
        }
      },
    ],
    0,
    state.config
  )
  try {
    while (true) {
      const response = yield take(req)
      console.log(`createrule response`, response)
      yield put(mybetsWorkers.setCashout(response))
    }
  } catch (e) {
    yield cancel()
    console.log(`Errored adding events `, e)
  }
}

function* cashout(data: { type: string; payload: { amount: number; id: number } }) {
  const state = yield select((state) => state)
  console.log(`cashout started`)
  const req = yield call(
    createRequest,
    api.cashout,
    {
      id: data.payload.id,
      type: 1,
      token: state.config.CUSTOMER_CONFIG.TOKEN,
      amount: data.payload.amount,
    },
    'cashout',
    [
      (emitter, response) => {
        console.log(`response is`, response)
        if (response.data) {
          console.log(`response data: `, response.data)
          emitter(response.data)
        }
      },
    ],
    0,
    state.config
  )
  console.log(`hello there`)
  try {
    while (true) {
      const response = yield take(req)
      console.log(`cashout response`, response)
      yield put(mybetsWorkers.setCashout(response))
    }
  } catch (e) {
    yield cancel()
    console.log(`Errored adding events `, e)
  }
}

export const myBetsSagas = [
  takeEvery(types.GET_MYBETS_WATCHER, getMyBets),
  takeEvery(types.CASHOUT, cashout),
  takeEvery(types.CREATE_RULE, createRule),
]
