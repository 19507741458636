import React from 'react';
import { NavLink } from "react-router-dom";

const NavBarItem = ({ item }) => {

    return (
        <li className="cb-navbar__item">
            <NavLink
                activeClassName='cb-navbar__link--active'
                className="font font--white font--semibold cb-navbar__link"
                to={item.path}
            >
                {item.title}
                {item.count !== undefined && <div className="cb-navbar__count">{item.count}</div>}
            </NavLink>
        </li>
    );
};

export default NavBarItem;