import React, { ChangeEvent, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ButtonGroup from './ButtonGroup'
import RangeInput from './RangeInput'
import { getLiveCompetitors } from '../../../../../../../redux/selectors/eventSelectors'
import { getPrematchCompetitors } from '../../../../../../../redux/selectors/prematchEventSelectors'
import { getRangeInputsValues } from '../../../../../../../helpers/transform/eventTransform'
import { OddOutcome } from '../../../../../../../helpers/commonInterfaces/interfaces'
import { getBetslip } from '../../../../../../../redux/selectors/betslipSelectors'
import { getOddsType } from '../../../../../../../redux/selectors/configSelectors'
import BetButton from 'components/common/buttons/BetButton'
import ColumnTitle from '../../ColumnTitle'

interface IRangeBody {
  outcomes: Array<OddOutcome>
  sportId: number
  eventId: number
  createBet: (
    eventId: number,
    id: number,
    od: number,
    ha: string,
    check: boolean,
    qb: boolean
  ) => void
}

const RangeBody: React.FC<IRangeBody> = ({
  outcomes,
  eventId,
  sportId,
  createBet,
}) => {
  const { quickBet, bt } = useSelector(getBetslip)
  const ODDS_TYPE = useSelector(getOddsType)

  const values = getRangeInputsValues(outcomes)
  const location = useLocation()

  const selector = location.pathname.includes('live')
    ? getLiveCompetitors
    : getPrematchCompetitors

  const competitors = useSelector(selector)

  const [firstValue, setFirstValue] = useState<string>(values.first[0])
  const [secondValue, setSecondValue] = useState<string>(values.second[0])

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'first') {
      setFirstValue(value)
    } else {
      setSecondValue(value)
    }
  }

  const findOutcome = (): OddOutcome | undefined => {
    return outcomes.find(item => {
      if (item.outcome === `${firstValue}:${secondValue}`) return item
    })
  }

  const currentOutcome = useCallback((): OddOutcome | undefined => {
    return findOutcome()
  }, [firstValue, secondValue])

  const drawOutcomes = outcomes.filter(
    outcome =>
      outcome.outcome.split(':')[0] === outcome.outcome.split(':')[1] &&
      outcome.status !== 1
  )
  const co1WinOutcomes = outcomes.filter(
    outcome =>
      outcome.outcome.split(':')[0] > outcome.outcome.split(':')[1] &&
      outcome.status !== 1
  )

  // without slider
  return (
    <div className="gll-market-group__body flex">
      <div className="gll-market-group__column">
        <ColumnTitle title={/* competitors?.[0]?.name ?? */ '1'} />
        {co1WinOutcomes.map(outcome => (
          <div
            key={outcome.id + outcome.outcome}
            className="gll-market-group__cell">
            <BetButton
              item={outcome}
              {...{ eventId }}
              {...{ sportId }}
              onCreateBet={createBet.bind(null, eventId)}
              panel={quickBet}
              allStakes={bt}
              oddsType={ODDS_TYPE}
              br={true}
            />
          </div>
        ))}
      </div>
      {outcomes.some(
        outcome =>
          outcome.outcome.split(':')[0] === outcome.outcome.split(':')[1]
      ) && (
          <div className="gll-market-group__column">
            <ColumnTitle title={'Draw'} />
            {drawOutcomes.map((outcome, idx) => (
              <div
                key={outcome.id + outcome.outcome}
                className="gll-market-group__cell"
                style={{
                  borderLeft:
                    idx >= co1WinOutcomes.length ? '1px solid #e7e7e7' : '0',
                }}>
                <BetButton
                  item={outcome}
                  {...{ eventId }}
                  {...{ sportId }}
                  onCreateBet={createBet.bind(null, eventId)}
                  panel={quickBet}
                  allStakes={bt}
                  oddsType={ODDS_TYPE}
                  br={true}
                />
              </div>
            ))}
          </div>
        )}
      <div className="gll-market-group__column">
        <ColumnTitle title={/* competitors?.[1]?.name ?? */ '2'} />
        {outcomes
          .filter(
            outcome =>
              outcome.outcome.split(':')[0] < outcome.outcome.split(':')[1] &&
              outcome.status !== 1
          )
          .map((outcome, idx) => (
            <div
              key={outcome.id + outcome.outcome}
              className="gll-market-group__cell"
              style={{
                borderLeft:
                  idx >= drawOutcomes.length ? '1px solid #e7e7e7' : '0',
              }}>
              <BetButton
                item={outcome}
                {...{ eventId }}
                {...{ sportId }}
                onCreateBet={createBet.bind(null, eventId)}
                panel={quickBet}
                allStakes={bt}
                oddsType={ODDS_TYPE}
                br={true}
              />
            </div>
          ))}
      </div>
    </div>
  )

  // with slider
  return (
    <div className="gll-market-group__body">
      <div className="gll-market-group__column">
        <div className="gll-market-group__cell height-auto p-a-10">
          <div className="flex align-center-middle">
            <RangeInput
              name="first"
              min={values.first[0]}
              max={values.first[values.first.length - 1]}
              value={firstValue}
              onChange={onChange}
              competitor={competitors[0]?.name ?? 'Competitor 1'}
            />
            {/*<BetButton
							item={currentOutcome}
							onCreateBet={createBet.bind(null, eventId)}
							panel={quickbet}
							allStakes={bt}
							// oddsType={ODDS_TYPE}
						/>*/}
            <ButtonGroup
              secondValue={secondValue}
              firstValue={firstValue}
              currentOutcome={currentOutcome}
              isCurSroce={true}
              {...{ eventId }}
              {...{ sportId }}
              br={true}
            />
            <RangeInput
              name="second"
              min={values.second[0]}
              max={values.second[values.second.length - 1]}
              value={secondValue}
              onChange={onChange}
              competitor={competitors[1]?.name ?? 'Competitor 2'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(RangeBody)
