import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getOddTypes } from 'redux/selectors/configSelectors'

const ChangeMarketDropDown = ({ changeShow, oddsName, oddsId, onChangeMarket }) => {
  const oddTypes = useSelector(getOddTypes)
  const [names, setNames] = useState(oddsName)

  useEffect(() => {
    //setNames(oddsId?.map((id) => oddTypes.find((type) => type.id === id)?.name))
  }, [oddTypes])

  return (
    <>
      <p className="font font--white-alpha-05 dropdown__toggle" onClick={changeShow}>
        Change Market
      </p>
      <div className="dropdown__menu">
        {oddsId?.map((item, i) => (
          <a className="dropdown__item" key={i} onClick={() => onChangeMarket(item, i)}>
            <span className="font">{names?.[i]}</span>
          </a>
        ))}
      </div>
    </>
  )
}

export default React.memo(ChangeMarketDropDown)
