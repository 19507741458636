import React from 'react'
import Navigate from '../../common/routing/Navigate'
import { myAccountRouting } from '../../../routing/accountRouting/myAccountRouting'

const MyAccount = () => {
	return (
		<div className="container">
			<Navigate routes={myAccountRouting} />
		</div>
	)
}

export default MyAccount
