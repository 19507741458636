import React, { useEffect } from 'react'
import ControlBarItem from './controlBarItem'
import { useSlider } from '../../../hooks/useSlider'
import Button from '../../common/buttons/Button'

const ControlBar = ({ data, setDay, active }) => {
  const sliderRef = React.useRef() as React.MutableRefObject<HTMLDivElement>

  const { setSliderData, showButtons, toNextSlide, toPrevSlide, transform, max, min } = useSlider()

  useEffect(() => {
    setSliderData(sliderRef)
  }, [data])

  return (
    <div className="ips-control-bar relative">
      {showButtons && !min && (
        <Button onClick={toPrevSlide} className="ipn-control-btn ipn-control-left" />
      )}
      <div
        className="flex align-middle ips-control-bar__wrap full-height"
        ref={sliderRef}
        style={{
          transform: `translate(-${transform}px, 0px)`,
        }}
      >
        <>
          {Object.values(data)?.map(({ NA, DD }) => (
            <ControlBarItem
              key={DD}
              day={NA}
              date={DD}
              click={() => setDay(NA)}
              active={active === NA}
            />
          ))}
        </>
      </div>
      {showButtons && !max && (
        <Button onClick={toNextSlide} className="ipn-control-btn ipn-control-right" />
      )}
    </div>
  )
}

export default ControlBar
