import React from 'react'
import {
  fromIdToIcons,
  transformFromSoccerData,
} from '../../../../helpers/transform/scoreboardTransform'
import ColumnCompetitorScores from '../common/tableComponents/ColumnCompetitorsScores'

const SoccerScoreboard = ({ currentAwayScore, currentHomeScore, soccerStatistics }) => {
  const transformedData = transformFromSoccerData({
    currentAwayScore,
    currentHomeScore,
    soccerStatistics,
  })

  const titles = Object.keys(transformedData)

  return (
    <>
      {titles?.map((title) => {
        const { homeScore, awayScore } = transformedData[title]

        return (
          <ColumnCompetitorScores
            key={title}
            soccer={true}
            title={fromIdToIcons[title]}
            homeScore={homeScore}
            awayScore={awayScore}
          />
        )
      })}
    </>
  )
}

export default React.memo(SoccerScoreboard)
