import React, { useContext } from 'react'
import LoginOverlay from './LoginOverlay'
import { RegisterContext } from '../../../context/registrationContext'
import { EnterCode } from './EnterCode'
import { CodeSent } from './CodeSent'

const AuthButton = () => {
  const { showRegistration, showLostCredentialsForm, codeSent, showForm, enterCode, loginForm } = useContext(
    RegisterContext
  )
  return (
    <>
      <div className="flex align-center align-justify">
        <div className="h-account__wrap">
          {/* <button
						className="btn btn--grey-600 btn--h-24 h-account__btn"
						onClick={showForm}>
						<span className="font font--11 font--white">
							{t('COMMON.SUBMENU.LOG_IN')}
						</span>
					</button> */}
          {/* <Transition in={loginForm} unmountOnExit mountOnEnter timeout={1000} classNames='fade'> */}
          {loginForm && (
            /*<LoginForm
              active={loginForm}
              handler={showLostCredentialsForm}
              overlayClick={showForm}
            />*/
            <LoginOverlay active={false} />
          )}
          {enterCode && <EnterCode />}
          {codeSent && <CodeSent />}
          {/* </Transition> */}
        </div>
        <div className="h-account__wrap">
          <button onClick={showForm} className="btn btn--gold btn--h-24 h-account__btn">
            <span className="font font--11">{'Join/Login'}</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default AuthButton
