import React from 'react'
import { useTranslation } from 'react-i18next'

const Support = () => {
  const { t } = useTranslation()

  return (
    <div className="mn-popup__wrap">
      <div className="text-center">
        <p className="font font--white-alpha-05">
          {t('Need some help?')}
          <a className="font font--blue m-l-5" href="#">
            {t('Support')}
          </a>
        </p>
      </div>
    </div>
  )
}

export default Support
