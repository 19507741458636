import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import ColumnTitle from '../ColumnTitle'
import MarketColumn from '../MarketColumn'
import MarketRow from '../MarketRow'
import { getBetslip } from '../../../../../../redux/selectors/betslipSelectors'
import { getOddsType } from '../../../../../../redux/selectors/configSelectors'
import { OddOutcome } from '../../../../../../helpers/commonInterfaces/interfaces'
import BetButton from 'components/common/buttons/BetButton'

type IOverUnderColumn = {
  title: string
}

const OverUnderColumn: React.FC<IOverUnderColumn> = React.memo(
  function OverUnderColumn({ title, children }) {
    return (
      <MarketColumn>
        <ColumnTitle title={title} />
        {children}
      </MarketColumn>
    )
  }
)
interface IOverUnder {
  outcomes: Array<OddOutcome>
  sportId: number
  eventId: number
  createBet: (
    eventId: number,
    id: number,
    od: number,
    ha: string,
    check: boolean,
    qb: boolean
  ) => void
}
const OverUnderBody: React.FC<IOverUnder> = ({
  outcomes,
  eventId,
  sportId,
  createBet,
}) => {
  const { quickBet, bt } = useSelector(getBetslip)
  const ODDS_TYPE = useSelector(getOddsType)

  const over: Array<ReactElement> = []
  const under: Array<ReactElement> = []

  const sorted_outcomes = outcomes.sort(sortOutcomes)
  sorted_outcomes.forEach(outcome => {
    const cloneOutcome = { ...outcome }

    if (cloneOutcome.outcome.includes('Over')) {
      cloneOutcome.outcome = cloneOutcome.outcome.replace('Over ', '')
      over.push(
        <div
          key={cloneOutcome.outcomeId + cloneOutcome.specifiers?.total}
          className="gll-market-group__cell">
          <BetButton
            item={cloneOutcome}
            key={cloneOutcome.id + cloneOutcome.outcome}
            onCreateBet={createBet.bind(null, eventId)}
            panel={quickBet}
            allStakes={bt}
            oddsType={ODDS_TYPE}
          />
        </div>
      )
    } else {
      cloneOutcome.outcome = outcome.outcome.replace('Under ', '')
      under.push(
        <div
          key={cloneOutcome.outcomeId + cloneOutcome.specifiers?.total}
          className="gll-market-group__cell">
          <BetButton
            item={cloneOutcome}
            key={cloneOutcome.id + cloneOutcome.outcome}
            onCreateBet={createBet.bind(null, eventId)}
            panel={quickBet}
            allStakes={bt}
            oddsType={ODDS_TYPE}
          />
        </div>
      )
    }
  })

  return (
    <div className="gll-market-group__body">
      <MarketRow isMulti={false}>
        <OverUnderColumn title="Over">
          {over}
        </OverUnderColumn>
        <OverUnderColumn title="Under">
          {under}
        </OverUnderColumn>
      </MarketRow>
    </div>
  )
}

export default React.memo(OverUnderBody)

function sortOutcomes(a, b) {
  if (Number(a.outcome.split(' ')[1]) < Number(b.outcome.split(' ')[1])) {
    return -1
  }
  if (Number(a.outcome.split(' ')[1]) > Number(b.outcome.split(' ')[1])) {
    return 1
  }
  return 0
}
