import React from 'react'
import { modifyBets } from '../../../../helpers/modifyBets'

const MarketValueWithName = ({ outcome, highlight, oddValue, oddType }) => {
  return (
    <span className="nowrape flex flex-1-1">
      <span className="font font--grey-300 text-ellipsis mx-w flex-1-1">{outcome}</span>
      <span className={'font font--grey-500 flex-1-1 ' + highlight.className}>
        {modifyBets(oddValue, oddType)}
      </span>
    </span>
  )
}

export default React.memo(MarketValueWithName)
