import React from 'react'
import icon from '../../../assets/img/icon-country/int.png'

const SearchIcon = () => {
  return (
    <div className="h-search__img">
      <img src={icon} alt={''} />
    </div>
  )
}

export default SearchIcon
