import React from 'react'
import Content from '../../components/containers/main/Content'
import LiveMenu from '../../components/headerComponents/menus/LiveMenu'
import AsideRight from '../../components/containers/main/AsideRight'
import AsideRightContext from '../../context/asideRightContext'
import ScheduleWrapper from '../../components/forLivePageComponents/schedule/scheduleWrapper'
import Main from 'components/containers/main/Main'

const Schedule = () => {
  return (
    <>
      <LiveMenu />
      <ScheduleWrapper />
    </>
  )
}

export default Schedule
