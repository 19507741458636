import React from 'react'
import '../../assets/styles/registrationNew.css'

const Overlay: React.FC<{ node?: React.MutableRefObject<any> }> = ({ node, children }) => {
  return (
    <div ref={node} className="overlay overlay--main overlay--block">
      {children}
    </div>
  )
}

export default Overlay
