import React, { useContext } from 'react'
import AuthButton from './AuthButton'
import UserAccount from './UserAccount'
import { ConfigContext } from '../../../context/globalConfig'
import { useSelector } from 'react-redux'
import { getConfig } from 'redux/selectors/configSelectors'

const AccountWrapper = () => {
	const { CUSTOMER_CONFIG } = useSelector(getConfig)

	return (
		<div className="h-account">
			{CUSTOMER_CONFIG?.LOGGED_IN ? <UserAccount /> : <AuthButton />}
		</div>
	)
}

export default AccountWrapper
