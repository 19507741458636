export const days = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]
export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

const transformDate = number => {
	return number < 10 ? `0${number}` : `${number}`
}

export const getCurrentTime = startDate => {
	const [dateString, timeString] = startDate.split('T')

	const [year, month, day] = dateString.split('-')
	const [hours, minutes] = timeString.split(':')

	const date = new Date(
		Number(year),
		Number(month),
		Number(day),
		Number(hours),
		Number(minutes)
	)

	return {
		month: months[date.getMonth()],
		weekDay: days[date.getDay()],
		day: date.getDate(),
		hours: transformDate(date.getHours()),
		minutes: transformDate(date.getMinutes()),
		seconds: transformDate(date.getSeconds()),
	}
}
