import React from 'react'
import { useSelector } from 'react-redux'
import { getAccount } from 'redux/selectors/accountSelectors'
import NavBarLink from './NavBarLink'

const UserAsideNavBar = () => {
	const { inbox } = useSelector(getAccount)
	const data = [
		{
			title: 'My Account',
			innerLinks: [
				{
					title: 'Personal Information',
					path: '/account/my-account/personal-information',
				},
				{
					title: 'Contact',
					path: '/account/my-account/contact',
				},
				{
					title: 'Account Verification',
					path: '/account/my-account/account-verification',
				},
				{
					title: 'Account Settings',
					path: '/account/my-account/account-settings',
				},
				{
					title: 'Change Password',
					path: '/account/my-account/change-password',
				},
			],
		},
		{
			title: 'History',
			innerLinks: [
				{
					path: '/account/history/bet-history',
					title: 'Bet History',
				},
				{
					path: '/account/history/casino-games',
					title: 'Casino Games',
				},
				{
					path: '/account/history/league-casino',
					title: 'Live Casino Games',
				},
			],
		},
		{
			title: 'Balance',
			innerLinks: [
				{
					path: '/account/balance/deposit',
					title: 'Deposit',
				},
				{
					path: '/account/balance/withdraw',
					title: 'Withdraw',
				},
				{
					path: '/account/balance/history',
					title: 'History',
				},
			],
		},
		{
			title: 'Offers',
			innerLinks: [
				{
					path: '/account/offers/available-offers',
					title: 'Available Offers',
				},
				{
					path: '/account/offers/active',
					title: 'Active',
				},
				{
					path: '/account/offers/archive',
					title: 'Archive',
				},
				{
					path: '/account/offers/open',
					title: 'Open',
				},
			],
		},
		{
			title: 'Messages',
			notification: true,
			innerLinks: [
				{
					path: '/account/messages/inbox',
					title: 'Inbox',
					count: inbox.length,
				},
				{
					path: '/account/messages/sent',
					title: 'Sent',
				},
				{
					path: '/account/messages/open',
					title: 'Open',
				},
			],
		},
	]
	return (
		<div className="cb-navbar">
			<ul>
				{data?.map((item, i) => (
					<NavBarLink key={i} {...{ item }} />
				))}
			</ul>
		</div>
	)
}

export default UserAsideNavBar
