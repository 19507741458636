import React from 'react'
import ScoreboardOption from './ScoreboardOption'
import ScoreboardTeamsName from './ScoreboardTeamsName'
import { CSSTransition } from 'react-transition-group'

const ScoreboardHeader = ({ handler, competitors, open, options = true, name }) => {
  return (
    <div className="ip-scoreboard__header">
      <CSSTransition
        in={!open}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames="ip-scoreboard-teams"
      >
        <ScoreboardTeamsName competitors={competitors} name={name} />
      </CSSTransition>
      {options && <ScoreboardOption handler={handler} open={open} />}
    </div>
  )
}

export default React.memo(ScoreboardHeader)
