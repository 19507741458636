import React, { useEffect } from 'react'
import GlobalConfig from '../context/globalConfig'
import Navigate from './common/routing/Navigate'
import Header from './containers/header/Header'
import RegistrationContext from '../context/registrationContext'
import { Provider } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { routes } from '../routing/mainRoutes'
import { store } from '../redux/store'
import BtnScroll from './common/buttons/BtnScroll'
import { Wrapper } from './containers/Wrapper'
import Main from './containers/main/Main'
import Content from './containers/main/Content'
import AsideLeftContext from 'context/asideLeftContext'
import AsideLeft from './containers/main/AsideLeft'
import PrematchClassification from './forAsideLeft/prematchClassification/PrematchClassification'
import AsideRightContext from 'context/asideRightContext'
import AsideRight from './containers/main/AsideRight'
import { WasmFingerprint } from 'helpers/utils/WasmFingerprint'

const App = () => {

  useEffect(() => {
    localStorage.removeItem('config')
  }, [])

  // attach wasm mod to class
  useEffect(() => {
    // const data = get('https://drbh.github.io/wasm-fingerprint/', [], false)()
    const wf = new WasmFingerprint()
    const inter = setInterval(() => {
      if (wf.make_fingerprint) {
        sessionStorage.setItem('fingerprint', wf.make_fingerprint()['print'])
        clearInterval(inter)
      }
    }, 500)
  }, [])

  return (
    <Provider store={store}>
      <BrowserRouter>
        <GlobalConfig>
          <RegistrationContext>
            <Wrapper>
              <Header />
              <Main>
                <Content>
                  <AsideLeftContext>
                    <AsideLeft />
                  </AsideLeftContext>
                  <Navigate routes={routes} />
                  <AsideRightContext>
                    <AsideRight />
                  </AsideRightContext>
                </Content>
              </Main>
            </Wrapper>
          </RegistrationContext>
        </GlobalConfig>
      </BrowserRouter>
      <BtnScroll />
    </Provider>
  )
}

export default App
