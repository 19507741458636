import React from 'react'

const FixtureScore = ({ score }) => {
	return (
		<div className="ipn-fixture__score">
			<div className="flex align-right">
				{score?.map(sc => (
					<div
						key={Math.random()}
						style={{
							marginRight: '3px',
						}}>
						<p
							className="font font--grey-300"
							dangerouslySetInnerHTML={{
								__html: sc[0] < 10 ? '&nbsp;&nbsp;' + sc[0] : sc[0],
							}}
						/>
						<p
							className="font font--grey-300"
							dangerouslySetInnerHTML={{
								__html: sc[1] < 10 ? '&nbsp;&nbsp;' + sc[1] : sc[1],
							}}
						/>
					</div>
				))}
			</div>
		</div>
	)
}

export default React.memo(FixtureScore)
