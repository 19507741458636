import React, { useCallback, useEffect, useState } from 'react'
import { OddOutcome } from '../../../../../../../helpers/commonInterfaces/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { getBetslip } from '../../../../../../../redux/selectors/betslipSelectors'
import { getEvent } from '../../../../../../../redux/selectors/eventSelectors'
import {
  addStake,
  removeStake,
} from '../../../../../../../redux/actions/betslipActions'
import BetButton from 'components/common/buttons/BetButton'
import { getOddsType } from '../../../../../../../redux/selectors/configSelectors'
import Lock from '../../../../../../../assets/img/icon/locker.svg'

type IButtonGroup = {
  secondValue: string
  firstValue: string
  currentOutcome(): OddOutcome | undefined
  eventId: number
  sportId: number
  isCurSroce: boolean
  br?: boolean
}

const ButtonGroup: React.FC<IButtonGroup> = ({
  secondValue,
  firstValue,
  currentOutcome,
  eventId,
  sportId,
  isCurSroce,
  br,
}) => {
  const dispatch = useDispatch()
  const { quickBet, bt } = useSelector(getBetslip)
  const ODDS_TYPE = useSelector(getOddsType)

  const outcome = currentOutcome()

  const createBet = (
    eventId: number,
    id: number,
    od: number,
    ha: string,
    check: boolean,
    qb: boolean
  ) => {
    check
      ? dispatch(removeStake(id))
      : dispatch(addStake(eventId, id, od, sportId, ha, qb))
  }

  return (
    <div className="gll-market-group__btn-group">
      {/* <div className="gll-market-group__count">
				<p className="font font--grey-300">
					{firstValue}-{secondValue}
				</p>
			</div> */}
      {outcome !== undefined ? (
        <BetButton
          name={`${firstValue}-${secondValue}`}
          item={outcome}
          onCreateBet={createBet.bind(null, eventId)}
          panel={quickBet}
          allStakes={bt}
          oddsType={ODDS_TYPE}
          isCurScore={isCurSroce}
          {...{ br }}
        />
      ) :
        <>
          <div className="gll-market-group__count">
            <p className="font font--grey-300">
              {firstValue}-{secondValue}
            </p>
          </div>
          <span className="flex align-center m-b-10">
            <img src={Lock} alt="locked" />
          </span>
        </>
      }
    </div>
  )
}

export default React.memo(ButtonGroup)
