import React from 'react'
import TabItem from '../tabItem'

const tabs = [
  {
    title: 'All',
    id: '1',
  },
  {
    title: 'Favourite',
    id: '2',
  },
]

const Tabs = ({ active, handler }) => {
  return (
    <div className="wn-classification__tab">
      <div className="flex align-middle full-height">
        {tabs?.map((item) => (
          <TabItem key={item.title} {...{ item }} {...{ handler }} {...{ active }} />
        ))}
      </div>
    </div>
  )
}

export default Tabs
