import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { favoritesWorkers } from 'redux/actions/favoritesActions'
import { getFavorites } from 'redux/selectors/favoritesSelectors'
import Icon from '../../../../../common/elements/SportIcon'

const SportHeader = ({ sport }) => {
  const { name, id, matchCount } = sport
  const dispatch = useDispatch()
  const favorites = useSelector(getFavorites)

  const toggleFavSport = (e) => {
    e.stopPropagation()
    if (favorites.sports?.some(sp => sp === id.toString())) {
      dispatch(favoritesWorkers.removeSport(id.toString()))
    } else {
      dispatch(favoritesWorkers.addSport(id.toString()))
    }
  }

  return (
    <div className={`flex align-middle ipn-classification__header wn-classification-sport__header`}>
      {/* <DragHandle /> */}
      <div className="ipn-classification__icon ">
        <Icon id={sport.id} name={sport.name} className="ip-classification-sport-icon" />
        {/* icons[sport.id] ? <Icon svg={icons[sport.id]} /> : <Icon svg={icons[1]} />*/}
      </div>
      <p className="font font--white text text-ellipsis">{name}</p>
      {/* <p className="font font--white count">{matchCount}</p> */}
      <div
        className={"ipn-competition__favourite"
          + (favorites.sports.some(sp => sp === sport.id.toString()) ? ' active' : '')}
        onClick={toggleFavSport}
      />
    </div>
  )
}

export default SportHeader
