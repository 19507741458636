import React from 'react'
import LanguageItem from './LanguageItem'

const Languages = ({ drop, active, onChangeLanguage }) => {
  return (
    <>
      <div className="dropdown__menu">
        {drop?.map((item) => (
          <LanguageItem
            key={item.id}
            active={Number(active) === Number(item.id)}
            item={item}
            onChangeLanguage={onChangeLanguage}
          />
        ))}
      </div>
    </>
  )
}

export default Languages
