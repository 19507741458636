import React, { useState } from 'react'
import EventDropDownBetsInner from './EventDropDownBetsInner'

const EventDropDownBets = ({ odds, eventId, sportId }) => {
  const [show, setShow] = useState(false)

  const onClick = () => setShow(!show)

  return (
    <div className="ip-competition__drop" onClick={onClick}>
      <div className="c-icon c-icon--sort" />
      {show && (
        <EventDropDownBetsInner {...{ eventId }} {...{ sportId }} odds={odds} setShow={setShow} />
      )}
    </div>
  )
}

export default React.memo(EventDropDownBets)
