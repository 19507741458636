import React from 'react'
import Table from '../common/competition/competitionContent/table/Table'
import TableRow from '../common/competition/competitionContent/table/TableRow'
import ActionsCell from '../common/competition/competitionContent/event/actionsCell/ActionsCell'
import ParticipantInfoCell from '../common/competition/competitionContent/event/participantsInfo/ParticipantInfoCell'
import EventOtherBets from '../common/competition/competitionContent/event/otherBets/EventOtherBets'
import { EventBetsForPrematch } from './EventBetsForPrematch'
import { useHistory } from 'react-router-dom'
import { isIterable } from 'helpers/utils/utils'
import { TournamentCell } from './TournamentCell'
import { PrematchActionsCell } from 'components/common/competition/competitionContent/event/actionsCell/PrematchActionsCell'

const EventsByDate = ({ events, idx }) => {
  const { push } = useHistory()

  const toEvent = (sportId, leagueId, id) => {
    push(`/sports/${sportId}/${leagueId}/${id}`)
  }

  return (
    <div className="ip-competition">
      {isIterable(events) &&
        events?.map((event) => {
          const {
            name,
            competitors,
            additionalData,
            odds,
            oddsCount,
            id,
            sportId,
            leagueId,
            startDate,
            providerStatus,
          } = event
          return (
            <React.Fragment key={id}>
              <Table>
                <TableRow className="tr tr--body">
                  {name ? (
                    <TournamentCell {...{ event }} />
                  ) : (
                    <>
                      <PrematchActionsCell
                        {...{ event }}
                      // providerStatus={providerStatus}
                      // startDate={startDate}
                      // clock={additionalData?.eventClock}
                      />
                      <ParticipantInfoCell
                        competitors={competitors}
                        score={additionalData?.scoreboard?.data}
                        point={additionalData?.currentServer?.data}
                        sportId={sportId}
                        toEvent={(e) => {
                          e.stopPropagation()
                          toEvent(sportId, leagueId, id)
                        }}
                      />
                      <EventBetsForPrematch sportId={sportId} id={id} event={event} odds={odds} />
                    </>
                  )}
                  <EventOtherBets oth={oddsCount} toEvent={() => toEvent(sportId, leagueId, id)} />
                </TableRow>
              </Table>
            </React.Fragment>
          )
        })}
    </div>
  )
}

export default EventsByDate
