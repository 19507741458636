import { combineReducers } from 'redux'
import { inplay, IInplay } from './inplayReducer'
import { event, IState as EventState } from './eventReducer'
import { config, IState as ConfigState } from './configReducer'
import { context, IState as ContextState } from './contextReducer'
import { IState as PrematchState, prematch } from './prematchReducer'
import { schedule, ISchedule } from './scheduleReducer'
import { search, ISearch } from './searchReducer'
import { betslip, IBetslipReducer } from './betslipReducer'
import { banners, IState as BannersState } from './bannersReducer'
import { IPrematchState as PrematchLeagueState, prematchLeague } from './prematchLeagueReducer'
import { MyBetsState, myBets } from './myBetsReducer'
import { prematchEvent, IState as IPrematchEvent } from './prematchEventReducer'
import { preloader, IPreloader } from './preloaderReducer'
import { account, IAccountReducer } from './accountReducer'
import { favorites, FavoritesState } from './favoritesReducer'
import { iconsState, icons } from './iconsReducer'
import { topGames } from './topGamesReducer'

export interface IRootState {
  preloader: IPreloader
  inplay: IInplay
  event: EventState
  config: ConfigState
  context: ContextState
  prematch: PrematchState
  betslip: IBetslipReducer
  banners: BannersState
  prematchLeague: PrematchLeagueState
  prematchEvent: IPrematchEvent
  myBets: MyBetsState
  favorites: FavoritesState
  account: IAccountReducer
  icons: iconsState
  search: ISearch
  schedule: ISchedule
}

export const rootReducer = combineReducers({
  preloader,
  inplay,
  account,
  event,
  config,
  context,
  betslip,
  banners,
  prematch,
  prematchLeague,
  prematchEvent,
  myBets,
  favorites,
  icons,
  topGames,
  search,
  schedule,
})
