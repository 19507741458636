import React from 'react'

interface IText {
  title?: any
  className?: string
  style?: any
  children?: any
}
const Text: React.FC<IText> = ({
  title = '',
  className = '',
  children
}) => {
  return (
    <p className={className}>
      {title} {children}
    </p>
  )
}

export default React.memo(Text)
