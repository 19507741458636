import React from 'react'
import HeaderLinks from './HeaderLinks'
import IconPlay from './IconPlay'
import TrophyDropDown from './TrophyDropDown'
import BellDropDown from './BellDropDown'

const EventHeader = () => {
	return (
		<div
			className="ipe-header"
			style={{
				height: '20px',
				backgroundColor: '#232737',
			}}>
			<div className="flex align-middle align-justify full-height">
				{/* <HeaderLinks /> */}
				<div className="flex align-middle ipe-header__option">
					{/* <IconPlay /> */}
					{/* <TrophyDropDown /> */}
					{/* <BellDropDown /> */}
				</div>
			</div>
		</div>
	)
}

export default React.memo(EventHeader)
