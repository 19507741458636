import React from 'react'
import bonusBG from '../../../../assets/img/banners/bonusOnDeposit.png'

const BonusOnDeposit = ({ inSlider = false }) => {
  return (
    <div
      className={
        inSlider ? 'wn-banner wn-banner--shadow' : 'wn-banner m-b-10 wn-banner--shadow full-width'
      }
      style={{ backgroundImage: `url(${bonusBG})` }}
    >
      <div className="wn-banner__wrap wn-banner__wrap--down">
        <div className="text-left full-width">
          <p className="font font--gold font--17 text-uppercase">
            BONUS ON THE 1ST
            <br /> DEPOSIT UP TO
          </p>
        </div>
      </div>
      <div className="flex align-middle full-width wn-banner__footer">
        <h1 className="h1 h1--gold h1--semibold">
          <span className="m-r-5">3000</span>
          <span className="font font--gold font--16 text-uppercase">Uah</span>
        </h1>
      </div>
    </div>
  )
}

export default BonusOnDeposit
