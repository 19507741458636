import React from 'react'
import TournamentScoreboard from './TournamentScoreboard'
import MatchScoreboard from './MatchScoreboard'

const ScoreboardContent = ({
  additionalData,
  competitors,
  scoreboard,
  content,
  sportId,
  eventType,
  providerStatus,
  startDate,
  name,
}) => {
  return (
    <div className="ip-scoreboard__body--wrapper">
      <div className="ip-scoreboard__body">
        {eventType === 'Tournament' ? (
          <TournamentScoreboard name={name} startDate={startDate} />
        ) : (
          <MatchScoreboard
            sportId={sportId}
            scoreboard={scoreboard}
            content={content}
            additionalData={additionalData}
            competitors={competitors}
          />
        )}
        {/*<ScoreboardDots active={content} handler={showTable} />*/}
      </div>
    </div>
  )
}

export default React.memo(ScoreboardContent)
