import React from 'react'

type TextButton = {
  classes: string
  onclick?(e: React.MouseEvent): void
  text: string
  innerClasses: string
  type?: 'button' | 'submit'
  disabled?: boolean
}

const TextButton: React.FC<TextButton> = ({
  classes,
  onclick,
  text,
  innerClasses,
  type,
  disabled,
}) => {
  return (
    <button className={classes} onClick={onclick} type={type} disabled={disabled}>
      <span className={innerClasses}>{text}</span>
    </button>
  )
}

TextButton.defaultProps = {
  classes: '',
  onclick: () => null,
  text: '',
  innerClasses: '',
  type: 'button',
}

export default TextButton
