import React, { useCallback, useState } from 'react'
import Spinner from '../../../common/loaders/Spinner'
import MarketValueWithName from './MarketValueWithName'
import MarketValue from './MarketValue'
import { useSelector } from 'react-redux'
import { getOddsType } from 'redux/selectors/configSelectors'

const MarketCell = ({ className = '', panel, odd, allMarkets, onCreateBet, named, evId }) => {
  const ODDS_TYPE = useSelector(getOddsType)

  const { id, oddValue, specifiers, outcome } = odd
  const [highlight, setHighlight] = React.useState({
    oldOdd: oddValue,
    className: '',
  })
  const [snake, setSnake] = useState(false)

  const mark = useCallback(() => {
    if (allMarkets && allMarkets.length > 0) {
      const bet = allMarkets.find((bet) => Number(bet.pt[0].pi) === Number(id))
      return bet
    }
  }, [allMarkets])

  // useEffect(() => {
  //   if (mark()) {
  //     dispatch(updateOdd(id, oddValue))
  //   }
  // }, [oddValue])

  React.useEffect(() => {
    if (oddValue > highlight.oldOdd) {
      setHighlight({
        oldOdd: oddValue,
        className: 'highlight-up',
      })
    }
    if (oddValue < highlight.oldOdd) {
      setHighlight({
        oldOdd: oddValue,
        className: 'highlight-down',
      })
    }
    const timerId = setTimeout(() => {
      setHighlight({
        ...highlight,
        className: '',
      })
    }, 1500)

    return () => clearTimeout(timerId)
  }, [oddValue])

  const onMouseUpHandler = () => {
    setSnake(false)
    window.clearTimeout(window.snakeTimeout)
  }
  const onMouseDownHandler = (event) => {
    if (!snake) {
      setSnake(true)
    }

    const root = document.querySelector(':root') as HTMLElement
    root.style.setProperty('--tr-X', `${event.currentTarget.offsetWidth}px`)
    root.style.setProperty('--tr-Y', `${event.currentTarget.offsetHeight}px`)

    window.snakeTimeout = setTimeout(() => {
      setSnake(false)
      onCreate(true)
    }, 2000)
  }

  const onClick = (e) => {
    e.stopPropagation() // to not turn off over/under mini-popup in inplay/sports
    if (!panel) {
      onCreate(false)
    }
  }

  const onMouseDown = (event) => {
    panel ? onMouseDownHandler(event) : setSnake(false)
  }

  const onCreate = (qb) => {
    let ha = null
    if (specifiers) {
      if (specifiers.hcp) {
        ha = specifiers.hcp
      }
      if (specifiers.total) {
        ha = specifiers.total
      }
    }
    console.log(`speci`, specifiers)
    console.log(`HA`, ha)
    onCreateBet(id, oddValue, ha, mark(), qb)
  }

  return (
    <div className={"gll-market-group__cell" + ' ' + className}>
      <button
        className={`btn btn--coff gll-market-group__btn
				${mark() ? ' gll-market-group__btn--active active' : ''}
				${panel && snake ? ' qb' : ''}`}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUpHandler}
      >
        {named ? (
          <MarketValueWithName
            outcome={outcome}
            highlight={highlight}
            oddValue={oddValue}
            oddType={ODDS_TYPE}
          />
        ) : (
          <MarketValue oddType={ODDS_TYPE} oddValue={oddValue} highlight={highlight} />
        )}
        <Spinner />
      </button>
    </div>
  )
}

export default React.memo(MarketCell)
