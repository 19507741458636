import React from 'react'

type IRangeInput = {
  name: string
  min: string
  max: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  competitor: string
}

const RangeInput: React.FC<IRangeInput> = ({
  min,
  name,
  max,
  value,
  competitor,
  onChange,
}) => {
  return (
    <div className="text-center gll-market-group__range-group">
      <p className="font font--grey-300">{competitor}</p>
      <input
        className="range range--big"
        type="range"
        name={name}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default React.memo(RangeInput)
