import React from 'react'

const Spinner = () => {
	return (
		<div className="spinner">
			<span className="top-spinner" />
			<span className="left-spinner" />
			<span className="bottom-spinner" />
			<span className="right-spinner" />
		</div>
	)
}

export default Spinner
