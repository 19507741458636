import { useCallback, useEffect, useState } from 'react'
import { useStorage } from './useStorage'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IRootState } from 'redux/reducers/rootReducer'
import { Nullable } from 'helpers/commonInterfaces/interfaces'

export const useAside = () => {
  const { setSession, getSession } = useStorage()
  const { pathname } = useLocation()
  const { leaguesEvents } = useSelector((state: IRootState) => state.inplay)

  const checkLocation = () => {
    const isSmallAside = getSession('smallAside') || false

    if (pathname.includes('event')) {
      return isSmallAside
    }

    return false
  }

  const [openedSports, setOpenedSports] = useState<Array<number>>([])
  const [openedLeagues, setOpenedLeagues] = useState<Array<number>>([])
  const [dataForAsideSmall, setDataForAsideSmall] = useState<Nullable<{ id: number }>>(null)

  const [small, setSmall] = useState(checkLocation())

  const [position, setPosition] = useState<{ top: string, left: string, bottom?: string }>({
    top: '0px',
    left: '0px',
  })

  const collapse = () => {
    setSession('smallAside', !small)
    setSmall(!small)
  }

  const getPosition = (e) => {
    e.persist()
    const elemPos = e.currentTarget.getBoundingClientRect()
    const x = elemPos.right + 10
    const y = elemPos.top
    const height = window.innerHeight

    if (y + 290 >= height) {
      const newY = height - elemPos.bottom
      setPosition({ ...position, left: `${x}px`, bottom: `${newY}px` })
      return
    }

    setPosition({ ...position, left: `${x}px`, top: `${y}px` })
  }

  const setDataAsideSmall = (e, data) => {
    getPosition(e)
    setDataForAsideSmall(data)
  }

  const onMouseLeave = () => {
    setDataForAsideSmall(null)
    setPosition({
      top: '0px',
      left: '0px',
    })
  }

  const addOpenedLeague = (id: number) => {
    setOpenedLeagues([...openedLeagues, id])
  }

  const closeLeague = (id) => {
    setOpenedLeagues(openedLeagues.filter((leagueId) => leagueId !== id))
  }

  const addOpenedSport = (id: number) => {
    setOpenedSports([...openedSports, id])
  }

  const closeSport = (id) => {
    setOpenedSports(openedSports.filter((sportId) => sportId !== id))
  }

  const isOpenSport = useCallback(
    (id) => {
      // console.log(`openedSports`, openedSports)
      // console.log(`id is `, small ? dataForAsideSmall?.id === id : !!openedSports.find((sportId) => sportId === id)
      // )
      return small ? dataForAsideSmall?.id === id : !!openedSports.find((sportId) => sportId === id)
    },
    [dataForAsideSmall, openedSports, small]
  )

  const isOpenLeague = useCallback((id) => !!openedLeagues.find((leagueId) => leagueId === id), [
    openedLeagues,
  ])

  const checkIsSportOpen = (data) => {
    const { id } = data

    isOpenSport(id) ? closeSport(id) : addOpenedSport(id)
  }

  const sportCategoryHandler = (e, data) => {
    small ? setDataAsideSmall(e, data) : checkIsSportOpen(data)
  }

  const leagueCategoryHandler = (id) => {
    isOpenLeague(id) ? closeLeague(id) : addOpenedLeague(id)
  }

  useEffect(() => {
    if (!pathname.includes('event')) {
      setSmall(false)
    }
  }, [pathname])

  return {
    small,
    collapse,
    position,
    closeLeague,
    isOpenSport,
    isOpenLeague,
    leaguesEvents,
    onMouseLeave,
    addOpenedSport,
    addOpenedLeague,
    dataForAsideSmall,
    sportCategoryHandler,
    leagueCategoryHandler,
  }
}
