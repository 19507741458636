import { getCurrentTime } from './transform/transformTime'
import countries from '../assets/json/countries.json'
import defaultImage from '../assets/img/icon-country/na.png'
import { isIterable } from './utils/utils'

export const getAllLeagues = (sport) => {
  if (!sport || !sport.categories) {
    return []
  }

  return sport.categories.reduce((acc, { leagues, iconCode }) => {
    if (!leagues) {
      return acc
    }

    leagues?.forEach((league) => (league.iconCode = iconCode))

    acc = [...acc, ...leagues]
    return acc
  }, [])
}

export const groupEventByDate = (events) => {
  const data = {}

  events?.forEach((event) => {
    const { month, day, weekDay } = getCurrentTime(event.startDate)

    const date = `${weekDay} ${day} ${month}`

    if (!data[date]) {
      data[date] = []
    }

    data[date].push(event)
  })

  return data
}

export const fromAlpha3ToAlpha2 = (a3) => {
  let flagName = 'int'

  if (!a3) {
    return flagName
  }

  if (a3.length === 2) {
    return a3
  }

  countries?.forEach((c) => {
    if (c.alpha3 === a3.toLowerCase()) flagName = c.alpha2
  })
  return flagName
}

export const loadCountryIcon = async (iconCode) => {
  try {
    const result = await import(`../assets/img/icon-country/${fromAlpha3ToAlpha2(iconCode)}.png`)

    return result.default
  } catch (e) {
    return defaultImage
  }
}

export const getSportIcon = (name) => {
  if (name?.toLowerCase().includes('es')) {
    name = 'esport'
  }

  return `https://bestline.bet/media/icons/sports/png/${name
    ?.split(' ')
    .join('-')
    .toLowerCase()}.png`
}
export const dispatchData = (dispatch, arr) => {
  arr?.forEach((item) => dispatch(item))
}

export const getIds = (leagues) => {
  if (!leagues) {
    return null
  }
  return leagues[0].id
}

export const replaceEventUrl = ({ sportId, eventId, leagueId }) => {
  window.history.replaceState('', '', `/live/event/${sportId}/${leagueId}/${eventId}`)
}

export const sortInplayData = (data: any) => {
  if (data) {
    data = data.filter((sport: any) => {
      if (sport.inPlayMatchCount > 0) {
        sport.categories = sport.categories.filter((category) => {
          if (category.inPlayMatchCount > 0) {
            category.leagues = category.leagues.filter((league) => league.inPlayMatchCount > 0)
            return sport
          }
        })
        return sport
      } else {
        return false
      }
    })
    return data.sort((a, b) => a.priority - b.priority)
  }

  return []
}

export const isAnimation = (enrichment) => enrichment?.animation?.includes('betradarTracker')

export const isStreaming = (streaming, externalIds) => {
  try {
    if (!isIterable(streaming)) {
      return false
    }
    return !!streaming?.find(({ id }) => id === externalIds?.betradar?.split(':')[2])
  } catch (e) {
    console.error('Error: ', e)
  }
}

export function setCookie(name: string, value: string, days: number): void {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; SameSite=Lax' + '; path=/'
}
