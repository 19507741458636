import React from 'react'
import ScoreboardColumn from './ScoreboardColumn'
import SoccerColumnTitle from './SoccerColumnTitle'
import ColumnTitle from './ColumnTitle'
import ColumnCell from './ColumnCell'

const ColumnCompetitorScores = ({ homeScore, awayScore, title, soccer = false }) => {
  return (
    <ScoreboardColumn>
      {soccer ? <SoccerColumnTitle classes={title} /> : <ColumnTitle title={title} />}
      <ColumnCell title={homeScore} />
      <ColumnCell title={awayScore} />
    </ScoreboardColumn>
  )
}

export default React.memo(ColumnCompetitorScores)
