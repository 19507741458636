import React, { useState } from 'react'
import BetslipTitle from './BetslipTitle'
import Action from './BetslipAction'
import BetslipRow from './BetslipRow'
import CashoutContainer from './CashoutContainer'
import { CSSTransition } from 'react-transition-group'

const BetslipTable = ({ type, bet, active }) => {
  const [state, setState] = useState({
    show: active,
  })
  const showHideHandler = () => {
    setState({ ...state, show: !state.show })
  }

  const isAvailableCashout =
    (bet.CA && bet.CA === 1 && !!bet.CV && typeof bet.CP !== 'undefined') || false

  return (
    <div
      className={
        type ? 'betslip__table' : `betslip__table betslip__table--${state.show ? 'down' : 'up'}`
      }
    >
      {type ? (
        <>
          <BetslipTitle id={bet.ID} name={bet.NA} amount={bet.AM} click={showHideHandler} />
          <Action {...{ type }} bet={bet} idx={0} just_time={false} />
          <BetslipRow {...{ type }} {...{ bet }} />
          {isAvailableCashout && <CashoutContainer bet={bet} pa={bet.PA} />}
        </>
      ) : (
        <>
          <BetslipTitle click={showHideHandler} id={bet.ID} name={bet.NA} amount={bet.AM} />
          <Action {...{ type }} bet={bet} idx={0} just_time />
          <BetslipRow {...{ type }} {...{ bet }} />
          <CSSTransition
            in={state.show}
            classNames={'mult_slide'}
            timeout={150}
            mountOnEnter
            unmountOnExit
          >
            <>
              {bet.PA?.map((pa, idx) => (
                <Action key={idx} {...{ type }} bet={bet} idx={idx} just_time={false} />
              ))}
              {isAvailableCashout && <CashoutContainer bet={bet} pa={bet.PA} />}
            </>
          </CSSTransition>
        </>
      )}
    </div>
  )
}

export default BetslipTable
