import PersonalInformation from '../../components/forAccontPageComponents/my_account/PersonalInformation'
import Contacts from '../../components/forAccontPageComponents/my_account/Contacts'
import AccountVerification from '../../components/forAccontPageComponents/my_account/accountVerification/accountVerification'
import AccountSettings from '../../components/forAccontPageComponents/my_account/AccountSettings'
import ChangePassword from '../../components/forAccontPageComponents/my_account/ChangePassword'
import MyAccountRedirection from 'components/Routing/accountRedirection/myAccountRedirection'

export const myAccountRouting = [
	{
		path: '/account/my-account/personal-information',
		exact: true,
		component: PersonalInformation,
	},
	{
		path: '/account/my-account/contact',
		exact: true,
		component: Contacts,
	},
	{
		path: '/account/my-account/account-verification',
		exact: true,
		component: AccountVerification,
	},
	{
		path: '/account/my-account/account-settings',
		exact: true,
		component: AccountSettings,
	},
	{
		path: '/account/my-account/change-password',
		exact: true,
		component: ChangePassword,
	},
	{
		component: MyAccountRedirection
	},
]
