import React from 'react'
import SportIcon from '../../../common/elements/SportIcon'

const ClassificationBarItem = ({ item, onClick, className }) => {
  return (
    <div
      className={`ip-classification-bar__item flex align-center-middle flex-column ${className}`}
      onClick={onClick}
    >
      <div className="flex align-center align-bottom ip-classification-bar__block">
        <SportIcon
          name={item.name}
          id={item.id}
          style={{ width: '25px' }}
          className="wn-icon-big"
        />
      </div>
      <div className="flex align-center-middle ip-classification-bar__block text-ellipsis">
        <p className="font ip-classification-bar__text text-ellipsis p-a-3">{item.name}</p>
      </div>
    </div>
  )
}

export default React.memo(ClassificationBarItem)
