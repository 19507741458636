import React, { useRef } from 'react'
import BetslipEmpty from '../betslip/betslipComponents/BetslipEmpty'
import BetslipTable from './BetslipTable'
import controlbar from './myBetsControlBar.json'
import { useState } from 'react'
import { mybetsWatchers, mybetsWorkers } from '../../../../redux/actions/myBetsActions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getMyBetsData, getMyBetsLoading } from 'redux/selectors/myBetsSelectors'
import { getLeaguesEvents } from 'redux/selectors/inplaySelectors'
import 'react-calendar/dist/Calendar.css'
import '../../../../assets/styles/myBets.css'
import { getConfig, getCurrency } from 'redux/selectors/configSelectors'

const MyBetsWrapper = ({ display, data }) => {
  const dispatch = useDispatch()
  const leaguesEvents = useSelector(getLeaguesEvents)
  const config = useSelector(getConfig)

  const reduxData = useSelector(getMyBetsData)
  const [open, setOpen] = useState(false)
  const [choosen, setChoosen] = useState(1)
  const loading = useSelector(getMyBetsLoading)

  const setInRedux = (id) => {
    dispatch(mybetsWatchers.getMybets(id))
  }

  const called = useRef(false)

  useEffect(() => {
    if (config.CUSTOMER_CONFIG && !called.current && config.CUSTOMER_CONFIG.LOGGED_IN) {
      called.current = true
      dispatch(mybetsWatchers.getMybets(Number(choosen)))
    }
  }, [config, choosen, called])

  const onToggleOpen = () => setOpen(!open)

  const setInState = (id) => {
    setChoosen(id)
  }

  const filteredBets = useRef<any[]>([])
  useEffect(() => {
    if (reduxData && reduxData[controlbar[choosen].linkId]?.length > 0) {
      filteredBets.current = reduxData[controlbar[choosen].linkId]
      // filter if cash now
      if (choosen === 0) {
        filteredBets.current = filteredBets.current.filter((bet) => {
          if (bet.CA && bet.CA === 1 && !!bet.CV && typeof bet.CP !== 'undefined') {
            return true
          } else {
            return false
          }
        })
      }
      // filter if live now
      if (choosen === 1) {
        filteredBets.current = filteredBets.current.filter((bet) => {
          for (const events of Object.values(leaguesEvents)) {
            for (const event of events) {
              if (bet.PA.some((pa) => pa.FI === event.id)) {
                return true
              }
            }
          }
          return false
        })
      }
    }
  }, [data, leaguesEvents, choosen, reduxData])

  useEffect(() => {
    dispatch(mybetsWorkers.setLoading(true))
  }, [choosen])

  return (
    <div style={{ display: display ? 'block' : 'none' }}>
      <div className="betslip__settled" onClick={onToggleOpen}>
        <div className="flex align-middle full-height">
          <p className="font font--12">
            {controlbar.find((item, idx) => idx === choosen)?.title.text}
          </p>
          <i className="fal fa-angle-down"></i>
        </div>
        <div className={open ? 'myb-dropdown dropdown--active' : 'myb-dropdown'}>
          {controlbar?.map((item, idx) => {
            if (idx !== choosen) {
              return (
                <div
                  key={idx}
                  role="button"
                  className="myb-dropdown__dropitem font font--12"
                  onClick={() => {
                    setInState(idx)
                    setInRedux(item.linkId)
                  }}
                  onKeyPress={() => 0}
                  tabIndex={0}
                >
                  {item.title.text}
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
      <div className="betslip__body">
        {loading && (
          <div className="betslip__overlay">
            <div className="spinner__outer">
              <div className="betslip__overlay-spinner" />
            </div>
          </div>
        )}
        {filteredBets.current.length > 0 ? (
          <>
            {filteredBets.current?.map((item, idx) => {
              return item.PA?.length > 1 ? (
                <BetslipTable key={item.ID} bet={item} active={idx <= 10} type={0} />
              ) : (
                <BetslipTable key={item.ID} bet={item} active={idx <= 10} type={1} />
              )
            })}
          </>
        ) : (
          <BetslipEmpty quickbetMode={false} />
        )}
      </div>
    </div>
  )
}

export default MyBetsWrapper
