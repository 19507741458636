import React from 'react'
import Button from '../../../../buttons/Button'

const DropDownBottomButtons = ({ close }) => {
  return (
    <div className="flex align-center-middle m-t-20">
      <Button
        className="btn btn--gold btn--h-24 ip-classification-header__btn"
        title="Ok"
        onClick={close}
      />
    </div>
  )
}

export default DropDownBottomButtons
