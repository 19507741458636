import React, { FC } from 'react'

export const BurgerPW: FC = () => {
	return (
		<svg
			width="30"
			height="18"
			viewBox="0 0 30 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="5" width="25" height="2" rx="1" fill="#6C90FC" />
			<rect x="5" y="16" width="25" height="2" rx="1" fill="#6C90FC" />
			<rect y="8" width="30" height="2" rx="1" fill="#6C90FC" />
		</svg>
	)
}
