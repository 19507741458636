import React from 'react'

const BAcceptHeader = ({removeAllBetsHandler}) => {
	return(
		<div className="b-accept__header">
			<p className="b-accept__remove">Bet accepted</p>
			<a className="b-accept__edit" onClick={removeAllBetsHandler}>Done</a>
		</div>
	)
}

export default BAcceptHeader