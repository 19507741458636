import { ILeague } from 'helpers/commonInterfaces/interfaces'
import React from 'react'
import { lastOpenedT } from '../ClassificationBody'
import League from '../league/League'

type ReactBodyProps = {
  leagues: Array<ILeague>
  lastOpened: lastOpenedT,
  openedHandler: (key: string, value: number) => void
}

const RegionBody: React.FC<ReactBodyProps> = ({ leagues, lastOpened, openedHandler }) => {
  return (
    <div className="wn-classification-region__body">
      {leagues?.map((league) => {
        return <League
          key={league.id}
          league={league}
          {...{ lastOpened }}
          {...{ openedHandler }} />
      })}
    </div>
  )
}

export default RegionBody
