import { BurgerPW } from 'components/registration/elements/BurgerPW'
import { Envelope } from 'components/registration/elements/Envelope'
import Overlay from 'components/registration/overlay'
import { RegisterContext } from 'context/registrationContext'
import React, { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const CodeSent = () => {
  const { username, showEnterCode, showCodeSent } = useContext(RegisterContext)
  const { t } = useTranslation()
  const timeoutRef = useRef<NodeJS.Timeout>(setTimeout(() => null, 10))

  // closing on overlay click
  const hideLogin = (e) => {
    if (e.target.className === 'overlay overlay--main overlay--block') {
      if (showCodeSent) {
        window.clearTimeout(timeoutRef.current)
        showCodeSent()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', hideLogin)
    return () => document.removeEventListener('click', hideLogin)
  }, [])

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      if (showEnterCode && showCodeSent) {
        showCodeSent()
        showEnterCode()
      }
    }, 2500)
  }, [])

  return (
    <Overlay>
      <div className="mn-popup mn-popup--active sent-popup">
        <div className="relative sent">
          <BurgerPW />
          <Envelope />
        </div>
        <div>
          <div className="relative">
            <div className="text-center">
              <p className="font font--white">
                {t(
                  'ACCOUNT.WAS_SENT_TO',
                  'An email with a password reset instruction was sent to'
                )}{' '}
                {username?.slice(0, 2) ??
                  '**'}
                ***
                {'@' +
                  (username?.split('@')[1] ??
                    '**.***')}
              </p>
            </div>
            {/* <div className="text-center m-t-10">
							<p className="font font--white">
								Check
								<a className="font font--blue m-l-5" href="#">
									mail
								</a>
							</p>
						</div> */}
          </div>
        </div>
      </div>
    </Overlay>
  )
}