import { IRootState } from '../reducers/rootReducer'
import {
  ICompetitors,
  IMatch,
  IStreaming,
  ITournament,
  Nullable,
  Obj,
  Odd,
} from '../../helpers/commonInterfaces/interfaces'

export const getEvent = (state: IRootState): Nullable<IMatch | ITournament> => {
  return state.event.event
}

export const getFirstEventId = (events: Nullable<Array<IMatch | ITournament>>): number => {
  return events?.[0].id || 0
}

export const getLeagueEvents = (state: IRootState): any => {
  return state.event?.leagueEvents
}

export const getMarkets = (state: IRootState): Nullable<Obj<Odd>> => {
  return state.event.odds
}

export const getAside = (state: IRootState): { openedSports: Array<number>; openedLeagues: Array<number> } => {
  return state.event.aside
}

export const getLiveCompetitors = (state: IRootState): ICompetitors[] => {
  if (state.event.event?.eventType === 'Match') {
    return state.event.event.competitors
  }
  return []
}
