import React from 'react'
import BetButton from 'components/common/buttons/BetButton'
import MarketRow from '../MarketRow'
import MarketColumn from '../MarketColumn'
import TitleCell from '../Cells/TitleCell'
import BetCellWithoutTitle from '../Cells/BetCellWithoutTitle'
import {
  IOutcome,
  OddOutcome,
} from '../../../../../../helpers/commonInterfaces/interfaces'
import { useSelector } from 'react-redux'
import { getBetslip } from '../../../../../../redux/selectors/betslipSelectors'
import { getOddsType } from '../../../../../../redux/selectors/configSelectors'

interface IAnytime {
  outcomes: Array<OddOutcome>
  sportId: number
  eventId: number
  createBet: (
    eventId: number,
    id: number,
    od: number,
    ha: string,
    check: boolean,
    qb: boolean
  ) => void
}

const AnytimeBody: React.FC<IAnytime> = ({
  outcomes,
  eventId,
  sportId,
  createBet,
}) => {
  const { quickBet, bt } = useSelector(getBetslip)
  const ODDS_TYPE = useSelector(getOddsType)

  return (
    <div className="gll-market-group__body">
      {outcomes.map(item => (
        <MarketRow key={item.id + item.outcomeId} isMulti={false}>
          <div
            style={{
              flex: '1 1 67%',
            }}>
            <TitleCell
              title={item.outcome
                .replace('over', 'Over')
                .replace('under', 'Under')
                .replace('Draw', 'X')}
            />
          </div>
          <div
            style={{
              flex: '1 1 33%',
            }}>
            <div className="gll-market-group__cell">
              {/*<BetCellWithoutTitle outcome={item} />*/}
              <BetButton
                item={item}
                key={item.id}
                heightNotFixed={true}
                onCreateBet={createBet.bind(null, eventId)}
                panel={quickBet}
                allStakes={bt}
                oddsType={ODDS_TYPE}
                isMatchBet={true}
              />
            </div>
          </div>
        </MarketRow>
      ))}
    </div>
  )
}

export default React.memo(AnytimeBody)
