import { types } from '../types/types'

export const setMessages = (messages, type) => {
  return {
    type: types.SET_MESSAGES,
    payload: { messages, type },
  }
}

export const setVerify = (value) => {
  return {
    type: types.SET_VERIFY,
    payload: value,
  }
}

export const setShowMoney = (value) => {
  return {
    type: types.SET_SHOW_MONEY,
    payload: value,
  }
}
