import React, { FC } from 'react'

type MultiBetProps = {
  bet: any
}

export const MultiBet: FC<MultiBetProps> = ({ bet }) => {
  return (
    <>
      <div className="t-subcategory">
        <div className="flex align-middle full-height">
          <div className="t-subcategory__left">
            <div className="btn btn--blue btn--h-18 bet-number">
              <span className="font font--11 font--white">3456</span>
            </div>
          </div>
          <div className="t-subcategory__right">
            <p className="font font--10 font--default-alpha-05">21.09 23:10</p>
          </div>
        </div>
      </div>
      <table className="cb-table cb-table--custom">
        <tbody>
          <tr className="cb-table__tr" role="row">
            <td className="cb-table__td cb-table__td--border" style={{ width: '7%' }}>
              <div className="text-center">
                <p className="font">01.09 23:10</p>
              </div>
            </td>
            <td className="cb-table__td cb-table__td--border" rowSpan={3} style={{ width: '7%' }}>
              <div className="text-center">
                <p className="font font--red font--semibold">Live</p>
              </div>
            </td>
            <td className="cb-table__td cb-table__td--border" style={{ width: '40%' }}>
              <div className="flex align-middle align-justify overflow-hidden">
                <p className="font text-ellipsis">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum est hic ipsum
                  consequuntur laboriosam voluptatem voluptatibus quas reiciendis veritatis, nobis
                  repudiandae? Optio perspiciatis tempore quos aliquam adipisci, quaerat at.
                  Dolorem.
                </p>
                <div className="flex align-middle">
                  <p className="font score">68</p>
                  <p className="font score">18</p>
                  <p className="font score">23</p>
                  <p className="font score">18</p>
                  <p className="font font--semibold score">84</p>
                </div>
              </div>
              <div className="flex align-middle align-justify overflow-hidden">
                <p className="font text-ellipsis">
                  Paris Saint-Germain Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui
                  deleniti facilis earum delectus eum ad vitae beatae maxime iste ullam voluptatum
                  tempore repudiandae consectetur maiores cumque, rem asperiores laboriosam
                  praesentium.
                </p>
                <div className="flex align-middle">
                  <p className="font score">68</p>
                  <p className="font score">18</p>
                  <p className="font score">23</p>
                  <p className="font score">18</p>
                  <p className="font font--semibold score">84</p>
                </div>
              </div>
            </td>
            <td className="cb-table__td cb-table__td--border" style={{ width: '35%' }}>
              <div className="flex align-middle align-justify">
                <p className="font font--semibold">Match winner</p>
                <p className="font font--10 font--default-alpha-05">odds</p>
              </div>
              <div className="flex align-middle align-justify">
                <p className="font text-ellipsis">Juventus</p>
                <p className="font font--semibold font--blue">2.17</p>
              </div>
            </td>
            <td className="cb-table__td cb-table__td--border" rowSpan={3} style={{ width: '15%' }}>
              <div className="flex flex-wrap align-middle align-justify waiting">
                <div className="text-left">
                  <p className="font font--10 font--default-alpha-05">stake</p>
                  <p className="font">500.00 $</p>
                </div>
                <div className="text-left">
                  <p className="font font--10 font--default-alpha-05">to return</p>
                  <p className="font font--green">00.00 $</p>
                </div>
              </div>
            </td>
          </tr>
          <tr className="cb-table__tr" role="row">
            <td className="cb-table__td cb-table__td--border" style={{ width: '7%' }}>
              <div className="text-center">
                <p className="font">11.09 23:10</p>
              </div>
            </td>
            <td className="cb-table__td cb-table__td--border" style={{ width: '40%' }}>
              <div className="flex align-middle align-justify overflow-hidden">
                <p className="font text-ellipsis">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum est hic ipsum
                  consequuntur laboriosam voluptatem voluptatibus quas reiciendis veritatis, nobis
                  repudiandae? Optio perspiciatis tempore quos aliquam adipisci, quaerat at.
                  Dolorem.
                </p>
                <div className="flex align-middle">
                  <p className="font score">68</p>
                  <p className="font score">18</p>
                  <p className="font score">23</p>
                  <p className="font score">18</p>
                  <p className="font font--semibold score">84</p>
                </div>
              </div>
              <div className="flex align-middle align-justify overflow-hidden">
                <p className="font text-ellipsis">
                  Paris Saint-Germain Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui
                  deleniti facilis earum delectus eum ad vitae beatae maxime iste ullam voluptatum
                  tempore repudiandae consectetur maiores cumque, rem asperiores laboriosam
                  praesentium.
                </p>
                <div className="flex align-middle">
                  <p className="font score">68</p>
                  <p className="font score">18</p>
                  <p className="font score">23</p>
                  <p className="font score">18</p>
                  <p className="font font--semibold score">84</p>
                </div>
              </div>
            </td>
            <td className="cb-table__td cb-table__td--border" style={{ width: '35%' }}>
              <div className="flex align-middle align-justify">
                <p className="font font--semibold">Match winner</p>
                <p className="font font--10 font--default-alpha-05">odds</p>
              </div>
              <div className="flex align-middle align-justify">
                <p className="font text-ellipsis">Juventus</p>
                <p className="font font--semibold font--blue">2.17</p>
              </div>
            </td>
          </tr>
          <tr className="cb-table__tr" role="row">
            <td className="cb-table__td cb-table__td--border" style={{ width: '7%' }}>
              <div className="text-center">
                <p className="font">21.09 23:10</p>
              </div>
            </td>
            <td className="cb-table__td cb-table__td--border" style={{ width: '40%' }}>
              <div className="flex align-middle align-justify overflow-hidden">
                <p className="font text-ellipsis">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum est hic ipsum
                  consequuntur laboriosam voluptatem voluptatibus quas reiciendis veritatis, nobis
                  repudiandae? Optio perspiciatis tempore quos aliquam adipisci, quaerat at.
                  Dolorem.
                </p>
                <div className="flex align-middle">
                  <p className="font score">68</p>
                  <p className="font score">18</p>
                  <p className="font score">23</p>
                  <p className="font score">18</p>
                  <p className="font font--semibold score">84</p>
                </div>
              </div>
              <div className="flex align-middle align-justify overflow-hidden">
                <p className="font text-ellipsis">
                  Paris Saint-Germain Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui
                  deleniti facilis earum delectus eum ad vitae beatae maxime iste ullam voluptatum
                  tempore repudiandae consectetur maiores cumque, rem asperiores laboriosam
                  praesentium.
                </p>
                <div className="flex align-middle">
                  <p className="font score">68</p>
                  <p className="font score">18</p>
                  <p className="font score">23</p>
                  <p className="font score">18</p>
                  <p className="font font--semibold score">84</p>
                </div>
              </div>
            </td>
            <td className="cb-table__td cb-table__td--border" style={{ width: '35%' }}>
              <div className="flex align-middle align-justify">
                <p className="font font--semibold">Match winner</p>
                <p className="font font--10 font--default-alpha-05">odds</p>
              </div>
              <div className="flex align-middle align-justify">
                <p className="font text-ellipsis">Juventus</p>
                <p className="font font--semibold font--blue">2.17</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
