import Live from '../pages/live/Live'
import Account from '../pages/account/Account'
import Sports from '../pages/sports/Sports'
import SportsRedirection from 'components/Routing/sportsRedirection'

export const routes = [
  {
    path: '/sports',
    component: Sports,
    private: false,
    exact: false,
  },
  {
    path: '/live',
    component: Live,
    exact: false,
    private: false,
  },
  {
    path: '/mybets',
    component: Account,
    exact: false,
  },
  {
    path: '/account',
    component: Account,
    exact: false,
    private: false,
  },
  {
    component: SportsRedirection,
  },
]
