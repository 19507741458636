import { types } from '../types/types'

export const setQuickbet = (value: boolean) => {
  return {
    type: types.SET_QUICKBET,
    payload: value,
  }
}

export const setBetslip = (data: any) => {
  return {
    type: types.SET_BETSLIP,
    payload: data,
  }
}

export const disableOddsUpdates = () => {
  return {
    type: types.DISABLE_ODDS_UPDATES,
  }
}

export const enableOddsUpdates = () => {
  return {
    type: types.ENABLE_ODDS_UPDATES,
  }
}

export const updateOdd = (id: number, oddValue: number) => {
  return {
    type: types.UPDATE_ODD_VALUE,
    payload: { id, oddValue },
  }
}

export const initBetslip = () =>
  ({
    type: types.INIT_BETSLIP,
  } as const)

export const addStake = (
  FI: number,
  ID: number,
  OD: number,
  CL: number,
  HA: string,
  qb?: boolean
) => {
  return {
    type: types.ADD_STAKE,
    payload: { FI, ID, OD, CL, HA, qb },
  } as const
}

export const placeBet = (
  payload: false | { FI: number; ID: number; OD: number; CL: number; HA: string }
) =>
  ({
    type: types.PLACE_BET,
    payload,
  } as const)

export const acceptChanges = () =>
  ({
    type: types.ACCEPT_CHANGES,
  } as const)

export const setPopup = (payload: boolean) =>
  ({
    type: types.SET_MAX_STAKES_POPUP,
    payload,
  } as const)

export const removeStake = (ID: number) =>
  ({
    type: types.REMOVE_STAKE,
    payload: { ID },
  } as const)

export const removeAll = () =>
  ({
    type: types.REMOVE_ALL,
  } as const)

export const cleanLimits = () =>
  ({
    type: types.CLEAN_LIMITS,
  } as const)


export const setPath = (path: string) =>
  ({
    type: types.SET_PATH,
    payload: path,
  } as const)

export const setError = (er: string) =>
  ({
    type: types.SET_ERROR_MESSAGE,
    payload: er,
  } as const)
