import React from 'react'
import { useSelector } from 'react-redux'
import { getAllSports } from 'redux/selectors/prematchSelectors'
import TopLeagueItem from './TopLeagueItem'

const TopLeague = () => {
  const sports = useSelector(getAllSports)
  //console.log(sports)

  const getTopLeagues = (data) => {
    const leagues = [
      {
        iconCode: '',
        leagues: [],
      },
      {
        iconCode: '',
        leagues: [],
      },
      {
        iconCode: '',
        leagues: [],
      },
    ]

    data?.forEach((item) => {
      if (item.id === 1) {
        if (item.categories.length >= 3) {
          leagues[0].iconCode = item.categories[0].iconCode
          item.categories[0].leagues.length < 3
            ? (leagues[0].leagues = item.categories[0].leagues)
            : (leagues[0].leagues = item.categories[0].leagues.slice(0, 3))
          leagues[1].iconCode = item.categories[1].iconCode
          item.categories[1].leagues.length < 3
            ? (leagues[1].leagues = item.categories[1].leagues)
            : (leagues[1].leagues = item.categories[1].leagues.slice(0, 3))
          leagues[2].iconCode = item.categories[0].iconCode
          item.categories[2].leagues.length < 3
            ? (leagues[2].leagues = item.categories[2].leagues)
            : (leagues[2].leagues = item.categories[2].leagues.slice(0, 3))
        }
      }
    })

    return leagues
  }

  // console.log(getTopLeagues(sports)[0].leagues)
  const top_leagues: any[] = getTopLeagues(sports)
  return (
    <>
      <div className="top-league">
        <div className="top-league__header">
          <p className="font font--semibold text-ellipsis top-league__headline">Top League</p>
          <div className="flex align-middle top-league__wrap">
            <div className="top-league__icon">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.41688 9.97995L6.24475 9.38906L5.64154 7.17975L7.20899 5.60189L9.39242 6.20119L9.98776 8.39863L8.41688 9.97995ZM14.9338 6.9157L13.1615 8.70026L10.9225 8.09107L10.3414 5.94505L11.9536 4.3217L14.4077 4.99566L14.666 4.04133L12.2075 3.36637L11.5577 0.968673L10.6101 1.22877L11.259 3.62301L9.64686 5.24587L7.46295 4.64607L6.88823 2.52527L8.70227 0.699185L8.0077 0L6.19366 1.82609L3.73958 1.15212L3.4812 2.10646L5.93922 2.78092L6.51393 4.9027L4.88852 6.53941L2.79204 5.96928L2.11269 3.48257L1.16515 3.74465L1.81797 6.13345L0 7.96399L0.69457 8.66317L2.44671 6.89938L4.71463 7.51649L5.28394 9.60069L3.64379 11.2517L1.22901 10.5951L0.973086 11.5499L3.42226 12.216L4.09325 14.671L5.0403 14.4089L4.36243 11.9272L5.94658 10.332L8.10545 10.9195L8.70865 13.1461L6.93686 14.9296L7.63143 15.6288L9.44056 13.8077L11.9005 14.4767L12.1569 13.5224L9.64343 12.8385L9.06969 10.7212L10.7197 9.06023L12.85 9.63976L13.5284 12.1433L14.4759 11.8827L13.8148 9.44147L15.6288 7.61538L14.9338 6.9157Z"
                  fill="#099A01"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.976801 7.81392C0.976801 7.22442 1.05299 6.63004 1.20977 6.04493C1.84225 3.68498 3.68498 1.84225 6.04493 1.20977C6.62955 1.05299 7.22442 0.976801 7.81392 0.976801C9.60391 0.976801 11.3504 1.6801 12.6496 2.97973C13.9487 4.27888 14.652 6.02491 14.652 7.8149C14.652 8.40488 14.5758 8.99927 14.419 9.58437C13.7871 11.9438 11.9438 13.7871 9.58388 14.419C8.99927 14.5758 8.40488 14.652 7.8149 14.652C6.02491 14.652 4.27888 13.9487 2.97973 12.6496C1.6801 11.3504 0.976801 9.60391 0.976801 7.81392ZM9.83687 15.3626C12.5338 14.6398 14.6398 12.5338 15.3626 9.83687C15.5414 9.16874 15.6288 8.48889 15.6288 7.8149C15.6293 5.76947 14.8249 3.77387 13.3402 2.28864C11.8554 0.803907 9.85934 0 7.81392 0C7.13993 0 6.46007 0.0874237 5.79194 0.266178C3.09499 0.989011 0.989011 3.09548 0.266178 5.79194C0.0874237 6.46007 0 7.13993 0 7.81392C0 9.85934 0.803907 11.8554 2.28864 13.3402C3.77338 14.8249 5.76947 15.6293 7.8149 15.6288C8.48889 15.6288 9.16874 15.5419 9.83687 15.3626Z"
                  fill="#099A01"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="top-league__body">
          {top_leagues[0].leagues !== null
            ? top_leagues[0].leagues?.map((item, idx) => (
                <TopLeagueItem
                  key={idx}
                  flag={getTopLeagues(sports)[0].iconCode}
                  name={item.name}
                  eventsCount={item.matchCount}
                />
              ))
            : ''}
          {top_leagues[1].leagues !== null
            ? top_leagues[1].leagues?.map((item, idx) => (
                <TopLeagueItem
                  key={idx}
                  flag={getTopLeagues(sports)[1].iconCode}
                  name={item.name}
                  eventsCount={item.matchCount}
                />
              ))
            : ''}
          {top_leagues[2].leagues !== null
            ? top_leagues[2].leagues?.map((item, idx) => (
                <TopLeagueItem
                  key={idx}
                  flag={getTopLeagues(sports)[2].iconCode}
                  name={item.name}
                  eventsCount={item.matchCount}
                />
              ))
            : ''}
        </div>
      </div>
    </>
  )
}

export default TopLeague
