import React from 'react'

const SubMenuDropDownItem = ({ item, active, onChoosen }) => {
  return (
    <div
      role="button"
      className={
        item.key === active
          ? 'dropdown__item h-submenu__lang h-submenu__lang--active'
          : 'dropdown__item h-submenu__lang h-submenu__lang'
      }
      tabIndex={0}
      onKeyDown={() => 0}
      onClick={() => onChoosen(item)}
    >
      <p className="font font--grey-300 font--12 text-uppercase m-l-5">
        {item.name && item.name.lang ? item.name.text : item.name}
      </p>
    </div>
  )
}

export default SubMenuDropDownItem
