import React from 'react'
import Region from '../region/Region'

const SportBody = ({ lastOpened, openedHandler, categories }) => {

  categories.sort((a, b) => {
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  })

  return (
    <div className="wn-classification-sport__body">
      {categories?.map((category) => {
        return <Region
          key={category.id}
          category={category}
          {...{ lastOpened }}
          {...{ openedHandler }} />
      })}
    </div>
  )
}

export default SportBody
