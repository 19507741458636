import { modifyBets } from 'helpers/modifyBets'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getOddsType, getTZA } from 'redux/selectors/configSelectors'
import Statuses from './Statuses'

const BetlipAction = ({ type, bet, idx, just_time }) => {
  const tza = useSelector(getTZA)
  const nu = (str: string): string => (str.length === 1 ? '0' + str : str)
  const ODDS_TYPE = useSelector(getOddsType)
  const correctTime = useCallback(
    (time: number): string => {
      const startDate = new Date(time * 1000)
      const month = startDate.getMonth()
      const date = startDate.getDate()
      const year = startDate.getFullYear()
      const hours = startDate.getHours()
      const mins = startDate.getMinutes()
      const cor_h = ((Number(hours) * 60 + Number(mins) + Number(tza)) / 60).toFixed(0)
      const cor_m = ((Number(hours) * 60 + Number(mins) + Number(tza)) % 60).toFixed(0)
      return (
        nu(date.toString()) +
        '/' +
        nu((month + 1).toString()) +
        '/' +
        year +
        ' ' +
        nu(hours.toString()) +
        ':' +
        nu(mins.toString())
      )
    },
    [bet]
  )

  if (!bet?.PA[idx]) {
    return null
  }

  if (just_time) {
    return (
      <div className="betslip__action">
        <div className="betslip__tr betslip__tr--border">
          <p className="font">{correctTime(bet.TS)}</p>
        </div>
      </div>
    )
  }

  const transformMarket = (name = '') => {
    name = name.replace(/{\$competitor1}/gi, bet.PA[0].NA.split(' v ')[0])
    name = name.replace(/{\$competitor2}/gi, bet.PA[0].NA.split(' v ')[1])
    name = name.replace(/{\+hcp}/gi, '')
    name = name.replace(/{-hcp}/gi, '')
    name = name.replace(/{total}/gi, '')
    name = name.replace(/{!goalnr}/gi, '')
    return name
  }


  const { MA, NA, SL, OD } = bet.PA[idx]

  return (
    <div className="betslip__action">
      {type ? (
        <>
          <div className="betslip__tr betslip__tr--border">
            <p className="font">{correctTime(bet.TS)}</p>
          </div>
          <div className="betslip__tr betslip__tr--border">
            <div className="betslip__td betslip__td--big">
              <p className="font font--semibold m-b-10">{transformMarket(SL)}</p>
              <Statuses
                item={bet.PA[0]}
                ST={bet.ST}
                RE={bet.RE} />
            </div>
            {/* <div className="betslip__td betslip__td--small betslip__td--border">
              <div className="flex flex-column">
                <p className="font font--semibold">21.09</p>
                <p className="font">21.09</p>
              </div>
            </div> */}
            <div className="betslip__td--grow" style={{ padding: '0 5px' }}>
              <div className="flex flex-column">
                <div className="flex align-middle align-justify">
                  <p className="font font--12 font--blue">{MA}</p>
                  <p className="font font--12 font--blue font--semibold mr-5">
                    {modifyBets(OD, ODDS_TYPE)}
                  </p>
                </div>
                <div className="flex align-middle align-justify">
                  <p className="font font--12">{NA}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="betslip__tr betslip__tr--border">
            <div className="betslip__td betslip__td--big">
              <p className="font font--semibold m-b-10">{transformMarket(bet.SL)}</p>
              <Statuses
                item={bet.PA[idx]}
                ST={bet.ST}
                RE={bet.RE} />
            </div>
            {/* <div className="betslip__td betslip__td--small betslip__td--border">
              <div className="flex flex-column">
                <p className="font font--semibold">21.09</p>
                <p className="font">21.09</p>
              </div>
            </div> */}
            <div className="betslip__td betslip__td--grow" style={{ display: 'block' }}>
              <div className="flex flex-column">
                <div className="flex align-middle align-justify">
                  <p className="font font--semibold m-b-10">{transformMarket(SL)}</p>
                  <p className="font font--12 font--blue font--semibold mr-5">
                    {modifyBets(OD, ODDS_TYPE)}
                  </p>
                </div>
                <div className="flex align-middle align-justify">
                  <p className="font font--12 font--blue">{MA}</p>
                </div>
                <div className="flex align-middle align-justify">
                  <p className="font font--12">{NA}</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="betslip__tr">
						<div className="betslip__td betslip__td--big">
							<p className="font font--semibold m-b-10">{bet.SL}</p>
						</div>
						<div className="betslip__td betslip__td--small betslip__td--border">
							<div className="flex flex-column">
								<p className="font font--semibold">21.09</p>
								<p className="font">21.09</p>
							</div>
						</div>
						<div className="betslip__td betslip__td--grow">
							<div className="flex flex-column">
								<div className="flex align-middle align-justify">
									<p className="font font--12 font--blue">{bet.MA}</p>
									<p className="font font--12 font--blue font--semibold">1.34</p>
								</div>
								<div className="flex align-middle align-justify">
									<p className="font font--12">{bet.NA}</p>
								</div>
							</div>
						</div>
					</div> */}
        </>
      )}
    </div>
  )
}

export default BetlipAction
