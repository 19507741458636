import React from 'react'

const SearchIcon = () => {
  return (
    <div className="h-search__img">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="15" height="15" rx="0.5" stroke="#FFD666" />
        <rect x="2" y="8" width="11" height="1" fill="#FFD666" />
        <rect x="2" y="10" width="6" height="1" fill="#FFD666" />
        <rect x="2" y="12" width="10" height="1" fill="#FFD666" />
        <rect x="2" y="2" width="4" height="4" fill="#FFD666" />
      </svg>
    </div>
  )
}

export default SearchIcon
