import React from 'react'
import { useTranslation } from 'react-i18next'

interface ILiveStreaming {
  liveStreaming: boolean
  setLStreaming: (state: boolean) => void
}

const LiveStreaming: React.FC<ILiveStreaming> = ({
  setLStreaming,
  liveStreaming,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex align-middle ip-classification-header__streaming">
      <label className="font font--white-alpha-05 m-r-5" htmlFor="streaming">
        {t('COMMON.ASIDE_RIGHT.LIVE_STREAMING', 'Live Streaming')}
      </label>
      <label className="toggle toggle--grey-200">
        <input
          className="toggle__input"
          type="checkbox"
          id="streaming"
          value={liveStreaming ? 'checked' : ''}
          onChange={() => {
            setLStreaming(!liveStreaming)
          }}
        />
        <span className="toggle__swipe" />
        <span className="toggle__bg" />
      </label>
    </div>
  )
}

export default React.memo(LiveStreaming)
