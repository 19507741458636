import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { inplayWorkers } from 'redux/actions/inplayActions'
import ChangeMarketDropDown from './ChangeMarketDropDown'

const ChangeMarket = ({ currentOdd, oddsName, currentOddId, oddsId }) => {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()

  const onChangeMarket = (value, idx) => {
    dispatch(inplayWorkers.setCurrentOddId(value))
    dispatch(inplayWorkers.setCurrentOddName(oddsName[idx]))
    changeShow()
  }
  const changeShow = () => setShow(!show)

  return (
    <div className="flex align-middle align-right">
      <p className="font font--white m-r-10">{currentOdd}</p>
      <div className={show ? 'dropdown dropdown--active' : 'dropdown'}>
        <ChangeMarketDropDown
          oddsName={oddsName}
          oddsId={oddsId}
          changeShow={changeShow}
          onChangeMarket={onChangeMarket}
        />
      </div>
    </div>
  )
}

export default React.memo(ChangeMarket)
