import { types } from '../types/types'

const initialState = {
	leftAside: false,
	leftAsideActiveLink: '1',
	rightAside: false,
	betslip: false,
	search: false,
	authJoin: false,
}

export type IState = typeof initialState

export const context = (state = initialState, action: any): IState => {
	switch (action.type) {
		case types.SET_CONTEXT:
			return {
				...state,
				...action.payload,
			}
		default:
			return state
	}
}
