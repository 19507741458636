import Deposit from '../../components/forAccontPageComponents/balance/Deposit'
import Withdraw from '../../components/forAccontPageComponents/balance/Withdraw'
import BalanceHistory from '../../components/forAccontPageComponents/balance/BalanceHistory'
import BalanceRedirection from 'components/Routing/accountRedirection/balanceRedirection'

export const balanceRouting = [
	{
		path: '/account/balance/deposit',
		exact: true,
		component: Deposit,
	},
	{
		path: '/account/balance/withdraw',
		exact: true,
		component: Withdraw,
	},
	{
		path: '/account/balance/history',
		exact: true,
		component: BalanceHistory,
	},
	{
		component: BalanceRedirection
	},
]
