import React from 'react'

type ButtonType = {
  title: string
  handler?: any
  type?: any /* 'submit' | 'button' | 'reset' */
} & React.HTMLProps<HTMLButtonElement>
const Button: React.FC<ButtonType> = ({ type = 'button', title, handler, ...rest }) => {
  return (
    <div className="mn-popup__wrap">
      <button
        type={type}
        className="btn btn--blue btn--h-44"
        onClick={handler}
        {...(rest as React.HTMLProps<HTMLButtonElement>)}
      >
        <span className="font font--white">{title}</span>
      </button>
    </div>
  )
}

export default Button
