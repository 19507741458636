import React, { useState, useEffect } from 'react'
import { useRequest } from '../../../hooks/useRequest'
import Time from './time/Time'
import SubmenuItem from './SubmenuItem'
import LanguageWrapper from './language/LanguageWrapper'
import SeparateLine from './SeparateLine'
// import submenuJSON from '../../../json/submenu'
import SubMenuItemWithDropDown from './dropDown/SubMenuItemWithDropDown'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getConfig } from 'redux/selectors/configSelectors'
import { isIterable } from 'helpers/utils/utils'
import { configWorkers } from 'redux/actions/configActions'

const Submenu = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { CUSTOMER_CONFIG } = useSelector(getConfig)
  const { get } = useRequest()
  // const { setUserConfig, getUserConfig, changeLanguage } = useContext(ConfigContext)

  const setUserConfig = (key, value) => {
    dispatch(configWorkers.updateCustConfig(key, value))
  }

  interface ISubmenu {
    id: number
    title: { text: string; lang: boolean }
    drop: any[]
    active: string
    config_key: string
  }
  const [submenu, setSubmenu] = useState<ISubmenu[]>([])

  useEffect(() => {
    if (CUSTOMER_CONFIG) {
      const data = submenu
      if (isIterable(data)) {
        const oddsType = data.find((item) => item.config_key === 'ODDS_TYPE')
        if (oddsType) {
          oddsType!.drop = oddsType!.drop?.map((item) => {
            if (item.key === CUSTOMER_CONFIG.ODDS_TYPE) {
              oddsType!.active = '1'
            }
            return item
          })
          setSubmenu(data)
        }
      }
    }
  }, [CUSTOMER_CONFIG])

  // load submenu
  useEffect(() => {
    async function getSubmenu() {
      try {
        const data = await get('https://dev.absolute.bet/config/submenu.json', [], false)
        setSubmenu(isIterable(data) ? data : [])
        return Promise.resolve(1)
      } catch (e) {
        console.log('On submenu load request error:', e)
        return Promise.reject()
      }
    }
    getSubmenu()
  }, [])

  return (
    <div className="h-submenu">
      <div className="flex align-middle full-height h-submenu__wrapper">
        <SeparateLine />
        {submenu?.map(({ title, drop, id, config_key }) => {
          if (title.text === 'COMMON.SUBMENU.LANGUAGE') {
            return (
              <LanguageWrapper
                key={id}
                {...{ drop }}
                {...{ title }}
                {...{ config_key }}
                {...{ setUserConfig }}
                active={CUSTOMER_CONFIG?.LANGUAGE_ID ?? '1'}
              />
            )
          }

          if (drop) {
            return (
              <SubMenuItemWithDropDown
                key={id}
                {...{ drop }}
                {...{ title }}
                {...{ config_key }}
                {...{ setUserConfig }}
                active={config_key && CUSTOMER_CONFIG && CUSTOMER_CONFIG[config_key]}
              />
            )
          }

          return (
            <SubmenuItem key={id}>
              <a className="font font--12 h-submenu__link">{title.lang ? t(title.text) : title}</a>
            </SubmenuItem>
          )
        })}
        <SeparateLine />
        <Time />
      </div>
    </div>
  )
}
export default Submenu
