import React, { useContext, useEffect, useRef } from 'react'
import LostLogin from './lostLogin'
import Overlay from '../overlay'
import LostPassword from './lostPassword'
import LeftPart from '../leftPart'
import Text from '../../common/elements/Text'
import Tabs from '../../common/tabs/Tabs'
import { RegisterContext } from '../../../context/registrationContext'

const tabs = [
  {
    title: 'Username',
    id: '0',
  },
  {
    title: 'Password',
    id: '1',
  },
]

const LostCredentials = () => {
  const ref = useRef()

  const { lostCredentials, showLostCredentialsForm, setLostCredentials } =
    useContext(RegisterContext)

  const hide = (e) => {
    if (ref.current === e.target && showLostCredentialsForm) {
      showLostCredentialsForm('')
    }
  }

  const changeContent = (id) => {
    if (setLostCredentials && lostCredentials) {
      setLostCredentials({
        ...lostCredentials,
        type: id,
      })
    }
  }

  useEffect(() => {
    document.addEventListener('click', hide)
    return () => document.removeEventListener('click', hide)
  }, [ref.current])

  return (
    <Overlay node={ref}>
      <div className="mn-popup mn-popup--active lost-cred">
        <div className="flex align-center-middle mn-popup__block">
          <LeftPart>
            <Text className="font font--16 font--white font--semibold m-b-20" title="Lost password" />
            <Text
              className="font font--white"
              title="Forgot yours username. Fill out the form and we will send you an e-mail"
            />
          </LeftPart>
          <div className="mn-popup__right">
            {/* <Tabs
              activeTab={lostCredentials?.type ?? '1'}
              handler={changeContent}
              className="mn-popup__tabs"
              activeCl="mn-popup__link--active"
              defaultCl="mn-popup__link"
              {...{ tabs }}
            /> */}
            {/* {lostCredentials?.type === '1' ? <LostPassword /> : <LostLogin />} */}
            <LostPassword />
          </div>
        </div>
      </div>
    </Overlay>
  )
}

export default LostCredentials
