import React, { useContext, useState } from 'react'
import * as yup from 'yup'
import Button from '../elements/button'
import Input from '../../common/elements/Input'
import Wrap from '../elements/wrap'
import Text from '../../common/elements/Text'
import Support from '../elements/support'
import { useFormik } from 'formik'
import { isLoggedIn } from 'redux/selectors/configSelectors'
import { useSelector } from 'react-redux'
import { Nullable } from 'helpers/commonInterfaces/interfaces'
import { post } from 'helpers/asyncRequests'
import { links } from 'connection/links'
import { RegisterContext } from 'context/registrationContext'

const numericRegex = /(?=.*[0-9])/
const lowercase = /(?=.*[a-z])/g

const LostPassword = () => {
  const { showCodeSent, showLostCredentialsForm } = useContext(RegisterContext)

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
  const [isEmail, setIsEmail] = useState<boolean>(true)
  const LOGGED_IN = useSelector(isLoggedIn)

  const submitHandler = async (values: any) => {
    if (Object.values(values).every((item) => !!item)) {
      setErrorMessage(null)
      const hid = sessionStorage.getItem('fingerprint') ?? ''
      const resetData = {
        Details: { Login: values.username, hid },
      }
      const res = await post(links.resetPassword, JSON.stringify(resetData))()
      if (res.RESET.SUCCESS && showCodeSent && showLostCredentialsForm) {
        showLostCredentialsForm('password')
        showCodeSent()
      } else {
        setErrorMessage(res.RESET.INFO)
      }
    }
  }

  const forgotSchemaEmail = yup.object().shape({
    username: yup.string().trim().min(6).email('Uncorrect email.').required(),
  })
  const forgotSchemaNumber = yup.object().shape({
    username: yup
      .string()
      .trim()
      .min(10)
      .max(13, 'Phone number is too long')
      .matches(numericRegex, 'One lowercase required')
      .required(),
  })
  const { handleSubmit, values, errors, touched, handleChange } = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: isEmail ? forgotSchemaEmail : forgotSchemaNumber,
    onSubmit: submitHandler,
  })

  const checkSchema = (e: any) => {
    if (e.target.value.match(lowercase)) {
      setIsEmail(true)
    } else {
      setIsEmail(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="mn-popup__body">
      <div className="mn-popup__wrap">
        <div className="text-center">
          <Text className="font font--white" title="Please enter email" />
        </div>
      </div>
      <Wrap>
        {errorMessage &&
          <div className="auth__message flex align-center-middle m-b-20">
            <p className="font h6--white">{errorMessage}</p>
          </div>
        }
        {errors.username && touched.username && (
          <span className="field--error">{errors.username}</span>
        )}
        <Input
          className="field field--main"
          type="email"
          placeholder="E-mail*"
          value={values.username}
          name="username"
          onChange={e => {
            checkSchema(e)
            handleChange(e)
          }} />
      </Wrap>
      <Wrap>
        <Button
          type="submit"
          className="btn btn--blue btn--h-44 font--white"
          title="Reset password"
        />
      </Wrap>
      <Support />
    </form>
  )
}

export default LostPassword
