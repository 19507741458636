export const types = {
  // icons
  ADD_ICONS: 'ADD_ICONS' as const,
  // In-play
  CHANGE_COLUMN_MARKET: 'CHANGE_COLUMN_MARKET',
  GET_INPLAY_TREE: 'GET_INPLAY_TREE',
  SET_INPLAY_DATA: 'SET_INPLAY_DATA' as const,
  // CLEAR_ADDITIONAL_DATA: 'CLEAR_ADDITIONAL_DATA',
  // SET_INPLAY_LEAGUES: 'SET_INPLAY_LEAGUES',
  // DELETE_INPLAY_LEAGUES: 'DELETE_INPLAY_LEAGUES',
  // SET_INPLAY_VIEW: 'SET_INPLAY_VIEW',
  // SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
  // SET_CURRENT_LEAGUES: 'SET_CURRENT_LEAGUES',
  // DELETE_CURRENT_LEAGUES: 'DELETE_CURRENT_LEAGUES',
  SET_LEAGUES_IN_INPLAY: 'SET_LEAGUES_IN_INPLAY' as const,
  SET_LEAGUES_IN_CURRENT_VIEW: 'SET_LEAGUES_IN_CURRENT_VIEW' as const,
  UPDATE_CURRENT_VIEW: 'UPDATE_CURRENT_VIEW',
  GET_STREAMING_EVENTS: 'GET_STREAMING_EVENTS' as const,
  SET_STREAMING_EVENTS: 'SET_STREAMING_EVENTS' as const,
  DISABLE_STREAMING_EVENTS_UPDATE: 'DISABLE_STREAMING_EVENTS_UPDATE' as const,
  CURRENT_ODD_NAME: 'CURRENT_ODD_NAME' as const,
  CURRENT_ODD_ID: 'CURRENT_ODD_ID' as const,
  SET_ALL_ODDS_NAME: 'SET_ALL_ODDS_NAME' as const,
  SET_ALL_ODDS_ID: 'SET_ALL_ODDS_ID' as const,
  INPLAY_ALL: 'INPLAY_ALL' as const,
  SET_ACTIVE_TRACKER: 'SET_ACTIVE_TRACKER' as const,
  SET_ACTIVE_TRANSLATION: 'SET_ACTIVE_TRANSLATION' as const,
  SET_PINNED_MATCH: 'SET_PINNED_MATCH' as const,

  /////
  SET_CHOSEN_LEAGUES_WATCHER: 'SET_CHOSEN_LEAGUES_WATCHER' as const,
  SET_CHOSEN_LEAGUES: 'SET_CHOSEN_LEAGUES' as const,
  TOGGLE_CHOSEN_LEAGUE: 'TOGGLE_CHOSEN_LEAGUE' as const,
  DESTROY_LEAGUE_WATCHER: 'DESTROY_LEAGUE_WATCHER' as const,
  DESTROY_LEAGUE: 'DESTROY_LEAGUE' as const,
  LOAD_LEAGUE_EVENTS_WATCHER: 'LOAD_LEAGUE_EVENTS_WATCHER' as const,
  LOAD_LEAGUE_EVENTS: 'LOAD_LEAGUE_EVENTS' as const,
  SET_CURRENT_VIEW_WATCHER: 'SET_CURRENT_VIEW_WATCHER' as const,
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW' as const,
  DISABLE_INPLAY_UPDATE_WATCHER: 'DISABLE_INPLAY_UPDATE_WATCHER' as const,

  // EventLive
  SET_EVENT_WATCHER: 'SET_EVENT_WATCHER' as const,
  SET_EVENT: 'SET_EVENT' as const,
  SET_EVENT_ERROR: 'SET_EVENT_ERROR' as const,
  SET_LEAGUE_EVENTS_WATCHER: 'SET_LEAGUE_EVENTS_WATCHER' as const,
  SET_LEAGUES_EVENTS_WATCHER: 'SET_LEAGUES_EVENTS_WATCHER' as const,
  SET_LEAGUE_EVENTS: 'SET_LEAGUE_EVENTS' as const,
  SET_LEAGUES_EVENTS: 'SET_LEAGUES_EVENTS' as const,
  SET_MARKETS: 'SET_MARKETS' as const,
  SET_ASIDE: 'SET_ASIDE' as const,
  REMOVE_EVENT: 'REMOVE_EVENT' as const,
  REMOVE_EVENT_BY_ID: 'REMOVE_EVENT_BY_ID' as const,
  CLEAR_EVENT_DATA: 'CLEAR_EVENT_DATA' as const,

  // Betslip
  REFRESHSLIP: 'REFRESHSLIP' as const,
  SET_QUICKBET: 'SET_QUICKBET' as const,
  SET_BETSLIP: 'SET_BETSLIP' as const,
  UPDATE_ODD_VALUE: 'UPDATE_ODD_VALUE' as const,
  INIT_BETSLIP: 'INIT_BETSLIP' as const,
  ADD_STAKE: 'ADD_STAKE' as const,
  REMOVE_STAKE: 'REMOVE_STAKE' as const,
  ACCEPT_CHANGES: 'ACCEPT_CHANGES' as const,
  REMOVE_ALL: 'REMOVE_ALL' as const,
  PLACE_BET: 'PLACE_BET' as const,
  DISABLE_ODDS_UPDATES: 'DISABLE_ODDS_UPDATES' as const,
  ENABLE_ODDS_UPDATES: 'ENABLE_ODDS_UPDATES' as const,
  DISABLE_BS_UPDATES: 'DISABLE_BS_UPDATES' as const,
  RESTART_BS_SUB: 'RESTART_BS_SUB' as const,
  SET_MAX_STAKES_POPUP: 'SET_MAX_STAKES_POPUP' as const,
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE' as const,
  CLEAN_LIMITS: 'CLEAN_LIMITS' as const,
  ADD_ODD: 'ADD_ODD' as const,
  REMOVE_ODD: 'REMOVE_ODD' as const,
  SET_PATH: 'SET_PATH' as const,

  SET_CONTEXT: 'SET_CONTEXT' as const,
  TOGGLE_SEARCH: 'TOGGLE_SEARCH' as const,

  // Config
  GET_CONFIG_WATCHER: 'GET_CONFIG_WATCHER' as const,
  SET_CONFIG: 'SET_CONFIG' as const,
  GET_ODD_TYPES: 'GET_ODD_TYPES' as const,
  SET_ODD_TYPES: 'SET_ODD_TYPES' as const,
  SET_LOCALES: 'SET_LOCALES' as const,
  SET_CUSTOMER_CONFIG: 'SET_CUSTOMER_CONFIG' as const,
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE' as const,

  // Banners
  GET_SLIDES: 'GET_SLIDES' as const,
  GET_INLINE_BANNERS: 'GET_INLINE_BANNERS' as const,
  SET_SLIDES: 'SET_SLIDES' as const,
  SET_INLINE_BANNERS: 'SET_INLINE_BANNERS' as const,

  // Balance
  GET_BALANCE_WATCHER: 'GET_BALANCE_WATCHER' as const,
  SET_BALANCE: 'SET_BALANCE' as const,

  // Prematch
  GET_PREMATCH_ALL_WATCHER: 'GET_PREMATCH_ALL_WATCHER' as const,
  SET_PREMATCH_ALL: 'GET_PREMATCH_ALL' as const,
  GET_PREMATCH_CURRENT_SPORT: 'GET_PREMATCH_CURRENT_SPORT' as const,
  SET_PREMATCH_SPORT: 'SET_PREMATCH_SPORT' as const,
  CLEAR_CURRENT_SPORT_WATCHER: 'CLEAR_CURRENT_SPORT_WATCHER' as const,
  CLEAR_CURRENT_SPORT: 'CLEAR_CURRENT_SPORT' as const,

  //PrematchLeague
  GET_PREMATCH_LEAGUE_WATCHER: 'GET_PREMATCH_LEAGUE_WATCHER' as const,
  SET_PREMATCH_LEAGUE: 'SET_PREMATCH_LEAGUE' as const,
  CLEAR_PREMATCH_LEAGUE_WATCHER: 'CLEAR_PREMATCH_LEAGUE_WATCHER' as const,
  CLEAR_PREMATCH_LEAGUE: 'CLEAR_PREMATCH_LEAGUE' as const,
  SET_PREMATCH_LEAGUES_EVENTS: 'SET_PREMATCH_LEAGUES_EVENTS' as const,

  //PrematchEvent
  SET_PREMATCH_EVENT_WATCHER: 'SET_PREMATCH_EVENT_WATCHER' as const,
  SET_PREMATCH_EVENT: 'SET_PREMATCH_EVENT' as const,
  SET_PREMATCH_EVENT_ERROR: 'SET_PREMATCH_EVENT_ERROR' as const,
  SET_PREMATCH_SAME_LEAGUE_EVENTS_WATCHER: 'SET_PREMATCH_SAME_LEAGUE_EVENTS_WATCHER' as const,
  SET_PREMATCH_SAME_LEAGUES_EVENTS_WATCHER: 'SET_PREMATCH_SAME_LEAGUES_EVENTS_WATCHER' as const,
  SET_PREMATCH_SAME_LEAGUE_EVENTS: 'SET_PREMATCH_SAME_LEAGUE_EVENTS' as const,
  SET_PREMATCH_MARKETS: 'SET_PREMATCH_MARKETS' as const,
  REMOVE_PREMATCH_EVENT: 'REMOVE_PREMATCH_EVENT' as const,
  CLEAR_PREMATCH_EVENT_DATA: 'CLEAR_PREMATCH_EVENT_DATA' as const,

  // favorites
  ADD_SPORT_SPEC_TO_FAV: 'ADD_SPORT_SPEC_TO_FAV' as const,
  ADD_SPORT_TO_FAV: 'ADD_SPORT_TO_FAV' as const,
  ADD_LEAGUE_TO_FAV: 'ADD_LEAGUE_TO_FAV' as const,
  ADD_EVENT_TO_FAV: 'ADD_EVENT_TO_FAV' as const,
  ADD_MARKET_TO_FAV: 'ADD_MARKET_TO_FAV' as const,
  REMOVE_SPORT_FROM_FAV: 'REMOVE_SPORT_FROM_FAV' as const,
  REMOVE_LEAGUE_FROM_FAV: 'REMOVE_LEAGUE_FROM_FAV' as const,
  REMOVE_EVENT_FROM_FAV: 'REMOVE_EVENT_FROM_FAV' as const,
  REMOVE_MARKET_FROM_FAV: 'REMOVE_MARKET_FROM_FAV' as const,

  //My bets
  GET_MYBETS_WATCHER: 'GET_MYBETS_WATCHER' as const,
  GET_MYBETS: 'GET_MYBETS' as const,
  SET_MY_BETS_LOADING: 'SET_MY_BETS_LOADING' as const,
  CREATE_RULE: 'CREATE_RULE' as const,
  CASHOUT: 'CASHOUT' as const,
  SET_CASHOUT: 'SET_CASHOUT' as const,

  // Preloaders
  START_PRELOADER: 'START_PRELOADER' as const,
  FINISH_PRELOADER: 'FINISH_PRELOADER' as const,

  // Account
  SET_MESSAGES: 'SET_MESSAGES' as const,
  SET_VERIFY: 'SET_VERIFY' as const,
  SET_SHOW_MONEY: 'SET_SHOW_MONEY' as const,

  // Top games
  GET_TOP_GAMES_WATCHER: 'GET_TOP_GAMES_WATCHER' as const,
  DISABLE_TOP_GAMES_WATCHER: 'DISABLE_TOP_GAMES_WATCHER' as const,
  SET_TOP_GAMES: 'SET_TOP_GAMES' as const,

  // Search
  SET_SEARCH_DATA: 'SET_SEARCH_DATA' as const,

  // Schedule
  SET_SCHEDULE_DATA: 'SET_SCHEDULE_DATA' as const,
  SET_SCHEDULE_VIEW: 'SET_SCHEDULE_VIEW' as const,
}
