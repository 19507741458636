import React, { useContext, useEffect, useRef, useState } from 'react'
import { useRequest } from '../../../hooks/useRequest'
import { RegisterContext } from '../../../context/registrationContext'
import BannerSlide from './BannerSlide'
import ThreeWayBanner from '../../forAsideRight/accountAside/threeWayBanner/ThreeWayBanner'
import BetVS from '../../forAsideRight/accountAside/betVS/BetVS'
import BonusOnDeposit from '../../forAsideRight/accountAside/bonusOnDeposit/BonusOnDeposit'
import EventMoreInfo from '../../forAsideRight/accountAside/eventMoreInfo/EventMoreInfo'
import { useSelector } from 'react-redux'
import { getInplay } from 'redux/selectors/inplaySelectors'
import { useLocation } from 'react-router'

const BannerSlider = () => {
  const { get } = useRequest()
  const { showRegistration } = useContext(RegisterContext)
  const location = useLocation()
  const inplay = useSelector(getInplay)
  const onceRef = useRef(true)

  const [slides, setSlides] = useState<Array<any>>([])
  const bannerRef = React.createRef<HTMLDivElement>()

  const [step, setStep] = useState(0)
  const [activeDot, setAsctiveDot] = useState(0)
  const [transform, setTransform] = useState(0)
  const [transition, setTransition] = useState(900)
  const [dots, setDotsData] = useState<Array<number>>([])
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    async function loadBanners() {
      try {
        const banners = await get('https://dev.absolute.bet/config/bannerSlider.json', [], false)
        setSlides(banners)
        console.log(banners)
      } catch (e) {
        setSlides([])
        console.log('Banners didn`t load,', e)
      }
    }
    loadBanners()
  }, [])

  useEffect(() => {
    // replace event ids
    if (onceRef.current && inplay && Object.values(inplay.leaguesEvents).length > 0) {
      const banners = [...slides]
      banners.forEach((banner) => {
        if (banner.eventId) {
          let found = false
          Object.values(inplay.leaguesEvents)?.forEach((events) => {
            events.forEach((event) => {
              if (event.id === Number(banner.eventId)) {
                found = true
              }
            })
          })
          if (!found) {
            const randIdx = getRandomInt(0, Object.values(inplay.leaguesEvents).length)
            banner.eventId = Object.values(inplay.leaguesEvents)[randIdx]?.[0].id.toString()
          }
        }
      })
      onceRef.current = false
      setSlides(banners)
    }
  }, [inplay])

  useEffect(() => {
    onceRef.current = true
  }, [location])

  const toNextSlide = (ind) => {
    setAsctiveDot(ind)
    if (ind * step >= scroll) {
      setTransform(scroll)
      return
    }
    setTransform(ind * step)
  }

  useEffect(() => {
    const cur = bannerRef.current
    const resizeCallback = () => {
      setTransition(0)
      setTransform(0)

      setTimeout(() => {
        if (cur !== null && cur.hasChildNodes()) {
          const width = cur.offsetWidth
          const scr = cur.scrollWidth

          setScroll(scr - width + 20)

          const currentStep = Math.floor(width / 296) * 296
          setStep(currentStep)
          if (width < scr) {
            const dots = slides?.map(() => Math.random())
            dots.length = Math.ceil(scr / currentStep)
            setDotsData(dots)
            toNextSlide(0)
            setTransition(900)
          }

        }
      }, 50)
    }
    resizeCallback()
    const ph = document.querySelector(
      '.main .content .center .simplebar-wrapper .simplebar-placeholder'
    )
    cur?.addEventListener('resize', resizeCallback)
    return () => cur?.removeEventListener('resize', resizeCallback)
  }, [slides])

  const prevHandler = () => {
    if (activeDot > 0) {
      toNextSlide(activeDot - 1)
    }
  }
  const nextHandler = () => {
    if (activeDot < dots.length - 1) {
      toNextSlide(activeDot + 1)
    }
  }
  /* useEffect(() => {
    console.log(`type of showRegistration`, typeof showRegistration)
    console.log(`showRegistration`, showRegistration)
  }, [showRegistration]) */
  return (
    <div className="wn-banner-slider" ref={bannerRef}>
      <button className="fa slides-prev" onClick={prevHandler}></button>

      <div
        className="flex align-middle wn-banner-slider__wrap"
        style={{
          transform: `translate(-${transform}px, 0px)`,
          transition: `all ${transition}ms`,
        }}
      >
        {slides?.map((item) => {
          if (item.type === 'OPEN_ACCOUNT_OFFER') {
            return (
              <BannerSlide
                key={item.id}
                click={() => {
                  console.log(`type of showRegistration`, typeof showRegistration)
                  console.log(`showRegistration`, showRegistration)
                  if (typeof showRegistration !== 'undefined') showRegistration()
                }}
                {...{ item }}
              />
            )
          }
          if (item.type === 'BET_VS') {
            return <BetVS
              key={item.id}
              eventId={item.eventId}
              inSlider={true} />
          }
          if (item.type === 'BONUS_ON_DEPOSIT') {
            return <BonusOnDeposit key={item.id} inSlider={true} />
          }
          if (item.type === 'EVENT_MORE_INFO') {
            return <EventMoreInfo key={item.id} />
          }
          if (item.type === 'THREE_WAY_BANNER') {
            return <ThreeWayBanner
              key={item.id}
              eventId={item.eventId}
            />
          }
          return <></>
        })}
      </div>
      <button className="fa slides-next" onClick={nextHandler}></button>
      <div className="flex align-center-middle wn-banner-slider__dots">
        {dots?.map((item, i) => (
          <span
            key={item}
            onClick={() => toNextSlide(i)}
            className={activeDot === i ? 'active' : ''}
            role="button"
            onKeyPress={() => 0}
            tabIndex={0}
          />
        ))}
      </div>
    </div>
  )
}

export default BannerSlider

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}