import React, { useContext } from 'react'
import TitleMarketCell from './TitleMarketCell'
import SuspendedMarketCell from './SuspendedMarketCell'
import MarketCell from './MarketCell'
import { useDispatch, useSelector } from 'react-redux'
import { EventContext } from '../../../../context/eventContext'
import { addStake, placeBet, removeStake } from '../../../../redux/actions/betslipActions'
import { getBetslip } from 'redux/selectors/betslipSelectors'
import { isLoggedIn } from 'redux/selectors/configSelectors'

const MarketsType = ({ className = '', odd, isTitle = false, named = true }) => {
  const dispatch = useDispatch()
  const { event } = useContext(EventContext)
  const { bt, quickBet } = useSelector(getBetslip)
  const LOGGED_IN = useSelector(isLoggedIn)

  // const createBet = (eventId, id, od, ha, check) => {
  //   check ? dispatch(removeStake(id)) : dispatch(addStake(eventId, id, od, event?.sportId || 1, ha))
  // }

  const createBet = (eventId, id, od, ha, check, qb): void => {
    check
      ? dispatch(removeStake(id))
      : qb && LOGGED_IN
        ? dispatch(placeBet({ FI: eventId, ID: id, OD: od, CL: event?.sportId ?? 1, HA: ha }))
        : dispatch(addStake(eventId, id, od, event?.sportId ?? 1, ha))
  }

  if (typeof odd === 'undefined') {
    return <></>
  }

  return (
    <>
      {isTitle ? (
        <TitleMarketCell odd={odd} />
      ) : odd.status === '1' ? (
        <SuspendedMarketCell className={className} />
      ) : (
        <MarketCell
          className={className}
          named={named}
          panel={quickBet}
          odd={odd}
          evId={event?.id}
          allMarkets={bt}
          onCreateBet={createBet.bind(null, event?.id)}
        />
      )}
    </>
  )
}

export default React.memo(MarketsType)

// : named ? (
//   <NamedMarketCell
// 	panel={quickbet}
// 	odd={odd}
// 	{...{ bt }}
// 	onCreateBet={createBet.bind(null, event.id)}
//   />
// ) : (
//   <UnnamedMarketCell
// 	panel={quickbet}
// 	odd={odd}
// 	{...{ bt }}
// 	onCreateBet={createBet.bind(null, event.id)}
//   />
// )
