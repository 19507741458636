import React from 'react'
import Text from '../../common/elements/Text'

const ScheduleEmpty = ({ init }) => {
  return (
    <div className="ips-table-empty flex align-center-middle">
      {init && (
        <Text
          className="font p-a-5 text-ellipsis font--15 font--grey-400"
          title="
                    Sorry, there are no events available
                    for yor chosen sport on this date
                "
        />
      )}
    </div>
  )
}

export default ScheduleEmpty
