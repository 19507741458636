import { MyBetsWorkersTypes } from '../actions/myBetsActions'
import { types } from '../types/types'

const initialState = {
  data: {
    1: [],
    2: [],
    3: [],
  },
  cashout: {
    status: 2,
    errors: {
      text: '',
      code: 2,
    },
    ID: 0,
  },
  loading: false,
}

export type MyBetsState = typeof initialState

export const myBets = (
  state: MyBetsState = initialState,
  action: MyBetsWorkersTypes
): MyBetsState => {
  switch (action.type) {
    case types.GET_MYBETS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.type]: action.payload.data,
        },
      }
    case types.SET_MY_BETS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case types.SET_CASHOUT:
      return {
        ...state,
        cashout: action.payload,
      }
    default:
      return state
  }
}
