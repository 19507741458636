import React from 'react'
import { useTranslation } from 'react-i18next'
import SubMenuDropDownItem from './SubMenuDropDownItem'

const SubMenuDropDown = ({ drop, title, active, onChoosen }) => {
  const { t } = useTranslation()

  return (
    <>
      <span className="font font--12 h-submenu__link dropdown__toggle">
        {t(title.lang ? title.text : title)}
      </span>
      <div>
        <div className="dropdown__menu">
          {drop?.map((item) => (
            <SubMenuDropDownItem key={item.key} {...{ item }} {...{ active }} {...{ onChoosen }} />
          ))}
        </div>
      </div>
    </>
  )
}

export default SubMenuDropDown
