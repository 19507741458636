import axios from 'axios'

axios.defaults.withCredentials = true

export const get = (
	url: string,
	headers: any[] = [],
	withCredentials = true
) => async () => {
	try {
		const req = await axios({
			method: 'get',
			url: url,
			headers: {
				Accept: 'application/json', // , text/plain, */*
				'Content-Type': 'text/plain',
				...headers,
			},
			withCredentials,
		})

		return await req.data
	} catch (e) {
		console.log('Error', e)
		return e
	}
}

export const getWithouCredentials = (
	url: string,
	headers?: any[],
	config?: any
) => async () => {
	try {
		const req = await axios({
			method: 'get',
			url: url,
			headers: {
				Accept: 'application/json', // , text/plain, */*
				'Content-Type': 'text/plain',
				...headers,
			},
			withCredentials: false,
			...config,
		})

		return await req.data
	} catch (e) {
		console.log('Error', e)
		return e
	}
}

export const post = (
	url: string,
	data?: any,
	headers?: Record<string, unknown>
) => async () => {
	try {
		const req = await axios.post(
			url,
			// {
			// url: url,
			// headers: {
			// 	Accept: 'application/json, text/plain, */*',
			// 	'Content-Type': 'text/plain',
			// 	// ...headers,
			// },
			data,
			{
				withCredentials: true,
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'text/plain',
					// ...headers,
				},
			}
			/* } */
		)

		return await req.data
	} catch (e) {
		if (e instanceof Error) {
			return e
		}
	}
}
