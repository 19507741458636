import React, { useState } from 'react'
import SubMenuDropDown from './SubMenuDropDown'

const SubMenuItemWithDropDown = ({ drop, title, active, config_key, setUserConfig }) => {
  const [open, setOpen] = useState(false)
  const [choosen, setChoosen] = useState<{ key?: string }>({})

  const onToggleOpen = () => setOpen(!open)

  const setInConfig = (item) => {
    setUserConfig(config_key, item.key)
  }

  const setInState = (item) => {
    setChoosen(item)
  }

  const checkWhereDataSaved = () => {
    if (config_key) {
      return {
        handler: setInConfig,
        active,
      }
    } else {
      return {
        handler: setInState,
        active: choosen.key ?? '1',
      }
    }
  }

  const { handler, active: act } = checkWhereDataSaved()

  return (
    <>
      {open && (
        <div
          role="button"
          className="h-popup__overlay"
          style={{ zIndex: 1 }}
          onClick={onToggleOpen}
          onKeyPress={onToggleOpen}
          tabIndex={0}
        />
      )}
      <div
        role="button"
        className={
          open
            ? 'h-submenu__item dropdown h-submenu__dropdown dropdown--active'
            : 'h-submenu__item dropdown h-submenu__dropdown'
        }
        onClick={onToggleOpen}
        onKeyPress={onToggleOpen}
        tabIndex={0}
      >
        <SubMenuDropDown {...{ drop }} {...{ title }} active={act} onChoosen={handler} />
      </div>
    </>
  )
}

export default SubMenuItemWithDropDown
