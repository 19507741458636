import MyAccount from '../../components/forAccontPageComponents/my_account/MyAccount'
import Balance from '../../components/forAccontPageComponents/balance/Balance'
import Offers from '../../components/forAccontPageComponents/offers/Offers'
import Messages from '../../components/forAccontPageComponents/messages/Messages'
import History from '../../components/forAccontPageComponents/history/History'

export const accountRouting = [
  {
    path: '/account/my-account',
    component: MyAccount,
  },
  {
    path: '/account/balance',
    component: Balance,
  },
  {
    path: '/account/offers',
    component: Offers,
  },
  {
    path: '/account/history',
    component: History,
  },
  {
    path: '/mybets',
    component: History,
  },
  {
    path: '/account/messages',
    component: Messages,
  },
]
