import React from 'react'
import MarketsContainer from './MarketsContainer'

const Markets = ({ markets }) => {
  const newMarkets =
    typeof markets !== 'undefined' && markets !== null ? Object.values(markets) : []

  const emptyMarkets = () => {
    return (
      <div
        className="font font--semibold empty-markets"
        style={{
          width: '100%',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          height: '300px',
          paddingTop: '50px',
          fontSize: '17px',
        }}
      >
        No markets available for this event.
      </div>
    )
  }

  if (!newMarkets.length) {
    return emptyMarkets()
  }

  return <MarketsContainer data={newMarkets} />
}

export default React.memo(Markets)
