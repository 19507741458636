import React from 'react'

const ScoreboardPoint = ({ active }) => {
  return (
    <div
      className="m-r-5 ip-scoreboard__cursor"
      style={{
        backgroundColor: active ? '#254' : 'transparent',
      }}
    />
  )
}

export default React.memo(ScoreboardPoint)
