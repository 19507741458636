import React from 'react'
import BetButton from '../../../../../buttons/BetButton'
import BetLockButton from '../../../../../buttons/BetLockButton'
import { useDispatch, useSelector } from 'react-redux'
import { addStake, placeBet, removeStake } from '../../../../../../../redux/actions/betslipActions'
import { transformOddName } from '../../../../../../../helpers/transform/transform'
import { getBetslip } from 'redux/selectors/betslipSelectors'
import { getOddsType, isLoggedIn } from 'redux/selectors/configSelectors'

interface EventBetsProps {
  item: {
    specifiers: any
    outcomes: Array<{
      id: number
      outcome: string
      outcomeId: string
      status: number
      providerTimestamp: string
      odd: number
    }>
  }
  competitors: Array<{
    id: number
    name: string
    externalIds: any
  }>
  eventId: number
  sportId: number
}
const EventBets: React.FC<EventBetsProps> = ({ item, competitors, eventId, sportId }) => {
  const dispatch = useDispatch()
  const LOGGED_IN = useSelector(isLoggedIn)
  const ODDS_TYPE = useSelector(getOddsType)
  const { quickBet, bt } = useSelector(getBetslip)

  const { specifiers, outcomes } = item
  const transformedOutcomes = transformOddName(outcomes, {
    ...specifiers,
    competitor1: competitors[0].name,
    competitor2: competitors[1].name,
  })
  const createBet = (eventId, id, od, ha, check, qb): void => {
    check
      ? dispatch(removeStake(id))
      : qb && LOGGED_IN
        ? dispatch(placeBet({ FI: eventId, ID: id, OD: od, CL: sportId, HA: ha }))
        : dispatch(addStake(eventId, id, od, sportId, ha))
  }

  return (
    <>
      {transformedOutcomes?.map((odd) => {
        return (
          <React.Fragment key={odd.id}>
            {odd.status === 1 ? (
              <BetLockButton />
            ) : (
              <BetButton
                item={odd}
                onCreateBet={createBet.bind(null, eventId)}
                panel={quickBet}
                allStakes={bt}
                inInplay
                oddsType={ODDS_TYPE}
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default EventBets
