import tennis from '../assets/img/scoreboardBackgrounds/tennis.png'
import baseball from '../assets/img/scoreboardBackgrounds/baseball.png'
import handball from '../assets/img/scoreboardBackgrounds/handball.png'
import soccer from '../assets/img/scoreboardBackgrounds/soccer.png'
import badminton from '../assets/img/scoreboardBackgrounds/badminton.png'
import hockey from '../assets/img/scoreboardBackgrounds/hockey.png'
import tableTennis from '../assets/img/scoreboardBackgrounds/tableTennis.png'
import golf from '../assets/img/scoreboardBackgrounds/golf.png'
import cricket from '../assets/img/scoreboardBackgrounds/cricket.png'
import basketball from '../assets/img/scoreboardBackgrounds/basketball.png'

export const scoreboardBackgrounds = new Map([
  [1, soccer],
  [3, cricket],
  [9, golf],
  [5, tennis],
  [3, baseball],
  [4, hockey],
  [2, basketball],
  [6, handball],
  [23, handball],
  [20, tableTennis],
  [31, badminton],
])
