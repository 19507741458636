import React from 'react'
import AnytimeScoreMarkets from './anytimeScoreResult/AnytimeScoreMarkets'
import MatchBetMarkets from './betMarkets/MatchBetMarkets'
import OverUnderMarkets from './overUnder/OverUnderMarkets'
import HandicapMarkets from './handicap/HandicapMarkets'
import DefaultMarketsBody from './defaultMarketsBody/DefaultMarketsBody'
import HandicapThreeWayMarkets from './handicapThreeWay/HandicapThreeWayMarkets'
import DoubleChanceMarkets from './doubleChance/DoubleChanceMarkets'
import body from '../../../../../helpers/bodiesTemplates'
import MatchBetMarketsNoOdds from './betMarkets/MatchBetMarketsNoOdds'
import MultigoalsMarkets from './MultigoalsMarkets/MultigoalsMarkets'
import TotalGoalsMarket from './TotalGoalsMarkets/TotalGoalsMarket'
import TeamGoalsInMatchMarket from './TeamGoalsInMatch/TotalGoalsInMatchMarket'
import MatchGoalsDoubles from './Match&GoalsDoubles/Match&TotalDoubles'
import OverUnderBody from './bodies/OverUnderBody'
import HandicapBody from './bodies/HandicapBody'
import AnytimeBody from './bodies/AnytimeBody'
import ExactBody from './bodies/ExactBody'
import TeamsBody from './bodies/TeamsBody'
import AlternativeBody from './bodies/AlternativeBody'
import RangeBody from './bodies/Range/RangeBody'
import { useDispatch } from 'react-redux'
import { addStake, placeBet, removeStake } from 'redux/actions/betslipActions'

const { anytime, doubleChance, handicap, overUnder } = body

const MarketBody = ({ outcomes, type, groupId, competitors, eventId, sportId }) => {
  const dispatch = useDispatch()
  //   if (groupId === 949) {
  //     return <MatchGoalsDoubles outcomes={outcomes} />
  //   }

  //   if (groupId === 948) {
  //     return <TeamGoalsInMatchMarket outcomes={outcomes} />
  //   }

  //   if (groupId === 946) {
  //     return <TotalGoalsMarket outcomes={outcomes} />
  //   }

  //   if (groupId === 501) {
  //     return <MultigoalsMarkets outcomes={outcomes} />
  //   }
  //   if (groupId === 35) {
  //     return <MatchBetMarkets outcomes={outcomes} competitors={competitors} />
  //   }

  //   if (typeof outcomes.odds === 'undefined' && groupId === 35) {
  //     return <MatchBetMarketsNoOdds outcomes={outcomes} competitors={competitors} />
  //   }

  //   if (anytime.includes(groupId)) {
  //     return <AnytimeScoreMarkets outcomes={outcomes} competitors={competitors} />
  //   }

  //   if (overUnder.includes(groupId)) {
  //     return <OverUnderMarkets outcomes={outcomes} />
  //   }

  //   if (handicap.includes(groupId)) {
  //     return <HandicapMarkets outcomes={outcomes} competitors={competitors} />
  //   }

  //   if (groupId === 14) {
  //     return <HandicapThreeWayMarkets outcomes={outcomes} competitors={competitors} />
  //   }

  //   if (doubleChance.includes(groupId)) {
  //     return <DoubleChanceMarkets outcomes={outcomes} competitors={competitors} id={groupId} />
  //   }

  //   return <DefaultMarketsBody competitors={competitors} outcomes={outcomes} />

  const createBet = (
    eventId: number,
    id: number,
    od: number,
    ha: string,
    check: boolean,
    qb: boolean
  ) => {
    console.log('id', id, 'eventId', eventId)
    check
      ? dispatch(removeStake(id))
      : qb
        ? dispatch(placeBet({ FI: eventId, ID: id, OD: od, CL: sportId, HA: ha }))
        : dispatch(addStake(eventId, id, od, sportId, ha))
  }

  switch (type) {
    case 'overUnder':
      return (
        <OverUnderBody
          outcomes={outcomes}
          eventId={eventId}
          sportId={sportId}
          {...{ createBet }}
        />
      )
    case 'handicap':
      // console.log(`name:`, name)
      return (
        <HandicapBody
          outcomes={outcomes}
          {...{ eventId }}
          {...{ sportId }}
          competitor1={'1'}
          competitor2={'2'}
          {...{ createBet }}
        />
      )
    case 'anytime':
      return (
        <AnytimeBody
          outcomes={outcomes}
          {...{ eventId }}
          {...{ sportId }}
          {...{ createBet }}
        />
      )
    case 'exact':
      return (
        <ExactBody
          outcomes={outcomes}
          {...{ eventId }}
          {...{ sportId }}
          {...{ createBet }}
        />
      )
    case 'teams':
      return (
        <TeamsBody
          outcomes={outcomes}
          {...{ eventId }}
          {...{ sportId }}
          {...{ createBet }}
        />
      )
    case 'alternative':
      return (
        <AlternativeBody
          outcomes={outcomes}
          {...{ eventId }}
          {...{ sportId }}
          {...{ createBet }}
        />
      )
    case 'range':
      return (
        <RangeBody
          outcomes={outcomes}
          {...{ eventId }}
          {...{ sportId }}
          {...{ createBet }}
        />
      )
    default:
      return (
        <DefaultMarketsBody competitors={competitors} outcomes={outcomes} rowClasses={outcomes.length > 4 ? 'col' : ''} />
      )
  }
}
export default React.memo(MarketBody)
