import React from 'react'
import ScoreboardColumn from '../../common/tableComponents/ScoreboardColumn'
import ScoreboardCell from '../../common/tableComponents/ScoreboardCell'
import ScoreboardPoint from '../../common/tableComponents/ScoreboardPoint'
import ScoreboardTeam from '../../common/tableComponents/ScoreboardTeam'
import Text from '../../../../common/elements/Text'

const ScoreboardCompetitorName = ({ competitors, currentServer }) => {
  const current = currentServer?.data === 'HOME' ? 0 : 1

  return (
    <ScoreboardColumn
      style={{
        flex: '10',
      }}
    >
      <ScoreboardCell className="th--left">
        <Text className="font font--11 font--white-alpha-05 text-ellipsis" title="" />
      </ScoreboardCell>
      {competitors?.map(({ id, name }, i) => (
        <ScoreboardCell key={id}>
          <div className="flex align-middle">
            {currentServer ? <ScoreboardPoint active={i === current} /> : <ScoreboardTeam />}
            <Text className="font font--11 font--white-alpha-05" title={name} />
          </div>
        </ScoreboardCell>
      ))}
    </ScoreboardColumn>
  )
}

export default React.memo(ScoreboardCompetitorName)
