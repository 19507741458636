import React from 'react'
import Logo from '../elements/Logo'

const LoaderLogo = () => {
  return (
    <div className="loader-logo h-popup__overlay flex align-center-middle">
      <div className="loader-logo_wrap">
        <Logo />
      </div>
    </div>
  )
}

export default LoaderLogo
