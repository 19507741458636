import React from 'react'
import PrematchScoreboard from '../forLivePageComponents/scoreboard/prematchScoreboard/PrematchScoreboard'
import Markets from '../forLivePageComponents/event/markets/Markets'

const PrematchEventContentCenter = ({ currentEvent }) => {
  return (
    <>
      <PrematchScoreboard event={currentEvent} />
      <Markets markets={currentEvent.odds} />
    </>
  )
}

export default React.memo(PrematchEventContentCenter)
