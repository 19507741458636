import React, { useState } from 'react'
import CompetitionHeader from './competitionHeader/CompetitionHeader'
import CompetitionContent from './competitionContent/CompetitionContent'
import { getSportLeagues } from '../../../helpers/transform/transform'

const CompetitionContainer = ({
  current,
  chosenLeagues,
  currentOddName,
  allOddsName,
  currentOddId,
  allOddsId,
}) => {
  const { categories } = current
  const [liveStreaming, setLiveStreaming] = useState<boolean>(true)
  const setLStreaming = (state: boolean) => {
    setLiveStreaming(state)
  }

  const oddsLength = allOddsId?.length >= 2
  const changeMarket = allOddsId?.length > 3

  const {
    chosenLeagues: data,
    leaguesLength,
    allLeagues,
  } = getSportLeagues(categories, chosenLeagues)

  return (
    <div className="ip-competitions-container">
      <CompetitionHeader
        data={current}
        {...{ liveStreaming }}
        {...{ setLStreaming }}
        leaguesLength={leaguesLength}
        allLeagues={allLeagues}
        changeMarket={changeMarket}
        oddsLength={oddsLength}
        chosenLeagues={chosenLeagues}
        allOddsName={allOddsName}
        currentOddName={currentOddName}
        allOddsId={allOddsId}
        currentOddId={currentOddId}
      />
      <CompetitionContent
        data={data}
        {...{ liveStreaming }}
        oddName={currentOddName}
        oddId={currentOddId}
        changeMarket={changeMarket}
      />
    </div>
  )
}

export default React.memo(CompetitionContainer)
