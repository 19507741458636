import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isLoggedIn } from 'redux/selectors/configSelectors'
import { ConfigContext } from '../../../../../context/globalConfig'

const links = [
  {
    title: 'COMMON.ASIDE_RIGHT.BET_SLIP.BET_SLIP',
    id: '1',
  },
  {
    title: 'COMMON.ASIDE_RIGHT.MY_BETS',
    id: '0',
  },
]

const BetslipHeader = ({ click, content }) => {
  const { t } = useTranslation()
  const LOGGED_IN = useSelector(isLoggedIn)
  const [cur_links, setCur_links] = useState(links)

  useEffect(() => {
    if (!LOGGED_IN) {
      setCur_links(links.slice(0, 1))
    } else {
      setCur_links(links)
    }
  }, [LOGGED_IN])

  return (
    <div className="betslip__header">
      {cur_links?.map((item) => (
        <a
          role="button"
          key={item.id}
          onClick={() => click('showBetslip', item.id)}
          onKeyPress={() => 0}
          tabIndex={0}
          className={
            content === item.id ? 'font betslip__link betslip__link--active' : 'font betslip__link'
          }
        >
          {t(item.title)}
        </a>
      ))}
    </div>
  )
}

export default BetslipHeader
