import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import Overlay from 'components/registration/overlay'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { NavLink } from 'react-router-dom'
import { isLoggedIn } from 'redux/selectors/configSelectors'
import { useSelector } from 'react-redux'
import { Obj } from 'helpers/commonInterfaces/interfaces'
import { post } from 'helpers/asyncRequests'
import { links } from 'connection/links'
import VerificationInputs from './VerificationInputs'
import TextButton from 'components/registration/elements/TextButton'
import { RegisterContext } from 'context/registrationContext'

export const EnterCode = () => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const login = useSelector(isLoggedIn)

  const { showEnterCode, enterCode } = useContext(RegisterContext)

  const [timer, setTimer] = useState('00:60')
  const [tries, setTries] = useState(3)
  const [errorMessage, setError] = useState('')
  const [timedout, setTimedout] = useState(false)

  const [passcode, setPasscode] = useState<Obj<string>>({})

  useEffect(() => {
    console.log(`enter code`, enterCode)
  }, [enterCode])

  // closing on overlay click
  const hideLogin = (e) => {
    if (e.target.className === 'overlay overlay--main overlay--block') {
      if (showEnterCode) {
        console.log(`Hide enter code!!!`)
        showEnterCode()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', hideLogin)
    return () => document.removeEventListener('click', hideLogin)
  }, [])

  useEffect(() => {
    console.log(login)
    if (login) {
      history.push('/sports/main')
    }
  }, [])

  const disabled = () => {
    const values = Object.values(passcode)

    if (!values.length || values.length !== 4) {
      return true
    }

    return !values.every((letter) => !!letter)
  }

  const onSet = async () => {
    if (tries > 0) {
      if (location.pathname.includes('register')) {
        const res = await post(
          links.regConfirm,
          JSON.stringify({
            Code: Number(
              Object.values(passcode).reduce(
                (acc, curr) => (acc += curr.toString())
              )
            ),
          })
        )()
        if (res.CREATE.SUCCESS) {
          window.location.href = '/'
        } else {
          if (tries === 1) {

          }
          setError(res.CREATE.INFO)
        }
      } else {
        const res = await post(
          links.resetPasswordConfirm,
          JSON.stringify({
            Code: Number(
              Object.values(passcode).reduce(
                (acc, curr) => (acc += curr.toString())
              )
            ),
          })
        )()
        if (res.RESET.SUCCESS) {
          history.push('/auth/new-password')
        } else {
          if (tries === 1) {

          }
          setError(res.RESET.INFO)
        }
      }
      setPasscode({})
      setTries(tries - 1)
    }
  }

  useEffect(() => {
    if (timer.split(':')[1] !== '00') {
      setTimeout(() => {
        const seconds = Number(timer.split(':')[1]) - 1
        const secondsStr =
          seconds.toString().length === 1 ? '0' + seconds : seconds.toString()
        setTimer('00:' + secondsStr)
      }, 1000)
    } else {
      if (!location.pathname.includes('enter-code')) {
        return
      }
      setTimedout(true)
      // history.push('/auth/sent')
    }
  }, [timer])

  return (
    <Overlay>
      <div className="mn-popup mn-popup--active">
        <form className="flex flex-column auth__form">
          <div className="q-popup__title">
            <div className="text-center">
              <p className="font font--20 font--white">
                {t('ACCOUNT.CODE', 'Code')}
              </p>
              <CSSTransition
                in={errorMessage !== ''}
                classNames="possible_slide"
                timeout={300}>
                <p className="font font--13 font--red m-t-15">{errorMessage}</p>
              </CSSTransition>
              <CSSTransition in={errorMessage === ''} classNames="possible_slide" timeout={300}>
                <p
                  className={
                    'font font--13 m-t-15 h0 very-green' +
                    (tries > 0 ? ' font--green' : ' font--red') +
                    (tries === 3 ? ' font--green' : '')
                  }>
                  {tries} {t('ACCOUNT.ATT_LEFT', 'attempts left')}
                </p>
              </CSSTransition>
              {tries === 0 ? (
                <>
                  <p className="font font--13 font--white-alpha-05 m-t-20">
                    {t('ACCOUNT.OUT_OF_TRIES', 'Out of tries,')}
                    <NavLink
                      to={
                        '/auth/code-sent' +
                        (location.pathname.includes('register') ? '/register' : '')
                      }
                      className="font--blue font">
                      {' '}
                      {t('ACCOUNT.SEND_AGAIN', 'send again.')}
                    </NavLink>
                  </p>
                </>
              ) : timedout ? (
                <>
                  <p className="font font--13 font--white-alpha-05 m-t-20">
                    {t(
                      'ACCOUNT.ENTER_CODE_SENT',
                      'Enter the code that we sent'
                    )}
                  </p>
                  <p className="font font--13 font--white-alpha-05">
                    {t('ACCOUNT.TIME_OUT', 'Time out,')}
                    <NavLink
                      to={
                        '/auth/code-sent' +
                        (location.pathname.includes('register') ? '/register' : '')
                      }
                      className="font--blue font">
                      {' '}
                      send again.
                    </NavLink>
                  </p>
                </>
              ) : (
                <p className="font font--13 font--white-alpha-05 m-t-20">
                  {t(
                    'ACCOUNT.ENTER_CODE_SENT',
                    'Enter the code that we sent'
                  )}
                  <br />
                  {t('ACCOUNT.RESEND', 'Resend via ')} {timer}{' '}
                  {t('ACCOUNT.SEC', 'sec')}
                </p>
              )}
            </div>
          </div>
          <div
            className="q-popup__body"
            style={{
              flex: '0',
            }}>
            <div
              className="q-popup__wrap">
              <VerificationInputs passcode={passcode} setPasscode={setPasscode} />
            </div>
            <div
              className="q-popup__wrap">
              {/* <div className="q-popup__fingerprint">
                      <p className="q-popup__text">Use fingerprint</p>
                      <label className="q-popup__toggle">
                        <input
                          className="q-popup__toggle-input"
                          type="checkbox"
                          id="fingerprint"
                        />
                        <span className="q-popup__toggle-swipe" />
                        <span className="q-popup__toggle-bg" />
                      </label>
                    </div> */}
            </div>
            <div
              className="q-popup__wrap">
              <div className="flex align-middle">
                <TextButton
                  classes={
                    'btn btn--blue btn--h-44 flex-grow m-r-10 next-btn' +
                    (tries === 0 ? ' btn--disabled' : '')
                  }
                  innerClasses="font font--white"
                  text={
                    location.pathname.includes('register')
                      ? t('ACCOUNT.CONFIRM', 'Confirm')
                      : t('ACCOUNT.NEXT', 'Next')
                  }
                  disabled={disabled()}
                  onclick={onSet}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Overlay>
  )
}