import React from 'react'
import Table from '../table/Table'
import TableRow from '../table/TableRow'
import EmptyEvent from '../event/EmptyEvent'
import EventWrapper from '../event/EventWrapper'
import { useHistory } from 'react-router-dom'
import { IEvent, IMatch, ITournament } from 'helpers/commonInterfaces/interfaces'

interface ILeagueEvents {
  events: Array<IEvent>
  liveStreaming: boolean
  leagueBanners: Array<{ bannerID: number; position: number }>
  oddName: string
  oddId: number
  changeMarket: any
  matchCount: number
  tournamentCount: number
}

const LeagueEvents: React.FC<ILeagueEvents> = ({
  events,
  liveStreaming,
  leagueBanners,
  oddName,
  oddId,
  changeMarket,
  matchCount,
  tournamentCount,
}) => {
  const { push } = useHistory()


  const toEvent = (sportId, leagueId, eventId) => {
    push(`/live/event/${sportId}/${leagueId}/${eventId}`)
  }



  if (!events || events.length === 0) {
    return <EmptyEvent matchCount={matchCount} tournamentCount={tournamentCount} />
  }

  return (
    <>
      {events.map((event) => (
        <React.Fragment key={event.id}>
          <Table>
            <TableRow className="tr tr--body">
              <EventWrapper
                event={event}
                oddName={oddName}
                oddId={oddId}
                changeMarket={changeMarket}
                toEvent={toEvent.bind(null, event.sportId, event.leagueId, event.id)}
              />
            </TableRow>
          </Table>
        </React.Fragment>
      ))}
    </>
  )
}

export default LeagueEvents
