import { IState } from '../reducers/configReducer'
import { eventChannel } from 'redux-saga'
import { Connection } from '../../connection/Connection'
import { linkTransform } from '../../helpers/transform/linkTransform'

export const createRequest = (
  path: string,
  data: object,
  topic: string,
  listeners: Array<(emitter, res) => void>,
  loop: number,
  config: IState,
  isPrivate = false
) =>
  eventChannel((emitter) => {
    let connection
    const tryConnect = () => {
      if (config?.SITE_CONFIG?.CONNECTION_DETAILS) {
        connection = new Connection(null)
        console.log(`listeners:`, listeners)
        connection.send({
          cmd: 'Request',
          path: linkTransform(path, config),
          data: {
            ...data,
            token: config?.CUSTOMER_CONFIG?.TOKEN,
          },
          topic,
          listeners: listeners.map((listener) => (response) => {
            response.forEach((res) => {
              console.log(`res`, res)
              listener(emitter, res)
            })
          }),
          loop,
          isPrivate,
        })
      } else {
        setTimeout(tryConnect, 50)
      }
    }
    tryConnect()
    return () => null
  })

export const createRequests = (
  paths: string[],
  data: object,
  topics: string[],
  listeners: Array<(emitter, res) => void>,
  loop: number,
  config: IState,
  isPrivate = false
) =>
  eventChannel((emitter) => {
    let connection
    const responses: any[] = []

    const tryConnect = () => {
      if (config?.SITE_CONFIG?.CONNECTION_DETAILS) {
        connection = new Connection(null)
        let i = 0
        for (const topic of topics) {
          connection.send({
            cmd: 'Request',
            path: linkTransform(paths[i], config),
            data: {
              ...data,
              token: config?.CUSTOMER_CONFIG?.TOKEN,
            },
            topic,
            listeners: [
              (response) => {
                console.log(`response`, response)
                responses.push(response)
              },
            ],
            loop,
            isPrivate,
          })
          i++
        }
      } else {
        setTimeout(tryConnect, 150)
      }
    }

    const tryReturn = () => {
      if (responses.length === topics.length) {
        console.log(``)
        console.log(
          `responses:`,
          responses.map((res) => res[0])
        )
        listeners[0](
          emitter,
          responses.map((response) => response[0])
        )
      } else {
        setTimeout(tryReturn, 100)
      }
    }
    tryConnect()
    tryReturn()
    return () => null
  })
