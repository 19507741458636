import React, { useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { links } from '../../../../../connection/links'
import { getEvent } from 'redux/selectors/eventSelectors'
import { getBetradarId } from 'helpers/utils/utils'
import { getCurrentActive, getLeaguesEvents, getTrackerAndTranslation } from 'redux/selectors/inplaySelectors'
import { getAnimation } from 'redux/selectors/configSelectors'

const LvTranslationLive = () => {
  const event = useSelector(getEvent)
  const ref = useRef<HTMLDivElement>(null)
  const { tracker, pinnedMatch } = useSelector(getTrackerAndTranslation)
  const leaguesEvents = useSelector(getLeaguesEvents)
  const [id, setId] = useState<false | string>(false)
  const animLink = useSelector(getAnimation)
  const curActive = useSelector(getCurrentActive)

  const setIframeHeight = (event: MessageEvent): void => {
    if (ref.current !== null && event) {
      const iframe = ref.current.querySelector('iframe')
      const container = document.querySelector<HTMLElement>('.lv-translation__live .flex div')
      if (iframe && container) {
        container.style.height = `${event.data.height}px`
        iframe.style.height = `${event.data.height}px`
        iframe.style.opacity = '1'
      }
    }
  }

  useLayoutEffect(() => {
    if (pinnedMatch) {
      return
    }
    if (tracker) {
      for (const events of Object.values(leaguesEvents)) {
        for (const ev of events) {
          if (ev && 'enrichment' in ev) {
            if (ev?.enrichment?.animation?.includes('betradarTracker') && ev.id === tracker) {
              const betRadar = ev!.externalIds.betradar
              if (betRadar && ev.sportId === curActive) {
                setId(getBetradarId(betRadar))
              } else {
                setId(false)
              }
              break
            }
          }
        }
      }
      return
    }
    if (event) {
      if (event && 'enrichment' in event) {
        if (event?.enrichment?.animation?.includes('betradarTracker')) {
          const betRadar = event!.externalIds.betradar

          if (betRadar) {
            setId(getBetradarId(betRadar))
          }
        }
      }
    } else {
      if (curActive) {
        // select the default event
        let found = false
        for (const events of Object.values(leaguesEvents)) {
          for (const ev of events) {
            if (ev && 'enrichment' in ev && ev.sportId === curActive) {
              if (ev?.enrichment?.animation?.includes('betradarTracker')) {
                const betRadar = ev!.externalIds.betradar
                if (betRadar) {
                  found = true
                  setId(getBetradarId(betRadar))
                  break
                }
              }
            }
          }
        }
        if (!found) {
          setId(false)
        }
      } else {
        setId(false)
      }
    }
  }, [event, curActive, leaguesEvents, tracker])

  useLayoutEffect(() => {
    if (ref.current) {
      const iframe = ref.current.querySelector('iframe')
      if (iframe !== null) {
        iframe.onload = () => {
          iframe.contentWindow?.postMessage('message', '*')
          window.addEventListener('message', setIframeHeight)
          console.log('.lv-translation__live .flex', document.querySelector('.lv-translation__live .flex'))
        }
      }
    } else {
      const container = document.querySelector<HTMLElement>('.lv-translation__live .flex div')
      if (container) {
        container.style.height = `325px`
      }
    }
  }, [tracker, id, ref])

  return (
    <div className="lv-translation__live relative">
      <div className="flex align-center-middle">
        {id ? (
          <div ref={ref} className="full-width">
            <iframe
              title="Live Translation"
              src={animLink.replace('/ru/', '/en/') + id}
              width="100%"
              height="100%"
            />
          </div>
        ) : (
          <div className="lv-translation__placeholder">
            <p className="font font--13 font--grey-300">Animation is not available</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(LvTranslationLive)
