import HistoryRedirection from 'components/Routing/accountRedirection/historyRedirection'
import BetHistory from '../../components/forAccontPageComponents/history/betHistory/BetHistory'
import CasinoGames from '../../components/forAccontPageComponents/history/CasinoGames'
import LiveCasinoGames from '../../components/forAccontPageComponents/history/LiveCasinoGames'

export const historyRouting = [
  {
    path: '/account/history/bet-history',
    exact: true,
    component: BetHistory,
  },
  {
    path: '/mybets',
    exact: true,
    component: BetHistory,
  },
  {
    path: '/account/history/casino-games',
    exact: true,
    component: CasinoGames,
  },
  {
    path: '/account/history/league-casino',
    exact: true,
    component: LiveCasinoGames,
  },
  {
    component: HistoryRedirection,
  },
]
