import React from 'react'
import Item from './Item'

const Items = ({ stakes, placedSuccess, change, blur, focus, removeBetHandler }) => {
	return (
		<div className="betslip__items">
			<>
				{stakes?.map(item => {
					let value = 0
					if (typeof item.stake !== 'undefined') {
						value = item.stake
					}
					let className = 'betslip__item'
					const restricted = stakes.some(
						(bet) =>
						bet.pt?.[0].pi !== item.pt?.[0].pi && item.fi === bet.fi
					)
					if (item.mr || restricted) {
						className += ' betslip__item--active'
					}
					if (item.su) {
						className += ' betslip__item--disabled'
					}
					return (
						<Item
							key={item.pt[0].pi.toString()}
							inputid={item.pt[0].pi.toString()}
							className={className}
							item={item}
							{...{	placedSuccess }}
							{...{ removeBetHandler }}
							{...{ value }}
							change={change}
							blur={blur}
							focus={focus}
						/>
					)
				})}
			</>
		</div>
	)
}

export default Items
