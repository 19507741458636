import React from 'react'

const Upload = ({ getRootProps, getInputProps, isDragActive }) => {

  return (
    <div className="cb-upload__body">
      <div className="cb-upload__drop" draggable="true" {...getRootProps()}>
        <img src="img/account-verification/drop.svg" alt="" />
        <p>
          {
            isDragActive ?
              <span className="font">Drop here... </span>
              : <span className="font">Drop yor image here, or </span>
          }
          <label className="font font--blue" htmlFor="file">browse</label>
          <input
            className="cb-upload__upload"
            type="file"
            name="file"
            id="file"
            {...getInputProps()} />
        </p>
        <p className="font font--10 font--grey-300">Valid file formats: jpg, jpeg, gif, no
          more than 5 MB in size.</p>
      </div>
    </div>

  )
}

export default Upload