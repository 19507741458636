import React from 'react'
import Tab from './Tab'

interface TabsType {
  activeTab: string
  handler: (id: number) => void
  tabs: any[]
  defaultCl: string
  activeCl: string
  className?: string
}

const Tabs: React.FC<TabsType> = ({
  activeTab,
  handler,
  tabs = [],
  className = '',
  defaultCl,
  activeCl,
}) => {
  return (
    <div className={className}>
      {tabs?.map((item) => (
        <Tab
          key={item.id}
          {...{ defaultCl }}
          {...{ activeCl }}
          {...{ item }}
          {...{ handler }}
          active={activeTab === item.id}
        />
      ))}
    </div>
  )
}

export default Tabs
