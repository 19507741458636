import React from 'react'
import PopularItem from './PopularItem'

const Popular = () => {
	return (
		<div className="wn-popular m-b-10">
			<div className="wn-popular__title">
				<p className="font font--11 font--white">Popular</p>
			</div>
			<PopularItem />
			<PopularItem />
			<PopularItem />
		</div>
	)
}

export default Popular
