import { types } from '../types/types'

export interface ISearch {
  JSON: any[]
}

const initialState = {
  JSON: [],
}

export const search = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_DATA:
      return {
        ...state,
        JSON: action.payload,
      }
    default:
      return state
  }
}
