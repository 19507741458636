import React, { useEffect } from 'react'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getConfig, getCurrency, getOddsType } from 'redux/selectors/configSelectors'
import { floatToCurrency } from '../../../../../../../../helpers/floatToCurrency'
import { modifyBets } from '../../../../../../../../helpers/modifyBets'

export const BAcceptFooter = ({ multistakes, betTotal, possibleWin }) => {
  const ODDS_TYPE = useSelector(getOddsType)
  const { CUSTOMER_CONFIG } = useSelector(getConfig)
  const currencyConfig = useSelector(getCurrency)
  const toReturn = useCallback(() => {
    return multistakes?.dm?.st
      ? (parseFloat(multistakes.dm.st) * parseFloat(modifyBets(multistakes.dm.od, '1'))).toFixed(3)
      : '.2323'
  }, [multistakes])

  useEffect(() => {
    console.log('')
    console.log('betTotal: ', betTotal)
    console.log('possibleWin: ', possibleWin)
  }, [betTotal, possibleWin])

  return (
    <div className="b-accept__footer">
      {multistakes.dm?.st ? (
        <div className="b-accept__info">
          <div className="b-accept__cell">
            <p className="b-accept__text">{multistakes.dm.bd}</p>
            <p className="b-accept__odds">{modifyBets(multistakes.dm.od, ODDS_TYPE)}</p>
          </div>
          <div className="b-accept__cell">
            <p className="b-accept__text">Stake</p>
            <p
              className="b-accept__money"
              dangerouslySetInnerHTML={{
                __html: floatToCurrency(
                  multistakes.dm.st,
                  CUSTOMER_CONFIG.currencyConfig,
                  true,
                  true
                ),
              }}
            ></p>
          </div>
          <div className="b-accept__cell">
            <p className="b-accept__text">To return</p>
            <p
              className="b-accept__money b-accept__money--green"
              dangerouslySetInnerHTML={{
                __html: floatToCurrency(toReturn(), currencyConfig, true, true),
              }}
            ></p>
          </div>
        </div>
      ) : null}
      <div className="b-accept__info">
        <div className="b-accept__cell">
          <p className="b-accept__text">Total Stake</p>
          <p
            className="b-accept__money"
            dangerouslySetInnerHTML={{
              __html: floatToCurrency(betTotal ?? 0, currencyConfig, true, true),
            }}
          ></p>
        </div>
        <div className="b-accept__cell">
          <p className="b-accept__text">To return</p>
          <p
            className="b-accept__money b-accept__money--green"
            dangerouslySetInnerHTML={{
              __html: floatToCurrency(possibleWin, currencyConfig, true, true),
            }}
          ></p>
        </div>
      </div>
    </div>
  )
}
