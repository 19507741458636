import React from 'react'
import FixtureScore from './FixtureScore'
import { transformScoreNew } from '../../../../helpers/transform/transform'
import FixtureTeams from './FixtureTeams'

const FixtureWrapper = ({ event }) => {
  const { competitors, additionalData, name } = event

  const score = transformScoreNew(additionalData?.scoreboard?.data, false, event.sportId)

  return (
    <div className="ipn-fixture__header">
      <div className="flex align-middle align-justify full-height">
        {competitors ? (
          <>
            <FixtureTeams competitors={competitors} />
            <FixtureScore score={score} />
          </>
        ) : (
          <FixtureTeams name={name} />
        )}
      </div>
    </div>
  )
}

export default React.memo(FixtureWrapper)
