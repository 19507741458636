import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import MarketColumn from '../MarketColumn'
import ColumnTitle from '../ColumnTitle'
import MarketRow from '../MarketRow'
import BetButton from 'components/common/buttons/BetButton'
import { OddOutcome } from '../../../../../../helpers/commonInterfaces/interfaces'
import { getHandicapValue } from '../../../../../../helpers/transform/eventTransform'
import { getOddsType } from '../../../../../../redux/selectors/configSelectors'
import { getBetslip } from '../../../../../../redux/selectors/betslipSelectors'

type IHandicapColumn = {
  title: string
}
type IHandicapBody = {
  outcomes: Array<OddOutcome>
  competitor1: string
  competitor2: string
  eventId: number
  sportId: number
  createBet: (
    eventId: number,
    id: number,
    od: number,
    ha: string,
    check: boolean,
    qb: boolean
  ) => void
}

const HandicapColumn: React.FC<IHandicapColumn> = React.memo(
  function HandicapColumn({ title, children }) {
    return (
      <MarketColumn>
        <ColumnTitle title={title} />
        {children}
      </MarketColumn>
    )
  }
)

const HandicapBody: React.FC<IHandicapBody> = ({
  outcomes,
  competitor1,
  competitor2,
  eventId,
  sportId,
  createBet,
}) => {
  const { quickBet, bt } = useSelector(getBetslip)
  const ODDS_TYPE = useSelector(getOddsType)
  const competitor1Column: ReactElement[] = []
  const competitor2Column: ReactElement[] = []
  const drawColumn: ReactElement[] = []
  // console.log(``)
  // console.log(`outcomes of handicap:`)
  // console.log(outcomes)

  sortOutcomes(outcomes).forEach(item => {
    const cloneOutcome = { ...item }
    if (
      cloneOutcome.outcome.replace(/\s/g, '').charAt(0) == '1' ||
      cloneOutcome.outcome.includes('competitor1')
    ) {
      cloneOutcome.outcome = getHandicapValue(cloneOutcome.outcome)
      competitor1Column.push(
        <div
          key={cloneOutcome.outcomeId + cloneOutcome.specifiers?.hcp}
          className="gll-market-group__cell">
          <BetButton
            item={cloneOutcome}
            key={cloneOutcome.id + cloneOutcome.outcome}
            onCreateBet={createBet.bind(null, eventId)}
            panel={quickBet}
            allStakes={bt}
            oddsType={ODDS_TYPE}
          />
        </div>
      )
      return
    }

    if (
      cloneOutcome.outcome.replace(/\s/g, '').charAt(0) == '2' ||
      cloneOutcome.outcome.includes('competitor2')
    ) {
      cloneOutcome.outcome = getHandicapValue(cloneOutcome.outcome)
      competitor2Column.push(
        <div
          key={cloneOutcome.outcomeId + cloneOutcome.specifiers?.hcp}
          className="gll-market-group__cell">
          <BetButton
            item={cloneOutcome}
            key={cloneOutcome.id + cloneOutcome.outcomeId}
            onCreateBet={createBet.bind(null, eventId)}
            panel={quickBet}
            allStakes={bt}
            oddsType={ODDS_TYPE}
          />
        </div>
      )
      return
    }

    if (
      cloneOutcome.outcome.includes('draw') ||
      cloneOutcome.outcome.includes('Draw')
    ) {
      console.log(`cloned outcome`, cloneOutcome.outcome)
      drawColumn.push(
        <div
          key={cloneOutcome.outcomeId + cloneOutcome.specifiers?.hcp}
          className="gll-market-group__cell">
          <BetButton
            item={cloneOutcome}
            key={cloneOutcome.id + cloneOutcome.outcomeId}
            onCreateBet={createBet.bind(null, eventId)}
            panel={quickBet}
            allStakes={bt}
            oddsType={ODDS_TYPE}
          />
        </div>
      )
    }
  })

  return (
    <div className="gll-market-group__body">
      <MarketRow isMulti={false}>
        <HandicapColumn title={competitor1}>{competitor1Column}</HandicapColumn>
        {drawColumn.length > 0 && (
          <HandicapColumn title="X">{drawColumn}</HandicapColumn>
        )}
        <HandicapColumn title={competitor2}>{competitor2Column}</HandicapColumn>
      </MarketRow>
    </div>
  )
}

export default React.memo(HandicapBody)

function sortOutcomes(arr: Array<OddOutcome>): Array<OddOutcome> {
  return arr.sort((a, b) => {
    if (a.outcome.includes('+')) {
      if (b.outcome.includes('-')) {
        return 1
      } else {
        if (Number(b.outcome.split('+')[1]) > Number(a.outcome.split('+')[1])) {
          return -1
        } else {
          return 1
        }
      }
    } else {
      if (b.outcome.includes('-')) {
        if (Number(b.outcome.split('-')[1]) > Number(a.outcome.split('-')[1])) {
          return 1
        } else {
          return -1
        }
      } else {
        return -1
      }
    }
  })
}