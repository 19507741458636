import React from 'react'
import { Link } from 'react-router-dom'

const ToLive = () => {
  return (
    <Link className="flex align-middle wn-classification-sport__header to_live" to="/live/overview">
      <p className="font font--white text text-ellipsis">Live</p>
      <p className="font font--white count" />
    </Link>
  )
}

export default ToLive
