import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { get } from '../../../../helpers/asyncRequests'
import { getFirstLeagueId } from '../../../../helpers/utils/utils'
import { transformMarkets } from '../../../../helpers/transform/eventTransform'
import { getFirstEventId } from '../../../selectors/eventSelectors'
import { getInplayTree } from '../../../selectors/inplaySelectors'
import { types } from '../../../types/types'
import { links } from '../../../../connection/links'
import { EventWatchersTypes, eventWorkers } from '../../../actions/eventActions'
import { IMatch, ITournament, Nullable } from '../../../../helpers/commonInterfaces/interfaces'
import { createSubscriptionChannel, unsubscribe } from '../../subscriptionHandler'
import { store } from '../../../store'
import _ from 'lodash'
import { emitterByCmd } from '../../../utils/emitter'

export type GetEventType = {
  ids: {
    id: string | undefined
    leagueId: string | undefined
  }
  type: EventWatchersTypes
}

function* enableEventShortPull(id: number | string) {
  const config = yield select((state) => state.config)
  const channel = yield call(
    createSubscriptionChannel,
    '/feedapi/?key=event&eventId=' + id,
    { eventId: id },
    'event-' + id,
    [
      (emitter, rs) => {
        const oldState = _.cloneDeep(store.getState().event)
        emitterByCmd(oldState, rs, emitter)
      },
    ],
    3500,
    config
  )

  yield fork(function* () {
    yield take(`REMOVE_EVENT_BY_ID_${id}`)
    unsubscribe('/feedapi/?key=event', 'event-' + id, 3500, config)
    yield removeEvent()
  })

  try {
    while (true) {
      const { event } = yield take(channel)
      yield setEventData(event)
    }
  } catch (e) {
    channel.close()
  }
}

function* loadEventDataCaseOne() {
  const data = yield select(getInplayTree)
  const events = yield getEvents(getFirstLeagueId(data))
  const eventId = yield getFirstEventId(events)

  yield enableEventShortPull(eventId)
}

function* getEvent({ ids }: GetEventType) {
  const { leagueId, id } = ids

  try {
    !id || !leagueId ? yield loadEventDataCaseOne() : yield enableEventShortPull(id)
  } catch (e) {
    console.log(e)
  }
}

function* getEvents(id: number | string) {
  try {
    const { events } = yield call(get(links.league + id))
    return events
  } catch (e) {
    console.log(e)
  }
}

function* setMarkets(event: Nullable<IMatch | ITournament>) {
  try {
    if (event?.eventType === 'Match') {
      const data = transformMarkets(event.odds || [], event.competitors || null, 'live')
      yield put(eventWorkers.setMarkets(data))
    }
  } catch (e) {
    console.log(e)
  }
}

function* setEventData(event: Nullable<IMatch | ITournament>) {
  yield put(eventWorkers.setEvent(event))
  yield setMarkets(event)
}

function* removeEvent() {
  yield put(eventWorkers.clearEventData())
}

function* setLeagueEvents({ id }: { id: number; type: string }) {
  const events = yield getEvents(id)
  yield put(eventWorkers.setEvents(events))
}

function* setLeaguesEvents() {
  const state = yield select((state) => state)
  const sport = state.inplay.data.find((sport) => sport.id === state.event.event.sportId)

  const events: any[] = []
  for (const category of sport.categories) {
    for (const league of category.leagues) {
      events.push(yield getEvents(league.id))
      console.log(events[events.length - 1])
      events[events.length - 1][0].leagueName = league.name
    }
  }

  yield put(eventWorkers.setEvents(events))
}

export const eventSagas = [
  takeEvery(types.SET_EVENT_WATCHER, getEvent),
  takeEvery(types.REMOVE_EVENT, removeEvent),
  takeEvery(types.SET_LEAGUE_EVENTS_WATCHER, setLeagueEvents),
  takeEvery(types.SET_LEAGUES_EVENTS_WATCHER, setLeaguesEvents),
]
