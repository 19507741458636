import React, { useContext } from 'react'
import { RegisterContext } from '../../../../../context/registrationContext'

const NotLogon = () => {
  const { showForm } = useContext(RegisterContext)

  const goToLogin = () => {
    if (showForm) {
      showForm()
    }
  }

  return (
    <div className="wrap--authorize">
      <div
        role="button"
        className="not-logon--icon"
        onClick={goToLogin}
        onKeyPress={() => 0}
        tabIndex={0}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            d="M13.696 5.714l6.392 10.226A2 2 0 0118.392 19H5.608a2 2 0 01-1.696-3.06l6.392-10.226a2 2 0 013.392 0zM11 9v5h2V9h-2zm1 8a1 1 0 100-2 1 1 0 000 2z"
          ></path>
        </svg>
      </div>
      <div role="button" className="text" onClick={goToLogin} onKeyPress={() => 0} tabIndex={0}>
        Please Authorize
      </div>
    </div>
  )
}

export default NotLogon
