import React from 'react'
import { contextWorkers } from 'redux/actions/contextActions'

const InputCheckbox = ({ name, value, change }) => {
	const t = contextWorkers.t

	return (
		<div className="flex">
			<input
				className="checkbox checkbox--blue"
				type="checkbox"
				id="years"
				name={name}
				value={value}
				onChange={change}
			/>
			<label className="font font--12 m-l-5" htmlFor="years" style={{color: '#fff'}}>
				{t('I am 18 years old. I agree to the')}
				<a className="font font--blue m-l-5 m-r-5">{t('terms')}</a>
				{t('and')}
				<a className="font font--blue m-l-5 m-r-5">{t('conditions')}</a>
				{t('of the site')}
			</label>
		</div>
	)
}

export default InputCheckbox
