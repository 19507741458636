import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import FlipMove from 'react-flip-move'
import Sport from './sport/Sport'
import ToLive from './sport/ToLive'
import { useSelector } from 'react-redux'
import { getAllSports } from 'redux/selectors/prematchSelectors'
import { sortSportsData } from 'helpers/utils/utils'
import { debounce } from 'helpers/debounce'
import { getFavorites } from 'redux/selectors/favoritesSelectors'

export type lastOpenedT = {
  sport: number
  category: number
  league: number
}

const ClassificationBody = ({ isAll }) => {
  const favorites = useSelector(getFavorites)

  const phRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<number>(0)

  const [lastOpened, setLastOpened] = useState<lastOpenedT>({
    sport: 0,
    category: 0,
    league: 0,
  })

  const sortOrNot = (a, b) => {
    if (favorites.sports.some(sport => sport === a.id.toString())) {
      if (favorites.sports.some(sport => sport === b.id.toString())) {
        return 1
      } else {
        return -1
      }
    } else {
      return 1
    }
  }

  const sports = useSelector(getAllSports)
  const [sorted, sort] = useState(sortSportsData(sports ?? []).sort(sortOrNot))

  useLayoutEffect(() => {
    if (!phRef.current || !ref.current) {
      return
    }
    phRef.current.style.height = `${ref.current.getBoundingClientRect().height
      }px`
  }, [sorted])

  useEffect(
    () =>
      document.body.addEventListener(
        'scroll',
        debounce(() => {
          scrollRef.current = document.body.scrollTop
          if (phRef.current) {
            phRef.current.scrollTop = document.body.scrollTop
          }
        }, 200)
      ),
    []
  )

  useEffect(() => {
    sort(sortSportsData(sports ?? []).sort(sortOrNot))
  }, [sports])

  useEffect(() => {
    const lastOpened =
      sessionStorage.getItem('lastOpened') ??
      JSON.stringify({ sport: 0, category: 0, league: 0 })
    console.log(`last opened : `, JSON.parse(lastOpened))
    if (window.location.href.includes("sports") && !window.location.href.includes("main")) {
      setLastOpened(JSON.parse(lastOpened))
    }
  }, [])

  const openedHandler = (key: keyof typeof lastOpened, value: number) => {
    const state = {
      ...lastOpened,
      [key]: value,
    }
    setLastOpened(state)
    sessionStorage.setItem('lastOpened', JSON.stringify(state))
  }

  return (
    <div className="wn-classification__body">
      <ToLive />
      <div style={{ position: 'relative' }}>
        <div ref={phRef} className="flipmove-placeholder"></div>
        <div ref={ref}>
          <FlipMove>
            {isAll === '1' ?
              sorted.map((sport) => {
                return <Sport
                  key={sport.id}
                  sport={sport}
                  {...{ lastOpened }}
                  {...{ openedHandler }} />
              })
              :
              sorted.filter(sport => favorites.sports.some(fav => fav === sport.id.toString()))
                .map((sport) => {
                  return <Sport
                    key={sport.id}
                    sport={sport}
                    {...{ lastOpened }}
                    {...{ openedHandler }} />
                })}
          </FlipMove>
        </div>
      </div>
    </div >
  )
}

export default ClassificationBody
