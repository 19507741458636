import React from 'react'

const Option = ({ item }) => {

	return (
		<option
			value={item.value}
			defaultChecked={item.deflt || false}
		>
			{item.title}
		</option>
	)
}

export default Option