import React from 'react'

type IMarketColumn = {
  style?: {
    [key: string]: string
  }
}

const MarketColumn: React.FC<IMarketColumn> = ({ children, style }) => {
  return (
    <div style={style} className="gll-market-group__column">
      {children}
    </div>
  )
}

export default MarketColumn
