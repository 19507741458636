import React, { useContext } from 'react'
import { modifyBets } from '../../../../../helpers/modifyBets' // set coeficient in user-config's format
import { ConfigContext } from '../../../../../context/globalConfig'

const Multiple = (props) => {
  const { ODDS_TYPE } = useContext(ConfigContext)
  return (
    <div className="betslip__multiple">
      <div className="flex align-middle align-justify">
        <div className="text-left">
          <p className="font font--12 font--default-alpha-05">
            {props.item.bd !== '' ? props.item.bd : 'Singles'}
          </p>
          {props.item.bt === 4 ? (
            <p className="font font--12 font--blue">
              {props.item.od !== '' ? modifyBets(props.item.od, ODDS_TYPE) : props.item.bc + '.00'}
            </p>
          ) : null}
        </div>
        <div className="flex align-middle align-justify">
          <p className="font font--11 font--default-alpha-05 m-r-5">{props.item.bc}x</p>
          <div className="betslip__amount">
            <input
              data-key={props.inputid}
              className="field"
              type="text"
              placeholder="0"
              value={props.value}
              onChange={props.change}
              onBlur={props.blur}
              onFocus={props.focus}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Multiple
