import React from 'react'
import { useLocation } from 'react-router-dom'
const InputWrap = ({ children, className }) => {
	const location = useLocation()
	return <div className={`${location.pathname.includes('/account/') ? 'wrap' : 'mn-popup__wrap'} ${className}`} style={{marginBottom: '0'}}>{children}</div>
}

InputWrap.defaultProps = {
	className: '',
}

export default InputWrap
