import React from 'react';

const OpenMessage = () => {
    return (
        <div className="container">
            <div className="container__head">
                <p className="font font--semibold">Open</p>
            </div>
            <div className="container__wrap">
                <div className="container__header">
                    <div className="flex align-middle action">
                        <div className="flex align-middle action action__item action__item--back"><i
                            className="fal fa-angle-left"></i></div>
                        <div className="flex align-middle action action__item action__item--delete"><i
                            className="fal fa-trash-alt m-r-5"></i>
                            <p className="font font--grey-500">Delete</p>
                        </div>
                    </div>
                </div>
                <div className="message">
                    <div className="message__content">
                        <p className="font font--12">Content</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpenMessage;