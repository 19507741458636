import React from 'react'

const EyeIcon = ({ showPasswordHandler, type }) => {
	return (
		<span
			className={type ? 'show-password' : 'show-password show-password--active'}
			onClick={showPasswordHandler}>
			<div className="fas fa-eye"></div>
		</span>
	)
}

export default EyeIcon
