import React from 'react'

// eslint-disable-next-line react/display-name
const SearchIconMatch: React.FC<{ id: string }> = React.memo(({ id }) => {
  return (
    <div className="h-search__img">
      <img src={`http://bestline.bet/icon/sport/${id}`} alt={''} />
    </div>
  )
})

export default SearchIconMatch
