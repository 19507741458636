import React from 'react'
import ScoreboardCell from './ScoreboardCell'
import ScoreboardTableIcon from './ScoreboardTableIcon'

const SoccerColumnTitle = ({ classes }) => {
  return (
    <ScoreboardCell>
      <ScoreboardTableIcon className={classes} />
    </ScoreboardCell>
  )
}

export default React.memo(SoccerColumnTitle)
