import { Nullable } from 'helpers/commonInterfaces/interfaces'
import React, { useState } from 'react'

// const lastEventIcons = {
// 	1: 'half-time',
// 	2: 'c-icon--goal',
// 	3: '',
// 	4: 'c-icon--y-card',
// 	5: '',
// 	6: 'c-icon--r-card',
// 	7: 'c-icon--corner',
//
type SGT = Nullable<{ ST: Array<{ LA: string; ID: number }> }>

const ScoreboardEvents: React.FC<{ SG?: SGT }> = ({ SG = null }) => {
  const [active, setActive] = useState(false)
  if (!active) {
    return <div></div>
  }

  const status = () => setActive(!active)

  if (!SG) {
    return <div />
  }

  const sliceTime = (str) => {
    const [f, ...rest] = str.split('-')

    return {
      time: f,
      event: rest.join(''),
    }
  }
  return <></>
  /* return (
		<CSSTransition in={active} timeout={300} classNames="ev">
			<div className="ip-scoreboard__events">
				<div className="ip-scoreboard__block scroll">
					<div className="text-left">
						{SG?.ST?.map(st => {
							const { time, event } = sliceTime(st.LA)

							return (
								<div
									key={st.ID}
									className="flex align-middle ip-scoreboard__event">
									<div className="c-icon c-icon--goal m-r-10" />
									<p className="font font--11 font--white m-r-10">{time}</p>
									<p className="font font--11 font--white-alpha-05 text-ellipsis">
										{event}
									</p>
								</div>
							)
						})}
					</div>
				</div>
				
				{SG && SG.ST.length > 3 && (
						<div
							className="flex align-center-middle ip-scoreboard__more"
							onClick={status}
						/>
					)}
			</div> 
				</CSSTransition>
			)*/
}

export default React.memo(ScoreboardEvents)
