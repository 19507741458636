import React, { useEffect, useRef } from 'react'
import BetButton from 'components/common/buttons/BetButton'
import { useDispatch, useSelector } from 'react-redux'
import { getOddsType } from 'redux/selectors/configSelectors'
import { getBetslip } from 'redux/selectors/betslipSelectors'
import { addStake, placeBet, removeStake } from 'redux/actions/betslipActions'
import { transformOddName } from 'helpers/transform/transform'

const EventDropDownBetsInner = ({ odds, setShow, eventId, sportId }) => {
  const ODDS_TYPE = useSelector(getOddsType)
  const dispatch = useDispatch()
  const { quickBet, bt } = useSelector(getBetslip)
  const ref = useRef(null)

  const onLeave = () => setShow(false)

  const onListenClick = (e) => {
    e.stopPropagation()
    if (e.target.closest('.dropdown') !== ref.current) {
      onLeave()
    }
  }

  const createBet = (eventId, id, od, ha, check, qb) => {
    check
      ? dispatch(removeStake(id))
      : qb
        ? dispatch(placeBet({ FI: eventId, ID: id, OD: od, CL: sportId, HA: ha }))
        : dispatch(addStake(eventId, id, od, sportId, ha))
  }

  /* useEffect(() => {
    document.body.addEventListener('click', onListenClick)
    return () => document.body.removeEventListener('click', onListenClick)
  }, []) */

  return (
    <div className="dropdown dropdown--center" onMouseLeave={onLeave} ref={ref}>
      <div className="c-icon c-icon--sort" onClick={onLeave} />
      {odds?.map(({ outcomes, specifiers }, i) => (
        <div className="flex align-middle dropdown__item" key={i}>
          {transformOddName(outcomes, specifiers)?.map((item, idx) => {
            return (
              <BetButton
                key={item.id.toString() + idx.toString()}
                item={item}
                oddsType={ODDS_TYPE}
                allStakes={bt}
                panel={quickBet}
                onCreateBet={createBet.bind(null, eventId)}
              />
            )
            /*<button className="btn btn--coff ip-competition__coff" key={id}>
              <span className="font font--11 font--default-alpha-05">
                {specifiers[Object.keys(specifiers)[0]]}
              </span>
              <span className="font font--12">{modifyBet(oddValue, ODDS_TYPE)}</span>
            </button>
             */
          })}
        </div>
      ))}
    </div>
  )
}

export default React.memo(EventDropDownBetsInner)
