import React from 'react'

type SearchTitle = {
  title?: string
}

const SearchTitle = ({ title = 'Search history' }: SearchTitle) => {
  return (
    <div className="h-search__item">
      <p className="font font--13 font--white-alpha-05 h-search__text">{title}</p>
    </div>
  )
}

export default SearchTitle
