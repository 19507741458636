import React from 'react'
import EventTimer from '../../../../EventTimer'
import EventTimerSet from '../../../../EventTimerSet'
import { getCurrentTime } from '../../../../../../helpers/transform/transformTime'

const EventTime = ({
  color,
  startDate,
  providerStatus,
  clock,
  periodId,
  eventType,
  sportId,
  scoreboard,
}) => {
  const getStartedTime = () => {
    const { minutes, hours } = getCurrentTime(startDate)
    return `${hours}:${minutes}`
  }

  if (providerStatus === 'not_started' || eventType === 'Tournament') {
    return (
      <div className="flex align-middle align-center m-b-5" style={{ minHeight: '15px' }}>
        <p className="font font--red font--semibold">{getStartedTime()}</p>
      </div>
    )
  }
  if (!clock || typeof clock.data === 'undefined') {
    return <EventTimerSet sportId={sportId} scoreboard={scoreboard} />
  }

  return (
    <div className="flex align-middle align-center m-b-5" style={{ minHeight: '15px' }}>
      <p className="font font--red font--semibold">
        <EventTimer {...{ color }} clock={clock} periodId={periodId} sportId={sportId} scoreboard={scoreboard} />
      </p>
    </div>
  )
}

export default React.memo(EventTime)
