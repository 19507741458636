import React from 'react'
import ScoreboardCompetitorName from './header/ScoreboardCompetitorName'
import ScoreboardTable from '../common/tableComponents/ScoreboardTable'
import ScoreboardCompetitorsScores from './header/scoreboardCompetitorsScores'

const ScoreboardTableWrapper = ({ additionalData, competitors, sportId }) => {
  const { scoreboard, currentServer, soccerStatistics } = additionalData

  return (
    <ScoreboardTable>
      <ScoreboardCompetitorName competitors={competitors} currentServer={currentServer} />
      <ScoreboardCompetitorsScores
        scoreboard={scoreboard}
        sportId={sportId}
        soccerStatistics={soccerStatistics}
      />
    </ScoreboardTable>
  )
}

export default React.memo(ScoreboardTableWrapper)
