import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTZA } from 'redux/selectors/configSelectors'
import { IMatch, ITournament } from 'helpers/commonInterfaces/interfaces'
import { PrematchEventTime } from './PrematchEventTime'
import EventTime from './EventTime'
import EventActions from './EventActions'

type ActionCellProps = {
  event: IMatch | ITournament
}

export const PrematchActionsCell: FC<ActionCellProps> = React.memo(function PrematchActionsCell({
  event,
}) {

  if (event.eventType === 'Tournament' || event.providerStatus === 'not_started') {
    return (
      <td className="td td--border">
        <p className="font font--13 font--red font--semibold text-center" style={{ color: 'grey' }}>
          {event.day + '.' + event.month + '.' + event.year}
        </p>
        <p
          className="font font--13 font--red font--semibold text-center"
          style={{ marginTop: '3px', marginRight: '2px', color: 'grey' }}
        >
          {event.time}
        </p>
      </td>
    )
  }

  const { externalIds, enrichment, leagueId, sportId, id } = event

  return (
    <td className="td td--border">
      {/* <PrematchEventTime event={event} /> */}
      <EventTime
        color="black"
        startDate={event.startDate}
        providerStatus={event.providerStatus}
        clock={event.additionalData?.eventClock}
        periodId={event.additionalData?.periodId}
        eventType={event.eventType}
        sportId={sportId}
        scoreboard={event.additionalData?.scoreboard}
      />
      <EventActions
        id={event.id}
        enrichment={enrichment}
        externalIds={externalIds}
        sportID={event.sportId}
      />
      {/* <div className="ip-competition__divider"></div> */}
    </td>
  )
})
