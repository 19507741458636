import React from 'react'

const ScheduleHeader = () => {
  return (
    <table className="ips-table">
      <tbody>
        <tr className="tr tr--title">
          <td className="td">
            <p className="font font--11 font--default-alpha-05">Sports</p>
          </td>
          <td className="td" />
          <td className="td" />
          <td className="td">
            <div className="text-center">
              <p className="font font--11 font--default-alpha-05">Live streaming</p>
            </div>
          </td>
          <td className="td" />
        </tr>
      </tbody>
    </table>
  )
}

export default ScheduleHeader
