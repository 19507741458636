import { IState } from '../../redux/reducers/configReducer'

export const linkTransform = (url: string, config?: IState): string => {
	// let newUrl = url.replace(
	// 	'?',
	// 	`?token=${config?.CUSTOMER_CONFIG?.TOKEN ?? 'TOKEN'}&`
	// )
	let newUrl =
		url +
		(url.includes('?') ? '&' : '?') +
		`token=${config?.CUSTOMER_CONFIG?.TOKEN ?? 'TOKEN'}`
	return (newUrl += '&ln=' + (config?.CUSTOMER_CONFIG?.LANGUAGE_ID ?? '1'))
}
