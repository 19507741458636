import React from 'react'
import LiveMenu from '../../components/headerComponents/menus/LiveMenu'
import OverviewWrapper from '../../components/forLivePageComponents/overview/OverviewWrapper'

const Overview: React.FC = () => {
  return (
    <div className="live-wrapper">
      <LiveMenu />
      <div className="view">
        <OverviewWrapper />
      </div>
    </div>
  )
}

export default Overview
