import React, { useEffect, useState } from 'react'
import EventDropDownBets from './bets/EventDropDownBets'
import EventBets from './bets/EventBets'
import { transformOddsForChangeMarket } from '../../../../../../helpers/transform/transform'
import { outoputDashes } from './EventMarkets'

const EventMarketsForChangeMarket = ({ odds, oddName, oddId, competitors, eventId, sportId }) => {
  const [odd, setOdd] = useState<any>(null)
  const [dropBets, setDropBets] = useState<any>(null)

  useEffect(() => {
    const transformedOdds = transformOddsForChangeMarket(odds, oddId)
    setOdd(transformedOdds.odd)
    setDropBets(transformedOdds.dropBets)
  }, [odds, oddId])

  return (
    <td className="td">
      <div className="flex align-middle">
        {odd && odd[0] ? (
          <div className="flex align-stretch ip-competition__info">
            <EventBets
              item={odd[0]}
              competitors={competitors}
              eventId={eventId}
              sportId={sportId}
            />
          </div>
        ) : (
          outoputDashes()
        )}
        {dropBets && <EventDropDownBets {...{ eventId }} {...{ sportId }} odds={odd} />}
      </div>
    </td>
  )
}

export default EventMarketsForChangeMarket
