import React, { useState } from 'react'
import CompetitionSelectLeague from './selectLeague/dropDown/CompetitionSelectLeague'
import CompetitionHeaderTitle from './CompetitionHeaderTitle'
import SelectLeagueHeaderButton from './selectLeague/SelectLeagueHeaderButton'
import ChangeMarket from './changeMarket/ChangeMarket'
import LiveStreaming from './LiveStreaming'

const CompetitionHeader = ({
  data,
  liveStreaming,
  setLStreaming,
  allLeagues,
  leaguesLength,
  changeMarket,
  oddsLength,
  allOddsName,
  currentOddName,
  allOddsId,
  currentOddId,
  chosenLeagues,
}) => {
  const { name, id } = data

  const [dropDown, showDropDown] = useState(false)

  const showSelect = (e) => {
    e.preventDefault()
    showDropDown(!dropDown)
  }

  return (
    <div
      className={
        dropDown
          ? `ip-classification-header ip-classification-header--${name.toLowerCase()} ip-classification-header--active`
          : `ip-classification-header ip-classification-header--${name.toLowerCase()}`
      }
    >
      <div className="flex align-middle align-justify full-height ip-classification-header__wrap">
        <CompetitionHeaderTitle {...{ name }} {...{ id }} />
        {leaguesLength && <SelectLeagueHeaderButton onClick={showSelect} />}
        {changeMarket && oddsLength && (
          <ChangeMarket
            oddsName={allOddsName}
            oddsId={allOddsId}
            currentOdd={currentOddName}
            currentOddId={currentOddId}
          />
        )}
        <LiveStreaming
          {...{ liveStreaming }}
          {...{ setLStreaming }} />
      </div>
      {leaguesLength && dropDown && (
        <CompetitionSelectLeague
          showSelect={showSelect}
          leagues={allLeagues}
          chosenLeagues={chosenLeagues}
        />
      )}
    </div>
  )
}

export default React.memo(CompetitionHeader)
