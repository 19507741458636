import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const BreadCrumbsItem = ({ link, title, active }) => {
  const { t } = useTranslation()

  const transformTitle = () => {
    /*if (title) {
      const tr = dispatch(
        t(
          'COMMON.BREADCRUMBS.' +
            title
              .split('-')
              ?.map((item) => item.replace(item[0], item[0].toUpperCase()))
              .join(' ')
              .toUpperCase()
        )
      ).then(() => {
        return tr.includes('COMMON') ? title : tr
      })
    }
    return ''*/
    return title
  }

  return (
    <div className="breadcrumbs__item">
      <Link
        to={link.split('/').length === 5 ? '/' + link.split('/')[1] : link}
        className={`font font--white-alpha-05 text-ellipsis breadcrumbs__link ${
          active && 'breadcrumbs__link--active'
        }`}
        style={{
          maxWidth: '250px',
        }}
      >
        {transformTitle()}
      </Link>
    </div>
  )
}

export default BreadCrumbsItem
