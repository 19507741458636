import React, { useEffect, useRef } from 'react'
import { Nullable } from '../../../helpers/commonInterfaces/interfaces'

type InputProps = {
  classes: string
  type: string
  placeholder?: string
  onInput?(e: React.FormEvent<HTMLElement>): void
  onChange?(e: React.FormEvent<HTMLElement>): void
  id?: string
  value?: string
  checked?: boolean
  name: string
  active?: boolean
}

const SimpleInput: React.FC<InputProps> = ({ active, classes, checked, ...rest }) => {
  const ref = useRef<Nullable<HTMLInputElement>>(null)

  useEffect(() => {
    if (ref.current && active) {
      ref.current.focus()
    }
  }, [active])

  if (typeof checked !== 'undefined') {
    return <input ref={ref} className={classes} checked={checked} {...rest} />
  }

  return <input ref={ref} className={classes} {...rest} />
}

SimpleInput.defaultProps = {
  placeholder: '',
  onInput: () => null,
  onChange: () => null,
  id: '',
  value: '',
}

export default SimpleInput
