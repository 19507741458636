import { NavLink } from 'react-router-dom'
import BetButton from 'components/common/buttons/BetButton'
import BetLockButton from 'components/common/buttons/BetLockButton'
import EventTime from 'components/common/competition/competitionContent/event/actionsCell/EventTime'
import { IMatch, Nullable } from 'helpers/commonInterfaces/interfaces'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addStake, placeBet, removeStake } from 'redux/actions/betslipActions'
import { getBetslip } from 'redux/selectors/betslipSelectors'
import { getOddsType, isLoggedIn } from 'redux/selectors/configSelectors'
import { getInplay } from 'redux/selectors/inplaySelectors'
import img from '../../../../assets/img/banners/ThreeWayBanner.png'
import { transformOddName } from 'helpers/transform/transform'
import { debounce } from '../../../../helpers/debounce'

const ThreeWayBanner = ({ eventId }) => {
  const dispatch = useDispatch()
  const inplay = useSelector(getInplay)
  const LOGGED_IN = useSelector(isLoggedIn)
  const ODDS_TYPE = useSelector(getOddsType)
  const { quickBet, bt } = useSelector(getBetslip)

  const [event, setEvent] = useState<Nullable<IMatch>>(null)
  const [outcomes, setOutcomes] = useState<any[]>([])
  const [names, setNames] = useState({
    sport: 'Football',
    category: 'England',
    league: 'Championship',
    competitors: [
      {
        name: 'Chelsea',
      },
      {
        name: 'Arsenal',
      },
    ],
  })

  const createBet = (eventId, id, od, ha, check, qb): void => {
    check
      ? dispatch(removeStake(id))
      : qb && LOGGED_IN
        ? dispatch(placeBet({ FI: eventId, ID: id, OD: od, CL: event?.sportId ?? 1, HA: ha }))
        : dispatch(addStake(eventId, id, od, event?.sportId ?? 1, ha))
  }


  useEffect(() => {
    if (names.league !== '' && names.league !== 'Championship') {
      return
    }
    let sportName = '',
      categoryName = '',
      leagueName = '',
      sportId = 0,
      leagueId = 0,
      categoryId = 0,
      competitors = names.competitors
    // finding event
    Object.values(inplay.leaguesEvents).forEach((events) => {
      events.forEach((event) => {
        if (event.id === Number(eventId)) {
          const ev = event as IMatch
          setEvent(ev)
          const market = event.odds?.find((odd) => odd.id === 1 || odd.id === 175 || odd.id === 206)
            ?? event.odds?.[0]
          console.log(`marketId = `, market)
          console.log(event.odds?.filter(odd => odd.id === market?.id) ?? [])
          const item = event.odds?.filter(odd => odd.id === market?.id)[0]
          setOutcomes(transformOddName((item?.outcomes), {
            ...item?.specifiers,
            competitor1: competitors[0].name,
            competitor2: competitors[1].name,
          }) ?? [])
          sportId = event.sportId
          leagueId = event.leagueId
          categoryId = event.categoryId
          competitors = ev.competitors
        }
      })
    })
    if (!inplay.data) {
      return
    }
    // finding sport, category and league names
    inplay.data.forEach((sport) => {
      if (sport.id === sportId) {
        sportName = sport.name
        sport.categories.forEach((category) => {
          if (category.id === categoryId) {
            categoryName = category.name
            category.leagues.forEach((league) => {
              if (league.id === leagueId) {
                leagueName = league.name
              }
            })
          }
        })
      }
    })
    if (competitors[0].name === '') {
      return
    }
    setNames({ sport: sportName, category: categoryName, league: leagueName, competitors })
  }, [inplay, eventId])

  // updating odds
  useEffect(() => {
    const update = () => {
      Object.values(inplay.leaguesEvents).forEach((events) => {
        events.forEach((event) => {
          if (event.id === Number(eventId)) {
            const ev = event as IMatch
            if (shouldOutcomesUpdate(outcomes, ev.odds, names.competitors)) {
              setEvent(ev)
              const market = event.odds?.find((odd) => odd.id === 1 || odd.id === 175 || odd.id === 206)
                ?? event.odds?.[0]
              const item = event.odds?.filter(odd => odd.id === market?.id)[0]
              setOutcomes(transformOddName((item?.outcomes), {
                ...item?.specifiers,
                competitor1: names.competitors[0].name,
                competitor2: names.competitors[1].name,
              }) ?? [])
            }
          }
        })
      })
    }
    update()
  }, [eventId, names, outcomes, inplay.leaguesEvents])

  return (
    <div
      className="wn-banner wn-banner--shadow"
      style={{ backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat' }}
    >
      <div className="wn-banner__wrap">
        <NavLink to={`/live/event/${event?.sportId}/${event?.leagueId}/${event?.id}`}>
          <div className="text-center full-width">
            <p className="font font--gold m-b-10">{names.league}</p>
            <p className="font font--white font--20 m-b-10">
              {names.competitors[0].name} - {names.competitors[1].name}
            </p>
            <EventTime
              color="#f1cb62"
              startDate={event?.startDate}
              providerStatus={event?.providerStatus}
              clock={event?.additionalData?.eventClock}
              periodId={event?.additionalData?.periodId}
              eventType={event?.eventType}
              sportId={event?.sportId}
              scoreboard={event?.additionalData?.scoreboard} />
          </div>

          <div className="flex align-middle full-width padding wn-banner__footer" onClick={(e) => { e.stopPropagation() }}>
            {outcomes?.map((odd) => {
              return (
                <React.Fragment key={odd.id}>
                  {odd.status === '1' ? (
                    <BetLockButton />
                  ) : (
                    <BetButton
                      item={odd}
                      className="wn-banner__coff"
                      onCreateBet={createBet.bind(null, eventId)}
                      panel={quickBet}
                      allStakes={bt}
                      oddsType={ODDS_TYPE}
                    />
                  )}
                </React.Fragment>
              )
            })}
            {/* <button className="btn btn--h-32 wn-banner__coff" style={{ marginRight: "10px" }}>
              <span className="font font--default-alpha-05">1</span>
              <span className="font">2.17</span>
            </button>
            <button className="btn btn--h-32 wn-banner__coff" style={{ marginRight: "10px" }}>
              <span className="font font--default-alpha-05">x</span>
              <span className="font">2.17</span>
            </button>
            <button className="btn btn--h-32 wn-banner__coff">
              <span className="font font--default-alpha-05">2</span>
              <span className="font">2.17</span>
            </button> */}
          </div>
        </NavLink>
      </div>
    </div>
  )
}

export default ThreeWayBanner

export const shouldOutcomesUpdate = (old, newO, competitors) => {
  const market = newO?.find((odd) => odd.id === 1 || odd.id === 175 || odd.id === 206)
    ?? newO?.[0]
  const item = newO?.filter(odd => odd.id === market?.id)[0]
  const newOdds = (transformOddName((item?.outcomes), {
    ...item?.specifiers,
    competitor1: competitors[0].name,
    competitor2: competitors[1].name,
  }) ?? [])
  if (old.length !== newOdds.length) {
    return true
  }
  let should = false
  old.forEach((odd, idx) => {
    if (odd.oddValue !== newOdds[idx].oddValue) {
      should = true
    }
  })
  return should
}