import React, { useState } from 'react'
import parse, { domToReact } from 'html-react-parser'
import Wrap from '../../registration/elements/wrap'
import Input from '../../common/elements/Input'
import Text from '../../common/elements/Text'
import Button from '../../common/buttons/Button'
import EyeIcon from '../../common/elements/EyeIcon'
import { useFormik } from 'formik'
import { useAccount } from '../../../hooks/useAccount'
import PopupSucceed from '../PopupSucceed'
import { Nullable } from 'helpers/commonInterfaces/interfaces'

const Contacts = () => {
  const [state, setState] = useState({
    showPassword: false,
    showPopup: false,
  })
  const { sumbit, contact, schemaContact } = useAccount()
  const showPasswordHandler = () => {
    setState({ ...state, showPassword: !state.showPassword })
  }

  const closePopupHandler = () => {
    window.clearTimeout(window.closePopupTimeout)
    setState({ ...state, showPopup: false })
  }

  const formik = useFormik({
    initialValues: contact.initialValues,
    validationSchema: schemaContact,
    onSubmit: (values, methods) => {
      setState({ ...state, showPopup: true })
      window.clearTimeout(window.closePopupTimeout)
      window.closePopupTimeout = setTimeout(() => {
        window.clearTimeout(window.closePopupTimeout)
        setState({ ...state, showPopup: false })
      }, 3000)
      sumbit('persInfo', values)
    },
  })

  let passwordType: Nullable<string> = null
  if (state.showPassword) passwordType = 'text'
  else passwordType = 'password'
  const { values, handleChange, handleSubmit, errors, touched } = formik

  const responseHTML = `<form>
        <div class="container__head">
                <p class="font font--semibold">Contact</p>
            </div>
            <div class="container__wrap">
                <div class="account">
                    <div class="left">
                        <div class="wrap">
                            <p class="font font--default-alpha-05 m-b-10">Contact number</p>
                            <input 
                            class="field field--main" 
                            name="number" 
                            type="text" 
                            value="Sergey"
                            data-regex="/^[0-9]+$/" 
                            data-min="7"
                            data-max="7"
                            data-req="true" />
                        </div>
                        <div class="wrap">
                            <input 
                            class="field field--main" 
                            name="password" 
                            type="text" 
                            placeholder="Password"
                            data-regex="/[a-zA-Z]/" 
                            data-min="3"
                            data-max="22"
                            data-req="true"/>
                            <a class="show-password">
                                <div class="fas fa-eye"></div>
                            </a>
                        </div>
                          <button class="btn btn--h-40 btn--blue"><span
                              class="font font--12 font--white">Save</span>
                          </button>
                    </div>
                    <div class="right">
                        <div class="wrap">
                            <p class="font font--default-alpha-05 m-b-10">E-mail</p>
                            <input 
                              class="field field--main" 
                              name="email"
                              type="email" 
                              value="Prokofiev" 
                              data-regex="email" 
                              data-min="4"
                              data-max="22"
                              data-req="true"/>
                        </div>
                    </div>
                </div>
            </div>
            </form>`
  const options = {
    replace: (domNode) => {
      // adding submit handler to form
      if (domNode.name === 'form') {
        return <form onSubmit={handleSubmit}>{domToReact(domNode.children, options)}</form>
      }
      // button processing
      if (domNode.name === 'button') {
        return (
          <Button
            className="btn btn--h-40 btn--blue"
            title={domNode.children[0].children[0].data}
            type="submit"
          />
        )
      }
      // Inputs Processing
      if (domNode.name === 'input') {
        console.log('Input domNode', domNode)
        if (domNode.attribs.name === 'password') {
          return (
            <Input
              className={domNode.attribs.class}
              placeholder={domNode.attribs.placeholder}
              type={passwordType}
              name={domNode.attribs.name}
              value={values[domNode.attribs.name]}
              onChange={handleChange}
              autoComplete="on"
            />
          )
        } else {
          return (
            <Input
              className={domNode.attribs.class}
              placeholder={domNode.attribs.placeholder}
              type={domNode.attribs.type}
              name={domNode.attribs.name}
              value={values[domNode.attribs.name]}
              onChange={handleChange}
            />
          )
        }
      }
      // Setting correct div.wrap elems
      if (domNode.attribs !== undefined && domNode.attribs.class === 'wrap') {
        const input = domNode.children.filter((item) => {
          if (item.name === 'input') {
            return item.attribs.name
          }
        })
        const inputName = input[0].attribs.name
        return (
          <Wrap className={errors[inputName] && touched[inputName] && 'required required--error'}>
            {domToReact(domNode.children, options)}
          </Wrap>
        )
      }
      // Eye icon processing
      if (domNode.attribs !== undefined && domNode.attribs.class === 'show-password') {
        return <EyeIcon {...{ showPasswordHandler }} type={state.showPassword} />
      }
      // p.span processing
      if (domNode.attribs !== undefined && domNode.name === 'p') {
        return <Text className={domNode.attribs.class} title={domNode.children[0].data} />
      }
      return <></>
    },
  }

  const elem = parse(responseHTML, options)
  // elem = null;
  console.log('elem', elem)
  return (
    <div className="container">
      {elem ? (
        elem
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="container__head">
            <p className="font font--semibold">Contact</p>
          </div>
          <div className="container__wrap">
            <div className="account">
              <div className="left">
                <Wrap className={errors.number && touched.number && 'required required--error'}>
                  <Text className="font font--default-alpha-05 m-b-10" title="Contact number" />
                  <Input
                    className="field field--main"
                    type="text"
                    name="number"
                    value={values.number}
                    onChange={handleChange}
                  />
                </Wrap>
                <Wrap className={errors.password && touched.password && 'required required--error'}>
                  <Input
                    className="field field--main"
                    type={passwordType}
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <EyeIcon {...{ showPasswordHandler }} type={state.showPassword} />
                </Wrap>

                <Button className="btn btn--h-40 btn--blue" title="Save" type="submit" />
              </div>
              <div className="right">
                <Wrap className={errors.email && touched.email && 'required required--error'}>
                  <Text className="font font--default-alpha-05 m-b-10" title="E-mail" />
                  <Input
                    className="field field--main"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </Wrap>
              </div>
            </div>
          </div>
        </form>
      )}
      {state.showPopup && (
        <PopupSucceed
          close={closePopupHandler}
          message={'Personal information has changed successfully'}
        />
      )}
    </div>
  )
}

export default Contacts
