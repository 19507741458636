import React from 'react'
import SuccessPopup from './components/SuccessPopup'

const BalanceHistory = () => {
  const [success, setSuccess] = React.useState(false)
  const [active, setActive] = React.useState('Deposit')
  const switchTabHandler = (newTab) => {
    return () => {
      setActive(newTab)
    }
  }
  const toggleSuccessHandler = () => {
    setSuccess(!success)
  }
  const [time, setTime] = React.useState({
    activeFilter: '24 Hours',
    dropdown: false,
  })
  const dropdownToggleHandler = () => {
    setTime({
      ...time,
      dropdown: !time.dropdown,
    })
  }
  const setFilterHandler = (value) => {
    return () => {
      if (!value) {
        value = time.activeFilter
      }
      setTime({
        activeFilter: value,
        dropdown: false,
      })
    }
  }
  return (
    <div className="content">
      <div className="container">
        <div className="container__head">
          <p className="font font--semibold">History</p>
        </div>
        <div className="container__wrap">
          <div className="container__header">
            <div className="cb-tab">
              <div
                className={
                  active === 'Deposit' ? 'cb-tab__item cb-tab__item--active' : 'cb-tab__item'
                }
                onClick={switchTabHandler('Deposit')}
              >
                <p className="font">Deposit</p>
              </div>
              <div
                className={
                  active === 'Withdraw' ? 'cb-tab__item cb-tab__item--active' : 'cb-tab__item'
                }
                onClick={switchTabHandler('Withdraw')}
              >
                <p className="font">Withdraw</p>
              </div>
              <div
                className={
                  active === 'Bonus' ? 'cb-tab__item cb-tab__item--active' : 'cb-tab__item'
                }
                onClick={switchTabHandler('Bonus')}
              >
                <p className="font">Bonus</p>
              </div>
            </div>
            {time.dropdown && (
              <div
                className="h-popup__overlay"
                style={{ zIndex: 1 }}
                onClick={dropdownToggleHandler}
              ></div>
            )}
            <div className="dropdown">
              <span className="font dropdown__toggle" onClick={dropdownToggleHandler}>
                {time.activeFilter === 'All time' ? time.activeFilter : 'Last ' + time.activeFilter}
              </span>
              <div className={time.dropdown ? 'dropdown__menu active' : 'dropdown__menu'}>
                <span className="dropdown__item" onClick={setFilterHandler('1 Hour')}>
                  <p className="font">1 Hour</p>
                </span>
                <span className="dropdown__item" onClick={setFilterHandler('24 Hours')}>
                  <p className="font">24 Hours</p>
                </span>
                <span className="dropdown__item" onClick={setFilterHandler('1 Month')}>
                  <p className="font">1 Month</p>
                </span>
                <span className="dropdown__item" onClick={setFilterHandler('2 months')}>
                  <p className="font">2 months</p>
                </span>
                <span className="dropdown__item" onClick={setFilterHandler('All time')}>
                  <p className="font">All time</p>
                </span>
              </div>
            </div>
            <hr className="hr" />
            <div className="calendar">
              <div className="calendar__item">
                <i className="fal fa-calendar-alt"></i>
              </div>
            </div>
          </div>
          <div className="balance">
            <table className="cb-table">
              <tbody>
                <tr className="cb-table__tr cb-table__tr--title">
                  <th className="cb-table__th">
                    <div className="text-center">
                      <p className="font font--11 font--grey-500">id</p>
                    </div>
                  </th>
                  <th className="cb-table__th">
                    <div className="text-center">
                      <p className="font font--11 font--grey-500">Date</p>
                    </div>
                  </th>
                  <th className="cb-table__th">
                    <div className="text-center">
                      <p className="font font--11 font--grey-500">Currency</p>
                    </div>
                  </th>
                  <th className="cb-table__th">
                    <div className="text-center">
                      <p className="font font--11 font--grey-500">Amount</p>
                    </div>
                  </th>
                  <th className="cb-table__th">
                    <div className="text-left">
                      <p className="font font--11 font--grey-500">Metod</p>
                    </div>
                  </th>
                  <th className="cb-table__th">
                    <div className="text-center">
                      <p className="font font--11 font--grey-500">Note</p>
                    </div>
                  </th>
                  <th className="cb-table__th"></th>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">0123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <div className="details">
                        <span className="font font--blue" onClick={toggleSuccessHandler}>
                          Details
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr tr--show" role="row">
                  <td className="cb-table__td cb-table__td--border" colSpan={2}>
                    <p className="font font--11 font--default-alpha-05">Exchance Rate:</p>
                    <p className="font">BTC to ETH = 44.203099</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border" colSpan={3}>
                    <p className="font font--11 font--default-alpha-05">Hash:</p>
                    <div className="flex align-middle">
                      <p className="font text-ellipsis hash m-r-5">
                        lM%svqjd67#ZCcSD^AG!45liI^2JV73c1%E6
                      </p>
                      <div className="copy">
                        <i className="fal fa-copy"></i>
                      </div>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Status:</p>
                      <p className="font font--green">Confirmed</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Confirmations:</p>
                      <p className="font">5</p>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">1123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <div className="details">
                        <span className="font font--blue" onClick={toggleSuccessHandler}>
                          Details
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr tr--hide" role="row">
                  <td className="cb-table__td cb-table__td--border" colSpan={2}>
                    <p className="font font--11 font--default-alpha-05">Exchance Rate:</p>
                    <p className="font">BTC to ETH = 44.203099</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border" colSpan={3}>
                    <p className="font font--11 font--default-alpha-05">Hash:</p>
                    <div className="flex align-middle">
                      <p className="font text-ellipsis hash m-r-5">
                        lM%svqjd67#ZCcSD^AG!45liI^2JV73c1%E6
                      </p>
                      <div className="copy">
                        <i className="fal fa-copy"></i>
                      </div>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Status:</p>
                      <p className="font font--green">Confirmed</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Confirmations:</p>
                      <p className="font">5</p>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">2123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <div className="details">
                        <span className="font font--blue" onClick={toggleSuccessHandler}>
                          Details
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr tr--hide" role="row">
                  <td className="cb-table__td cb-table__td--border" colSpan={2}>
                    <p className="font font--11 font--default-alpha-05">Exchance Rate:</p>
                    <p className="font">BTC to ETH = 44.203099</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border" colSpan={3}>
                    <p className="font font--11 font--default-alpha-05">Hash:</p>
                    <div className="flex align-middle">
                      <p className="font text-ellipsis hash m-r-5">
                        lM%svqjd67#ZCcSD^AG!45liI^2JV73c1%E6
                      </p>
                      <div className="copy">
                        <i className="fal fa-copy"></i>
                      </div>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Status:</p>
                      <p className="font font--green">Confirmed</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Confirmations:</p>
                      <p className="font">5</p>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">3123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <div className="details">
                        <span className="font font--blue" onClick={toggleSuccessHandler}>
                          Details
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr tr--hide" role="row">
                  <td className="cb-table__td cb-table__td--border" colSpan={2}>
                    <p className="font font--11 font--default-alpha-05">Exchance Rate:</p>
                    <p className="font">BTC to ETH = 44.203099</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border" colSpan={3}>
                    <p className="font font--11 font--default-alpha-05">Hash:</p>
                    <div className="flex align-middle">
                      <p className="font text-ellipsis hash m-r-5">
                        lM%svqjd67#ZCcSD^AG!45liI^2JV73c1%E6
                      </p>
                      <div className="copy">
                        <i className="fal fa-copy"></i>
                      </div>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Status:</p>
                      <p className="font font--green">Confirmed</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Confirmations:</p>
                      <p className="font">5</p>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">4123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <div className="details">
                        <span className="font font--blue" onClick={toggleSuccessHandler}>
                          Details
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr tr--hide" role="row">
                  <td className="cb-table__td cb-table__td--border" colSpan={2}>
                    <p className="font font--11 font--default-alpha-05">Exchance Rate:</p>
                    <p className="font">BTC to ETH = 44.203099</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border" colSpan={3}>
                    <p className="font font--11 font--default-alpha-05">Hash:</p>
                    <div className="flex align-middle">
                      <p className="font text-ellipsis hash m-r-5">
                        lM%svqjd67#ZCcSD^AG!45liI^2JV73c1%E6
                      </p>
                      <div className="copy">
                        <i className="fal fa-copy"></i>
                      </div>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Status:</p>
                      <p className="font font--green">Confirmed</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--11 font--default-alpha-05">Confirmations:</p>
                      <p className="font">5</p>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <div className="confirmed">
                        <a className="font font--green">Confirmed</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <div className="reject">
                        <a className="font font--red">! Reject</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <div className="processing">
                        <a className="font font--gold">Processing</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">UAH</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">500.00</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Neteller</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font font--default-alpha-05">Dear Ivan Pupkin! ...</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <button className="btn btn--h-22 cancel">
                      <span className="font font--default-alpha-05">Cancel</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="cb-table">
              <tbody>
                <tr className="cb-table__tr cb-table__tr--title">
                  <th className="cb-table__th">
                    <div className="text-center">
                      <p className="font font--11 font--grey-500">id</p>
                    </div>
                  </th>
                  <th className="cb-table__th">
                    <div className="text-center">
                      <p className="font font--11 font--grey-500">Date</p>
                    </div>
                  </th>
                  <th className="cb-table__th">
                    <div className="text-left">
                      <p className="font font--11 font--grey-500">Game</p>
                    </div>
                  </th>
                  <th className="cb-table__th"></th>
                  <th className="cb-table__th"></th>
                  <th className="cb-table__th"></th>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">0123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Live Casino</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">240.00 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">100% deposit bonus up to 10,000 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <a className="external-link" href="#" target="_blank">
                        <i className="fal fa-external-link-alt"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">1123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Live Casino</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">240.00 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">100% deposit bonus up to 10,000 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <a className="external-link" href="#" target="_blank">
                        <i className="fal fa-external-link-alt"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">2123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Live Casino</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">240.00 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">100% deposit bonus up to 10,000 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <a className="external-link" href="#" target="_blank">
                        <i className="fal fa-external-link-alt"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">3123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Live Casino</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">240.00 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">100% deposit bonus up to 10,000 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <a className="external-link" href="#" target="_blank">
                        <i className="fal fa-external-link-alt"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr className="cb-table__tr" role="row">
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">4123456</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <p className="font">21.09, 23:10</p>
                    </div>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">Live Casino</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">240.00 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <p className="font">100% deposit bonus up to 10,000 $</p>
                  </td>
                  <td className="cb-table__td cb-table__td--border">
                    <div className="text-center">
                      <a className="external-link" href="#" target="_blank">
                        <i className="fal fa-external-link-alt"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {success && <SuccessPopup close={toggleSuccessHandler} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BalanceHistory
