import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setVerify } from '../../../../redux/actions/accountActions'
import PopupSucceed from '../../PopupSucceed'
import CbMessage from './cbMessage'
import { useDropzone } from 'react-dropzone'
import Upload from './upload'
import PasswordFile from './passwordFile'
import ProgressBar from './progressBar'
import { getAccount } from 'redux/selectors/accountSelectors'

declare global {
	interface Window {
		closePopupTimeout: ReturnType<typeof setTimeout>
	}
}

const AccountVerification = () => {
	const { verified } = useSelector(getAccount)
	const dispatch = useDispatch()
	const [state, setState] = useState({
		showPopup: false,
		failed: false,
		success: false,
		inProcess: !verified,
		activeMenu: 'password',
	})

	const Progress = async percent => {}

	const switchActiveMenuHandler = tabName => {
		return () => {
			setState({
				...state,
				activeMenu: tabName,
			})
		}
	}

	useEffect(() => {
		if (state.success) {
			dispatch(setVerify(true))
		}
	}, [state.success])

	const closePopupHandler = () => {
		window.clearTimeout(window.closePopupTimeout)
		setState({ ...state, showPopup: false, inProcess: false, success: true })
	}
	const onSubmitHandler = () => {
		setState({ ...state, showPopup: true })
		window.clearTimeout(window.closePopupTimeout)
		window.closePopupTimeout = setTimeout(() => {
			window.clearTimeout(window.closePopupTimeout)
			setState({ ...state, showPopup: false, inProcess: false, success: true })
		}, 3000)
	}

	// Drag'n'Drop logic
	const [files, setFiles] = useState<Array<JSX.Element>>([])
	// remove file handler
	const removeFile = (fileIndex, arr) => {
		return () => {
			const newArr = setFiles(
				arr
					.filter((item, idx) => {
						return idx !== fileIndex
					})
					?.map((file, idx, arr) => {
						return (
							<PasswordFile
								fileStatus={file.props.fileStatus}
								key={Math.random()}
								fileName={file.props.fileName}
								filePostfixType={file.props.filePostfixType}
								removeFile={removeFile(idx, arr)}
							/>
						)
					})
			)
		}
	}
	
	const onDrop = useCallback(
		(acceptedFiles) => {
			const newFiles:Array<JSX.Element> = []
			newFiles.unshift(...files)
			newFiles.unshift(
				...acceptedFiles?.map((file, idx) => (
						<PasswordFile
							fileStatus="uploading"
							fileName={file.name}
							filePostfixType="uploading"
						/>
					)
			) as never[])
			setFiles(
				newFiles?.map((file, idx) => (
						<PasswordFile
							fileStatus={file.props.fileStatus}
							key={Math.random()}
							fileName={file.props.fileName}
							filePostfixType={file.props.filePostfixType}
							removeFile={removeFile(idx, newFiles)}
						/>
					)
				))
		},
		[files]
	)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
	})
	return (
		<div className="container">
			<div className="container__head">
				<p className="font font--semibold">Account Verification</p>
			</div>
			<div className="container__wrap">
				<div className="account">
					<div className="center">
						{state.inProcess && (
							<>
								<div className="title-group text-center">
									<p className="font m-b-10">
										Here you can upload color photographs of documents (document
										scans are not allowed).{' '}
									</p>
									<p className="font font--11 font--grey-300 m-b-10">
										Document pages should be fully visible. Valid document
										languages ​​are Russian, English, Ukrainian. After checking
										the documents, your game account will have the status
										"verified".
									</p>
								</div>
								<div className="cb-upload">
									<div className="flex align-center-middle cb-upload__header">
										<div
											className={
												state.activeMenu === 'license'
													? 'cb-upload__item cb-upload__item--license cb-upload__item--active'
													: 'cb-upload__item cb-upload__item--license'
											}
											onClick={switchActiveMenuHandler('license')}>
											<div className="cb-upload__icon">
												<i className="fal fa-address-card"></i>
											</div>
											<p className="font font--grey-300 cb-upload__text">
												Drivers license
											</p>
										</div>
										<div
											className={
												state.activeMenu === 'idcard'
													? 'cb-upload__item cb-upload__item--id-card cb-upload__item--active'
													: 'cb-upload__item cb-upload__item--id-card'
											}
											onClick={switchActiveMenuHandler('idcard')}>
											<div className="cb-upload__icon">
												<i className="fal fa-address-card"></i>
											</div>
											<p className="font font--grey-300 cb-upload__text">
												Id card
											</p>
										</div>
										<div
											className={
												state.activeMenu === 'password'
													? 'cb-upload__item cb-upload__item--password cb-upload__item--active'
													: 'cb-upload__item cb-upload__item--password'
											}
											onClick={switchActiveMenuHandler('password')}>
											<div className="cb-upload__icon">
												<i className="fal fa-address-card"></i>
											</div>
											<p className="font font--grey-300 cb-upload__text">
												Password
											</p>
										</div>
									</div>
									<Upload
										{...{ getRootProps }}
										{...{ getInputProps }}
										{...{ isDragActive }}
									/>
								</div>
								{files}
								{/*
                <div className="cb-alert cb-alert--confirmed">
                  <div className="flex align-middle align-justify full-height cb-alert__wrap">
                    <p className="font font--10 cb-alert__text text-ellipsis">My passport_page_3.JPG</p>
                    <p className="font font--10 cb-alert__message">Confirmed!</p>
                  </div>
                </div>
                <div className="cb-alert cb-alert--bad">
                  <div className="flex align-middle align-justify full-height cb-alert__wrap">
                    <p className="font font--10 cb-alert__text text-ellipsis">My passport_page_3.JPG</p>
                    <p className="font font--10 cb-alert__message">Confirmed!</p>
                  </div>
                </div>
                <div className="cb-alert cb-alert--warning">
                  <div className="flex align-middle align-justify full-height cb-alert__wrap">
                    <p className="font font--10 cb-alert__text text-ellipsis">My passport_page_3.JPG</p>
                    <p className="font font--10 cb-alert__message">Confirmed!</p>
                  </div>
                </div>
                <div className="cb-alert cb-alert--default">
                  <div className="flex align-middle align-justify full-height cb-alert__wrap">
                    <p className="font font--10 cb-alert__text text-ellipsis">My passport_page_1.JPG</p>
                    <p className="font font--10 cb-alert__message">Under consideration…</p>
                  </div>
                </div>
                <div className="cb-alert cb-alert--default">
                  <div className="flex align-middle align-justify full-height cb-alert__wrap">
                    <p className="font font--10 cb-alert__text text-ellipsis">My passport_page_1.JPG</p>
                    <div className="cb-alert__times"><i className="fal fa-times"></i></div>
                  </div>
                </div>
                <div className="cb-alert cb-alert--failed">
                  <div className="flex align-middle align-justify full-height cb-alert__wrap">
                    <p className="font font--10 cb-alert__text text-ellipsis">My passport_page_1.JPG</p>
                    <div className="flex align-middle">
                      <p className="font font--10 m-r-10 cb-alert__message">Under consideration…</p>
                      <div className="cb-alert__reload"><i className="fal fa-redo-alt"></i></div>
                    </div>
                  </div>
                </div>
                  */}
								<button
									className="btn btn--h-40 btn--blue"
									style={{ margin: '20px auto' }}
									onClick={onSubmitHandler}>
									<span className="font font--12 font--white">Submit</span>
								</button>

								<div className="text-center m-t-10">
									<p className="font m-b-10">
										Your documents are pending. It can take some time.{' '}
									</p>
									<p className="font font--11 font--grey-300 m-b-10">
										<span className="font--11 m-r-5">
											For any questions, please contact
										</span>
										<a className="font font--11 font--blue" href="#">
											support
										</a>
									</p>
								</div>
								<div className="text-center support">
									<p>
										<span className="font m-r-5">Need some help?</span>
										<a className="font font--blue" href="#">
											Support
										</a>
									</p>
								</div>
							</>
						)}
						{state.showPopup && <PopupSucceed close={closePopupHandler} />}
						{state.success && <CbMessage type="message" />}
						{state.failed && <CbMessage type="error" />}
					</div>
				</div>
			</div>
		</div>
	)
}

export default AccountVerification
