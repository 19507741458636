import React, { useState } from 'react'
import RegionHeader from './RegionHeader'
import RegionBody from './RegionBody'

const Region = ({ lastOpened, openedHandler, category }) => {
  const [active, setActive] = useState(category.id === lastOpened.category)

  const showBody = () => {
    setActive(!active)
  }

  const onHeaderClick = e => {
    e.stopPropagation()
    if (lastOpened.category !== category.id) {
      openedHandler('category', category.id)
    }
    showBody()
  }

  return (
    <div
      className={
        active
          ? 'wn-classification-region wn-classification-region--active'
          : 'wn-classification-region'
      }>
      <RegionHeader
        name={category.name}
        sportId={category.sportId}
        leagues={category.leagues}
        count={category.matchCount}
        iconCode={category.iconCode}
        onHeaderClick={onHeaderClick}
      />
      {category.leagues && active && <RegionBody
        leagues={category.leagues}
        {...{ lastOpened }}
        {...{ openedHandler }} />}
    </div>
  )
}

export default Region
