import React from 'react'
import TableRow from '../table/TableRow'
import TitleCell from '../table/TitleCell'
import Table from '../table/Table'
import DropDownCell from './dropDown/DropDownCell'

const LeagueHeader = ({ id, sportId, iconCode, name, changeMarket }) => {
	return (
		<Table>
			<TableRow className="tr tr--title">
				<TitleCell id={id} sportId={sportId} name={name} iconCode={iconCode} />
				{/*!changeMarket &&
					keys?.map(key => {
						if (key === 'ids') {
							return null
						}

						const item = groupOdds[key]

						return (
							<DropDownCell key={item.id} item={item} ids={groupOdds.ids} />
						)
					})*/}
			</TableRow>
		</Table>
	)
}

export default LeagueHeader
