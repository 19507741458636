import React from 'react'
import TableRow from '../common/competition/competitionContent/table/TableRow'
import Table from '../common/competition/competitionContent/table/Table'

const EventsStartDate = ({ date }) => {
  return (
    <Table>
      <TableRow className="tr tr--title">
        <td className="td">
          <div className="text-left">
            <a className="font font--11 font--default-alpha-05 text-ellipsis">{date}</a>
          </div>
        </td>
      </TableRow>
    </Table>
  )
}

export default EventsStartDate
