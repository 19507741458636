import React from 'react'

const SuspendedMarketCell = ({ className = '' }) => {
  return (
    <div className={"gll-market-group__cell" + className}>
      <div className="gll-market-group__coff gll-market-group__btn--center">
        <span className="font font--grey-300">
          <i
            className="fal fa-lock-alt"
            style={{
              fontSize: '15px',
            }}
          />
        </span>
      </div>
    </div>
  )
}

export default React.memo(SuspendedMarketCell)
