import { Nullable } from '../../helpers/commonInterfaces/interfaces'
import { BannersWorkersType } from '../actions/bannersActions'
import { types } from '../types/types'

const initialState = {
  slides: null as Nullable<any[]>,
  inline: null as Nullable<any[]>,
}
export type IState = typeof initialState

export const banners = (state = initialState, action: BannersWorkersType): IState => {
  switch (action.type) {
    case types.SET_SLIDES:
      return {
        ...state,
        slides: action.payload,
      }
    case types.SET_INLINE_BANNERS:
      return {
        ...state,
        inline: action.payload,
      }
    default:
      return state
  }
}
