import React from 'react'
import Icon from '../common/elements/SportIcon'
import { icons } from '../../assets/sportIcons/sportIcons'

const SportHeader = ({ sport }) => {
  return (
    <div
      className={`ip-classification-header ip-classification-header--${sport.name
        .split(' ')
        .join('_')
        .toLowerCase()}`}
    >
      <div className="flex align-middle align-justify full-height ip-classification-header__wrap">
        <div className="flex align-middle ip-classification-header__sports">
          <div className="ip-classification-header__icon m-r-10">
            {/* <SportIcon name={sport.name} /> */}
            {icons[sport.id] ? (
              <Icon name={icons[sport.id]} id={sport.id} />
            ) : (
              <Icon id={sport.id} name={icons[1]} />
            )}
          </div>
          <p className="font ip-classification-header__text">{sport.name}</p>
        </div>
      </div>
    </div>
  )
}

export default SportHeader
