import React from 'react'
import EventTime from './EventTime'
import EventActions from './EventActions'
import { IEvent, IMatch, ITournament } from 'helpers/commonInterfaces/interfaces'

type IActionsCell = {
  startDate?: number
  providerStatus?: boolean
  clock?: number
  enrichment?: {
    animation: string
  }
  externalIds?: {
    betradarId: string
  }
  periodId?: string
  eventType?: 'Tournament' | 'Match'
  event?: IMatch
}

const ActionsCell: React.FC<IActionsCell> = ({
  startDate,
  providerStatus,
  clock,
  enrichment,
  externalIds,
  periodId,
  eventType,
  event,
}) => {
  return (
    <td className="td td--border">
      <EventTime
        color='black'
        startDate={startDate}
        providerStatus={providerStatus}
        clock={clock}
        periodId={periodId}
        eventType={eventType}
        sportId={event?.sportId}
        scoreboard={event?.additionalData?.scoreboard}
      />
      <EventActions
        id={event?.id}
        externalIds={externalIds}
        enrichment={enrichment}
        sportID={event?.sportId} />
    </td>
  )
}

export default React.memo(ActionsCell)
