import React from 'react'
import LeagueWrapper from './league/LeagueWrapper'
import { useSelector } from 'react-redux'
import { getLeaguesEvents } from '../../../../redux/selectors/inplaySelectors'
import LeagueEvents from 'components/prematchLeagueComponents/LeagueEvents'

const sortLeaguesData = (data: Array<any>): Array<any> => {
  data.forEach((sport) => {
    if (sport.priority === 0) {
      sport.priority = 200
    }
  })
  data.sort((a, b) => a.priority - b.priority)

  return data
}

const CompetitionContent = ({ data, liveStreaming, oddName, oddId, changeMarket }) => {
  const leaguesEvents = useSelector(getLeaguesEvents)
  const sortedData = sortLeaguesData(data)
  return (
    <div className="ip-competition">
      {sortedData?.map((league) => (
        <LeagueWrapper
          key={league.id}
          league={league}
          {...{ liveStreaming }}
          events={leaguesEvents?.[league.id]}
          oddName={oddName}
          oddId={oddId}
          changeMarket={changeMarket}
        />
      ))}
    </div>
  )
}

export default CompetitionContent
