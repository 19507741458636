import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStorage } from '../../../../../hooks/useStorage'
import { acceptChanges, initBetslip, setPath } from '../../../../../redux/actions/betslipActions'
import BetslipQuickbet from '../betslipComponents/BetslipQuickbet'
import BetslipBody from './BetslipBody'
import BetslipFooter from './BetslipFooter'
import { betslipLogic } from '../../../../../helpers/betslipLogic'
import BetslipEmpty from '../betslipComponents/BetslipEmpty'
import QuickbetPanel from '../betslipComponents/QuickbetPanel'
// import $ from 'jquery'
// import { niceScroll } from 'jquery.nicescroll'
import PlacedSuccess from '../../popups/PlacedSuccess'
import BetslipSettings from '../betslipComponents/BetslipSettings/BetslipSettings'
import { BetslipAccept } from './BetslipAccept'
import { CSSTransition } from 'react-transition-group'
import QuickbetPanelMobile from '../betslipComponents/QuickbetPanelMobile'
import { getCurrency, getTotalBalance } from 'redux/selectors/configSelectors'
import { floatToCurrency } from '../../../../../helpers/floatToCurrency'
import { getContextReducer } from 'redux/selectors/contextSelectors'
import { contextWorkers } from 'redux/actions/contextActions'
import { useLocation } from 'react-router'
import { BetslipOverlay } from './BetslipOverlay'

const BetslipWrapper = ({ display, quickbetPanel, quickbetToggle, data }) => {
  const dispatch = useDispatch() // for betlipLogic.js
  const balance = useSelector(getTotalBalance)
  const { getSession } = useStorage()
  const { betslip: isOpenBetslip } = useSelector(getContextReducer)
  const currencyConfig = useSelector(getCurrency)
  const location = useLocation()

  useEffect(() => {
    const cur = data.path
    if (cur) {
      const route = cur.split('->')
      if (route.pop() === location.pathname) {
        return
      }
      if (route.length >= 3) {
        const last = route.pop()
        const prev = route.pop()
        dispatch(setPath(prev + '->' + last + '->' + location.pathname))
      } else {
        dispatch(setPath(cur + '->' + location.pathname))
      }
    } else {
      dispatch(setPath(location.pathname))
    }
  }, [location])

  const [state, setState] = useState({
    quickbet: {
      st: 1.0,
      stake: `1${currencyConfig.CURRENCY_DECIMAL_SEPARATOR}00`,
    },
    settingsMode: false,
    quickbetMode: false,
    rememberStake: {
      enabled: false,
      st: 0,
      stake: '0',
    },
    totalStake: 0,
    totalToReturn: 0,
    acceptAllOddsChanges: false,
    stakes: data.bt,
    placedBt: data.placedBt,
    multistakes: {
      dm: data.dm,
      mo: data.mo,
    },
    changes: {
      checkbox: false,
      accept: false,
    },
    hidden: false,
    possibleWin: 0.0,
    betTotal: 0.0,
    placedSuccess: false,
    notLoggedIn: false,
    notEnoughFunds: false,
    loading: true,
  })

  useEffect(() => {
    const init = () => {
      if (currencyConfig) {
        dispatch(initBetslip())
      } else {
        setTimeout(init, 200)
      }
    }
    init()

    return () => {
      console.log('%creturning from useEffect (BS wrapper)', 'background: #222; color: #bada55')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const st = sessionStorage.getItem('quickbet') ?? '1'
      sessionStorage.setItem('quickbet', st)
      setState({
        ...state,
        quickbetMode: quickbetPanel,
        quickbet: {
          st: Number(st),
          stake: floatToCurrency(st, currencyConfig, true),
        },
        stakes: [],
      })
    }, 1)
  }, [quickbetPanel])

  // setting stakes from redux
  useEffect(() => {
    // add stakes
    const STdata = {
      ...data,
      bt: data.bt.filter((stake) => stake && stake.pt),
    }
    if (!STdata) {
      return
    }
    STdata.bt = data.bt.map((stake) => {
      const stateStake = state.stakes.find((stk) => stk?.pt?.[0].pi === stake?.pt[0].pi)
      if (stateStake) {
        stake.st = stateStake.st
        stake.stake = stateStake.stake
        return {
          ...stake,
        }
      } else {
        const st = state.quickbetMode
          ? state.quickbet.st
          : state.rememberStake.enabled
            ? state.rememberStake.st ?? 0
            : 0

        const stk = state.quickbetMode
          ? floatToCurrency(state.quickbet.st, currencyConfig, true, false)
          : state.rememberStake.enabled
            ? floatToCurrency(state.rememberStake.st ?? 0, currencyConfig, true, false)
            : ''
        return {
          ...stake,
          stake: stk,
          st: st,
        }
      }
    })

    // add multistakes
    // dm
    const stateDM = state.multistakes.dm
    if (stateDM && state.stakes.length === data.bt?.length) {
      STdata.dm.st = stateDM.st ?? 0
      if (!stateDM.stake) {
        STdata.dm.stake = undefined
      } else {
        STdata.dm.stake = stateDM.stake
      }
    }
    // singles
    let singles = false
    STdata.bt.forEach((stake, idx, bt) => {
      if (idx > 0) {
        if (bt[idx - 1].st == bt[idx].st) {
          singles = true
        } else {
          singles = false
          return
        }
      }
    })
    // other mo
    STdata.mo =
      data.mo?.map((stake) => {
        if (typeof stake.st !== 'undefined') {
          return {
            ...stake,
            stake: floatToCurrency(stake.st.toString(), currencyConfig, true),
          }
        } else {
          return stake
        }
      }) ?? []

    if (singles && STdata.mo.length > 0) {
      STdata.mo[0].stake = STdata.bt[0].stake
    }

    let changes =
      STdata.bt.some((bet) => bet.su === 1) ||
      STdata.bt.some((bet: any) => bet?.growth || bet?.lm) ||
      state.totalStake > Number(balance)
    const notEnoughFunds = state.totalStake > Number(balance)

    let placedSuccess = false
    let totalStake = 0
    let totalToReturn = 0
    // place bet
    if (data.betsPlaced) {
      changes = false
      placedSuccess = true
      if (
        !isOpenBetslip &&
        state.quickbetMode &&
        /* betslipReduxData.placedBt.length !== 0 && */
        !state.placedSuccess &&
        !location.pathname.includes('mybets') &&
        !state.hidden
      ) {
        dispatch(contextWorkers.toggleBetslip())
      }
      totalStake = Number(data.ts?.toFixed(2) ?? 0)
      totalToReturn = Number(data.tr?.toFixed(2) ?? 0)
    }
    if (
      // (changes && state.quickbetMode) ||
      changes &&
      state.acceptAllOddsChanges
    ) {
      dispatch(acceptChanges())
    }
    if (
      STdata.bt.length === 0 &&
      STdata.placedBt.length === 0 &&
      !state.settingsMode &&
      isOpenBetslip
    ) {
      // dispatch(contextWorkers.toggleBetslip())
    } else {
      if (STdata.bt.length > 0 && !isOpenBetslip && state.quickbetMode) {
        dispatch(contextWorkers.toggleBetslip())
      }
    }
    const newState = {
      ...state,
      loading: !data.updatesEnabled && !placedSuccess,
      quickbetMode: data.quickBet,
      stakes: STdata.bt,
      multistakes: {
        dm: STdata.dm,
        mo: STdata.mo,
      },
      placedBt: STdata.placedBt,
      changes: { ...state.changes, accept: !changes },
      placedSuccess: placedSuccess,
      errorMessage: data.er,
      notEnoughFunds,
      totalStake,
      totalToReturn,
      hidden: false,
    }
    setState(newState)
    calcTotal(newState)
  }, [data])

  useEffect(() => {
    const checkbox = getSession('acceptAll') ?? false
    const quickbet = getSession('quickbet') ?? 0
    setState({
      ...state,
      quickbet: {
        st: quickbet,
        stake: floatToCurrency(quickbet.toString(), currencyConfig),
      },
      changes: {
        ...state.changes,
        checkbox: checkbox,
      },
    })
  }, [])

  const {
    calcTotal,
    onInputChangeHandler,
    onFocusHandler,
    onInputBlur,
    removeBetHandler,
    removeAllBetsHandler,
    onQuickbetBtnHandler,
    onAcceptChangesHandler,
    onAcceptHandler,
    placeBetHandler,
    toggleSettingsHandler,
    toggleAcceptAllOddsChangesHandler,
    toggleQuickbetModeHandler,
    toggleRememberStakeHandler,
    popupAccept,
  } = betslipLogic(state, setState, dispatch, currencyConfig)

  let quickbetValue = '0'

  if (typeof state.quickbet.stake !== 'undefined') {
    quickbetValue = state.quickbet.stake
  }

  return (
    <div style={{ display: display ? 'block' : 'none', position: 'relative' }}>
      {/* <BetslipQuickbet {...{ quickbetPanel }} {...{ quickbetToggle }} /> */}
      {state.loading && <BetslipOverlay />}
      {!state.placedSuccess && (
        <CSSTransition
          in={state.quickbetMode}
          timeout={300}
          classNames={'qb_slide'}
          appear
          mountOnEnter
          unmountOnExit
        >
          <QuickbetPanelMobile
            value={quickbetValue}
            quickbet={state.quickbet}
            quickbtnclick={onQuickbetBtnHandler}
            change={onInputChangeHandler}
            blur={onInputBlur}
            focus={onFocusHandler}
          />
        </CSSTransition>
      )}
      {state.placedSuccess && state.stakes.length === 0 ? (
        <BetslipAccept
          {...{ removeAllBetsHandler }}
          stakes={state.placedBt}
          multistakes={state.multistakes}
          possibleWin={state.totalToReturn}
          betTotal={state.totalStake}
        />
      ) : state.stakes.length === 0 ? (
        <>
          <BetslipEmpty quickbetMode={state.quickbetMode} />
          <BetslipSettings
            settingsMode={state.settingsMode}
            quickbetMode={state.quickbetMode}
            rememberStake={state.rememberStake}
            check={onAcceptHandler}
            changes={state.changes}
            {...{ quickbetPanel }}
            {...{ quickbetToggle }}
            acceptAllOddsChanges={state.acceptAllOddsChanges}
            {...{ toggleSettingsHandler }}
            {...{ toggleAcceptAllOddsChangesHandler }}
            {...{ toggleQuickbetModeHandler }}
            {...{ toggleRememberStakeHandler }}
          />
          {/* state.placedSuccess && <PlacedSuccess {...{ popupAccept }} /> */}
        </>
      ) : (
        <>
          <BetslipBody
            changes={state.changes}
            possibleWin={state.possibleWin}
            betTotal={state.betTotal}
            // quickbetValue={quickbetValue}
            removeBetHandler={removeBetHandler}
            stakes={state.stakes}
            multistakes={state.multistakes}
            settingsMode={state.settingsMode}
            quickbetMode={state.quickbetMode}
            rememberStake={state.rememberStake}
            acceptAllOddsChanges={state.acceptAllOddsChanges}
            // quickbetPanel={quickbetPanel}
            blur={onInputBlur}
            focus={onFocusHandler}
            {...{ quickbetToggle }}
            change={onInputChangeHandler}
            // quickbtnclick={onQuickbetBtnHandler}
            onAcceptHandler={onAcceptHandler}
            removeAll={removeAllBetsHandler}
            {...{ toggleSettingsHandler }}
            {...{ toggleAcceptAllOddsChangesHandler }}
            {...{ toggleQuickbetModeHandler }}
            {...{ toggleRememberStakeHandler }}
            {...{ quickbetPanel }}
            placedSuccess={state.placedSuccess}
          />
          <BetslipFooter
            changes={state.changes}
            acceptAllOddsChanges={state.acceptAllOddsChanges}
            placed={state.placedSuccess}
            notEnoughFunds={state.notEnoughFunds}
            notLoggedIn={state.notLoggedIn}
            {...{ onAcceptChangesHandler }}
            {...{ placeBetHandler }}
            {...{ popupAccept }}
            total={state.betTotal}
          />
        </>
      )}
    </div>
  )
}

export default React.forwardRef(BetslipWrapper)
