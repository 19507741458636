import React from 'react'
import SportIcon from '../../elements/SportIcon'

const CompetitionHeaderTitle = ({ name, id }) => {
  return (
    <div className="flex align-middle ip-classification-header__sports">
      <div className="ip-classification-header__icon m-r-10">
        <SportIcon name={name} id={id} />
      </div>
      <p className="font ip-classification-header__text">{name}</p>
    </div>
  )
}

export default React.memo(CompetitionHeaderTitle)
