import React from 'react'

const Messages = () => {
	return (
		<div className="betslip__message">
			<div className="text-center">
				<p className="font font--blue">
					The line, odds or availability of your selections has changed.
				</p>
			</div>
		</div>
	)
}

export default Messages
