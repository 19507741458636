import React, { useEffect } from 'react'
import PrematchLeagueWrapper from '../../components/prematchLeagueComponents/PrematchLeagueWrapper'
import Center from '../../components/containers/main/Center'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { prematchLeagueWatchers } from 'redux/actions/prematchLeagueActions'
import { getAllSports, getCurrent } from 'redux/selectors/prematchSelectors'
import { prematchWorkers } from 'redux/actions/prematchActions'

const PrematchLeague = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params: { leagueId?: string; sportId?: string } = useParams()

  const prematchSport = useSelector(getCurrent)
  const prematchAll = useSelector(getAllSports)

  useEffect(() => {
    if (prematchSport && prematchSport.id !== Number(params.sportId)) {
      dispatch(prematchWorkers.setCurrentSport(prematchAll?.find(sport =>
        sport.id === Number(params.sportId)) ?? null
      ))
    }
    params.leagueId && params.sportId
      ? dispatch(prematchLeagueWatchers.getLeague(params.sportId, params.leagueId))
      : history.push('/sports/main')
    // return () => {
    // 	dispatch(prematchLeagueWatchers.clearLeague())
    // }
  }, [params, dispatch, history])

  return (
    <Center>
      <PrematchLeagueWrapper />
    </Center>
  )
}

export default PrematchLeague
