import React from 'react'

const SportsTableElem = ({ item }) => {
  const { CL, NA, SM } = item

  return (
    <table className="ips-table">
      <tbody>
        <tr className="tr">
          <td className="td">
            <p className="font font--default-alpha-05">{CL}</p>
          </td>
          <td className="td">
            <div className="flex align-middle align-justify">
              <p className="font text-ellipsis m-r-5" />
              <a className="favourite favourite--dark" />
            </div>
          </td>
          <td className="td">
            <div className="flex align-middle">
              <p className="font text-ellipsis">{NA}</p>
            </div>
          </td>
          <td className="td">
            <div className="c-icon c-icon--play" />
          </td>
          <td className="td">
            <p className="font font--12 font--semibold">{SM}</p>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default SportsTableElem
