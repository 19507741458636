import React from 'react'

const generateKey = pre => {
	return `${pre}_${new Date().getTime()}`
}

const MultDropUp = props => {
	return (
		<div
			onClick={props.click}
			className={`betslip__drop betslip__drop--${props.arrow}`}>
			<div className="flex align-middle align-justify full-height">
				<p className="font font--12 font--default-alpha-05">
					Show all multiples
				</p>
				<div className="fal fa-angle-right"></div>
			</div>
		</div>
	)
}

export default MultDropUp
