import React, { useEffect, useLayoutEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import ClassificationItemHeader from './ClassificationItemHeader'
import ClassificationItemBody from './ClassificationItemBody'

import { CSSTransition } from 'react-transition-group'

interface IProps {
  data: {
    id: number
    name: string
  }
  liveTranslation: boolean
  open: boolean | undefined
  addOpenedSport?: (id: number) => void
}
const ClassificationItem: React.FC<IProps> = (props) => {
  const location = useLocation()
  const { data, open, addOpenedSport, liveTranslation } = props

  useLayoutEffect(() => {
    const sportId = location.pathname.split('/')[3]
    if (Number(sportId) === data.id && addOpenedSport && !open) {
      addOpenedSport(data.id)
    }
  }, [])

  return (
    <>
      <ClassificationItemHeader title={data.name} id={data.id} />
      <CSSTransition in={open} timeout={300} unmountOnExit={true} mountOnEnter={true}>
        <ClassificationItemBody data={data} {...{ liveTranslation }} /* isFavourite={false} */ />
      </CSSTransition>
    </>
  )
}

export default React.memo(ClassificationItem)
