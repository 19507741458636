import { floatToCurrency } from 'helpers/newFloatToCurrency'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getAccount } from 'redux/selectors/accountSelectors'
import { getConfig, getCurrency } from 'redux/selectors/configSelectors'

const UserCabinet = () => {
  const { CUSTOMER_CONFIG, balance } = useSelector(getConfig)
  const { verified, showMoney } = useSelector(getAccount)
  const currencyConfig = useSelector(getCurrency)

  return (
    <div className="cb-account">
      <div className="cb-account__presonal">
        <div className="flex full-height cb-account__identify">
          <div className="cb-account__img">
            <img src="https://via.placeholder.com/30x30/F4F4F4" alt="30x30" />
          </div>
          <div className="cb-account__cell">
            <div className="text-left">
              <p className="font font--white font--11">{CUSTOMER_CONFIG?.FULL_NAME ?? 'Unknown'}</p>
              <p className={"font font--white-alpha-05 font--11" +
                (!CUSTOMER_CONFIG?.FULL_NAME && CUSTOMER_CONFIG?.FULL_NAME !== ' '
                  ? " m-t-5"
                  : '')}>
                <span className="m-r-5">id</span>
                <span className="font--white">{CUSTOMER_CONFIG?.USER_NAME ?? 'Unknown'}</span>
              </p>
            </div>
          </div>
          <div className="cb-account__cell">
            <NavLink to={'/account/my-account/account-verification'}>
              <div className="text-right" style={{ cursor: 'pointer' }}>
                <p className={'font font--11' + (verified ? ' font--green' : ' font--red')}>
                  {verified ? 'Verified' : 'Not verified'}
                </p>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="flex full-height cb-account__date">
          <div className="cb-account__cell">
            <div className="text-left">
              <p className="font font--white-alpha-05 font--11">last login date</p>
            </div>
          </div>
          <div className="cb-account__cell">
            <div className="text-right">
              <p className="font font--white font--11"></p>
            </div>
          </div>
        </div>
      </div>
      <hr className="cb-account__hr" />
      {showMoney && balance && (
        <div className="cb-account__payements">
          <div className="flex m-t-10">
            <div className="cb-account__cell">
              <p className="font font--white-alpha-05 font--11">Account Balance</p>
            </div>
            <div className="cb-account__cell">
              <div className="text-right">
                <p
                  className="font font--white"
                  dangerouslySetInnerHTML={{
                    __html: floatToCurrency(balance.total, currencyConfig, true, true),
                  }}>
                </p>
              </div>
            </div>
          </div>
          <div className="flex m-t-10">
            <div className="cb-account__cell">
              <p className="font font--white-alpha-05 font--11">Main Balance</p>
            </div>
            <div className="cb-account__cell">
              <div className="text-right">
                <p
                  className="font font--white"
                  dangerouslySetInnerHTML={{
                    __html: floatToCurrency(balance.main, currencyConfig, true, true),
                  }}
                >
                </p>
              </div>
            </div>
          </div>
          <div className="flex m-t-10">
            <div className="cb-account__cell">
              <p className="font font--white-alpha-05 font--11">Bonuses</p>
            </div>
            <div className="cb-account__cell">
              <div className="text-right">
                <p
                  className="font font--white"
                  dangerouslySetInnerHTML={{
                    __html: floatToCurrency(balance.bonus, currencyConfig, true, true),
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="cb-account__progress">
        <div style={{ width: '50%' }}></div>
      </div>
      <div className="cb-account__footer">
        <NavLink to={'/account/balance/deposit'}>
          <button className="btn btn--h-40 btn--green">
            <span className="font font--12 font--white font--semibold">Deposit</span>
          </button>
        </NavLink>
      </div>
    </div>
  )
}

export default UserCabinet
