import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { favoritesWorkers } from 'redux/actions/favoritesActions'
import { getFavorites } from 'redux/selectors/favoritesSelectors'
import { Flag } from '../../../Flag'

const TitleCell = ({ id, sportId, iconCode, name }) => {
  const dispatch = useDispatch()
  const favorites = useSelector(getFavorites)
  const toggleFavLeague = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (favorites.leagues[sportId]?.some(lg => lg === id.toString())) {
      dispatch(
        favoritesWorkers.removeLeague(
          id.toString(),
          sportId.toString()
        )
      )
    } else {
      dispatch(
        favoritesWorkers.addLeague(id.toString(), sportId.toString())
      )
    }
  }

  return (
    <td className="td">
      <div className="flex align-middle">
        <Flag
          className="ip-competition__country m-r-10"
          style={{
            width: '30px',
            height: '25px',
          }}
          iconCode={iconCode}
        />
        <p className="font font--semibold text-ellipsis m-r-10">{name}</p>
        <a
          className={"ip-competition__favourite" + (Object.values(favorites.leagues).some((sport: any[]) => sport.some(lg => lg === id.toString())) ? '--active' : '')}
          onClick={toggleFavLeague} />
      </div>
    </td>
  )
}

export default React.memo(TitleCell)
