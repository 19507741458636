import { Nullable, ISite, ICustomer, IBalance } from '../../helpers/commonInterfaces/interfaces'
import { ConfigWorkersType } from '../actions/configActions'
import { types } from '../types/types'

const initialState = {
  SITE_CONFIG: null as Nullable<ISite>,
  CUSTOMER_CONFIG: null as any, //null as Nullable<ICustomer>,
  balance: null as Nullable<IBalance>,
  oddTypes: null as Nullable<any[]>,
  locales: {},
}

export type IState = typeof initialState

export const config = (state = initialState, action: ConfigWorkersType): IState => {
  switch (action.type) {
    case types.SET_CONFIG:
      return {
        ...state,
        ...action.payload,
      }
    case types.SET_CUSTOMER_CONFIG:
      return {
        ...state,
        CUSTOMER_CONFIG: {
          ...state.CUSTOMER_CONFIG,
          ...action.payload,
        },
      }

    case types.SET_LOCALES:
      return {
        ...state,
        locales: action.payload,
      }
    case types.SET_BALANCE:
      return {
        ...state,
        balance: action.payload,
      }
    case types.SET_ODD_TYPES:
      return {
        ...state,
        oddTypes: action.payload,
      }
    default:
      return state
  }
}
