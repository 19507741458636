import { types } from '../types/types'
import { IBet, InferType } from '../../helpers/commonInterfaces/interfaces'

export const mybetsWatchers = {
  getMybets: (id = 0) =>
    ({
      type: types.GET_MYBETS_WATCHER,
      payload: { id },
    } as const),
  cashout: (payload: { amount: number; id: number }) =>
    ({
      type: types.CASHOUT,
      payload,
    } as const),
  createRule: (payload: { amount: number; id: number }) =>
    ({
      type: types.CREATE_RULE,
      payload,
    } as const),
  setLoading: (loading: boolean) =>
    ({
      type: types.SET_MY_BETS_LOADING,
      payload: loading,
    } as const),
}

export const mybetsWorkers = {
  getMybets: ({ data, type }: { data: Array<IBet>; type: number }) =>
    ({
      type: types.GET_MYBETS,
      payload: { data, type },
    } as const),
  setCashout: (payload: {
    status: number
    errors: {
      text: string
      code: number
    }
    ID: number
  }) =>
    ({
      type: types.SET_CASHOUT,
      payload,
    } as const),
  setLoading: (loading: boolean) =>
    ({
      type: types.SET_MY_BETS_LOADING,
      payload: loading,
    } as const),
}

export type MyBetsWorkersTypes = ReturnType<InferType<typeof mybetsWorkers>>
