import React from 'react'

const PopularItem = () => {
	return (
		<div className="wn-popular__item wn-popular__item--active">
			<p className="font font--grey-300 text-ellipsis">Algeria League Women</p>
		</div>
	)
}

export default PopularItem
