export const debounce = (fn: (...args: any[]) => void, ms: number) => {
  let timer: ReturnType<typeof setTimeout> | null
  return () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      // @ts-ignore
      fn.apply(this, arguments)
    }, ms)
  }
}

export function debounceGen(fn: (...args: any[]) => void, args: any[], ms: number) {
  let timer: ReturnType<typeof setTimeout> | null
  return () => {
    if (timer) {
      clearTimeout(timer)
    }
    console.log(`created a timer for `, fn.name)
    timer = setTimeout(function () {
      timer = null
      console.log(`trying to yield `, fn.name)
      function* gen() {
        yield fn.apply(this, args)
      }
      console.log(gen().next().value)
    }, ms)
  }
}
