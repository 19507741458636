import { call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { END, eventChannel } from 'redux-saga'
import { sortInplayData } from '../../../../helpers/utils/utils'
import { getCurrentActive, getLeaguesEvents } from '../../../selectors/inplaySelectors'
import { get } from '../../../../helpers/asyncRequests'
import { inplayWorkers } from '../../../actions/inplayActions'
import { links } from '../../../../connection/links'
import { types } from '../../../types/types'
import {
  IMatch,
  ISport,
  ITournament,
  Nullable,
  Obj,
  Odd,
} from '../../../../helpers/commonInterfaces/interfaces'
import { createSubscriptionChannel, unsubscribe } from '../../subscriptionHandler'
import _ from 'lodash'
import { store } from '../../../store'
import { emitterByCmd } from '../../../utils/emitter'
import { api } from 'connection/api'

function isIterable(obj: any) {
  // checks for null and undefined
  if (obj == null) {
    return false
  }
  return typeof obj['filter'] === 'function'
}

const liveStreamingShortPull = () => {
  return eventChannel((emitter) => {
    const interval = setInterval(async () => {
      const data = await get(links.matchLiveStreaming)()
      if (data) {
        emitter(data)
      }
    }, 60000)

    return () => {
      emitter(END)
      clearInterval(interval)
    }
  })
}

function* findAndSetCurrentView(data: ISport[] | null) {
  const active = yield select(getCurrentActive)

  if (data) {
    const currentSport = data.find((sport: ISport) => sport.id === active)
    yield put(inplayWorkers.setCurrentView(currentSport || data[0]))
  }
}

export function* inplayTreeWorker(action: any) {
  try {
    yield fork(enableShortPull, action.data)
  } catch (e) {
    console.error(e)
  }
}

// inplay-tree subscription
function* enableShortPull(type: 'main' | 'live') {
  const config = yield select((state) => state.config)
  const updateChannel = yield call(
    createSubscriptionChannel,
    type === 'main' ? '/feedapi/?key=main-inplay-tree' : '/feedapi/?key=inplay-tree',
    {},
    type === 'main' ? 'main-inplay-tree' : 'inplay-tree',
    [
      (emitter, res) => {
        if (isIterable(res.data)) {
          const data = res.data
            .filter((sport: any) => sport?.categories && sport.inPlayMatchCount > 0)
            .filter(
              (sport) => !config.SITE_CONFIG.SITE_EXCLUDED_SPORTS.some((id) => id === sport.id)
            )
          emitter(data)
        }
      },
    ],
    3500,
    config
  )

  yield fork(function* () {
    yield take(types.DISABLE_INPLAY_UPDATE_WATCHER)
    unsubscribe(
      '/feed/?key=inplay-tree',
      type === 'main' ? 'main-inplay-tree' : 'inplay-tree',
      3500,
      config
    )
  })

  try {
    while (true) {
      const data = yield take(updateChannel)
      // const dataM = data.map(sport => {
      //   sport.categories = sport.categories.map(category => {
      //     category.leagues = category.leagues.map(league => {
      //       league.banners = [
      //         {
      //           bannerID: 1,
      //           position: 0,
      //         },
      //       ]
      //       return league
      //     })
      //     return category
      //   })
      //   return sport
      // })
      yield put(inplayWorkers.setInplayData(sortInplayData(data)))
      yield fork(findAndSetCurrentView, data)
    }
  } catch (e) {
    console.log(`ERRROR `, e)
    yield cancel()
    updateChannel.close()
  }
}

function* setCurrentView({ payload }: { payload: ISport; type: string }) {
  const inplay = yield select((state) => state.inplay)
  const newInplay = { ...inplay, currentView: payload }
  yield put(inplayWorkers.setChosenLeagues(null))
  yield put(inplayWorkers.setCurrentView(payload))
  yield put(inplayWorkers.setCurrentOddName(null))
  yield put(inplayWorkers.setCurrentOddId(null))
  yield put(inplayWorkers.setAllOddsNames(null))
  yield put(inplayWorkers.setAllOddsIds(null))
  yield getAdditionalData(
    {
      events: Object.values(inplay.leaguesEvents).filter(
        (events: Array<IMatch>) => events[0]?.sportId === payload.id
      ),
    },
    newInplay
  )
}

//subscription on all inplay events
function* subscriptionOnInplayAll() {
  try {
    const config = yield select((state) => state.config)
    const leaguesEvents = yield select((state) => state.inplay.leaguesEvents)
    const channel = yield call(
      createSubscriptionChannel,
      api.inplayAll,
      {},
      'inplay-all',
      [
        (emitter, rs) => {
          const oldState = _.cloneDeep(store.getState().inplay)
          emitterByCmd(oldState, rs, emitter)
        },
      ],
      3000,
      config
    )

    yield fork(function* () {
      yield take(`DESTROY_INPLAY_ALL`)
      unsubscribe(api.inplayAll, 'inplay-all', 3000, config)
    })

    try {
      while (true) {
        const res = yield take(channel)
        yield distributeLeagueEvents(res)
      }
    } catch (e) {
      channel.close()
    }
  } catch (e) {
    console.log(e)
  }
}

function* distributeLeagueEvents(response) {
  const events: Array<IMatch | ITournament> = response.events
  const leagueEvents = {}
  for (const ev of events) {
    if (ev.eventType === 'Tournament') {
      continue
    }
    if (leagueEvents[ev.leagueId.toString()]) {
      leagueEvents[ev.leagueId.toString()].push(ev)
    } else {
      leagueEvents[ev.leagueId.toString()] = [ev]
    }
  }
  const inplay = yield select((state) => state.inplay)
  const { allOddsName, currentOddName } = inplay.currentView
  if (!allOddsName || !currentOddName) {
    const events = Object.values(leagueEvents).find(
      (events: any[]) => events[0].sportId === inplay.active
    )
    console.log(`events`, events)
    yield getAdditionalData(
      {
        events,
      },
      inplay
    )
  }
  yield put(inplayWorkers.setLeaguesEvents(leagueEvents))
}

function* loadLeagueEvents({ id }: { id: number; type: string }) {
  try {
    console.log('load Leagues')
    /*------------Creating channel---------------*/

    const config = yield select((state) => state.config)

    const channel = yield call(
      createSubscriptionChannel,
      '/feedapi/?key=inplay-league&leagueId=' + id,
      {
        leagueId: id,
      },
      'inplay-league-' + id,
      [
        (emitter, rs) => {
          const oldState = _.cloneDeep(store.getState().inplay)
          emitterByCmd(oldState, rs, emitter)
        },
      ],
      1000,
      config
    )

    yield fork(function* () {
      yield take(`DESTROY_LEAGUE_${id}`)
      unsubscribe('/feed/?key=inplay-league&leagueId=' + id, 'inplay-league-' + id, 100, config)
    })

    try {
      while (true) {
        const res = yield take(channel)
        yield setLeagueEvents(res, id)
        const inplay = yield select((state) => state.inplay)
        const { allOddsName, currentOddName } = inplay.currentView
        if (!allOddsName || !currentOddName) {
          yield getAdditionalData(res, inplay)
        }
      }
    } catch (e) {
      channel.close()
    }
  } catch (e) {
    console.log(e)
  }
}

function* getAdditionalData({ events }, inplay) {
  const currentOddName = inplay.currentView.currentOddName
  const currentOddId = inplay.currentView.currentOddId
  // events = events.reduce((acc, { data }) => {
  //   acc = [...acc, ...data.events]
  //   return acc
  // }, [])
  try {
    const {
      allOddsName: oddsName,
      currentOddName: oddName,
      allOddsId: oddsId,
      currentOddId: oddId,
    } = getAdditionalDataForCurrentSport(events)
    // console.log(`allOddsName`, oddsName)
    // console.log(`oddName`, oddName)
    // console.log(`oddsId`, oddsId)
    // console.log(`oddId`, oddId)
    yield put(inplayWorkers.setCurrentOddName(currentOddName ?? oddName))
    yield put(inplayWorkers.setCurrentOddId(currentOddId ?? oddId))
    yield put(inplayWorkers.setAllOddsNames(oddsName))
    yield put(inplayWorkers.setAllOddsIds(oddsId))
  } catch (e) {
    console.log(`Error in getAdditional data:`, e)
  }

  return
}

export function getAdditionalDataForCurrentSport(events) {
  const set = new Set()
  const idSet = new Set()
  events &&
    events.forEach((event) =>
      event.odds
        ? event.odds?.forEach((odd) => {
            set.add(odd.name)
            idSet.add(odd.id)
          })
        : null
    )

  const allOddsName = [...set.values()]
  const allOddsId = [...idSet.values()]

  const currentOddName =
    //@ts-ignore
    allOddsName.find((odd: Odd) => odd === '3 way ' || odd === '2 Way') ?? allOddsName[0] ?? null
  const currentOddId =
    //@ts-ignore
    allOddsId.find((odd: Odd) => odd === 1 || odd === 175 || odd === 206) ?? allOddsId[0] ?? null

  return {
    allOddsId,
    allOddsName,
    currentOddName,
    currentOddId,
  }
}

function* setLeagueEvents(res, id) {
  const { events } = res

  yield put(inplayWorkers.setLeague({ id, data: events }))
}

function* destroyLeague({ id }: { id: number; type: string }) {
  const leagues = yield select(getLeaguesEvents)
  /* if (leagues) {
    const newLeagues = Object.keys(leagues).reduce((acc: Obj<Array<IMatch | ITournament>>, key) => {
      if (key !== String(id)) {
        acc[key] = leagues[key]
      }

      return acc
    }, {})
    yield put(inplayWorkers.destroyLeague(newLeagues))
  } */
}

function* toggleChosenLeague({ payload }: { payload: number; type: string }) {
  const chosenLeagues = yield select((state) => state.inplay.currentView.chosenLeagues)
  if (!chosenLeagues) {
    yield put(inplayWorkers.setChosenLeagues([payload]))
    return
  }
  if (chosenLeagues?.some((lg) => lg === payload)) {
    yield put(inplayWorkers.setChosenLeagues(chosenLeagues.filter((lg) => lg !== payload)))
  } else {
    chosenLeagues.push(payload)
    yield put(inplayWorkers.setChosenLeagues(chosenLeagues))
  }
}

function* setChosenLeagues({ ids }: { ids: Nullable<number[]>; type: string }) {
  yield put(inplayWorkers.setChosenLeagues(ids))
}

function* setStreaming() {
  try {
    const data = yield call(get(links.matchLiveStreaming))
    yield put(inplayWorkers.setStreamingEvents(data))

    yield fork(liveStreamingUpdate)
  } catch (e) {
    console.log(e)
  }
}

function* liveStreamingUpdate() {
  const updateChannel = yield call(liveStreamingShortPull)

  yield fork(function* () {
    yield take(types.DISABLE_STREAMING_EVENTS_UPDATE)
    updateChannel.close()
  })

  try {
    while (true) {
      const data = yield take(updateChannel)
      yield put(inplayWorkers.setStreamingEvents(data))
    }
  } catch (e) {
    updateChannel.close()
  }
}

export const overviewSagas = [
  takeEvery(types.GET_INPLAY_TREE, inplayTreeWorker),
  takeEvery(types.SET_CHOSEN_LEAGUES_WATCHER, setChosenLeagues),
  takeEvery(types.TOGGLE_CHOSEN_LEAGUE, toggleChosenLeague),
  takeEvery(types.DESTROY_LEAGUE_WATCHER, destroyLeague),
  takeEvery(types.LOAD_LEAGUE_EVENTS_WATCHER, subscriptionOnInplayAll),
  takeEvery(types.SET_CURRENT_VIEW_WATCHER, setCurrentView),
  takeEvery(types.GET_STREAMING_EVENTS, setStreaming),
]
