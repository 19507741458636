import { AsideContext } from 'context/asideLeftContext'
import { ISport } from 'helpers/commonInterfaces/interfaces'
import React, { useContext, useEffect, useRef, useState } from 'react'

type ClassificationItemWrapProps = {
  sportCategoryHandler: ((e: any, data: any) => void) | undefined
  data: ISport
  open: boolean
  children
}
const ClassificationItemWrap = React.forwardRef(function ClassificationItemWrap(
  { sportCategoryHandler, data, open, children }: ClassificationItemWrapProps,
  ref
) {
  const { small, dataForAsideSmall } = useContext(AsideContext)
  const createSportName = (name) => 'ipn-classification--' + name.split(' ').join('_').toLowerCase()
  const refer = ref as React.MutableRefObject<HTMLInputElement>

  const curSport = () => {
    return window.location.href.split('/')[5] === data.id.toString()
  }

  const [isOpened, setOpen] = useState(curSport())

  const onClick = (e) => {
    e.stopPropagation()
    if (sportCategoryHandler) {
      sportCategoryHandler(e, data)
    }
    setOpen(!isOpened)
  }

  const name = createSportName(data.name)

  useEffect(() => {
    setOpen(open || curSport())
  }, [open])

  return (
    <div
      ref={refer}
      onClick={onClick}
      className={
        (isOpened /* || dataForAsideSmall?.id === data.id */
          ? `ipn-classification ipn-classification--down ${name}`
          : `ipn-classification ${name}`)
        + (open ? ' arrow-up' : '')
      }
    >
      {children}
    </div>
  )
})

export default ClassificationItemWrap
