import React, { useContext, useEffect } from 'react'
import { modifyBets } from '../../../../../helpers/modifyBets' // set coeficient in user-config's format
import { ConfigContext } from '../../../../../context/globalConfig'
import { floatToCurrency } from '../../../../../helpers/floatToCurrency'
import { useSelector } from 'react-redux'
import { getConfig, getCurrency, getOddsType } from 'redux/selectors/configSelectors'

const Item = (props) => {
  const ODDS_TYPE = useSelector(getOddsType)
  const { CUSTOMER_CONFIG } = useSelector(getConfig)
  const currencyConfig = useSelector(getCurrency)
  const toReturn = (parseFloat(props.item.st) * parseFloat(modifyBets(props.item.od, '2'))).toFixed(
    2
  )
  const transformBetName = (name) => {
    if (name) {
      name = name.replace(/{\$competitor1}/gi, props.item.fd.split(' v ')[0])
      name = name.replace(/{\$competitor2}/gi, props.item.fd.split(' v ')[1])
      name = name.replace(/{\+hcp}/gi, props.item.pt[0].ha)
      name = name.replace(/{-hcp}/gi, props.item.pt[0].ha)
      name = name.replace(/{total}/gi, props.item.pt[0].ha)
      name = name.replace(/{!goalnr}/gi, props.item.pt[0].ha)
    }
    return name
  }

  return (
    <div className={props.className}>
      <div
        role="button"
        className="betslip__close fal fa-times"
        onClick={props.removeBetHandler(props.inputid)}
        onKeyPress={() => 0}
        tabIndex={0}
      />
      <p className="font font--semibold p-r-10">
        {
          transformBetName(props.item.pt[0].bd) /* + */
          /* (props.item.pt[0].hd ? ' ' + props.item.pt[0].ha : '')) */
        }
      </p>
      <div className="flex align-middle align-justify m-t-10">
        <p className="font font--12 font--blue">{transformBetName(props.item.pt[0].md)}</p>
        <p
          className={
            props.item.growth
              ? `font font--blue font--semibold betslip__odds--${props.item.growth}`
              : 'font font--blue font--semibold betslip__odds'
          }
        >
          {modifyBets(props.item.od, ODDS_TYPE) === '' ||
            modifyBets(props.item.od, ODDS_TYPE) === 'NaN'
            ? ''
            : modifyBets(props.item.od, ODDS_TYPE)}
        </p>
      </div>
      <p className="font font--11 font--default-alpha-05 m-t-10">{props.item.fd}</p>
      <div className="flex align-middle align-justify m-t-10">
        <p className="font font--12 font--default-alpha-05">Bet amount:</p>
        <div className="betslip__amount" style={{ width: 'auto' }}>
          <input
            className="field"
            data-key={props.inputid}
            type="text"
            maxLength={12}
            placeholder="0"
            value={props.value}
            onChange={props.change}
            onBlur={props.blur}
            onFocus={props.focus}
          />
          {/* <hr />
          <div className="button--max font font--11">Max</div> */}
        </div>
      </div>
      {!isNaN(Number(toReturn)) && Number(toReturn) !== 0 && (
        <div className="flex align-middle align-justify m-t-10">
          <p className="font font--12">Possible win:</p>
          <p
            className="font font--12"
            dangerouslySetInnerHTML={{
              __html: floatToCurrency(toReturn, currencyConfig, true, true),
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Item
