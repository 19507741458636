import React, { useEffect, useState } from 'react'

const Time = () => {
  const [time, setTime] = useState('')

  useEffect(() => {
    const timer = setInterval(() => {
      const date = new Date().toLocaleTimeString()
      setTime(date)
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  return (
    <div
      className="h-submenu__item"
      style={{
        minWidth: '100px',
      }}
    >
      <p className="font font--12 h-submenu__text">{time} GMT+3</p>
    </div>
  )
}

export default Time
