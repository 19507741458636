import React, { useState, useEffect } from 'react'
import NavBarItem from './NavBarItem'
import { useLocation } from 'react-router-dom'

const NavBarLink = ({ item }) => {
	const [active, setActive] = useState(false)

	const click = () => setActive(!active)

	let location = useLocation()
	useEffect(() => {
		item.innerLinks?.forEach(item => {
			if (item.path === location.pathname) {
				setActive(true)
			}
		})
	}, [location])

	return (
		<li
			className={
				(item.notification ? 'cb-navbar__item--notification ' : '') +
				(active
					? 'cb-navbar__item cb-navbar__item--dropdown cb-navbar__item--active'
					: 'cb-navbar__item cb-navbar__item--dropdown')
			}>
			{item.notification && <div className="cb-navbar__notification"></div>}
			<span
				onClick={click}
				className="font font--white font--semibold cb-navbar__link">
				{item.title}
			</span>
			<ul className="cb-navbar">
				{item.innerLinks?.map((item, i) => (
					<NavBarItem key={i} {...{ item }} />
				))}
			</ul>
		</li>
	)
}

export default NavBarLink
