export function scrollToBottom(
  element: Element,
  to: number,
  duration: number
): undefined {
  if (duration <= 0) return
  const difference = to - element.scrollTop
  const perTick = (difference / duration) * 10

  setTimeout(function () {
    element.scrollTop = element.scrollTop + perTick
    if (element.scrollTop === to) return
    scrollToBottom(element, to, duration - 10)
  }, 10)
}

export function scrollToTop(
  element: Element,
  to: number,
  duration: number
): undefined {
  if (duration <= 0) return
  const difference = to - element.scrollTop
  const perTick = (difference / duration) * 10

  setTimeout(function () {
    element.scrollTop = element.scrollTop + perTick
    if (element.scrollTop === to) return
    scrollToTop(element, to, duration - 10)
  }, 10)
}
