export class MatchTimer {
	remainingTime
	periods
	
	constructor() {
		this.remainingTime = null
		this.periods = {
			6: 0,
			7: 45,
			41: 90,
			42: 105,
		}
	}

	addZero = value => {
		value = Math.floor(value)

		if (value < 10) {
			return `0${value}`
		}

		return `${value}`
	}

	remainingTimer = ({ clock, periodId }) => {
		const { remainingTime, stopped } = clock.data

		const { data = 6 } = periodId || {}

		if (data === 100) {
			return 'Match End'
		}

		if (stopped) {
			if (!remainingTime) {
				return ''
			}

			const [minutes, seconds] = remainingTime.split(':')

			return `${this.addZero(Number(minutes))}:${this.addZero(Number(seconds))}`
		}

		if (this.remainingTime) {
			const [minutes, seconds] = this.remainingTime.split(':')

			if (Number(minutes) === 0 && Number(seconds) === 0) {
				return '00:00'
			}

			let time = Number(minutes) * 60 + Number(seconds)

			time = time - 1

			const currentMinutes = Math.floor(time / 60)
			const currentSeconds = time - currentMinutes * 60

			this.remainingTime = `${this.addZero(currentMinutes)}:${this.addZero(
				currentSeconds
			)}`

			return this.remainingTime
		}

		if (!this.remainingTime) {
			if (remainingTime) {
				const [minutes, seconds] = remainingTime.split(':')

				if (Number(minutes) === 0 && Number(seconds) === 0) {
					return '00:00'
				}

				this.remainingTime = `${this.addZero(Number(minutes))}:${this.addZero(
					Number(seconds)
				)}`

				return this.remainingTime
			}
		}

		return ''
	}

	defaultTimer = ({ clock, periodId }) => {
		const { playingMinute, currentPeriodStartTimestamp } = clock.data

		const { data = 6 } = periodId || {}

		if (data === 100) {
			return 'Match End'
		}

		if (playingMinute) {
			return ('0' + playingMinute).substr(-2) + ':00'
		}

		if (currentPeriodStartTimestamp) {
			const timestamp =
				new Date().getTime() - clock.data.currentPeriodStartTimestamp

			const date = timestamp / 1000

			const minutes = date / 60 + (this.periods[periodId?.data || 0] || 0)

			const seconds = date % 60

			return `${this.addZero(minutes)}:${this.addZero(seconds)}`
		}
		return ''
	}

	clearRemainingTime = () => {
		this.remainingTime = null
	}

	start = data => {
		if (data.clock.data.remainingTime) {
			return this.remainingTimer(data)
		} else {
			return this.defaultTimer(data)
		}
	}
}
