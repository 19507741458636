import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'

import menu from '../../../assets/json/liveMenu.json'
// {
// 	"title": "COMMON.BREADCRUMBS.SCHEDULE",
// 	"path": "/live/schedule",
// 	"classes": {
// 		"default": "font ip-control-bar__link",
// 		"active": "ip-control-bar__link--active"
// 	}
// }

import '../../../assets/styles/liveMenu.css'

const LiveMenu = () => {
  const { t } = useTranslation()

  return (
    <div className="ip-control-bar m-b-10">
      <div className="flex align-middle full-height ip-control-bar__wrap">
        {menu?.map(({ classes, title, path }) => (
          <NavLink
            key={path}
            activeClassName={classes.active}
            className={classes.default}
            to={path}
          >
            {t(title)}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default withTranslation()(LiveMenu)
