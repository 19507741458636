import React, { useState } from 'react'
import ClassificationBody from './body/main/ClassificationBody'
import FavouritesBody from './body/favourites/FavouritesBody'
import Tabs from './header/Tabs'
import { useStorage } from '../../../hooks/useStorage'
import '../../../assets/styles/prematchMenu.css'
import Popular from './popularBlock/Popular'
import { debounce } from 'helpers/debounce'

const PrematchClassification = () => {
  const { setSession, getSession } = useStorage()

  const id = getSession('prematchMenu')
  const [isAll, setIsAll] = useState(id || '1')

  const setContent = (id) => {
    setSession('prematchMenu', id)
    setIsAll(id)
  }

  return (
    <>
      <Popular />
      <div className="wn-nav">
        <div className={'wn-classification'}>
          <Tabs active={isAll} handler={setContent} />
          <ClassificationBody {...{ isAll }} />
        </div>
      </div>
    </>
  )
}

export default React.memo(PrematchClassification)
