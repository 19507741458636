import { useState } from 'react'

export const useSlider = () => {
	const [showButtons, setShowButtons] = useState(false)
	const [scrollWidth, setScrollWidth] = useState(0)
	const [count, setCount] = useState(0)
	const [step, setStep] = useState(0)
	const [transform, setTransform] = useState(0)
	const [max, setMax] = useState(false)
	const [min, setMin] = useState(true)

	const toNextSlide = () => {
		const current = count + 1
		if (current * step > scrollWidth) {
			setTransform(scrollWidth)
			setMax(true)
		} else {
			setTransform(current * step)
		}

		setMin(false)
		setCount(current)
	}

	const toPrevSlide = () => {
		const current = count - 1

		if (count !== 0) {
			setTransform(step * current)
			setCount(current)
			setMax(false)
		}

		if (current === 0) {
			setMin(true)
		}
	}

	const setSliderData = ref => {
		const scroll = ref.current.scrollWidth
		const width = ref.current.offsetWidth

		setScrollWidth(scroll - width)

		if (scroll > width) {
			setStep(width)
			setShowButtons(true)
		}
	}

	return {
		setSliderData,
		toPrevSlide,
		toNextSlide,
		showButtons,
		scrollWidth,
		count,
		step,
		transform,
		max,
		min,
	}
}
