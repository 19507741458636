import React from 'react'

const EmptyEvent = ({ matchCount, tournamentCount }) => {
  const data = new Array(matchCount + tournamentCount).fill(0)

  return (
    <>
      {data?.map((event, idx) => (
        <div key={idx} className="ip-competition-empty" />
      ))}
    </>
  )
}

EmptyEvent.defaultProps = {
  matchCount: 0,
  tournamentCount: 0,
}

export default React.memo(EmptyEvent)
