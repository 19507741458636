import React, { useState, useEffect} from 'react';
import ProgressBar from './progressBar';

interface IPasswordFile {
  fileStatus: string,
  fileName:string,
  filePostfixType: string,
  removeFile?: (idx?: number, newFiles?: any[]) => void
}
const PasswordFile:React.FC<IPasswordFile> = ({ fileStatus, fileName, filePostfixType, removeFile}) => {
  const [fileClassName, setFileClassName] = useState('cb-alert cb-alert--default');
  const [postfix, setPostfix] = useState(() => (<div></div>))

  useEffect(() => {
    switch (fileStatus) {
      case 'confirmed': setFileClassName('cb-alert cb-alert--confirmed'); break;
      case 'bad': setFileClassName('cb-alert cb-alert--bad'); break;
      case 'warning': setFileClassName('cb-alert cb-alert--warning'); break;
      case 'default': setFileClassName('cb-alert cb-alert--default'); break;
      case 'close': setFileClassName('cb-alert cb-alert--default'); break;
      case 'failed': setFileClassName('cb-alert cb-alert--failed'); break;
      case 'uploading': setFileClassName('cb-alert cb-alert--uploading'); break;
      default: setFileClassName('cb-alert cb-alert--default');
    }
  },
    [fileStatus]);

  useEffect(() => {
    switch (filePostfixType) {
      case 'close':
        setPostfix(
          <div className="flex align-middle align-justify full-height cb-alert__wrap">
            <p className="font font--10 cb-alert__text text-ellipsis">{fileName}</p>
            <div className="cb-alert__times" onClick={() => {/* removeFile */}}><i className="fal fa-times"></i></div>
          </div>
        ); break;
      case 'bad':
        setPostfix(
          <div className="flex align-middle">
            <p className="font font--10 m-r-10 cb-alert__message">Under consideration…</p>
            <div className="cb-alert__reload"><i className="fal fa-redo-alt"></i></div>
          </div>
        ); break;
      case 'uploading': setPostfix(
        <ProgressBar name={fileName} remove={removeFile} setPost={setPostfix} setClass={setFileClassName}/>
      ); break;
      case 'default': setFileClassName('cb-alert cb-alert--default'); break;
      default: setPostfix(<p className="font font--10 cb-alert__message">Confirmed!</p>)
    }
  }, [filePostfixType])

  return (
    <div className={fileClassName}>
      {
        postfix
      }
    </div>
  );
}

export default PasswordFile;