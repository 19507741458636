import axios, { AxiosAdapter, AxiosRequestConfig } from 'axios'
import { links } from '../connection/links'

export const useRequest = () => {
  const get = async (url, headers?: any[], /* config: AxiosRequestConfig ,*/ withCredentials = true) => {
    try {
      const req = await axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        withCredentials,
        /* config: config, */
      })
      return await req.data
    } catch (e) {
      return new Error('Some propblem')
    }
  }

  const getWithoutCredentials = async (url, headers?: any[], withCredentials = false) => {
    try {

      const req = await axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        withCredentials,
        /* config: config, */
      })
      return await req.data

    } catch (err) {
      return new Error("Error");
    }
  }

  const post = async (url, data) => {
    try {
      const req = await axios.post(url, data, {
        withCredentials: true,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'text/plain',
          // ...headers,
        },
      })

      return await req.data
    } catch {
      return new Error('Some problem')
    }
  }

  const getAll = async leagues => {
    try {
      const promises = leagues?.map(league =>
        axios.get(links.league + league.id, /* { id: league.id } */)
      )
      return axios.all(promises)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  return {
    get,
    post,
    getAll,
    getWithoutCredentials,
  }
}
