import { types } from '../types/types'
import { InferType } from '../../helpers/commonInterfaces/interfaces'

export const start = () =>
  ({
    type: types.START_PRELOADER,
  } as const)

export const finish = () =>
  ({
    type: types.FINISH_PRELOADER,
  } as const)

export type PreloaderTypes = ReturnType<InferType<typeof start>>
