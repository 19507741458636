import React from 'react'
import img from '../../../assets/img/offer.png'

const Active = () => {
	return (
		<div className="container">
			<div className="container__head">
				<p className="font font--semibold">Active</p>
			</div>
			<div className="container__wrap">
				<div className="offers">
					<div className="offer" style={{ backgroundImage: `url(${img})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Избавь деревню от оборотней и получи 45 фриспинов в слоте the
									Wolf's Bane!
								</p>
							</div>
							<div className="offer__center">
								<p className="font font--10 font--white">
									Until the end is left
								</p>
								<p className="font font--14 font--gold">00 : 23 : 11 : 03</p>
								<div className="progress">
									<div className="flex align-justify align-middle progress__head">
										<p className="font font--white font--10">Your progress</p>
										<p className="font font--gold font--10">2 / 9</p>
									</div>
									<div className="flex align-justify align-middle progress__body">
										<div className="active"></div>
										<div className="active"></div>
										<div className="active"></div>
										<div className="active"></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>
								</div>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active offer__btn--not-active">
										<span className="font">Активировать</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="offer" style={{ backgroundImage: `url(${img})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									Избавь деревню от оборотней и получи 45 фриспинов в слоте the
									Wolf's Bane!
								</p>
							</div>
							<div className="offer__center">
								<p className="font font--10 font--white">
									Until the end is left
								</p>
								<p className="font font--14 font--gold">10 : 23 : 11 : 03</p>
								<div className="progress">
									<div className="flex align-justify align-middle progress__head">
										<p className="font font--white font--10">Your progress</p>
										<p className="font font--gold font--10">2 / 9</p>
									</div>
									<div className="flex align-justify align-middle progress__body">
										<div className="active"></div>
										<div className="active"></div>
										<div className="active"></div>
										<div className="active"></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>
								</div>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-justify">
									<button className="btn btn--gold btn--h-36 offer__btn offer__btn--active offer__btn--not-active">
										<span className="font">Активировать</span>
									</button>
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more">
										<span className="font font--gold">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Active
