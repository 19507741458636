import React, { useEffect, useState } from 'react'
import LeagueHeader from './LeagueHeader'
import LeagueEvents from './LeagueEvents'
import { isIterable } from 'helpers/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { inplayWatchers } from '../../../../../redux/actions/inplayActions'
import { getLeaguesEvents, getStreaming } from 'redux/selectors/inplaySelectors'
import { getInlineBanners } from 'redux/selectors/bannersSelectors'
import { IEvent } from 'helpers/commonInterfaces/interfaces'

const LeagueWrapper = ({ league, liveStreaming, changeMarket, oddName, oddId, events }) => {
  const { name, iconCode } = league
  const leaguesEvents = useSelector(getLeaguesEvents)
  const [filteredEvents, setFilteredEvents] = useState<Array<IEvent>>([])
  const inlineBanners = useSelector(getInlineBanners)
  const streaming = useSelector(getStreaming)

  const dispatch = useDispatch()

  useEffect(() => {
    if (events && events.length > 0) {
      let newEvents = [...events]
      if (!liveStreaming && streaming) {
        newEvents = newEvents.filter(ev => {
          return !!streaming?.find(
            ({ id }) => id === ev.externalIds?.betradar?.split(':')[2]
          )
        })
      }
      if (newEvents && isIterable(league.banners) && inlineBanners) {
        let i = 0
        for (const banner of league.banners) {
          newEvents.splice(
            banner.position + i,
            0,
            inlineBanners.find((item) => item.banner === banner.bannerID)
          )
          i++
        }
      }
      setFilteredEvents(newEvents)
    } else {
      setFilteredEvents(events ?? [])
    }
  }, [events, liveStreaming, getInlineBanners])

  useEffect(() => {
    dispatch(inplayWatchers.loadLeague(league.id))

    return () => {
      dispatch({ type: `DESTROY_LEAGUE_${league.id}` })
      dispatch(inplayWatchers.destroyLeague(league.id))
    }
  }, [])

  if (leaguesEvents[league.id]?.length === 0 || filteredEvents.length === 0) {
    return null
  }
  return (
    <>
      <LeagueHeader id={league.id} sportId={league.sportId} name={name} iconCode={iconCode} changeMarket={changeMarket} />
      <LeagueEvents
        matchCount={league.matchCount}
        {...{ liveStreaming }}
        tournamentCount={league.tournamentCount}
        leagueBanners={league.banners}
        events={filteredEvents}
        oddName={oddName}
        oddId={oddId}
        changeMarket={changeMarket}
      />
    </>
  )
}

export default LeagueWrapper
