import React, { RefObject } from 'react'
import SearchIcon from './SearchIcon'

type ISearchItem = {
  NA: string
  EI: string
}

const SearchItem: React.FC<{
  q: ISearchItem
  setQ: (query: string) => void
  inputRef: RefObject<HTMLInputElement>
}> = React.memo(({ q, setQ, inputRef }) => {
  const clickHandler = () => {
    const input = inputRef.current
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    )?.set
    nativeInputValueSetter?.call(input, q.NA)

    const ev2 = new Event('input', { bubbles: true })
    input?.dispatchEvent(ev2)
    setQ(q.NA)
  }
  return (
    <a className="h-search__link" href="#" onClick={clickHandler}>
      <SearchIcon />
      <p className="font font--13 font--white h-search__text">{q.NA}</p>
    </a>
  )
})

export default SearchItem
