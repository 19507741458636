import { types } from '../types/types'
import { PrematchWorkers } from '../actions/prematchActions'
import { ISport, Nullable } from '../../helpers/commonInterfaces/interfaces'

const initialState = {
	data: null as Nullable<ISport[]>,
	currentSport: null as Nullable<ISport>,
	error: false,
}

export type IState = typeof initialState

export const prematch = (
	state = initialState,
	action: PrematchWorkers
): IState => {
	switch (action.type) {
		case types.SET_PREMATCH_ALL:
			return {
				...state,
				data: action.payload,
			}
		case types.SET_PREMATCH_SPORT:
			return {
				...state,
				currentSport: action.payload,
			}
		case types.CLEAR_CURRENT_SPORT:
			return {
				...state,
				currentSport: null,
			}
		default:
			return state
	}
}
