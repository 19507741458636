import React from 'react'
import ScoreboardHeader from '../scoreboardHeader/ScoreboardHeader'
import { scoreboardBackgrounds } from '../../../../helpers/scoreboardBackgrounds'

const PrematchScoreboard = ({ event }) => {
  const { id, competitors, name, startDate } = event

  const scoreboardBanner = scoreboardBackgrounds[id] || scoreboardBackgrounds[1]

  return (
    <div className="ip-scoreboard" style={{ backgroundImage: `url(${scoreboardBanner})` }}>
      <ScoreboardHeader
        open={false}
        options={false}
        name={name}
        //date={startDate}
        handler={() => 0}
        competitors={competitors}
      />
    </div>
  )
}

export default PrematchScoreboard
