import React from 'react'
import Text from '../../../common/elements/Text'

interface ICompetitors {
  competitors: any
}

const Competitors: React.FC<ICompetitors> = React.memo(function Competitors({ competitors }) {
  return (
    <>
      {competitors?.map((competitor) => (
        <Text
          key={competitor.id}
          className="font font--grey-300 text-ellipsis"
          title={competitor.name}
        />
      ))}
    </>
  )
})

const FixtureTeams = ({ competitors, name }) => {
  return (
    <div className="ipn-fixture__team">
      {name ? (
        <Text className="font font--grey-300 text-ellipsis" title={name} />
      ) : (
        <Competitors competitors={competitors} />
      )}
    </div>
  )
}

FixtureTeams.defaultProps = {
  competitors: [],
  name: '',
}

export default React.memo(FixtureTeams)
