import React from 'react'
const Coupons = () => {
  return (
    <>
      <div className="s-block">
        <div className="s-block__header">
          <p className="font font--semibold">Coupons</p>
        </div>
        <div className="s-block__wrap">
          <div className="ip-competition ip-competition--prematch">
            <div className="ip-competition__table">
              <div className="ip-competition__row ip-competition__row--header">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAFiSURBVHja1JY7TgMxEIY/75qQBxKhggZBQc0doOMinImD0NJwAU6AhESVhjxJsmvPDEViZCLonIKp/NJ8/kfjGTszY59WsWfbO8ABHugCB4V9B2DtgaO7h6dxXbnit3+8vz3xwKCuHDdXZz91JbNsnsa2c2Z3D3h+HQEMPOCjKI0I01WLGTgHKbnc1lG+vruWxsmGvQ6iCuA9gKiybCKfbSwSmk5dETcAPEAQZdEGFk0ZgK8cIhlARJg3kXkTigDqyhFyBVGU6SowW4VCue+IUbIQ1UtmUjHTtgxAOrTV8jshL5aj67deN4BNCj3fIat1l/7py6UHUItgEdAyAIsbnylEpgGcghUCON34TADVdHspVoa2SZQUxO0bL6QAxSxTYBbAXLkQIZhKDhCgBtcvBLCtzw0gmAkfY8lK6U5p/LPM/tZeLD8bHHAMnAPDwu1gArynjnaYwlXQItC4f/+r+BoAR3i0yoItGRkAAAAASUVORK5CYII="
                    className="ip-competition__country m-r-10"
                    alt="iconCode"
                  />
                  <p className="font font--semibold text-ellipsis m-r-10">
                    England. Premier League
                  </p>
                  <a className="favourite favourite--dark"></a>
                </div>
                <div className="ip-competition__cell">
                  <div className="dropdown ip-competition__dropdown">
                    <a className="font font--11 font--default-alpha-05 text-ellipsis dropdown__toggle">
                      Total
                    </a>
                    <div className="dropdown__menu">
                      <a className="font font--11 dropdown__item" href="#">
                        1
                      </a>
                      <a className="font font--11 dropdown__item" href="#">
                        Match Goals
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ip-competition__row">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <div className="ip-competition__wrap">
                    <div className="flex align-middle align-justify m-b-5">
                      <p className="font font--red font--semibold">21:03</p>
                      <a className="favourite favourite--dark"></a>
                    </div>
                    <div className="flex align-middle align-justify">
                      <div className="c-icon c-icon--play ip-competition__action"></div>
                      <div className="c-icon c-icon--field ip-competition__action"></div>
                      <div className="c-icon c-icon--stats ip-competition__action"></div>
                    </div>
                  </div>
                  <div className="ip-competition__hr"></div>
                  <div className="flex align-middle align-justify full-width overflow-hidden">
                    <div className="text-left overflow-hidden m-r-5">
                      <div className="flex align-middle m-b-5">
                        <p className="font text-ellipsis">Juventus</p>
                      </div>
                      <div className="flex align-middle">
                        <p className="font text-ellipsis">Paris Saint-Germain</p>
                      </div>
                    </div>
                    <div className="text-right m-l-5">
                      <p className="font font--red font--semibold m-b-5">2</p>
                      <p className="font font--red">2</p>
                    </div>
                  </div>
                </div>
                <div className="ip-competition__cell">
                  <div className="flex align-stretch ip-competition__info">
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ip-competition__row">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <div className="ip-competition__wrap">
                    <div className="flex align-middle align-justify m-b-5">
                      <p className="font font--red font--semibold">21:03</p>
                      <a className="favourite favourite--dark"></a>
                    </div>
                    <div className="flex align-middle align-justify">
                      <div className="c-icon c-icon--play ip-competition__action"></div>
                      <div className="c-icon c-icon--field ip-competition__action"></div>
                      <div className="c-icon c-icon--stats ip-competition__action"></div>
                    </div>
                  </div>
                  <div className="ip-competition__hr"></div>
                  <div className="flex align-middle align-justify full-width overflow-hidden">
                    <div className="text-left overflow-hidden m-r-5">
                      <div className="flex align-middle m-b-5">
                        <p className="font text-ellipsis">Juventus</p>
                      </div>
                      <div className="flex align-middle">
                        <p className="font text-ellipsis">Paris Saint-Germain</p>
                      </div>
                    </div>
                    <div className="text-right m-l-5">
                      <p className="font font--red font--semibold m-b-5">2</p>
                      <p className="font font--red">2</p>
                    </div>
                  </div>
                </div>
                <div className="ip-competition__cell">
                  <div className="flex align-stretch ip-competition__info">
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ip-competition__row">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <div className="ip-competition__wrap">
                    <div className="flex align-middle align-justify m-b-5">
                      <p className="font font--red font--semibold">21:03</p>
                      <a className="favourite favourite--dark"></a>
                    </div>
                    <div className="flex align-middle align-justify">
                      <div className="c-icon c-icon--play ip-competition__action"></div>
                      <div className="c-icon c-icon--field ip-competition__action"></div>
                      <div className="c-icon c-icon--stats ip-competition__action"></div>
                    </div>
                  </div>
                  <div className="ip-competition__hr"></div>
                  <div className="flex align-middle align-justify full-width overflow-hidden">
                    <div className="text-left overflow-hidden m-r-5">
                      <div className="flex align-middle m-b-5">
                        <p className="font text-ellipsis">Juventus</p>
                      </div>
                      <div className="flex align-middle">
                        <p className="font text-ellipsis">Paris Saint-Germain</p>
                      </div>
                    </div>
                    <div className="text-right m-l-5">
                      <p className="font font--red font--semibold m-b-5">2</p>
                      <p className="font font--red">2</p>
                    </div>
                  </div>
                </div>
                <div className="ip-competition__cell">
                  <div className="flex align-stretch ip-competition__info">
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ip-competition__row">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <div className="ip-competition__wrap">
                    <div className="flex align-middle align-justify m-b-5">
                      <p className="font font--red font--semibold">21:03</p>
                      <a className="favourite favourite--dark"></a>
                    </div>
                    <div className="flex align-middle align-justify">
                      <div className="c-icon c-icon--play ip-competition__action"></div>
                      <div className="c-icon c-icon--field ip-competition__action"></div>
                      <div className="c-icon c-icon--stats ip-competition__action"></div>
                    </div>
                  </div>
                  <div className="ip-competition__hr"></div>
                  <div className="flex align-middle align-justify full-width overflow-hidden">
                    <div className="text-left overflow-hidden m-r-5">
                      <div className="flex align-middle m-b-5">
                        <p className="font text-ellipsis">Juventus</p>
                      </div>
                      <div className="flex align-middle">
                        <p className="font text-ellipsis">Paris Saint-Germain</p>
                      </div>
                    </div>
                    <div className="text-right m-l-5">
                      <p className="font font--red font--semibold m-b-5">2</p>
                      <p className="font font--red">2</p>
                    </div>
                  </div>
                </div>
                <div className="ip-competition__cell">
                  <div className="flex align-stretch ip-competition__info">
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ip-competition ip-competition--prematch">
            <div className="ip-competition__table">
              <div className="ip-competition__row ip-competition__row--header">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAFiSURBVHja1JY7TgMxEIY/75qQBxKhggZBQc0doOMinImD0NJwAU6AhESVhjxJsmvPDEViZCLonIKp/NJ8/kfjGTszY59WsWfbO8ABHugCB4V9B2DtgaO7h6dxXbnit3+8vz3xwKCuHDdXZz91JbNsnsa2c2Z3D3h+HQEMPOCjKI0I01WLGTgHKbnc1lG+vruWxsmGvQ6iCuA9gKiybCKfbSwSmk5dETcAPEAQZdEGFk0ZgK8cIhlARJg3kXkTigDqyhFyBVGU6SowW4VCue+IUbIQ1UtmUjHTtgxAOrTV8jshL5aj67deN4BNCj3fIat1l/7py6UHUItgEdAyAIsbnylEpgGcghUCON34TADVdHspVoa2SZQUxO0bL6QAxSxTYBbAXLkQIZhKDhCgBtcvBLCtzw0gmAkfY8lK6U5p/LPM/tZeLD8bHHAMnAPDwu1gArynjnaYwlXQItC4f/+r+BoAR3i0yoItGRkAAAAASUVORK5CYII="
                    className="ip-competition__country m-r-10"
                    alt="iconCode"
                  />
                  <p className="font font--semibold text-ellipsis m-r-10">
                    England. Premier League
                  </p>
                  <a className="favourite favourite--dark"></a>
                </div>
                <div className="ip-competition__cell">
                  <div className="dropdown ip-competition__dropdown">
                    <a className="font font--11 font--default-alpha-05 text-ellipsis dropdown__toggle">
                      Total
                    </a>
                    <div className="dropdown__menu">
                      <a className="font font--11 dropdown__item" href="#">
                        1
                      </a>
                      <a className="font font--11 dropdown__item" href="#">
                        Match Goals
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ip-competition__row">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <div className="ip-competition__wrap">
                    <div className="flex align-middle align-justify m-b-5">
                      <p className="font font--red font--semibold">21:03</p>
                      <a className="favourite favourite--dark"></a>
                    </div>
                    <div className="flex align-middle align-justify">
                      <div className="c-icon c-icon--play ip-competition__action"></div>
                      <div className="c-icon c-icon--field ip-competition__action"></div>
                      <div className="c-icon c-icon--stats ip-competition__action"></div>
                    </div>
                  </div>
                  <div className="ip-competition__hr"></div>
                  <div className="flex align-middle align-justify full-width overflow-hidden">
                    <div className="text-left overflow-hidden m-r-5">
                      <div className="flex align-middle m-b-5">
                        <p className="font text-ellipsis">Juventus</p>
                      </div>
                      <div className="flex align-middle">
                        <p className="font text-ellipsis">Paris Saint-Germain</p>
                      </div>
                    </div>
                    <div className="text-right m-l-5">
                      <p className="font font--red font--semibold m-b-5">2</p>
                      <p className="font font--red">2</p>
                    </div>
                  </div>
                </div>
                <div className="ip-competition__cell">
                  <div className="flex align-stretch ip-competition__info">
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ip-competition__row">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <div className="ip-competition__wrap">
                    <div className="flex align-middle align-justify m-b-5">
                      <p className="font font--red font--semibold">21:03</p>
                      <a className="favourite favourite--dark"></a>
                    </div>
                    <div className="flex align-middle align-justify">
                      <div className="c-icon c-icon--play ip-competition__action"></div>
                      <div className="c-icon c-icon--field ip-competition__action"></div>
                      <div className="c-icon c-icon--stats ip-competition__action"></div>
                    </div>
                  </div>
                  <div className="ip-competition__hr"></div>
                  <div className="flex align-middle align-justify full-width overflow-hidden">
                    <div className="text-left overflow-hidden m-r-5">
                      <div className="flex align-middle m-b-5">
                        <p className="font text-ellipsis">Juventus</p>
                      </div>
                      <div className="flex align-middle">
                        <p className="font text-ellipsis">Paris Saint-Germain</p>
                      </div>
                    </div>
                    <div className="text-right m-l-5">
                      <p className="font font--red font--semibold m-b-5">2</p>
                      <p className="font font--red">2</p>
                    </div>
                  </div>
                </div>
                <div className="ip-competition__cell">
                  <div className="flex align-stretch ip-competition__info">
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ip-competition__row">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <div className="ip-competition__wrap">
                    <div className="flex align-middle align-justify m-b-5">
                      <p className="font font--red font--semibold">21:03</p>
                      <a className="favourite favourite--dark"></a>
                    </div>
                    <div className="flex align-middle align-justify">
                      <div className="c-icon c-icon--play ip-competition__action"></div>
                      <div className="c-icon c-icon--field ip-competition__action"></div>
                      <div className="c-icon c-icon--stats ip-competition__action"></div>
                    </div>
                  </div>
                  <div className="ip-competition__hr"></div>
                  <div className="flex align-middle align-justify full-width overflow-hidden">
                    <div className="text-left overflow-hidden m-r-5">
                      <div className="flex align-middle m-b-5">
                        <p className="font text-ellipsis">Juventus</p>
                      </div>
                      <div className="flex align-middle">
                        <p className="font text-ellipsis">Paris Saint-Germain</p>
                      </div>
                    </div>
                    <div className="text-right m-l-5">
                      <p className="font font--red font--semibold m-b-5">2</p>
                      <p className="font font--red">2</p>
                    </div>
                  </div>
                </div>
                <div className="ip-competition__cell">
                  <div className="flex align-stretch ip-competition__info">
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ip-competition__row">
                <div className="ip-competition__cell ip-competition__cell--info">
                  <div className="ip-competition__wrap">
                    <div className="flex align-middle align-justify m-b-5">
                      <p className="font font--red font--semibold">21:03</p>
                      <a className="favourite favourite--dark"></a>
                    </div>
                    <div className="flex align-middle align-justify">
                      <div className="c-icon c-icon--play ip-competition__action"></div>
                      <div className="c-icon c-icon--field ip-competition__action"></div>
                      <div className="c-icon c-icon--stats ip-competition__action"></div>
                    </div>
                  </div>
                  <div className="ip-competition__hr"></div>
                  <div className="flex align-middle align-justify full-width overflow-hidden">
                    <div className="text-left overflow-hidden m-r-5">
                      <div className="flex align-middle m-b-5">
                        <p className="font text-ellipsis">Juventus</p>
                      </div>
                      <div className="flex align-middle">
                        <p className="font text-ellipsis">Paris Saint-Germain</p>
                      </div>
                    </div>
                    <div className="text-right m-l-5">
                      <p className="font font--red font--semibold m-b-5">2</p>
                      <p className="font font--red">2</p>
                    </div>
                  </div>
                </div>
                <div className="ip-competition__cell">
                  <div className="flex align-stretch ip-competition__info">
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                    <button className="btn btn--coff ip-competition__coff">
                      <span className="font font--12">2.17</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Coupons
