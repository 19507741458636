import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { isLoggedIn } from 'redux/selectors/configSelectors'
import menuData from '../../../assets/json/menu.json'

type menuType = Array<{
  title: {
    lang: boolean
    text: string
  }
  className: string
  itemType: string
  target: string
}>

const Menu = () => {
  const { t } = useTranslation()
  const loggedIn = useSelector(isLoggedIn)
  const [menu, setMenu] = useState<menuType>([])

  useEffect(() => {
    setMenu(menuData.menu)
  }, [])

  const linkTitle = ({ lang, text }) => {
    return lang ? t(text) : text
  }

  return (
    <menu className="h-menu">
      <div className="flex align-center full-height">
        {menu?.map(({ target, itemType, title }) => {
          if (target === 'mybets' && !loggedIn) {
            return null
          }
          return itemType === 'route' ? (
            <NavLink
              className="font font--grey-300 h-menu__item"
              activeClassName="h-menu__item--active"
              to={`/${target}`}
              key={title.text}
            >
              {linkTitle(title)}
            </NavLink>
          ) : (
            <a href={target} key={title.text} className="font font--grey-300 h-menu__item">
              {linkTitle(title)}
            </a>
          )
        })}
      </div>
    </menu>
  )
}

export default Menu

// {links?.map({ target } => {
//   const { name, ...rest } = item
//
//   return item.to ? (
// 	<NavLink
// 	  className="font font--grey-300 h-menu__item"
// 	  to={`/${target}`}
// 	  key={item.name}>
// 	  {t(name)}
// 	</NavLink>
//   ) : (
// 	<a
// 	  href={item.href}
// 	  key={item.name}
// 	  className="font font--grey-300 h-menu__item">
// 	  {t(name)}
// 	</a>
//   )
// })}

{
  /*<div className="dropdown h-menu__dropdown">
    <a className="font h-menu__item dropdown__toggle">Tournamets</a>
    <div className="dropdown__menu">
        <a className="font font--white font--12 dropdown__item">Item 1</a>
        <a className="font font--white font--12 dropdown__item">Item 1</a>
        <a className="font font--white font--12 dropdown__item">Item 1</a>
        <a className="font font--white font--12 dropdown__item">Item 1</a>
        <a className="font font--white font--12 dropdown__item">Item 1</a>
    </div>
</div>*/
}
