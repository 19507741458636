import React, { ChangeEvent, useState } from 'react'
import SimpleInput from '../../registration/elements/SimpleInput'
import { Obj } from '../../../helpers/commonInterfaces/interfaces'

const inputs: number[] = [0, 1, 2, 3]

type IVerificationInputs = {
  passcode: Obj<string>
  setPasscode(data: Obj<string>): void
}

const VerificationInputs: React.FC<IVerificationInputs> = ({ passcode, setPasscode }) => {
  const [active, setActive] = useState<number>(0)

  const onSetPasscode = (e: ChangeEvent<HTMLInputElement>): void => {
    const name = Number(e.target.name)

    const newValue = e.target.value
    const oldValue = passcode[name]

    setPasscode({
      ...passcode,
      [name]: newValue.split('')[0] || '',
    })

    if (!oldValue) {
      setActive(name > 4 ? 4 : name + 1)
    }

    if (newValue === '') {
      setActive(name > 0 ? name - 1 : 0)
    }
  }

  return (
    <div className="flex align-middle q-popup__password">
      {inputs.map((item, idx) => (
        <SimpleInput
          key={item}
          classes="field field--main"
          type="number"
          name={`${idx}`}
          value={passcode[idx]}
          onChange={onSetPasscode}
          active={active === idx}
        />
      ))}
    </div>
  )
}

export default VerificationInputs
