import {
  ILeague,
  IMatch,
  InferType,
  ITournament,
  Nullable,
} from '../../helpers/commonInterfaces/interfaces'
import { types } from '../types/types'

export const prematchLeagueWorkers = {
  setLeague: (data: Nullable<ILeague>) =>
    ({
      type: types.SET_PREMATCH_LEAGUE,
      data,
    } as const),
  setEvents: ({ events }: { events: Nullable<Array<IMatch | ITournament>> }) =>
    ({
      type: types.SET_PREMATCH_LEAGUES_EVENTS,
      payload: events,
    } as const),
  clearLeague: () =>
    ({
      type: types.CLEAR_PREMATCH_LEAGUE,
    } as const),
  setCurrentOddName: (data) => ({
    type: types.CURRENT_ODD_NAME,
    payload: data,
  }),
  setCurrentOddId: (data) => ({
    type: types.CURRENT_ODD_ID,
    payload: data,
  }),
  setAllOddsNames: (data) => ({
    type: types.SET_ALL_ODDS_NAME,
    payload: data,
  }),
  setAllOddsIds: (data) => ({
    type: types.SET_ALL_ODDS_ID,
    payload: data,
  }),
}

export const prematchLeagueWatchers = {
  getLeague: (sportId: string, id: string) =>
    ({
      type: types.GET_PREMATCH_LEAGUE_WATCHER,
      ids: { sportId, id },
    } as const),
  clearLeague: () =>
    ({
      type: types.CLEAR_PREMATCH_LEAGUE_WATCHER,
    } as const),
}
export type PrematchLeagueWorkers = ReturnType<InferType<typeof prematchLeagueWorkers>>
export type PrematchLeagueWatchers = ReturnType<InferType<typeof prematchLeagueWatchers>>
