import React from 'react'
import SportIcon from '../../common/elements/SportIcon'

const ClassificationBarItem = ({ item, className, onClick }) => {
  return (
    <div className={`wn-classification-bar__item ${className}`} onClick={onClick}>
      <div className="flex align-center align-bottom wn-classification-bar__block">
        <div className="wn-classification-bar__icon">
          <SportIcon name={item.name} id={item.id} />
        </div>
      </div>
      <div className="flex align-center-middle wn-classification-bar__block">
        <p className="font wn-classification-bar__text">{item.name}</p>
      </div>
    </div>
  )
}

export default React.memo(ClassificationBarItem)
