import { ICurrentView, IInplay } from '../reducers/inplayReducer'
import {
  IMatch,
  ISport,
  IStreaming,
  ITournament,
  Nullable,
} from '../../helpers/commonInterfaces/interfaces'
import { IRootState } from '../reducers/rootReducer'

export const getInplay = (state: IRootState): IInplay => {
  return state.inplay
}

export const getInplayTree = (state: IRootState): Array<ISport> | null => {
  return state.inplay?.data
}

/*export const getCasinoData = (state: IRootState): ICasino | null => {
  return state
}*/

export const getCurrentActive = (state: IRootState): number => {
  return state.inplay?.active || 1
}

export const getTrackerAndTranslation = (
  state: IRootState
): { tracker: number; translation: number; pinnedMatch: boolean } => {
  return {
    tracker: state.inplay.activeTracker,
    translation: state.inplay.activeTranslation,
    pinnedMatch: state.inplay.pinnedMatch,
  }
}

export const getCurrentView = (state: IRootState): ICurrentView => {
  return state.inplay.currentView
}

export const getLeaguesEvents = (state: IRootState): Record<string, (IMatch | ITournament)[]> => {
  return state.inplay.leaguesEvents
}

export const getChosenLeague = (state: IRootState): Nullable<number[]> => {
  return state.inplay.currentView.chosenLeagues
}

export const getLeagueEvents = (
  state: IRootState,
  id: number
): Nullable<Array<IMatch | ITournament>> => {
  console.log(id)
  return state.inplay.leaguesEvents[id] || null
}

export const getStreaming = (state: IRootState): Nullable<Array<IStreaming>> => state.inplay.stream
