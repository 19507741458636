import React from 'react'
import { BAcceptBet } from './BAcceptBet'

export const BAcceptBets = ({ stakes }) => {
  return (
    <div className="b-accept__bets">
      {stakes
        /* ?.filter((bet) => bet.st > 0) */
        .map((stake, idx) => (
          <BAcceptBet key={idx.toString()} item={stake} />
        ))}
    </div>
  )
}
