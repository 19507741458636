import React from 'react'
import _ from 'lodash'

const areEqual = (prev, next) => {
  return _.isEqual(prev, next)
}

const EventTimer = ({ sportId, scoreboard }) => {
  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        marginBottom: '5px'
      }}
    >
      {
        <>
          {sportId === 5 || sportId === 20 || sportId === 23 || sportId === 2 ? (
            <p className="font font--13 font--red font--semibold" style={{ marginRight: '5px' }}>
              {scoreboard?.data.periodScores?.length
                ? (sportId === 5 ? 'Set ' : sportId === 2 ? 'Q' : 'Game ') +
                scoreboard?.data.periodScores?.length
                : 'Ended'}
            </p>
          ) : null}
        </>
      }
    </span>
  )
}

export default React.memo(EventTimer, areEqual)
