import React from 'react'
import BetLockButton from '../common/buttons/BetLockButton'
import BetButton from '../common/buttons/BetButton'
import { useDispatch, useSelector } from 'react-redux'
import { addStake, placeBet, removeStake } from '../../redux/actions/betslipActions'
import { getBetslip } from 'redux/selectors/betslipSelectors'
import { getOddsType } from 'redux/selectors/configSelectors'
import { transformOddsForChangeMarket } from 'helpers/transform/transform'
import { outoputDashes } from 'components/common/competition/competitionContent/event/markets/EventMarkets'
import EventDropDownBets from 'components/common/competition/competitionContent/event/markets/bets/EventDropDownBets'
import EventBets from 'components/common/competition/competitionContent/event/markets/bets/EventBets'
import { getPrematchLeague, getPrematchLeagueOdds } from 'redux/selectors/prematchLeagueSelectors'

export const EventBetsForPrematch = ({ odds, event, id: eventId, sportId }) => {
  const dispatch = useDispatch()
  const league = useSelector(getPrematchLeagueOdds)

  // const createBet = (id, od, ha, check, qb) => {
  //   check
  //     ? dispatch(removeStake(id))
  //     : qb
  //     ? dispatch(placeBet({ FI: eventId, ID: id, OD: od, CL: sportId, HA: ha }))
  //     : dispatch(addStake(eventId, id, od, sportId, ha))
  // }
  if (!odds) {
    return null
  }

  const { odd, dropBets } = transformOddsForChangeMarket(odds, league.currentOddId ?? 206)

  return (
    <td className="td">
      <div className="flex align-middle ">
        {odd ? (
          <div className="flex align-stretch ip-competition__info">
            <EventBets
              item={odd[0]}
              competitors={event.competitors}
              eventId={eventId}
              sportId={sportId}
            />
          </div>
        ) : (
          outoputDashes()
        )}
        {dropBets && <EventDropDownBets {...{ eventId }} {...{ sportId }} odds={odd} />}
        {/* <div
          className="flex align-stretch ip-competition__info"
          style={{
            minHeight: '28px',
          }}
        >
          {odds.outcomes?.map((outcome) => (
            <React.Fragment key={outcome.id}>
              {outcome.status === '1' ? (
                <BetLockButton />
              ) : (
                <BetButton
                  item={outcome}
                  onCreateBet={createBet}
                  panel={quickBet}
                  allStakes={bt}
                  oddsType={ODDS_TYPE}
                />
              )}
            </React.Fragment>
          ))} 
        </div>*/}
      </div>
    </td>
  )
}
