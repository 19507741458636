import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

const SettingsBody = ({
  settingsMode,
  quickbetMode,
  rememberStake,
  changes,
  check,
  quickbetPanel,
  quickbetToggle,
  acceptAllOddsChanges,
  toggleAcceptAllOddsChangesHandler,
  toggleQuickbetModeHandler,
  toggleRememberStakeHandler,
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState(false)

  useEffect(() => {
    if (settingsMode) {
      setState(true)
      setTimeout(() => setState(false), 250)
    } else {
      setState(false)
    }
  }, [])

  useEffect(() => {
    console.log('remember stake in useEffect', rememberStake)
  }, [rememberStake])

  useEffect(() => {
    console.log('accept all odds changes in useEffect', acceptAllOddsChanges)
  }, [acceptAllOddsChanges])
  return (
    <CSSTransition
      in={settingsMode}
      appear
      classNames="settings_slide"
      timeout={250}
      mountOnEnter
      unmountOnExit
    >
      <div
        className={
          state ? 'betslip__settings-body settings_slide-enter-done' : 'betslip__settings-body'
        }
      >
        <div className="betslip__settings-item" onClick={toggleRememberStakeHandler}>
          <p className="font font--12 default-alpha-05">
            {t('COMMON.SETTINGS.REM_STAKE', 'Remember Stake')}
          </p>
          <label className="toggle">
            <input
              className="toggle__input"
              type="checkbox"
              checked={rememberStake.enabled}
              disabled
            />
            <span className="toggle__swipe"></span>
            <span className="toggle__bg"></span>
          </label>
        </div>
        <div
          className="betslip__settings-item"
          onClick={() => toggleQuickbetModeHandler(quickbetToggle)}
        >
          <p className="font font--12 default-alpha-05">
            {t('COMMON.ASIDE_RIGHT.BET_SLIP.QUICK_BET', 'Quick bet')}
          </p>
          <label className="toggle">
            <input className="toggle__input" type="checkbox" checked={quickbetMode} disabled />
            <span className="toggle__swipe"></span>
            <span className="toggle__bg"></span>
          </label>
        </div>
        <div className="betslip__settings-item" onClick={toggleAcceptAllOddsChangesHandler}>
          <p className="font font--12 default-alpha-05">
            {t('COMMON.SETTINGS.REM_STAKE', 'Accept all odds changes')}
          </p>
          <label className="toggle">
            <input
              className="toggle__input"
              type="checkbox"
              checked={acceptAllOddsChanges}
              disabled
            />
            <span className="toggle__swipe"></span>
            <span className="toggle__bg"></span>
          </label>
        </div>
        {/* <div className="betslip__settings-item">
					<label className="font font--12 default-alpha-05 betslip__checkbox" htmlFor="accept-changes">Accept all odds changes</label>
					<input 
						className="checkbox checkbox--blue" 
						type="checkbox"
						onChange={check} 
						checked={changes !== null ? (changes.checkbox ? 'checked' : '') : 'checked'}
					/>
				</div> */}
      </div>
    </CSSTransition>
  )
}

export default SettingsBody
