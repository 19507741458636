import { useState, useEffect } from 'react'
import * as Yup from 'yup'

export const useRegistration = () => {
  const [step, setStep] = useState(1)
  const [done, setDone] = useState(false)
  const [formIsFulled, setFormIsFulled] = useState(false)
  const [fullForm, setFullForm] = useState({
    firstStep: {
      email: '',
      username: '',
      firstName: '',
      lastName: '',
      numberCode: '',
      number: '',
    },
    secondStep: {
      day: '',
      month: '',
      year: '',
      gender: '',
      betType: '',
      password: '',
      confirmPassword: '',
    },
    thirdStep: {
      country: '',
      town: '',
      street: '',
      postcode: '',
      houseNumber: '',
      bonusCode: '',
      yearsConfirmation: '',
      newsletter: false,
    },
  })

  const [fastForm, setFastForm] = useState({
    initialValues: {
      email: '',
      betType: '',
      password: '',
      confirmPassword: '',
      yearsConfirmation: '',
      newsletter: '',
    },
  })

  const [socialsForm, setSocialsForm] = useState({
    firstStep: {
      social: '',
      username: '',
      yearsConfirmation: '',
      newsletter: '',
    },
    secondStep: {
      firstName: '',
      lastName: '',
      email: '',
      yearsConfirmation: '',
      newsletter: '',
    },
  })

  const makeRequest = () => {
    console.log(fullForm)
    console.log(fastForm)
    console.log(socialsForm)
    setDone(true)
  }

  const clearValues = () => {
    setStep(1)
    setFullForm({
      firstStep: {
        email: '',
        username: '',
        firstName: '',
        lastName: '',
        numberCode: '',
        number: '',
      },
      secondStep: {
        day: '',
        month: '',
        year: '',
        gender: '',
        betType: '',
        password: '',
        confirmPassword: '',
      },
      thirdStep: {
        country: '',
        town: '',
        street: '',
        houseNumber: '',
        bonusCode: '',
        yearsConfirmation: '',
        postcode: '',
        newsletter: false,
      },
    })
  }
  useEffect(() => {
    if (step === 3) {
      makeRequest()
      clearValues()
    }
  }, [formIsFulled])

  const returnToStep = (stepValue) => {
    if (stepValue > step) return
    setStep(Number(stepValue))
  }

  const addStep = (part, stepName) => {
    if (stepName === 'fast') {
      setFastForm({
        ...part,
      })
      setStep(3)
      setFormIsFulled(true)
      return
    }
    if (stepName === 'socials') {
      if (step !== 2) {
        setSocialsForm({
          ...socialsForm,
          firstStep: part,
        })
        setStep(2)
      } else {
        setSocialsForm({
          ...socialsForm,
          secondStep: part,
        })
        setStep(3)
        setFormIsFulled(true)
        return
      }
    }
    setFullForm({
      ...fullForm,
      [stepName]: part,
    })
    if (step === 3) {
      setFormIsFulled(true)
      return
    }

    setStep(step + 1)
  }

  const numericRegex = /^[0-9]+$/

  const schemaFast = Yup.object().shape({
    email: Yup.string().email('Invalid email').min(3, 'Too').max(26, 'Too').required('Required'),
    //betType: Yup.string().required('Required'),
    password: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .required('No password provided.'),
    //confirmPassword: Yup.string()
    //.oneOf([Yup.ref('password'), null], 'Passwords must match')
    //.required('Required'),
    yearsConfirmation: Yup.boolean().oneOf([true], 'Must Accept Legal Age').required('Required'),
    newsletters: Yup.string(),
  })

  const schemaFirstStep = Yup.object().shape({
    firstName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
    lastName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    username: Yup.string().min(3, 'Too').max(26, 'Too').required('Required'),
    number: Yup.string()
      .min(9, 'Too')
      .max(9, 'Too')
      .matches(numericRegex, 'only numbers')
      .required('Required'),
    numberCode: Yup.string().required('Required'),
  })

  const schemaSecondStep = Yup.object().shape({
    day: Yup.string().required('Required'),
    month: Yup.string().required('Required'),
    year: Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
    betType: Yup.string().required('Required'),
    password: Yup.string()
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

  const schemaThirdStep = Yup.object().shape({
    country: Yup.string().required('Required'),
    town: Yup.string().required('Required'),
    street: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
    houseNumber: Yup.string().required('Required'),
    bonusCode: Yup.string(),
    yearsConfirmation: Yup.boolean().oneOf([true], 'Must Accept Legal Age').required('Required'),
  })

  const schemaSocialsFirstStep = Yup.object().shape({
    social: Yup.string().required('Required'),
    username: Yup.string().min(3, 'Too').max(26, 'Too').required('Required'),
    yearsConfirmation: Yup.boolean().oneOf([true], 'Must Accept Legal Age').required('Required'),
    newsletters: Yup.boolean(),
  })

  const schemaSocialsSecondStep = Yup.object().shape({
    firstName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
    lastName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    yearsConfirmation: Yup.boolean().oneOf([true], 'Must Accept Legal Age').required('Required'),
    newsletters: Yup.boolean(),
  })

  return {
    step,
    setStep,
    fullForm,
    setFullForm,
    fastForm,
    setFastForm,
    socialsForm,
    setSocialsForm,
    done,
    setDone,
    returnToStep,
    addStep,
    clearValues,
    schemaFast,
    schemaFirstStep,
    schemaSecondStep,
    schemaThirdStep,
    schemaSocialsFirstStep,
    schemaSocialsSecondStep,
  }
}
