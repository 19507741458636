import {
  ICategory,
  ILeague,
  IScore,
  Nullable,
  Obj,
  Odd,
  OddOutcome,
} from '../commonInterfaces/interfaces'

interface IInitialObject {
  id: string | number
  name: string
  odds: Odd[]
  currentOdd?: Odd
}

interface ITransformCorrectScoreReturn {
  competitor1: Array<OddOutcome>
  draw: Array<OddOutcome>
  competitor2: Array<OddOutcome>
}

interface IEvent {
  id: number
  odds: Array<Odd>
}

interface IOverUnderOutcomes {
  over: OddOutcome[]
  under: OddOutcome[]
}

type ITransformMatchBetReturn = {
  competitor1: IOverUnder
  draw: IOverUnder
  competitor2: IOverUnder
}

type IOverUnder = {
  over: string[]
  under: string[]
}

// const isFavourite = (item: Record<string, unknown>) => {
// 	const storageData = getSession('FEM') || []
//
// 	const check = storageData.find(
// 		(el: Record<string, unknown>) => el.ID === item.ID
// 	)
//
// 	return !!check
// }

export const transformOddsForChangeMarket = (odds: any[], current: number) => {
  if (!odds || odds.length === 0) {
    return { odd: false, dropBets: false }
  }

  const odd = odds.filter((odd: Record<string, unknown>) => odd.id === current)

  if (odd.length) {
    return {
      odd,
      dropBets: odd.length > 1,
    }
  } else {
    const defaultOdd =
      odds.find((odd: Odd) => odd.id === 1 || odd.id === 175 || odd.id === 206) ?? odds[0] ?? false
    return {
      odd: [defaultOdd],
      dropBets: false,
    }
  }
}

export const getSportLeagues = (
  data: Array<ICategory>,
  chosen: Nullable<Array<ILeague>>
): {
  leaguesLength: boolean
  allLeagues: Array<ILeague>
  chosenLeagues: Array<ILeague>
} => {
  if (!data) {
    return {
      leaguesLength: false,
      allLeagues: [],
      chosenLeagues: [],
    }
  }

  const allLeagues = data.reduce((acc: Array<ILeague>, item: ICategory) => {
    const newLeagues = item.leagues?.map((league) => ({
      ...league,
      iconCode: item.iconCode ?? '',
    }))
    acc = [...acc, ...newLeagues]
    return acc
  }, [])

  let chosenLeagues = allLeagues ?? []

  const leaguesLength = allLeagues.length >= 3

  if (chosen) {
    chosenLeagues = allLeagues.filter((league: Record<string, unknown>) =>
      chosen.find((chosenLeague) => league.id === chosenLeague)
    )
  }

  return {
    leaguesLength,
    allLeagues,
    chosenLeagues,
  }
}

export const getSpecifiersValue = (spec: Record<string, string>): string => {
  return spec ? spec[Object.keys(spec)[0]] : ''
}

export const getOddBySpecifiersValue = (item: any, id: string, currentOdd: any) => {
  if (item.id === id) {
    if (currentOdd && currentOdd.specifiers && item && item.specifiers) {
      const isCurrent =
        getSpecifiersValue(currentOdd.specifiers) === getSpecifiersValue(item.specifiers)

      return isCurrent ? item : null
    } else {
      return item
    }
  } else {
    return null
  }
}

const groupOddsForColumns = (initialObj: Record<string, IInitialObject>, odd: Odd) => {
  const { id, name } = odd

  if (!initialObj[id]) {
    initialObj[id] = {
      id,
      name,
      odds: [],
    }
  }

  initialObj[id].odds = initialObj[id].odds.filter((item) => {
    if (item.specifiers && odd.specifiers) {
      if (getSpecifiersValue(item.specifiers) !== getSpecifiersValue(odd.specifiers)) {
        return item
      } else {
        return false
      }
    }
    return false
  })

  initialObj[id].odds = [...initialObj[id].odds, odd].sort((a: Odd, b: Odd) => {
    if (a.specifiers && b.specifiers) {
      return Number(getSpecifiersValue(a.specifiers)) > Number(getSpecifiersValue(b.specifiers))
        ? -1
        : 1
    }
    return 0
  })

  if (initialObj[id].odds[0] && initialObj[id].odds[0].specifiers) {
    initialObj[id].currentOdd = initialObj[id].odds[0]
  }
}

export const transformLeagueData = (events: Array<IEvent>) => {
  const groupOdds = {}

  events &&
    events.forEach((event) =>
      event.odds ? event.odds?.forEach((odd) => groupOddsForColumns(groupOdds, odd)) : null
    )

  return groupOdds
}

export const getAdditionalDataForCurrentSport = (events: Array<IEvent>) => {
  const set = new Set()

  events &&
    events.forEach((event) => (event.odds ? event.odds?.forEach((odd) => set.add(odd.name)) : null))

  const allOddsName = [...Object.values(set)]
  const currentOddName = allOddsName[0] ? allOddsName[0] : null

  return {
    allOddsName,
    currentOddName,
  }
}

export const transformName = (str: string): Array<string> => {
  const commands = str.split(' ')

  const first: Array<string> = []
  const second: Array<string> = []

  let current = first
  commands?.map((str) => {
    if (str === 'v' || str === 'vs' || str === '@') return (current = second)
    current?.push(str)
  })

  return [first.join(' '), second.join(' ')]
}

export const transformScore = (score: string): Array<string | Array<string>> => {
  const sc = score.split(',')

  if (sc[0].includes('-') && sc.length === 1) {
    return [sc[0].split('-')]
  }
  const res = sc.every((sc) => sc.includes('-'))

  if (res && sc.length >= 2) {
    return sc?.map((e) => e.split('-'))
  }

  return [sc]
}

export const transformScoreNew = (
  score: IScore | undefined,
  allPeriods: boolean,
  id: number
): any[] => {
  const res: Array<Array<string>> = []

  if (score === undefined) {
    return [[0, 0]]
  }

  res.push([score.currentHomeScore, score.currentAwayScore])
  if (score.currentGamePoints) {
    if (allPeriods) {
      ; (score.periodScores || [])?.forEach((item) => {
        res.push([item.homeScore, item.awayScore])
      })
    } else {
      const last = (score.periodScores || [])[(score.periodScores || []).length - 1]
      res.push([last.homeScore, last.awayScore])
    }

    if (id === 5) {
      const currentPoints = score.currentGamePoints
      res.push([currentPoints.homePoints, currentPoints.awayPoints])
    }
  }
  return res
}

export const transformTennisScore = (score: string) => {
  const sc = score.split(',')

  const first: Array<string> = []
  const second: Array<string> = []

  sc?.map((item) => {
    const exp = item.split('-')
    exp?.forEach((item, i) => {
      if (i === 0) {
        first.push(item)
      } else {
        second.push(item)
      }
    })
  })

  return [first, second]
}

export const transformToArray = (data: Obj<any>): Array<any> => {
  return Object.values(data).reduce((acc, item) => {
    acc = [...acc, ...item]
    return acc
  }, [])
}

export const transformScheduleData = (data: any[]) => {
  interface ICL {
    EV: any[]
  }

  let CS, CL: ICL

  interface IModel {
    events: any[]
    sports?: any
    days?: any
  }

  const model: IModel = {
    events: [],
  }

  data?.map((item) => {
    if (item.type === 'CS') {
      if (item.ID === '0') {
        CS = {}
        model.sports = CS
      }
      if (item.ID === 'D1') {
        CS = {}
        model.days = CS
      }
    }

    if (item.type === 'CL') {
      item.EV = []
      CS[item.NA] = item
      CL = item
    }

    if (item.type === 'EV') {
      CL.EV.push(item)
      model.sports[item.CL].EV.push(item)
      model.events.push(item)
    }
  })

  const day = Object.keys(model.days)[0]

  return {
    model,
    day,
  }
}

// export const transformMR = (MR: string) => {
// 	// const reqExp = /\#\D+?(?=\$)/g
// 	const res = MR.match(reqExp)
//
// 	return res
// }

interface IOutcome {
  outcome: string
  outcomeId: string
  specifiers: any
  odds: any
  status: string
  id: number
}

interface IBt {
  total?: string
  hcp?: string
}
interface ICompetitors extends IBt {
  competitor1: string
  competitor2: string
}

export const transformMatchBet = (outcomes: Array<IOutcome>): ITransformMatchBetReturn => {
  const result = {
    competitor1: {
      over: [],
      under: [],
    },
    draw: {
      over: [],
      under: [],
    },
    competitor2: {
      over: [],
      under: [],
    },
  }

  // const maxLength = outcomes.reduce((acc, { odds }) => {
  // 	if (acc < odds.length) {
  // 		acc = odds.length
  // 	}
  //
  // 	return acc
  // }, 0)
  //
  // const resultObj = Object.keys(result).reduce((acc, key) => {
  // 	acc[key] = {
  // 		over: new Array(maxLength).fill(0, 0, maxLength),
  // 		under: new Array(maxLength).fill(0, 0, maxLength),
  // 	}
  //
  // 	return acc
  // }, result)
  //
  // console.log(resultObj)

  outcomes?.forEach(({ specifiers, odds }) => {
    odds?.forEach((odd: OddOutcome) => {
      const oddName = odd.outcome?.toLowerCase()

      if (oddName?.includes('competitor1')) {
        transformOverUnder(odd, result.competitor1)
      }

      if (oddName?.includes('competitor2')) {
        transformOverUnder(odd, result.competitor2)
      }

      if (oddName?.includes('draw')) {
        transformOverUnder(odd, result.draw)
      }

      // odd.outcome = transformOutcomeName(
      // 	oddName ? oddName : '',
      // 	specifiers,
      // 	competitors,
      // 	'total'
      // )
    })
  })

  return result
}

const transformOverUnder = (odd: OddOutcome, obj: IOverUnderOutcomes) => {
  const oddName = odd.outcome?.toLowerCase()

  if (oddName?.includes('over')) {
    obj.over.push(odd)
  }

  if (oddName?.includes('under')) {
    obj.under.push(odd)
  }
}

export const transformCorrectScore = (odds: Array<OddOutcome>) => {
  const result: ITransformCorrectScoreReturn = {
    competitor1: [],
    draw: [],
    competitor2: [],
  }

  odds?.forEach((item) => {
    const [home, away] = item.outcome?.split(':')

    if (Number(home) > Number(away)) {
      result.competitor1.push(item)
    }

    if (Number(home) < Number(away)) {
      result.competitor2.push(item)
    }

    if (Number(home) === Number(away)) {
      result.draw.push(item)
    }
  })

  return result
}

export const transformOddName = (outcomes, data) => {
  if (outcomes?.length === 0) {
    outcomes = [{ outcome: '1', status: 1 }, outcomes[0]]
  }
  const outcomesTitles = [
    {
      initial: '{$competitor1}',
      result: '',
    },
    {
      initial: '{$competitor2}',
      result: '',
    },
    {
      initial: '{total}',
      result: data.total,
    },
    {
      initial: '({-hcp})',
      result: data.hcp,
    },
    {
      initial: '({+hcp})',
      result: data.hcp,
    },
    {
      initial: 'over',
      result: 'O',
    },
    {
      initial: 'under',
      result: 'U',
    },
    {
      initial: 'yes',
      result: 'Yes',
    },
    {
      initial: 'no',
      result: 'No',
    },
  ]
  const replace = (str, i) => {
    return outcomesTitles.reduce((acc, item) => {
      if (str.includes(str)) {
        const replacedValue =
          str.includes('hcp') && i === 1 && item.result !== ''
            ? Number(item.result) * -1
            : item.result

        str = str.replace(item.initial, `${replacedValue} `)
        return str
      }
      return str
    }, '')
  }

  outcomes = outcomes?.map((item, i) => {
    item.outcome = item.outcome ? replace(item.outcome, i) : ''

    return item
  })

  return outcomes
}

export const transformOutcomeName = (
  name,
  specifiers,
  competitors,
  currentOutcome?: { title: string; idx: number } | null
) => {
  if (!currentOutcome) {
    currentOutcome = null
  }
  if (!name) {
    return name
  }

  const data = {
    ...specifiers,
    competitor1: competitors?.[0]?.name || '',
    competitor2: competitors?.[1]?.name || '',
  }

  // const inBreckets = /(?<=\{).+\?|\w+(?=\})/gi
  // const words = name.match(inBreckets)

  if (currentOutcome) {
    if (currentOutcome && typeof currentOutcome === 'object') {
      const { title, idx } = currentOutcome

      if (data[title]?.includes(':')) {
        return data[title]
      }

      const result = idx ? (Number(data[title]) * -idx).toFixed(1) : data[title]

      return Number(result) > 0 ? `+${result}` : result
    }

    return specifiers[currentOutcome]
  }

  const withBreckets = /{(.*?)}/gi
  const onlyWords = /\w+/gi

  const wordsInBreckets = name.match(withBreckets)

  if (wordsInBreckets) {
    const word = wordsInBreckets.reduce((acc, item) => {
      const cleanWord = item.match(onlyWords)
      const value = data[cleanWord[0]] || ''

      acc = acc.replace(item, value)
      return acc
    }, name)

    return uppercaseFirstLetter(word)
  }

  return uppercaseFirstLetter(name)
}

export const uppercaseFirstLetter = (str) => {
  if (str) {
    return (str = str.replace(str[0], str[0].toUpperCase()))
  }
}
