import React from 'react'
import { useTranslation } from 'react-i18next'

const Bonus = ({ close }) => {
  const { t } = useTranslation()
  const closeHandler = () => {
    close('showBonus')
  }

  return (
    <div className="ip-bonus m-b-10">
      <div className="flex align-middle full-height ip-bonus__wrap">
        <div className="fal fa-times ip-bonus__close" onClick={closeHandler} />
        <a className="ip-bonus__placeholder" href="#">
          <span className="font font--white font--10 text-uppercase">
            {t('COMMON.ASIDE_RIGHT.BONUS')}
          </span>
        </a>
        <p className="font font--14 font--white text-ellipsis">
          {t('COMMON.ASIDE_RIGHT.GET_YOUR_BONUS') + ' $15'}
        </p>
        <hr className="ip-bonus__hr" />
        <p className="font font--15 font--green text-nowrap">{t('COMMON.ASIDE_RIGHT.GET_BONUS')}</p>
      </div>
    </div>
  )
}

export default Bonus
