import { IRootState } from '../reducers/rootReducer'
import { CurrencyConfig } from '../../helpers/floatToCurrency'

export const getConfig = (state: IRootState): any => {
  return state?.config
}
export const isLoggedIn = (state: IRootState): boolean => {
  // return true
  return state?.config?.CUSTOMER_CONFIG?.LOGGED_IN ?? false
}
export const getSessionId = (state: IRootState): string => {
  return state?.config?.CUSTOMER_CONFIG?.SESSION_ID ?? ''
}

export const getToken = (state: IRootState): string => {
  return state?.config?.CUSTOMER_CONFIG?.TOKEN ?? '-1'
}

export const getLn = (state: IRootState): number => {
  return state?.config?.CUSTOMER_CONFIG?.LANGUAGE_ID ?? 1
}

export const getLocales = (state: IRootState): any => {
  return state?.config?.locales
}

export const getVerified = (state: IRootState): number => {
  return state?.config?.CUSTOMER_CONFIG?.CUSTOMER_TYPE ?? 0
}

export const getOddsType = (state: IRootState): string => {
  return state?.config?.CUSTOMER_CONFIG?.ODDS_TYPE ?? ''
}

export const getOddTypes = (state: IRootState): any => {
  return state?.config?.oddTypes ?? []
}
export const getUserName = (state: IRootState): string => {
  return state?.config.CUSTOMER_CONFIG?.USER_NAME || ''
}
export const getCurrencySymbol = (state: IRootState): string => {
  return state?.config.CUSTOMER_CONFIG?.CURRENCY_SYMBOL || ''
}
export const getCurrency = (state: IRootState): CurrencyConfig => {
  return {
    CURRENCY_SYMBOL: state?.config.CUSTOMER_CONFIG?.CURRENCY_SYMBOL ?? '&#36;',
    CURRENCY_GROUP_SEPARATOR: state?.config.CUSTOMER_CONFIG?.CURRENCY_GROUP_SEPARATOR ?? ',',
    CURRENCY_DECIMAL_SEPARATOR: state?.config.CUSTOMER_CONFIG?.CURRENCY_DECIMAL_SEPARATOR ?? '.',
    CURRENCY_PREFIX_SYMBOL: state?.config.CUSTOMER_CONFIG?.CURRENCY_PREFIX_SYMBOL ?? false,
    CURRENCY_MIN_SEP_VALUE: state?.config.CUSTOMER_CONFIG?.CURRENCY_MIN_SEP_VALUE ?? '1000',
    CURRENCY_SPACE_REQUIRED: state?.config.CUSTOMER_CONFIG?.CURRENCY_SPACE_REQUIRED ?? true,
    CURRENCY_PLURAL_SYMBOL: state?.config.CUSTOMER_CONFIG?.CURRENCY_PLURAL_SYMBOL ?? '',
    CURRENCY_EXCHANGE_RATE: state?.config.CUSTOMER_CONFIG?.CURRENCY_EXCHANGE_RATE ?? '1.00',
  }
}
export const getFullName = (state: IRootState): string => {
  return state?.config.CUSTOMER_CONFIG?.FULL_NAME || ''
}

export const getComplyId = (state: IRootState): string => {
  return state?.config.CUSTOMER_CONFIG?.GeoComplyId ?? ''
}
export const getTZ = (state: IRootState): string => {
  return state?.config.CUSTOMER_CONFIG?.TZ || ''
}
export const getTZA = (state: IRootState): string => {
  return state?.config.CUSTOMER_CONFIG?.TZA || ''
}

export const getAnimation = (state: IRootState): string => {
  return state?.config.SITE_CONFIG?.ANIMATION ?? ''
}

export const getLastLogin = (state: IRootState): string => {
  return state?.config.CUSTOMER_CONFIG?.LAST_LOGIN_TIME || ''
}

export const getMainBalance = (state: IRootState): string => {
  return state?.config.balance?.main || ''
}
export const getBonusBalance = (state: IRootState): string => {
  return state?.config.balance?.bonus || ''
}
export const getTotalBalance = (state: IRootState): string => {
  return state?.config.balance?.total || ''
}
