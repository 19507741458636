import React from 'react'
import BreadCrumbs from './BreadCrumbs'
// import BreadCrumbDropDown from './BreadCrumbDropDown'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getInplay } from 'redux/selectors/inplaySelectors'
import { getEvent } from 'redux/selectors/eventSelectors'
import { IRootState } from 'redux/reducers/rootReducer'

const BreadCrumbsWrapper = () => {
  const location = useLocation()

  const event = useSelector(getEvent)
  const data = useSelector(getInplay)
  const { data: table } = useSelector((state: IRootState) => state.prematch)

  // const getName = () => {
  // 	if (table[1] && location.pathname.includes('/sports/')) {
  // 		return table[1].TB.split('#¬')[1].split(',')[0]
  // 	} else {
  // 		return event.NA
  // 	}
  // }
  // const breadCrumbsData = useCallback(() => {
  // 	if (
  // 		location.pathname.includes('/sports/') &&
  // 		!location.pathname.includes('event')
  // 	) {
  // 		return table[1] ? getName() : ''
  // 	}
  // 	const cl = data.find(item => item.ID === event.CL)
  //
  // 	if (event.CL === '92') {
  // 		if (cl) {
  // 			return cl.CT[0]
  // 		}
  // 	}
  //
  // 	if (cl) {
  // 		const ct = cl.CT.find(item => item.NA === event.CT)
  // 		if (ct) {
  // 			return ct
  // 		}
  // 	}
  //
  // 	return {}
  // }, [event, table])
  //
  // const checkLocation = () => {
  // 	return (
  // 		location.pathname.includes('/league/event') ||
  // 		location.pathname.includes('/sports/')
  // 	)
  // }

  return (
    <div className="breadcrumbs">
      <div className="flex align-middle breadcrumbs__wrap">
        <BreadCrumbs />
        {/* {checkLocation() && (
					<BreadCrumbDropDown NA={getName()} data={breadCrumbsData()} />
				)} */}
      </div>
    </div>
  )
}

export default BreadCrumbsWrapper
