import React from 'react'

const LoaderProgress = ({ progress }) => {
  return (
    <div className="loader-progress" style={{ width: `${progress}%` }}>
      <div className="loader-progress-inner">
        <div className="loader-progress-inner-runner" />
      </div>
    </div>
  )
}

export default LoaderProgress
