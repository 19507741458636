import React from 'react'

type MarketFavouriteProps = {
  toggleFavMarket: (e) => void
  id: number
  isFav: boolean
}

const MarketFavourite: React.FC<MarketFavouriteProps> = ({ id, toggleFavMarket, isFav }) => {
  return (
    <a
      className={
        isFav
          ? 'gll-market-group__favourite gll-market-group__favourite--active'
          : 'gll-market-group__favourite'
      }
      onClick={e => {
        e.stopPropagation()
        toggleFavMarket(id)
      }}
    />
  )
}

export default MarketFavourite
