import React from 'react'

const Video = () => {
    return (
        <>
        <div className="l-game">
    <div className="l-game__left">
        <div className="l-game__video">
            <video width="100%" height="100%">
                <source src="https://v.ftcdn.net/03/83/06/75/240_F_383067506_KkaGzbis253FpjJo5LNUSXPUArnpcYkm_ST.mp4" type='video/ogg; codecs="theora, vorbis"' />
            </video>
            <div className="l-game__play">
                <svg width="100%" height="100%" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22.6564 16.5795C24.614 15.4169 24.614 12.5831 22.6564 11.4205L5.28179 1.10273C3.28202 -0.0848236 0.75 1.35638 0.75 3.68219V24.3178C0.75 26.6436 3.28203 28.0848 5.2818 26.8973L22.6564 16.5795Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
        </div>
    </div>
    <div className="l-game__right">
        <div className="l-game__head">
            <div className="c-icon c-icon--play l-game__icon"></div>
            <p className="font">Set 1 game 6</p>
        </div>
        <div className="l-game__body">
            <div className="flex align-justify l-game__row">
                <p className="font">Pedro Martinez</p>
                <p className="font font--semibold font--red">40</p>
            </div>
            <div className="flex align-justify l-game__row">
                <p className="font">Pedro Martinez</p>
                <p className="font font--red">42</p>
            </div>
        </div>
        <div className="l-game__footer">
            <div className="l-game__group">
                <button className="btn l-game__btn"><span className="font font--12 font--default-alpha-05">1</span><span className="font font--12">2.17</span></button>
                <button className="btn l-game__btn"><span className="font font--12 font--default-alpha-05">1</span><span className="font font--12">2.17</span></button>
            </div>
        </div>
    </div>
</div>

        </>
    )
}

export default Video;