import React from 'react'
import '../../../assets/styles/footer.css'
import { links } from '../../../connection/links'
import FooterList from '../../footerComponents/FooterList'
import FooterColumnTitle from '../../footerComponents/FooterColumnTitle'
import FooterColumnItem from '../../footerComponents/FooterColumnItem'
import FooterPartners from '../../footerComponents/FooterPartners'

const footerLinks = [
	{
		title: 'Help',
		id: '0',
		links: [
			{
				href: '/',
				title: 'Deposit',
				id: '0',
			},
			{
				href: '/',
				title: 'Withdraw',
				id: '1',
			},
			{
				href: '/',
				title: 'Faq',
				id: '2',
			},
			{
				href: '/',
				title: 'Terms and Conditions',
				id: '3',
			},
			{
				href: '/',
				title: 'Responsible Gambling',
				id: '4',
			},
		],
	},
	{
		title: '',
		id: '1',
		links: [
			{
				href: '/',
				title: 'Privacy Policy',
				id: '0',
			},
			{
				href: '/',
				title: 'Cookies Policy',
				id: '1',
			},
			{
				href: '/',
				title: 'Fair Payouts',
				id: '2',
			},
			{
				href: '/',
				title: 'Complaints Procedure',
				id: '3',
			},
			{
				href: '/',
				title: 'Rules',
				id: '4',
			},
		],
	},
	{
		title: 'Products',
		id: '2',
		links: [
			{
				href: '/',
				title: 'Sportsbook',
				id: '0',
			},
			{
				href: '/',
				title: 'Live bet',
				id: '1',
			},
			{
				href: '/',
				title: 'Virtual bet',
				id: '2',
			},
			{
				href: '/',
				title: 'Poker',
				id: '3',
			},
			{
				href: '/',
				title: 'Live casino',
				id: '4',
			},
		],
	},
	{
		title: 'Support',
		id: '3',
		links: [
			{
				href: '/',
				title: 'Support',
				id: '0',
			},
			{
				href: '/',
				title: 'Contact Us',
				id: '1',
			},
			{
				href: '/',
				title: 'Online Chat',
				id: '2',
			},
		],
	},
	{
		title: 'Social Networks',
		id: '4',
		links: [
			{
				href: '/',
				title: 'Facebook',
				id: '0',
			},
			{
				href: '/',
				title: 'Youtube',
				id: '1',
			},
			{
				href: '/',
				title: 'Google plus',
				id: '2',
			},
		],
	},
]

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__content">
				<div className="footer__info">
					{footerLinks?.map(({ id, title, links }) => (
						<FooterList key={id}>
							<FooterColumnTitle title={title} />
							{links?.map(({ href, title: t, id: d }) => (
								<FooterColumnItem link={href} title={t} key={d} />
							))}
						</FooterList>
					))}
				</div>
				{/*<FooterPartners/>*/}
				<div className="footer__copyright">
					<p className="font font--grey-400 font--11 footer__text">
						© 2018 Absolute Bet
					</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer
