import { takeEvery, call, put, fork, select, take } from 'redux-saga/effects'
import {
  PrematchEventWatchersTypes,
  prematchEventWorkers,
} from '../../actions/prematchEventActions'
import { links } from '../../../connection/links'
import { get } from '../../../helpers/asyncRequests'
import { types } from '../../types/types'
import { transformMarkets } from '../../../helpers/transform/eventTransform'
import { IMatch, ITournament, Nullable } from '../../../helpers/commonInterfaces/interfaces'
import { createSubscriptionChannel, unsubscribe } from '../subscriptionHandler'
import _ from 'lodash'
import { store } from '../../store'
import { eventWorkers } from '../../actions/eventActions'
import { getInplayTree } from '../../selectors/inplaySelectors'
import { getFirstLeagueId } from '../../../helpers/utils/utils'
import { getFirstEventId } from '../../selectors/eventSelectors'
import { emitterByCmd } from '../../utils/emitter'

type GetEventType = {
  ids: {
    id: string
    leagueId: string
    sportId: string
  }
  type: PrematchEventWatchersTypes
}

function* enableEventShortPull(id: number | string) {
  const config = yield select((state) => state.config)
  const channel = yield call(
    createSubscriptionChannel,
    '/feedapi/?key=event&eventId=' + id,
    { eventId: id },
    'event-' + id,
    [
      (emitter, rs) => {
        const oldState = _.cloneDeep(store.getState().prematchEvent)
        emitterByCmd(oldState, rs, emitter)
      },
    ],
    20000,
    config
  )

  yield fork(function* () {
    yield take(`REMOVE_EVENT_BY_ID_${id}`)
    unsubscribe('/feedapi/?key=event', 'event-' + id, 20000, config)
    yield removeEvent()
  })

  try {
    while (true) {
      const { event } = yield take(channel)
      yield setEventData(event)
    }
  } catch (e) {
    channel.close()
  }
}

function* loadEventDataCaseOne() {
  const data = yield select(getInplayTree)

  const events = yield getEvents({ id: getFirstLeagueId(data), type: '' })
  const eventId = yield getFirstEventId(events)

  yield enableEventShortPull(eventId)
}

function* setEventData(event: Nullable<IMatch | ITournament>) {
  yield put(prematchEventWorkers.setEvent(event))
  yield setMarkets(event)
}

function* getEvent({ ids }: GetEventType) {
  const { id, leagueId } = ids

  try {
    !id || !leagueId ? yield loadEventDataCaseOne() : yield enableEventShortPull(id)
  } catch (e) {
    console.log(e)
  }
}

function* getEvents({ id }: { id: string | number; type: string }) {
  try {
    const { events } = yield call(get(links.prematchLeague + id))
    return events
  } catch (e) {
    console.log(e)
  }
}

function* setLeagueEvents({ id }: { id: number; type: string }) {
  const events = yield getEvents({ id, type: '' })
  yield put(eventWorkers.setEvents(events))
}

function* setMarkets(event: Nullable<IMatch | ITournament>) {
  try {
    if (event && event.odds) {
      const data = transformMarkets(
        event.odds || [],
        event.eventType === 'Match' ? event.competitors : null,
        'prematch'
      )
      yield put(prematchEventWorkers.setMarkets(data))
    }
  } catch (e) {
    console.log(e)
  }
}

function* setLeaguesEvents() {
  const state = yield select((state) => state)
  const sport = state.prematch.data.find((sport) => sport.id === state.prematchEvent.event.sportId)

  const events: any[] = []

  for (const category of sport.categories) {
    if (category.id === state.prematchEvent.event.categoryId) {
      for (const league of category.leagues) {
        console.log('that league')
        console.log(league)
        events.push(yield loadLeagueEvents(league.id))
        events[events.length - 1][0].leagueName = league.name
      }
    }
  }

  yield put(prematchEventWorkers.setEvents(events))
}

function* loadLeagueEvents(id: number | string) {
  try {
    const { events } = yield call(get(links.prematchLeague + id))
    return events
  } catch (e) {
    console.log(e)
  }
}

function* removeEvent() {
  yield put(prematchEventWorkers.clearEventData())
}

export const prematchEventSagas = [
  takeEvery(types.SET_PREMATCH_EVENT_WATCHER, getEvent),
  takeEvery(types.REMOVE_PREMATCH_EVENT, removeEvent),
  takeEvery(types.SET_PREMATCH_SAME_LEAGUE_EVENTS_WATCHER, setLeagueEvents),
  takeEvery(types.SET_PREMATCH_SAME_LEAGUES_EVENTS_WATCHER, setLeaguesEvents),
]
