import React from 'react'

const ControlBarItemIcon = ({ props }) => {
  const {
    active,
    onClick,
    className,
    innerStyle = '',
    parentStyle = '',
    collapse = () => null,
  } = props

  return (
    <a
      className={
        active
          ? 'ipn-control-bar__item ipn-control-bar__item--active'
          : 'ipn-control-bar__item' + ` ${parentStyle}`
      }
      onClick={onClick ? onClick : collapse}
    >
      <i className={innerStyle} />
    </a >
  )
}

export default React.memo(ControlBarItemIcon)
