import React from 'react'

const EventOtherBets = ({ oth, toEvent }) => {
	return (
		<td className="td">
			<button
				className="btn btn--coff ip-competition__coff ip-competition__coff--outline"
				onClick={toEvent}>
				<span className="font font--12 font--blue font--semibold">+{oth}</span>
			</button>
		</td>
	)
}

export default React.memo(EventOtherBets)
