import Overview from '../pages/live/Overview'
import Schedule from '../pages/live/Schedule'
import Event from '../pages/live/Event'
import LiveEvent from '../pages/live/LiveEvent'
import LiveRedirection from 'components/Routing/liveRedirection'

export const liveRoutes = [
  {
    path: '/live/overview',
    component: Overview,
    exact: true,
  },
  {
    path: '/live/event/:sportId/:leagueId/:eventId',
    component: Event,
    exact: true,
  },
  {
    path: '/live/event/:sportId',
    component: Overview,
    exact: false,
  },
  {
    path: '/live/event',
    component: Event,
  },
  {
    path: '/live/schedule',
    component: Schedule,
    exact: true,
  },
  {
    component: LiveRedirection,
  },
]
