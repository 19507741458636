import { types } from '../types/types'
import { favoritesWorkersTypes } from '../actions/favoritesActions'

const lsState = JSON.parse(localStorage.getItem('favorites') ?? '{}')
const initialState = Object.entries(lsState).length > 0 ? lsState : {
  sports: [],
  leagues: {},
  events: [],
  markets: [],
}

export type FavoritesState = typeof initialState

const saveFavToLocalStorage = (state: FavoritesState) => {
  localStorage.setItem('favorites', JSON.stringify(state))
}

export const favorites = (
  state: FavoritesState = initialState,
  action: favoritesWorkersTypes
): FavoritesState => {
  let newState: FavoritesState
  switch (action.type) {
    case types.ADD_SPORT_TO_FAV:
      newState = {
        ...state,
        sports: [...state.sports, action.payload],
      }
      saveFavToLocalStorage(newState)
      return newState
    case types.ADD_LEAGUE_TO_FAV:
      if (Object.keys(state.leagues).some((sport) => action.payload.sportId === sport)) {
        newState = {
          ...state,
          leagues: {
            ...state.leagues,
            [action.payload.sportId]: [
              ...state.leagues[action.payload.sportId],
              action.payload.leagueId,
            ],
          },
        }
        saveFavToLocalStorage(newState)
        return newState
      } else {
        newState = {
          ...state,
          leagues: {
            ...state.leagues,
            [action.payload.sportId]: [action.payload.leagueId],
          },
        }
        saveFavToLocalStorage(newState)
        return newState
      }
    case types.ADD_EVENT_TO_FAV:
      newState = {
        ...state,
        events: [...state.events, action.payload],
      }
      saveFavToLocalStorage(newState)
      return newState
    case types.ADD_MARKET_TO_FAV:
      newState = {
        ...state,
        markets: [...state.markets, action.payload],
      }
      saveFavToLocalStorage(newState)
      return newState
    case types.REMOVE_SPORT_FROM_FAV:
      newState = {
        ...state,
        sports: [...state.sports.filter((sport) => sport !== action.payload)],
      }
      saveFavToLocalStorage(newState)
      return newState
    case types.REMOVE_LEAGUE_FROM_FAV:
      newState = {
        ...state,
        leagues: {
          ...state.leagues,
          [action.payload.sportId]: state.leagues[action.payload.sportId].filter(
            (league) => league !== action.payload.leagueId
          ),
        },
      }
      saveFavToLocalStorage(newState)
      return newState
    case types.REMOVE_EVENT_FROM_FAV:
      newState = {
        ...state,
        events: [...state.events.filter((event) => event !== action.payload)],
      }
      saveFavToLocalStorage(newState)
      return newState
    case types.REMOVE_MARKET_FROM_FAV:
      newState = {
        ...state,
        markets: [...state.markets.filter((market) => market !== action.payload)],
      }
      saveFavToLocalStorage(newState)
      return newState
    default:
      return state
  }
}
