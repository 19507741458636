import { types } from '../types/types'
import { IBetslip } from '../sagas/betslip/betslipSagas'

export interface IBetslipReducer extends IBetslip {
  ts: number
  tr: number
  quickBet: boolean
  updatesEnabled: true
  betsPlaced: boolean
  popup: boolean
  path: string
  er: string
  odds: number[]
  refreshing: boolean
}
const initialState: IBetslipReducer = {
  bg: '',
  sr: 0,
  mr: false,
  ir: false,
  vr: '60',
  cs: 1,
  bt: [],
  dm: {},
  mo: [],
  placedBt: [],
  odds: [],
  bs: [1, 2],
  ts: 0,
  tr: 0,
  path: '',
  updatesEnabled: true,
  quickBet: false,
  betsPlaced: false,
  popup: false,
  er: '',
  refreshing: false,
}

export const betslip = (state = initialState, action: any) => {
  const bt = state.bt
  const replacingIndex = bt.findIndex(
    (bet) => Number(bet?.pt?.[0]?.pi) == Number(action?.payload?.id)
  )
  let update = false // for odds update
  // const isDmChanged = bt.length !== action.payload?.bt?.length
  // if (isDmChanged && action.payload?.dm) {
  // 	action.payload.dm.st = 0
  // 	action.payload.dm.stake = ''
  // }
  const newDM = state.dm
  switch (action.type) {
    case types.SET_BETSLIP:
      if (action.payload == '') {
        return {
          ...state,
          bt: [],
          mo: [],
        }
      }
      if (typeof action.payload.mo !== 'undefined') {
        return {
          ...state,
          ...action.payload,
          odds: state.odds.filter((odd) =>
            action.payload.bt.some((bt) => Number(bt.pt[0].pi) === odd)
          ),
        }
      } else {
        return {
          ...state,
          ...action.payload,
          odds: state.odds.filter((odd) =>
            action.payload.bt.some((bt) => Number(bt.pt[0].pi) === odd)
          ),
          dm: [],
          mo: [],
        }
      }
    case types.ADD_ODD:
      return {
        ...state,
        odds: [...state.odds, action.payload],
      }
    case types.REMOVE_ODD:
      return {
        ...state,
        odds: state.odds.filter((odd) => odd !== action.payload),
      }
    case types.SET_QUICKBET:
      return {
        ...state,
        quickBet: action.payload,
      }
    case types.SET_PATH:
      return {
        ...state,
        path: action.payload,
      }
    case types.UPDATE_ODD_VALUE:
      if (bt[replacingIndex] && state.updatesEnabled) {
        const prevns = JSON.parse(sessionStorage.getItem('ns') || '{}')
        prevns[action?.payload?.id.toString()] = prevns[action?.payload?.id.toString()]?.replace(
          /#o=(.*)#f=/gi,
          '#o=' + action.payload.oddValue + '#f='
        ) ?? undefined
        sessionStorage.setItem('ns', JSON.stringify(prevns))
        if (bt[replacingIndex].od > action.payload.oddValue) {
          bt[replacingIndex].growth = 'down'
          update = true
        } else {
          if (bt[replacingIndex].od < action.payload.oddValue) {
            bt[replacingIndex].growth = 'up'
            update = true
          }
        }
        bt[replacingIndex].od = action.payload.oddValue
      }
      return {
        ...state,
        bt,
        sr: update ? 14 : 0,
      }
    case types.DISABLE_ODDS_UPDATES:
      return {
        ...state,
        updatesEnabled: false,
      }
    case types.ENABLE_ODDS_UPDATES:
      return {
        ...state,
        updatesEnabled: true,
      }
    case types.SET_MAX_STAKES_POPUP:
      return {
        ...state,
        popup: action.payload,
      }
    case types.CLEAN_LIMITS:
      newDM.st = newDM.lm
      delete newDM.lm
      return {
        ...state,
        bt: state.bt.map((bet) => {
          const newBet = bet
          delete newBet.lm
          return newBet
        }),
        dm: newDM,
        er: '',
      }

    case types.SET_ERROR_MESSAGE:
      return {
        ...state,
        er: action.payload,
      }
    default:
      return state
  }
}
