export default {
	overUnder: [
		156,
		157,
		158,
		62,
		63,
		64,
		17,
		18,
		19,
		82,
		83,
		84,
		212,
		214,
		215,
		219,
		222,
		701,
		702,
		239,
		241,
		242,
		257,
		258,
		259,
		265,
		266,
		267,
		269,
		692,
		224,
		229,
		693,
		685,
	],
	handicap: [61, 210, 218, 282, 16, 237, 256, 689, 691, 223],
	anytime: [
		15,
		856,
		891,
		253,
		36,
		37,
		890,
		892,
		893,
		914,
		963,
		1034,
		946,
		948,
		966,
		967,
		915,
		925,
		949,
		908,
		912,
		919,
		487,
	],
	doubleChance: [499, 500, 42, 910, 246],
}
