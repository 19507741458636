import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { get } from '../../../helpers/asyncRequests'
import { types } from '../../types/types'
import { links } from '../../../connection/links'
import { prematchLeagueWorkers } from '../../actions/prematchLeagueActions'
import { getAllSports } from '../../selectors/prematchSelectors'
import { findLeague } from '../../../helpers/utils/utils'
import { prematchWorkers } from '../../actions/prematchActions'
import { getAdditionalDataForCurrentSport } from '../liveSagas/overview/overviewSagas'

// yield put(
// 	prematchLeagueWorkers.setMarkets(
// 		transformMarkets(
// 			event.odds || [],
// 			event.competitors || null,
// 			'prematch'
// 		)
// 	)
// )

type GetPrematchLeague = {
  ids: {
    sportId: string
    id: string
  }
  type: string
}

function* getPrematchLeague({ ids }: GetPrematchLeague) {
  const { sportId, id } = ids

  try {
    let prematchAll = yield select(getAllSports)
    yield fork(getEvents, id)

    if (!prematchAll) {
      prematchAll = yield call(get(links.prematchAll))
      yield put(prematchWorkers.setPrematchAll(prematchAll))
    }

    console.log(prematchAll)

    yield put(prematchLeagueWorkers.setLeague(findLeague({ data: prematchAll, sportId, id })))
  } catch (e) {
    console.log(e)
  }
}

function* getAdditionalData({ events }) {
  try {
    const {
      allOddsName: oddsName,
      currentOddName: oddName,
      allOddsId: oddsId,
      currentOddId: oddId,
    } = getAdditionalDataForCurrentSport(events)
    yield put(prematchLeagueWorkers.setCurrentOddName(oddName))
    yield put(prematchLeagueWorkers.setCurrentOddId(oddId))
    yield put(prematchLeagueWorkers.setAllOddsNames(oddsName))
    yield put(prematchLeagueWorkers.setAllOddsIds(oddsId))
  } catch (e) {
    console.log(`Error in getAdditional data:`, e)
  }

  return
}

function* clearPrematchLeague() {
  yield put(prematchLeagueWorkers.clearLeague())
}

function* getEvents(id: string) {
  try {
    const data = yield call(get(links.prematchLeague + id))
    yield put(prematchLeagueWorkers.setEvents(data))

    yield getAdditionalData(data)
  } catch (e) {
    console.log(e)
  }
}

export const prematchLeagueSagas = [
  takeLatest(types.GET_PREMATCH_LEAGUE_WATCHER, getPrematchLeague),
  takeLatest(types.CLEAR_PREMATCH_LEAGUE_WATCHER, clearPrematchLeague),
]
