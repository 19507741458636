import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrency } from 'redux/selectors/configSelectors'
import { floatToCurrency } from '../../../../../helpers/floatToCurrency'

const Possible = ({ title, amount }) => {
  const currencyConfig = useSelector(getCurrency)
  return (
    <div className="betslip__posible">
      <div className="flex align-middle align-justify">
        <p className="font font--12">{title}</p>
        <p
          className="font font--12 font--semibold"
          dangerouslySetInnerHTML={{
            __html: floatToCurrency(amount, currencyConfig, true, true),
          }}
        ></p>
      </div>
    </div>
  )
}

export default Possible
