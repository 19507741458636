import React from 'react'

const BetLockButton = ({ text = '' }) => {
  return (
    <button className="btn btn--coff ip-competition__coff ip-competition__coff--big ip-competition__coff--disabled">
      <span className="font font--11 font--default-alpha-05">{text}</span>
      <i className="fal fa-lock-alt" />
    </button>
  )
}

export default React.memo(BetLockButton)
