import React from 'react'
import TableRow from '../common/competition/competitionContent/table/TableRow'
import TitleCell from '../common/competition/competitionContent/table/TitleCell'
import Table from '../common/competition/competitionContent/table/Table'

const LeagueHeader = ({ league }) => {
  return (
    <div className="ip-competition">
      <Table>
        <TableRow className="tr tr--title">
          <TitleCell id={league.id} sportId={league.sportId} name={league.name} iconCode={league.iconCode} />
        </TableRow>
      </Table>
    </div>
  )
}

export default LeagueHeader
