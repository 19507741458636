import React from 'react';

const Open = () => {
    return (
        <div className="container">
            <div className="container__head">
                <p className="font font--semibold">Active</p>
            </div>
            <div className="container__wrap">
                <div className="container__header">
                    <div className="flex align-middle action">
                        <div className="flex align-middle action action__item action__item--back"><i
                            className="fal fa-angle-left"></i>
                            <p className="font font--16 m-l-10">Shoot all the series and get $ 400</p>
                        </div>
                    </div>
                </div>
                <div className="offers"></div>
            </div>
        </div>
    );
};

export default Open;