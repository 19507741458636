import React from 'react'
import MarketName from './MarketName'
import MarketHeaderTabs from './MarketHeaderTabs'
import MarketOffers from './MarketOffers'
import MarketOffersTooltip from './MarketOffersTooltip'
import MarketFavourite from './MarketFavourite'
import { useDispatch, useSelector } from 'react-redux'
import { getFavorites } from 'redux/selectors/favoritesSelectors'
import { favoritesWorkers } from 'redux/actions/favoritesActions'

const MarketHeader = ({
  id,
  title,
  correctScore = false,
  rangeData,
  onToggleHandler,
}) => {
  const favorites = useSelector(getFavorites)
  const dispatch = useDispatch()
  const toggleFavMarket = (id: number | string) => {
    if (
      favorites.markets.some(market => {
        return market === id.toString()
      })
    ) {
      dispatch(favoritesWorkers.removeMarket(id.toString()))
    } else {
      dispatch(favoritesWorkers.addMarket(id.toString()))
    }
  }
  return (
    <div className="gll-market-group__header" onClick={onToggleHandler}>
      <MarketName title={title} />
      {rangeData ? correctScore && <MarketHeaderTabs {...rangeData} /> : ''}
      {/*<MarketOffers/>*/}
      {/*<MarketOffersTooltip/>*/}
      <MarketFavourite {...{ id }} {...{ toggleFavMarket }} isFav={favorites.markets.some(market => market === id.toString())} />
    </div>
  )
}

export default React.memo(MarketHeader)
