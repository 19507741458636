import React, { useEffect } from 'react'
import Competition from './CompetitionContainer'
import { useDispatch, useSelector } from 'react-redux'
import { inplayWatchers } from '../../../redux/actions/inplayActions'
import { getCurrentView } from '../../../redux/selectors/inplaySelectors'
import { start } from '../../../redux/actions/preloaderActions'
import '../../../assets/styles/competition.css'
import { getBootstrapPreloader } from 'redux/selectors/preloaderSelectors'

const SportCompetitions = () => {
  const dispatch = useDispatch()
  const bootstrapPreloader = useSelector(getBootstrapPreloader)

  const { current, chosenLeagues, currentOddName, currentOddId, allOddsName, allOddsId } =
    useSelector(getCurrentView)

  useEffect(() => {
    if (bootstrapPreloader) {
      dispatch(start())
    }
    dispatch(inplayWatchers.getStreamingEvents())
  }, [])

  // useEffect(() => {
  //   console.log('currentOddId', currentOddId)
  //   console.log('allOddsId', allOddsId)
  //   console.log('currentOddName', currentOddName)
  //   console.log('currentOddId', currentOddId)
  // }, [currentOddId, allOddsId])

  return (
    <>
      {current?.id && (
        <Competition
          current={current}
          chosenLeagues={chosenLeagues}
          currentOddName={currentOddName}
          allOddsName={allOddsName}
          currentOddId={currentOddId}
          allOddsId={allOddsId}
        />
      )}
    </>
  )
}

export default SportCompetitions
