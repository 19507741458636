import { Nullable } from 'helpers/commonInterfaces/interfaces'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getFavorites } from 'redux/selectors/favoritesSelectors'
import { getIcons } from 'redux/selectors/iconsSelectors'
import { soccerSrc } from '../../../helpers/utils/soccer'

const SportIcon = ({ name, id, style, className }) => {
  const icons = useSelector(getIcons)
  const favorites = useSelector(getFavorites)
  const [image, setImage] = useState<Nullable<HTMLImageElement>>(null)

  useEffect(() => {
    const image = new Image()
    image.src = icons.icons.find((icon) => {
      return icon.id === (id ? id : 1)
    })?.icon
    if (!image || image.src.includes('localhost')) {
      setImage({ ...new Image(), src: soccerSrc })
    } else {
      setImage(image)
    }
  }, [icons])

  if (image?.src.includes('localhost')) {
    console.log(
      `							image?.src === 'data:'
				? soccerSrc
				: image?.src
				? image.src
				: soccerSrc
			`,
      image?.src === 'data:' ? soccerSrc : image?.src ? image.src : soccerSrc
    )
  }
  return (
    <div
      className={'ipn-classification__icon ' + className}
      style={{
        backgroundImage: `url('${
          /* getSportIcon(item.id) */ !image || image.src === 'data:' ? soccerSrc : image?.src
        }'`,
      }}
    />
  )
  {
    /* <img src={image} alt={name} style={style} className={className} /> */
  }
}
SportIcon.defaultProps = {
  style: {},
  className: '',
}

export default React.memo(SportIcon)
