export const useStorage = () => {
  const setSession = (name, value) => sessionStorage.setItem(name, JSON.stringify(value))

  const setLocal = (name, value) => localStorage.setItem(name, JSON.stringify(value))

  const getSession = (name) => {
    const sS = sessionStorage.getItem(name)
    if (sS == null) {
      return ''
    } else {
      try {
        return JSON.parse(sS)
      } catch (e) {
        console.log(`Error in parsing `, sS, e)
      } finally {
        return ''
      }
    }
  }

  const getLocal = (name) => {
    const lS = localStorage.getItem(name)
    if (lS == null) {
      return ''
    } else {
      return JSON.parse(lS)
    }
  }

  const removeSession = (name) => sessionStorage.removeItem(name)

  const removeLocal = (name) => localStorage.removeItem(name)

  return {
    setSession,
    setLocal,
    getSession,
    getLocal,
    removeSession,
    removeLocal,
  }
}
