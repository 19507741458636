import React, { useState } from 'react'
import Wrap from '../../registration/elements/wrap'
import Input from '../../common/elements/Input'
import Select from '../../common/elements/Select'
import Text from '../../common/elements/Text'
import Button from '../../registration/elements/button'
import EyeIcon from '../../common/elements/EyeIcon'
import { useFormik } from 'formik'
import { useAccount } from '../../../hooks/useAccount'
import PopupSucceed from '../PopupSucceed'
import { Nullable } from 'helpers/commonInterfaces/interfaces'

const PersonalInformation = () => {
  const [state, setState] = useState({
    showPassword: false,
    showPopup: false,
  })
  const { sumbit, persInfo, schemaPersInfo } = useAccount()
  const showPasswordHandler = () => {
    setState({ ...state, showPassword: !state.showPassword })
  }

  const closePopupHandler = () => {
    window.clearTimeout(window.closePopupTimeout)
    setState({ ...state, showPopup: false })
  }

  // const init = useCallback(() => { let values = persInfo.initialValues; return values; }, [persInfo]);
  const formik = useFormik({
    initialValues: persInfo.initialValues,
    validationSchema: schemaPersInfo,
    onSubmit: (values, methods) => {
      setState({ ...state, showPopup: true })
      window.clearTimeout(window.closePopupTimeout)
      window.closePopupTimeout = setTimeout(() => {
        window.clearTimeout(window.closePopupTimeout)
        setState({ ...state, showPopup: false })
      }, 3000)
      sumbit('persInfo', values)
    },
  })

  let passwordType: Nullable<string> = null
  if (state.showPassword) passwordType = 'text'
  else passwordType = 'password'
  const { values, handleChange, handleSubmit, errors, touched } = formik
  // birth date
  let days: any[] = Array.from('x'.repeat(31))
  days = days?.map((day, idx) => {
    idx++
    if (idx < 9) {
      return {
        title: `0${idx}`,
        value: `0${idx}`,
      }
    } else {
      return {
        title: `${idx}`,
        value: `${idx}`,
      }
    }
  })
  let months: any[] = Array.from('x'.repeat(12))
  months = months?.map((month, idx) => {
    idx++
    if (idx < 9) {
      return {
        title: `0${idx}`,
        value: `0${idx}`,
      }
    } else {
      return {
        title: `${idx}`,
        value: `${idx}`,
      }
    }
  })
  let years: any[] = Array.from('x'.repeat(85))
  const start = 1935
  years = years?.map((year, idx) => {
    idx++
    if (idx < 9) {
      return {
        title: `${start + idx}`,
        value: `${start + idx}`,
      }
    } else {
      return {
        title: `${start + idx}`,
        value: `${start + idx}`,
      }
    }
  })
  return (
    <div className="container">
      <div className="container__head">
        <Text className="font font--semibold">Personal Information</Text>
      </div>
      <div className="container__wrap">
        <form onSubmit={handleSubmit}>
          <div className="account">
            <div className="left">
              <Wrap className={errors.firstName && touched.firstName && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10">First name</Text>
                <Input
                  className="field field--main"
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && touched.firstName && (
                  <Text className="font font--red font--10 error">{errors.firstName}</Text>
                )}
              </Wrap>
              <Wrap
                className={
                  (errors.day || errors.month || errors.year) &&
                  (touched.day || touched.month || touched.year) &&
                  'required required--error'
                }
              >
                <Text className="font font--default-alpha-05 m-b-10">Date of birth</Text>
                <div className="flex align-middle">
                  <Select
                    className="select select--main mn-popup__birth m-r-10"
                    optionsArray={days}
                    name="day"
                    value={values.day}
                    onChange={handleChange}
                  />
                  <Select
                    className="select select--main mn-popup__birth m-r-10"
                    optionsArray={months}
                    name="month"
                    value={values.month}
                    onChange={handleChange}
                  />
                  <Select
                    className="select select--main mn-popup__birth m-r-10"
                    optionsArray={years}
                    name="year"
                    value={values.year}
                    onChange={handleChange}
                  />
                </div>
              </Wrap>
              <Wrap className={errors.city && touched.city && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10">City</Text>
                <Input
                  className="field field--main"
                  type="text"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                />
                {errors.city && touched.city && (
                  <Text className="font font--red font--10 error">{errors.city}</Text>
                )}
              </Wrap>
              <Wrap className={errors.postcode && touched.postcode && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10">Postcode</Text>
                <Input
                  className="field field--main"
                  type="text"
                  name="postcode"
                  value={values.postcode}
                  onChange={handleChange}
                />
              </Wrap>
              <Wrap className={errors.password && touched.password && 'required required--error'}>
                <Input
                  className="field field--main"
                  type={passwordType}
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  autoComplete="on"
                />
                <EyeIcon {...{ showPasswordHandler }} type={state.showPassword} />
              </Wrap>
              <Button
                handler={() => {
                  console.log('errors: ', errors)
                }}
                type="submit"
                title="Save"
              />
            </div>
            <div className="right">
              <Wrap className={errors.surname && touched.surname && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10">Surname</Text>
                <Input
                  className="field field--main"
                  type="text"
                  name="surname"
                  value={values.surname}
                  onChange={handleChange}
                />
              </Wrap>
              <Wrap className={errors.country && touched.country && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10">Country</Text>
                <Input
                  className="field field--main"
                  type="text"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                />
              </Wrap>
              <Wrap className={errors.address && touched.address && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10">Address</Text>
                <Input
                  className="field field--main"
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                />
              </Wrap>
            </div>
            {state.showPopup && (
              <PopupSucceed
                close={closePopupHandler}
                message={'Personal information has changed successfully'}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default PersonalInformation
