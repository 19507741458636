import React from 'react';

const LiveCasinoGames = () => {
    const [time, setTime] = React.useState({
        activeFilter: '24 Hours',
        dropdown: false
    });
    const dropdownToggleHandler = () => {
        setTime({
            ...time,
            dropdown: !time.dropdown
        });
    };
    const setFilterHandler = (value) => {
        return () => {
            if (!value) {
                value = time.activeFilter;
            }
            setTime({
                activeFilter: value,
                dropdown: false
            });
        };
    };
    return (
        <div className="container">
            <div className="container__head">
                <p className="font font--semibold">Casino Games</p>
            </div>
            <div className="container__wrap">
                <div className="container__header">
                    {time.dropdown && <div className='h-popup__overlay' style={{ zIndex: 1 }} onClick={dropdownToggleHandler}></div>}
                    <div className="dropdown">
                        <span className="font dropdown__toggle" onClick={dropdownToggleHandler}>
                            {time.activeFilter === 'All time'
                                ? time.activeFilter
                                : 'Last ' + time.activeFilter}
                        </span>
                        <div className={time.dropdown ? "dropdown__menu active" : "dropdown__menu"}>
                            <span className="dropdown__item" onClick={setFilterHandler('1 Hour')}>
                                <p className="font">1 Hour</p>
                            </span>
                            <span className="dropdown__item" onClick={setFilterHandler('24 Hours')}>
                                <p className="font">24 Hours</p>
                            </span>
                            <span className="dropdown__item" onClick={setFilterHandler('1 Month')}>
                                <p className="font">1 Month</p>
                            </span>
                            <span className="dropdown__item" onClick={setFilterHandler('2 months')}>
                                <p className="font">2 months</p>
                            </span>
                            <span className="dropdown__item" onClick={setFilterHandler('All time')}>
                                <p className="font">All time</p>
                            </span>
                        </div>
                    </div>
                    <hr className="hr" />
                    <div className="calendar">
                        <div className="calendar__item"><i className="fal fa-calendar-alt"></i></div>
                    </div>
                </div>
                <div className="history">
                    <table className="cb-table">
                        <tbody>
                            <tr className="cb-table__tr cb-table__tr--title">
                                <th className="cb-table__th">
                                    <div className="text-center">
                                        <p className="font font--10 font--grey-500">#</p>
                                    </div>
                                </th>
                                <th className="cb-table__th">
                                    <div className="text-center">
                                        <p className="font font--10 font--grey-500">Date</p>
                                    </div>
                                </th>
                                <th className="cb-table__th">
                                    <div className="text-left">
                                        <p className="font font--10 font--grey-500">Game</p>
                                    </div>
                                </th>
                                <th className="cb-table__th"></th>
                                <th className="cb-table__th"></th>
                            </tr>
                            <tr className="cb-table__tr" role="row">
                                <td className="cb-table__td cb-table__td--border">
                                    <div className="text-center">
                                        <p className="font">0123456</p>
                                    </div>
                                </td>
                                <td className="cb-table__td cb-table__td--border">
                                    <div className="text-center">
                                        <p className="font">21.09, 23:10</p>
                                    </div>
                                </td>
                                <td className="cb-table__td cb-table__td--border">
                                    <p className="font">Sun of Egypt: Hold and Win</p>
                                </td>
                                <td className="cb-table__td cb-table__td--border">
                                    <p className="font font--10 font--default-alpha-05">stake</p>
                                    <p className="font">100 $</p>
                                </td>
                                <td className="cb-table__td cb-table__td--border">
                                    <p className="font font--10 font--default-alpha-05">to return</p>
                                    <p className="font">100 $</p>
                                </td>
                            </tr>
                            <tr className="cb-table__tr" role="row">
                                <td className="cb-table__td cb-table__td--border">
                                    <div className="text-center">
                                        <p className="font">1123456</p>
                                    </div>
                                </td>
                                <td className="cb-table__td cb-table__td--border">
                                    <div className="text-center">
                                        <p className="font">21.09, 23:10</p>
                                    </div>
                                </td>
                                <td className="cb-table__td cb-table__td--border">
                                    <p className="font">Sun of Egypt: Hold and Win</p>
                                </td>
                                <td className="cb-table__td cb-table__td--border">
                                    <p className="font font--10 font--default-alpha-05">stake</p>
                                    <p className="font">100 $</p>
                                </td>
                                <td className="cb-table__td cb-table__td--border">
                                    <p className="font font--10 font--default-alpha-05">to return</p>
                                    <p className="font font--green">100 $</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default LiveCasinoGames;