import React from 'react'
import ActionsCell from './actionsCell/ActionsCell'
import EventOtherBets from './otherBets/EventOtherBets'

const Tournament = ({ name, odds, toEvent, oth }) => {
  return (
    <>
      <ActionsCell />
      <td
        className="td"
        onClick={toEvent}
        style={{
          cursor: 'pointer',
        }}
      >
        <div className="flex align-middle align-justify">
          <div className="text-left overflow-hidden m-r-5">
            <div className="flex align-middle m-a-10">
              <p className="font text-ellipsis">{name}</p>
            </div>
          </div>
        </div>
      </td>
      <EventOtherBets oth={oth} toEvent={toEvent} />
    </>
  )
}

export default React.memo(Tournament)
