import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { favoritesWorkers } from 'redux/actions/favoritesActions'
import { getFavorites } from 'redux/selectors/favoritesSelectors'
import { Flag } from '../../../../../common/Flag'

const RegionHeader = ({ name, leagues, sportId, count, onHeaderClick, iconCode }) => {
  const dispatch = useDispatch()
  const favorites = useSelector(getFavorites)
  const toggleFavCategory = event => {
    event.stopPropagation()
    if (
      Object.keys(favorites.leagues).some(
        sportKey =>
          sportKey === sportId.toString() &&
          leagues.every(league =>
            favorites.leagues[sportId]?.some(lg => lg === league.id.toString())
          )
      )
    ) {
      for (const league of leagues) {
        dispatch(
          favoritesWorkers.removeLeague(
            league.id.toString(),
            sportId.toString()
          )
        )
      }
    } else {
      for (const league of leagues) {
        dispatch(
          favoritesWorkers.addLeague(league.id.toString(), sportId.toString())
        )
      }
    }
  }



  return (
    <div
      className="flex align-middle wn-classification-region__header"
      onClick={onHeaderClick}>
      <Flag iconCode={iconCode} style={{ width: '25px', height: '25px' }} />
      <p className="font font--grey-300 text text-ellipsis p-l-10">{name}</p>

      <div className={"ipn-competition__favourite" +
        (Object.keys(favorites.leagues).some(
          sportKey =>
            sportKey === sportId.toString() &&
            leagues.every(league =>
              favorites.leagues[sportId]?.some(
                lg => lg === league.id.toString()
              )
            )
        )
          ? ' active'
          : '')
      } onClick={toggleFavCategory} />
      {/* <p className="font font--grey-300 count">{count}</p> */}

    </div>
  )
}

export default RegionHeader
