import React, { useEffect, useState } from 'react'
import FixtureWrapper from '../../fixture/FixtureWrapper'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { inplayWatchers } from '../../../../../redux/actions/inplayActions'
import { getInplayTree, getStreaming } from 'redux/selectors/inplaySelectors'
import { IEvent } from 'helpers/commonInterfaces/interfaces'
import FixtureEmpty from '../../fixture/FixtureEmpty'

const CompetitionBody = ({ data, leagueId, liveTranslation }) => {
  const dispatch = useDispatch()
  const streaming = useSelector(getStreaming)
  const [filteredEvents, setFilteredEvents] = useState<IEvent[]>([])
  const inplayAll = useSelector(getInplayTree)

  useEffect(() => {
    return () => {
      dispatch({ type: `DESTROY_LEAGUE_${leagueId}` })
      dispatch(inplayWatchers.destroyLeague(leagueId))
    }
  }, [])

  useEffect(() => {
    if (data && data.length > 0) {
      let newEvents = [...data]
      if (liveTranslation && streaming) {
        newEvents = newEvents.filter(ev => {
          return !!streaming?.find(
            ({ id }) => id === ev.externalIds?.betradar?.split(':')[2]
          )
        })
      }
      setFilteredEvents(newEvents)
    }
  }, [data, liveTranslation])

  return (
    <div className="ipn-competition__body">
      {filteredEvents.length === 0 &&
        <FixtureEmpty />
      }
      {filteredEvents.map((event) => {
        const { id, sportId, leagueId } = event

        return (
          <NavLink
            onClick={(e) => {
              e.stopPropagation()
              if (inplayAll) {
                dispatch(inplayWatchers.setCurrentView(inplayAll.find(sport => sport.id === sportId) ?? inplayAll[0]))
              }
            }}
            activeClassName="active"
            className="ipn-fixture"
            key={id}
            to={{
              pathname: `/live/event/${sportId}/${leagueId}/${id}`,
            }}
          >
            <FixtureWrapper event={event} />
          </NavLink>
        )
      })}
    </div>
  )
}

export default React.memo(CompetitionBody)
