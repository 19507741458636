import { useState } from 'react'

type ScrollLoadingProps = {
  defaultLength: number
  defaultData: any[]
  selector: string
}

export const useScrollLoading = ({
  defaultLength = 5,
  defaultData = [],
  selector = '',
}: ScrollLoadingProps) => {
  const [length, setLength] = useState<number>(defaultLength)

  const onScrollHandler = (e) => {
    const docHeight = document.documentElement.clientHeight
    const scrollingElement = selector ? e.target.querySelector(selector) : e.currentTarget

    const { bottom } = scrollingElement.getBoundingClientRect()

    if (docHeight + 250 > bottom) {
      const newLength = length + defaultLength
      if (newLength > defaultData.length) {
        setLength(defaultData.length)
      } else {
        setLength(newLength)
      }
    }
  }

  return {
    onScrollHandler,
    length,
    data: defaultData.slice(0, length),
  }
}
