import React from 'react'

const DropDownTop = ({ checked, onChange, setLeagues, allLeagues }) => {
  const handleChange = (query) => {
    if (query.length == 0) {
      setLeagues(allLeagues)
    } else {
      const newArr: any = []
      allLeagues?.forEach((league) => {
        if (league.name.toLowerCase().includes(query.toLowerCase())) {
          newArr.push(league)
        }
      })
      setLeagues(newArr)
    }
  }
  return (
    <div className="flex align-middle l-h-32">
      <p className="font font--white font--12 m-r-30">Select League</p>
      <div className="h-search--leagues">
        <div className="h-search__form">
          <div className="flex align-middle full-height">
            <i className="fal fa-search h-search__icon" />
            <input
              className="field h-search__field--leagues"
              type="text"
              placeholder="Search"
              onChange={(event) => {
                handleChange(event.target.value)
              }}
            />
            <i className="fal fa-times h-search__reset" />
          </div>
        </div>
      </div>
      <div className="flex align-middle">
        <input
          className="checkbox checkbox--gold"
          type="checkbox"
          id="select-all"
          checked={checked}
          onChange={onChange}
        />
        <label className="font font--white font--12 m-l-5" htmlFor="select-all">
          Select all
        </label>
      </div>
    </div>
  )
}

export default React.memo(DropDownTop)
