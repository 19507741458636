import { types } from '../types/types'
import { useRequest } from '../../hooks/useRequest'
import { dispatchData } from '../../helpers/utils'
import { transformScheduleData } from '../../helpers/transform/transform'
import { start, finish } from './preloaderActions'

export const loadScheduleData = () => async (dispatch) => {
  const { get } = useRequest()
  dispatch(start())

  try {
    const data = await get('https://bestline.bet/schedule/')

    const { model, day } = transformScheduleData(data)

    const dataForDispatching = [
      {
        type: types.SET_SCHEDULE_DATA,
        payload: {
          ...model,
          init: true,
        },
      },
      {
        type: types.SET_SCHEDULE_VIEW,
        payload: {
          currentView: model.events,
          activeDay: day,
        },
      },
      finish(),
    ]

    dispatchData(dispatch, dataForDispatching)
  } catch (e) {
    console.log(e)
    dispatch(finish())
  }
}

export const setSport = (name, id) => (dispatch, getState) => {
  const {
    schedule: { activeDay, sports, days, events },
  } = getState()

  let newCurrentView = []

  if (id === '0') {
    if (activeDay) {
      newCurrentView = days[activeDay].EV
    } else {
      newCurrentView = events
    }
  } else {
    if (activeDay) {
      newCurrentView = days[activeDay].EV.filter((item) => item.CL === name)
    } else {
      newCurrentView = sports[name].EV
    }
  }
  dispatch({
    type: types.SET_SCHEDULE_VIEW,
    payload: {
      currentView: newCurrentView,
      activeSport: id,
    },
  })
}

export const setDay = (day) => (dispatch, getState) => {
  const {
    schedule: { activeSport, days },
  } = getState()

  let newCurrentView = []

  if (activeSport !== '0') {
    newCurrentView = days[day].EV.filter((item) => item.CI === activeSport)
  } else {
    newCurrentView = days[day].EV
  }

  dispatch({
    type: types.SET_SCHEDULE_VIEW,
    payload: {
      currentView: newCurrentView,
      activeDay: day,
    },
  })
}
