import React, { useRef, useEffect, useContext, useState, useCallback } from 'react'
import videojs, { VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.min.css'
import { useDispatch, useSelector } from 'react-redux'
import { getLeaguesEvents, getStreaming, getTrackerAndTranslation } from 'redux/selectors/inplaySelectors'
import { IStreaming, Nullable } from 'helpers/commonInterfaces/interfaces'
import { inplayWatchers, inplayWorkers } from 'redux/actions/inplayActions'
import { getEvent } from 'redux/selectors/eventSelectors'
import { getBetradarId, isIterable } from 'helpers/utils/utils'
import { isLoggedIn } from 'redux/selectors/configSelectors'

const LvTranslationStreaming = ({ soundRangeValue = 50 }) => {
  const dispatch = useDispatch()
  const { translation } = useSelector(getTrackerAndTranslation)
  const streaming = useSelector(getStreaming)
  const event = useSelector(getEvent)
  const leaguesEvents = useSelector(getLeaguesEvents)
  const LOGGED_IN = useSelector(isLoggedIn)
  const ref = useRef(null)
  const player = useRef<Nullable<VideoJsPlayer>>(null)
  const translationId = useRef(0)

  const [empty, setEmpty] = useState<boolean>(false)
  const [playerData, setPlayerData] = useState<Nullable<IStreaming>>(null)

  const [volumeOn, setVolumeOn] = useState(true)

  const getOptions = ({ hls, rtmp }) => ({
    volume: soundRangeValue / 100,
    autoplay: true,
    controls: false,
    preload: 'auto',
    sources: [
      {
        src: hls,
      },
      {
        src: rtmp,
      },
    ],
  })

  useEffect(() => {
    const volumeFromStorage = localStorage.getItem('volumeon') === 'true' ? true : false
    setVolumeOn(volumeFromStorage)
  }, [])

  const toggleVolume = () => {
    localStorage.setItem('volumeon', (!volumeOn).toString())
    setVolumeOn(!volumeOn)
  }

  useEffect(() => {
    dispatch(inplayWatchers.getStreamingEvents())

    return () => {
      dispatch(inplayWatchers.disableStreamingUpdate())
    }
  }, [])

  useEffect(() => {
    if (translation && translationId.current !== translation) {
      translationId.current = translation
      for (const events of Object.values(leaguesEvents)) {
        for (const ev of events) {
          if (ev.id === translation && ev.externalIds.betradar) {
            const betradarId = getBetradarId(ev.externalIds.betradar)
            const currentStreaming = streaming?.find(({ id }) => id === betradarId)
            if (currentStreaming) {
              setPlayerData(currentStreaming)
              setEmpty(false)
            } else {
              setEmpty(true)
            }
            break
          }
        }
      }
      return
    } else {
      if (playerData) {
        return
      }
    }
    if (streaming && isIterable(streaming)) {
      const betradarId = getBetradarId(event?.externalIds.betradar ?? '')

      const currentEvent = streaming.find(({ id }) => id === betradarId)

      if (currentEvent) {
        setPlayerData(currentEvent)
        setEmpty(false)
      } else {
        // select the default event
        for (const events of Object.values(leaguesEvents)) {
          for (const ev of events) {
            if (ev.externalIds.betradar) {
              const betradarId = getBetradarId(ev?.externalIds.betradar ?? '')
              const currentStreaming = streaming.find(({ id }) => id === betradarId)
              if (currentStreaming) {
                dispatch(inplayWorkers.setActiveTranslation(ev.id))
                setPlayerData(currentStreaming)
                setEmpty(false)
                return
              }
            }
          }
        }
      }
    }
  }, [streaming, leaguesEvents, event, translation])

  const initializePlayer = (rtmp: string, hls: string) => {
    if (ref.current) {
      const Player = videojs(
        ref.current,
        {
          muted: !volumeOn,
          autoplay: true,
          controls: false,
          preload: 'none',
          sources: [
            {
              src: hls,
            },
            {
              src: rtmp,
            },
          ],
        },
        () => {
          console.log('initializePlayer')
          player.current = Player
        }
      )
    }
  }

  const startVideo = () => {
    if (playerData) {
      console.log(`player data for init `, playerData)
      initializePlayer(playerData.rtmp, playerData.hls)
    }
  }

  const destroyVideo = () => {
    if (player.current) {
      player.current.dispose()
    }
  }

  useEffect(() => {
    if (!player.current) {
      startVideo()
    }

  }, [playerData, destroyVideo, startVideo])

  useEffect(() => {
    return () => {
      destroyVideo()
    }
  }, [])

  useEffect(() => {
    if (playerData && player.current) {
      player.current.src([{ src: playerData.rtmp }, { src: playerData.hls }])
    }
  }, [playerData])

  if (!LOGGED_IN) {
    return (
      <div className="lv-translation__streaming flex">
        <div className="video-js">
          <p className="font font-white-300 font--14">Live Streaming Avaliable</p>
          <p className="font font-grey-300 font--12">Please login to view</p>
        </div>
      </div>
    )
  }

  return (
    <div className="lv-translation__streaming flex">
      <div data-vjs-player style={{ width: '100%' }}>
        <video ref={ref} className="vjs-matrix video-js" />
      </div>
      {!streaming && (
        <p className="font font-white-300 font--14">Live streaming is not available</p>
      )}
    </div>
  )
}

export default LvTranslationStreaming
