import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConfigContext } from '../../../context/globalConfig'

const PrivateRoute = props => {
	const { LOGGED_IN } = useContext(ConfigContext)

	return <>{LOGGED_IN ? <Route {...props} /> : <Redirect to="/sports" />}</>
}

export default PrivateRoute
