import React from 'react'
import { BAcceptBets } from './BAcceptBets/BAcceptBets'
import { BAcceptFooter } from './BAcceptFooter/BAcceptFooter'

export const BAcceptBody = ({ stakes, multistakes, betTotal, possibleWin }) => {
  return (
    <div className="b-accept__body">
      <BAcceptBets {...{ stakes }} />
      <BAcceptFooter {...{ multistakes }} {...{ betTotal }} {...{ possibleWin }} />
    </div>
  )
}
