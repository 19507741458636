import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { favoritesWorkers } from '../../../../../redux/actions/favoritesActions'
import CollapsedBody from './CollapsedBody'

const CollapsedWrap = ({ data, liveTranslation, position, remove }) => {
  const ref = useRef(null)

  const dispatch = useDispatch()

  const addToFav = (sp, lg) => dispatch(favoritesWorkers.addLeague(lg, sp))

  const removeFromFav = (sp, lg) => dispatch(favoritesWorkers.removeLeague(lg, sp))

  return (
    <>
      <div className="h-popup__overlay" ref={ref} onClick={remove} />
      <div className="ipn-classification__body--small" style={position} onMouseLeave={remove}>
        <div className="ipn-classification__hidden">
          <p className="font font--white text-ellipsis ipn-classification__sports">{data.name}</p>
        </div>
        <SimpleBar className="ipn-classification__body-inner scroll">
          <CollapsedBody {...{ data }} {...{ addToFav }} {...{ liveTranslation }} />
        </SimpleBar>
      </div>
    </>
  )
}

CollapsedWrap.defaultProps = {
  data: {
    NA: 'Favourites',
    ID: '0',
  },
}

export default CollapsedWrap
