import React, { useState } from 'react'
import { useStorage } from '../hooks/useStorage'
import { useLocation } from 'react-router-dom'

type ContextValue = {
  wide: boolean
  removeWideHandler(): void
}

export const AsideContextRight = React.createContext<Partial<ContextValue>>({})

const AsideRightContext: React.FC = ({ children }) => {
  const location = useLocation()

  const { getSession, setSession } = useStorage()

  const wideState =
    (!location.pathname.includes('account') && getSession('liveTranslationWide')) || false

  const [wide, setWide] = useState(wideState)

  const removeWideHandler = () => {
    setSession('liveTranslationWide', !wide)
    setWide(!wide)
  }

  return (
    <AsideContextRight.Provider
      value={{
        wide,
        removeWideHandler,
      }}
    >
      {children}
    </AsideContextRight.Provider>
  )
}

export default AsideRightContext
