import React from 'react'
import EventsByDate from './EventsByDate'
import { useSelector } from 'react-redux'
import EventsStartDate from './EventsStartDate'
import { getPrematchEvents } from '../../redux/selectors/prematchLeagueSelectors'
import { getCurrentTime } from 'helpers/transform/transformTime'
import { IMatch, ITournament, Obj } from 'helpers/commonInterfaces/interfaces'
import { getTZA } from 'redux/selectors/configSelectors'

type IGroupEvents = Obj<Array<IMatch | ITournament>>
const groupEventForDate = (events: Array<IMatch | ITournament>, type, tza): IGroupEvents => {
  const data: IGroupEvents = {}

  const addZero = (num: number) => {
    const str = num.toFixed(0)
    return str.length > 1 ? str : '0' + str
  }

  //2021-11-13T21:15:00.000+0000
  const getCorrectTime = (event) => {

    const correctTime = event.startDate.slice(11, 16)
    let time = ''
    let day = Number(event.startDate.slice(8, 10))
    let month = Number(event.startDate.slice(5, 7))
    let year = Number(event.startDate.slice(2, 4))

    const hourGMT = correctTime.slice(0, 2)
    const minGMT = correctTime.slice(3)



    let hours = Math.floor((Number(hourGMT) * 60 + Number(minGMT) + Number(tza)) / 60)
    const mins = (Number(hourGMT) * 60 + Number(minGMT) + Number(tza)) % 60

    if (event.id === 1536757) {
      console.log(`hourGMT`, hourGMT)
      console.log(`minGMT`, minGMT)
      console.log(`hours`, hours)
      console.log(`mins`, mins)
    }

    const getMaxDay = () => {
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return 31
        // is leap year ?
        case 2:
          return year % 4 === 0 ? 29 : 28
      }
      return 30
    }

    if (hours > 23) {
      hours = hours - 24
      const newDay = day + 1
      if (newDay >= 28) {
        if (newDay >= getMaxDay()) {
          day = 1
          if (month + 1 > 12) {
            month = 1
            year += 1
          } else {
            month += 1
          }
        } else {
          day = newDay
        }
      } else {
        day = newDay
      }
    } else {
      if (hours < 0) {
        const newDay = day - 1
        if (newDay <= 0) {
          hours = hours + 24
          day = getMaxDay()
          if (month - 1 <= 0) {
            month = 12
            year -= 1
          } else {
            month -= 1
          }
        } else {
          day = newDay
        }
      }
    }

    time = addZero(hours) + ':' + addZero(mins)
    event.day = addZero(day)
    event.month = addZero(month)
    event.year = year
    event.time = time

    if (event.id === 1536757) {
      console.log(`event name`, event.competitors)
      console.log(`time`, time)
    }
  }

  events?.forEach((event) => {
    getCorrectTime(event)
    if (event.eventType === type || !type) {
      /* const { month, day, weekDay } = getCurrentTime(event.startDate)

      const date = `${weekDay} ${day} ${month}` */

      const date = `${event.day} ${event.month} ${event.year}`

      if (!data[date]) {
        data[date] = []
      }

      data[date].push(event)
    }
  })

  return data
}
const output = (text: string) => (
  <div className="ip-competition">
    <table className="table ip-competition__table ">
      <tbody>
        <tr className="tr tr--body">
          <td className="td">
            <a className="flex align-middle align-justify flex-grow overflow-hidden p-l-10 p-r-10">
              <div className="text-left overflow-hidden m-r-5">
                <div className="flex align-middle">
                  <p className="font text-ellipsis">{text}</p>
                </div>
              </div>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)
interface ILeagueEvents {
  type?: string
}
const LeagueEvents: React.FC<ILeagueEvents> = ({ type }) => {
  const events = useSelector(getPrematchEvents)
  const tza = useSelector(getTZA)

  if (!events) {
    return null
  }

  const groupedEvents = groupEventForDate(events, type, tza)

  const hasEvents = Object.keys(groupedEvents).length > 0

  console.log('grouped events: ', groupedEvents)
  return (
    <>
      {Object.keys(groupedEvents)?.map((key, idx) => (
        <React.Fragment key={key}>
          <EventsStartDate date={key} />
          <EventsByDate events={groupedEvents[key]} idx={idx} />
        </React.Fragment>
      ))}
      {!hasEvents ? (
        <>
          {type === 'Table'
            ? output('There are no tables in this league available for now')
            : type === 'Tournament'
              ? output('There are no outrights for this league available for now')
              : type === 'Match'
                ? output('There are no matches in this league available for now')
                : null}
        </>
      ) : null}
    </>
  )
}

export default LeagueEvents
