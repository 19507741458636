import React from 'react'
import image from '../../assets/img/banner.png'

const LeftPart = ({ children, img = false }) => {
	return (
		<div className="mn-popup__left">
			{img && <img className="mn-popup__banner" src={image} alt="banner" />}
			<div className="text-center mn-popup__title">{children}</div>
		</div>
	)
}

export default LeftPart
