import { ILeague, IMatch, ITournament, Nullable } from '../../helpers/commonInterfaces/interfaces'
import { PrematchLeagueWorkers } from '../actions/prematchLeagueActions'
import { types } from '../types/types'

const initialState = {
  league: null as Nullable<ILeague>,
  leagueEvents: null as Nullable<Array<IMatch | ITournament>>,
  currentOddName: null,
  currentOddId: null,
  allOddsName: null,
  allOddsId: null,
  error: false,
}

export type IPrematchState = typeof initialState

export const prematchLeague = (
  state: IPrematchState = initialState,
  action: PrematchLeagueWorkers
): IPrematchState => {
  switch (action.type) {
    case types.SET_PREMATCH_LEAGUE:
      return {
        ...state,
        league: action.data,
      }
    case types.SET_PREMATCH_LEAGUES_EVENTS:
      return {
        ...state,
        leagueEvents: action.payload,
      }
    case types.CLEAR_PREMATCH_LEAGUE:
      return {
        ...state,
        league: null,
        leagueEvents: null,
      }
    case types.SET_ALL_ODDS_NAME:
      return {
        ...state,
        allOddsName: action.payload,
      }
    case types.SET_ALL_ODDS_ID:
      return {
        ...state,
        allOddsId: action.payload,
      }
    case types.CURRENT_ODD_NAME:
      return {
        ...state,
        currentOddName: action.payload,
      }
    case types.CURRENT_ODD_ID:
      return {
        ...state,
        currentOddId: action.payload,
      }
    default:
      return state
  }
}
