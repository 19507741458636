import React, { useState, useContext } from 'react'
import LeftPart from './leftPart'
import RightPart from './rightPart'
import Text from '../common/elements/Text'
import { RegisterContext } from '../../context/registrationContext'
import { useFormik } from 'formik'
import Banner from '../../assets/img/banner.png'
import Button from './elements/button'
import Wrap from './elements/wrap'
import InputWrap from './elements/inputWrap'
import Input from '../common/elements/Input'
import InputCheckbox from './elements/inputCheckbox'
import { Nullable } from 'helpers/commonInterfaces/interfaces'
import { useTranslation } from 'react-i18next'

const RegistrationInner = () => {
  const { t } = useTranslation()
  const { showRegistration, showForm } = useContext(RegisterContext)
  //const [ currentView, setCurrentView ] = useState(false)

  const [state, setState] = useState({
    showPassword: false,
    showConfirmPassword: false,
  })
  const { addStep, fastForm, schemaFast } = useContext(RegisterContext)
  const showPasswordHandler = (property) => {
    setState({ ...state, [property]: !state[property] })
  }
  let passwordType: Nullable<string> = null,
    confirmPasswordType: Nullable<string> = null
  if (state.showPassword) passwordType = 'text'
  else passwordType = 'password'
  if (state.showConfirmPassword) confirmPasswordType = 'text'
  else confirmPasswordType = 'password'

  const formik = useFormik({
    initialValues: fastForm!.initialValues,
    validationSchema: schemaFast,
    onSubmit: (values) => {
      if (addStep) {
        addStep(values, 'fast')
      }
    },
  })

  const { values, handleChange, handleSubmit, errors, touched } = formik

  const goToLogin = () => {
    if (showRegistration && showForm) {
      showRegistration()
      showForm()
    }
  }
  return (
    <div className="mn-popup mn-popup--active register-popup">
      <div
        className="betslip__close fal fa-times"
        onClick={showRegistration}
        role="button"
        onKeyPress={() => 0}
        tabIndex={0}
      ></div>
      <div className="flex align-center-middle mn-popup__block">
        <div className="mn-popup__left">
          <img className="mn-popup__banner" src={Banner} alt="" />
          <div className="text-center mn-popup__title">
            <div className="text-center"></div>
            <p className="font font--white">
              Need some help?
              <a className="font font--blue m-l-5" href="#">
                Support
              </a>
            </p>
          </div>
        </div>
        <div className="mn-popup__right">
          <div className="mn-popup__head">
            <div className="flex align-center-middle">
              <a className="h-logo" style={{ height: '30px' }}></a>
            </div>
          </div>
          <div className="mn-popup__wrapper">
            <form onSubmit={handleSubmit}>
              <Wrap className={errors.email && touched.email && 'required required--error'}>
                <Input
                  className="field field--white"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder={t('E-mail*')}
                />
              </Wrap>
              <Wrap
                className={
                  'big-height ' +
                  (errors.password && touched.password && 'required required--error')
                }
              >
                <Input
                  className="field field--white"
                  type={passwordType}
                  placeholder={t('Password*')}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  autoComplete="on"
                />
                <div
                  className={
                    state.showPassword ? 'show-password show-password--active' : 'show-password'
                  }
                  onClick={() => {
                    showPasswordHandler('showPassword')
                  }}
                  role="button"
                  onKeyPress={() => 0}
                  tabIndex={0}
                >
                  <div className="fas fa-eye"></div>
                </div>
                {/* <EyeIcon
									showPasswordHandler={showPasswordHandler('showPassword')}
								/> */}
                <Text
                  title={t('Password must be at least 6 characters long')}
                  className="font font--10 font--default-alpha-05 m-t-5"
                  style={{ color: '#b5b7c6' }}
                />
              </Wrap>
              <InputWrap
                className={
                  errors.yearsConfirmation &&
                  touched.yearsConfirmation &&
                  'required required--error'
                }
              >
                <InputCheckbox
                  name="yearsConfirmation"
                  value={values.yearsConfirmation}
                  change={handleChange}
                />
              </InputWrap>
              <Wrap
                className={errors.newsletter && touched.newsletter && 'required required--error'}
              >
                <div className="flex align-middle">
                  <input
                    className="checkbox checkbox--blue"
                    type="checkbox"
                    id="newsletter"
                    name="newsletter"
                    value={values.newsletter}
                    onChange={handleChange}
                  />
                  <label
                    className="font font--12 m-l-5"
                    htmlFor="newsletter"
                    style={{ color: '#fff' }}
                  >
                    {t('I agree to the newsletter')}
                  </label>
                </div>
              </Wrap>
              <div className="mn-popup__wrap">
                <Button type="submit" title={t('Registration')} />
              </div>
              <div className="mn-popup__wrap">
                <div className="text-center">
                  <p className="font font--white">
                    Already have an account?
                    <a
                      className="font font--blue m-l-5"
                      onClick={() => {
                        goToLogin()
                      }}
                      role="button"
                      onKeyPress={() => 0}
                      tabIndex={0}
                    >
                      Log In
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationInner

/*
return (
    <div classNameName="mn-popup mn-popup--active">
      <div classNameName="flex align-center-middle mn-popup__block">
        <LeftPart img={true}>
          <Text
            classNameName="font font--16 font--semibold m-b-10"
            title={t('Registration')}
          />
          <Text classNameName="font" title={t('I have an account.')}>
            <a classNameName="font font--blue m-l-5" onClick={goToLogin}>
              {t('Entrance')}
            </a>
          </Text>
        </LeftPart>
        <RightPart />
      </div>
    </div>
  )
*/
