import React, { FC } from 'react'

export const Envelope: FC = () => {
	return (
		<svg
			width="50"
			height="38"
			viewBox="0 0 50 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M45.3125 0H4.68753C2.10267 0 0 2.10267 0 4.68753V32.8125C0 35.3974 2.10267 37.5001 4.68753 37.5001H45.3125C47.8973 37.5001 50 35.3974 50 32.8125V4.68753C50 2.10267 47.8973 0 45.3125 0ZM45.3125 3.12499C45.5248 3.12499 45.7267 3.16903 45.9114 3.24595L25 21.37L4.08854 3.24595C4.27318 3.16914 4.47514 3.12499 4.68743 3.12499H45.3125ZM45.3125 34.375H4.68753C3.82542 34.375 3.12499 33.6746 3.12499 32.8124V6.54754L23.9761 24.6185C24.2707 24.8733 24.6353 25 25 25C25.3647 25 25.7293 24.8734 26.0239 24.6185L46.875 6.54754V32.8125C46.875 33.6746 46.1746 34.375 45.3125 34.375Z"
				fill="#6C90FC"
			/>
		</svg>
	)
}
