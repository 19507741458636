import React from 'react'
import { useLocation } from 'react-router-dom'
const Wrap = ({ children, className }) => {
	const location = useLocation()
	return <div className={`${location.pathname.includes('/account/') ? 'wrap' : 'mn-popup__wrap'} ${className}`}>{children}</div>
}

Wrap.defaultProps = {
	className: '',
}

export default Wrap
