import React, { useState, useEffect, useContext } from 'react'
import Languages from './Languages'
import { ConfigContext } from '../../../../context/globalConfig'
import { Flag } from '../../../common/Flag'
import { useDispatch, useSelector } from 'react-redux'
import { getConfig } from 'redux/selectors/configSelectors'
import { useTranslation } from 'react-i18next'
import { configWatchers, configWorkers } from 'redux/actions/configActions'

const LanguageWrapper = ({ drop, title, active, config_key, setUserConfig }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { LANGUAGE_ID } = useSelector(getConfig)
  const { langIDs } = useContext(ConfigContext)

  const [currentLang, setCurrentLang] = useState('en')

  useEffect(() => {
    setCurrentLang(langIDs?.get(LANGUAGE_ID) ?? 'en')
  }, [])

  const [display, setDisplay] = useState(false)

  const onDisplay = () => setDisplay(!display)
  // const onHide = () => setDisplay(false)

  const onChangeLanguage = (item) => {
    // setUserConfig(config_key, item.id)
    dispatch(configWorkers.changeLanguage(item.id))
    window.location.reload()
  }

  return (
    <>
      <div
        className={
          display
            ? 'h-submenu__item h-submenu__dropdown dropdown dropdown--active lang lang--active'
            : 'h-submenu__item h-submenu__dropdown dropdown lang lang--active'
        }
        onClick={onDisplay}
        onKeyPress={() => 0}
        role="button"
        tabIndex={0}
      >
        <div className={'langs-overlay' + (display ? ' active' : '')}></div>
        <div className="flex align-middle dropdown__toggle">
          <div className="lang__img m-r-10">
            <Flag iconCode={currentLang} />
          </div>
          <a className="font font--12 font--grey-300 h-submenu__link">
            {display ? t(title.lang ? title.text : title) : currentLang.toUpperCase()}
          </a>
        </div>
        <Languages drop={drop} active={active} onChangeLanguage={onChangeLanguage} />
      </div>
    </>
  )
}

export default LanguageWrapper
