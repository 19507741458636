import React from 'react'


const RemoveAll = ({ removeAll }) => {
	return (
		<div className="betslip__remove-all" onClick={removeAll}>
			<div className="flex align-middle align-justify">
				<p className="font font--12">Remove all</p>
			</div>
		</div>
	)
}

export default RemoveAll