import React, { useContext } from 'react'
import BetslipAsideWrapper from '../../forAsideRight/betslipAside/BetslipAsideWrapper'
import AccountAside from '../../forAsideRight/accountAside/AccountAside'
import SimpleBar from 'simplebar-react'
import { AsideContextRight } from '../../../context/asideRightContext'
import '../../../assets/styles/asideRight.css'
import { useLocation } from 'react-router-dom'

const AsideRight: React.FC = () => {
  const location = useLocation()
  const { wide, removeWideHandler } = useContext(AsideContextRight)

  const refContainer = React.useRef(null)

  return (
    <SimpleBar
      ref={refContainer}
      className={
        wide ? 'height-aside-scroll aside--wide scroll' : 'height-aside-scroll aside--right scroll'
      }
    >
      {location.pathname.includes('account') ? (
        <AccountAside />
      ) : (
        <BetslipAsideWrapper wide={wide} removeWideHandler={removeWideHandler} />
      )}
    </SimpleBar>
  )
}

export default AsideRight
