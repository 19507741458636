import React, { useState, useContext } from 'react'
import Tabs from '../../common/tabs/Tabs'
import LinkItem from '../../common/elements/Link'
import { ConfigContext } from '../../../context/globalConfig'
import { useRequest } from '../../../hooks/useRequest'
import { floatToCurrency } from '../../../helpers/floatToCurrency'
import { links } from '../../../connection/links'
import { useSelector } from 'react-redux'
import { getConfig, getCurrency } from 'redux/selectors/configSelectors'
import { useTranslation } from 'react-i18next'

const tabs = [
  {
    title: 'COMMON.ACCOUNT_MENU.TABS.ACCOUNT',
    id: '1',
  },
  {
    title: 'COMMON.ACCOUNT_MENU.TABS.MY_OFFERS',
    id: '2',
  },
]

const accountLinks = [
  {
    title: 'COMMON.ACCOUNT_MENU.LINKS.MESSAGES',
    path: '/account/messages',
  },
  {
    title: 'COMMON.ACCOUNT_MENU.LINKS.MEMBERS',
    path: '/account/my-account/contact',
  },
  {
    title: 'COMMON.ACCOUNT_MENU.LINKS.WITHDRAW',
    path: '/account/balance/withdraw',
  },
  {
    title: 'COMMON.ACCOUNT_MENU.LINKS.DEPOSIT',
    path: '/account/balance/deposit',
  },
  {
    title: 'COMMON.ACCOUNT_MENU.LINKS.HISTORY',
    path: '/account/history',
  },
  {
    title: 'COMMON.ACCOUNT_MENU.LINKS.PROMOTION',
    path: '/account/offers',
  },
  {
    title: 'COMMON.ACCOUNT_MENU.LINKS.SETTINGS',
    path: '/account/my-account/account-settings',
  },
]

const AccountMenu = ({ active, showMenu, showMoney, toggleShowMoneyHandler }) => {
  const { t } = useTranslation()
  const { post } = useRequest()
  const currencyConfig = useSelector(getCurrency)
  const { CUSTOMER_CONFIG, balance } = useSelector(getConfig)

  const [activeTab, setActiveTab] = useState('1')

  const [refresh, setRefresh] = useState(false)

  const refreshHandler = () => {
    if (!refresh) {
      setRefresh(true)
      setTimeout(() => {
        setRefresh(false)
      }, 2000)
    }
  }

  const changeContent = (id) => setActiveTab(id)

  const logoutHandler = async () => {
    try {
      await post(links.logout, { SESSION_ID: CUSTOMER_CONFIG.SESSION_ID })
      localStorage.removeItem('config')
      window.location.reload()
    } catch (e) {
      console.log('error', e)
    }
  }

  if (!CUSTOMER_CONFIG) {
    return null
  }

  return (
    <menu
      className={active ? 'h-a-menu h-a-menu--active' : 'h-a-menu'}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={showMoney ? 'h-a-menu__head' : 'h-a-menu__head half-height'}>
        <div className={showMoney ? 'flex half-height' : 'flex full-height'}>
          <div className="text-left h-a-menu__info">
            <p
              style={!showMoney ? { paddingTop: '4px' } : {}}
              className="font font--14 font--grey-500-alpha-05"
            >
              {CUSTOMER_CONFIG.USER_NAME}
            </p>
            <div className="inline-flex">
              <div
                className={showMoney ? 'fas fa-eye' : 'fas fas--active fa-eye'}
                onClick={toggleShowMoneyHandler}
              />
              <div
                className={!refresh ? 'fas fa-sync-alt' : 'fas fas--active fa-sync-alt rotating'}
                onClick={refreshHandler}
              />
              {showMoney && balance && (
                <p
                  className="font inline-flex font--13 font--semibold"
                  dangerouslySetInnerHTML={{
                    __html: floatToCurrency(balance.total, currencyConfig, true, true),
                  }}
                ></p>
              )}
            </div>
          </div>
        </div>
        {showMoney && balance && (
          <div className="flex half-height">
            <div className="text-left h-a-menu__info">
              <p className="font font--12 font--grey-500-alpha-05">Withdrawable</p>
              <p
                className="font font--12 font--semibold"
                dangerouslySetInnerHTML={{
                  __html: floatToCurrency(balance.main, currencyConfig, true, true),
                }}
              ></p>
            </div>
            <div className="text-left h-a-menu__info">
              <p className="font font--12 font--grey-500-alpha-05">Bet Credits</p>
              <p
                className="font font--12 font--semibold"
                dangerouslySetInnerHTML={{
                  __html: floatToCurrency(balance.bonus, currencyConfig, true, true),
                }}
              ></p>
            </div>
          </div>
        )}
      </div>
      <Tabs
        className="mn-popup__tabs"
        activeCl="h-a-menu__link--active"
        defaultCl="font font--12 h-a-menu__link"
        {...{ tabs }}
        handler={changeContent}
        activeTab={activeTab}
      />
      {accountLinks?.map((item) => (
        <LinkItem
          key={item.title + Math.random()}
          className="font font--12 font--grey-500-alpha-08"
          title={t(item.title)}
          showMenu={showMenu}
          path={item.path}
        />
      ))}
      <div className="h-a-menu__body h-a-menu__body--active">
        <div className="h-a-menu__item" onClick={logoutHandler}>
          <span className="font font--12 font--grey-500-alpha-08">
            {t('COMMON.ACCOUNT_MENU.LINKS.LOG_OUT')}
          </span>
        </div>
      </div>
      <div className="h-a-menu__body">
        <p>1</p>
      </div>
    </menu>
  )
}

export default AccountMenu
