import React from 'react'
import RowGroup from '../../../common/RowGroup'
import { transformOutcomeName } from '../../../../../../helpers/transform/transform'
import ColumnGroup from '../../../common/ColumnGroup'
import MarketsType from '../../../common/MarketsType'

const DefaultMarketsBody = ({ outcomes, competitors, rowClasses = '', columnClasses = '' }) => {
  if (outcomes.length === 1) {
    outcomes = [{ outcome: '1', status: '1' }, outcomes[0]]
  }
  return (
    <>
      {/*outcomes?.map(({ specifiers, odds, id }) => (
					<RowGroup key={id} classes={rowClasses}>
						{odds?.map(odd => {
							odd.outcome = transformOutcomeName(
								odd.outcome,
								specifiers,
								competitors
							)
	
							return (
								<ColumnGroup key={odd.id} classes={columnClasses}>
									<MarketsType odd={odd} />
								</ColumnGroup>
							)
						})}
					</RowGroup>
				))*/}
      {/*outcomes?.map(({ specifiers, odds, id }) => (
					
	
							return (
								<ColumnGroup key={odd.id} classes={columnClasses}>
									<MarketsType odd={odd} />
								</ColumnGroup>
							)
				))*/}
      {
        <RowGroup classes={rowClasses + (outcomes.length > 4 ? ' flex-wrap flex-row' : '')}>
          {outcomes?.map((item, idx) => {
            return <MarketsType odd={item} key={idx} className={outcomes.length > 4 ? ' flex-50' : ''} />
          })}
        </RowGroup>
      }
    </>
  )
}

export default React.memo(DefaultMarketsBody)
