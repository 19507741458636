import {
  IMatch,
  ITournament,
  Nullable,
  Odd,
} from '../../helpers/commonInterfaces/interfaces'
import { EventWorkersTypes } from '../actions/eventActions'
import { types } from '../types/types'

export const initialState = {
  event: null as Nullable<IMatch | ITournament>,
  leagueEvents: null as any,
  odds: null as Nullable<{ [key: string]: Odd; }>,
  markets: null as Nullable<{ [key: string]: Odd; }>,
  error: false,
  aside: {
    openedSports: [] as Array<number>,
    openedLeagues: [] as Array<number>,
  },
}

export type IState = typeof initialState

export const event = (
  state = initialState,
  action: EventWorkersTypes
): IState => {
  switch (action.type) {
    case types.SET_EVENT:
      return {
        ...state,
        event: action.payload,
      }
    case types.SET_LEAGUE_EVENTS:
      return {
        ...state,
        leagueEvents: action.data,
      }
    case types.SET_MARKETS:
      return {
        ...state,
        odds: action.data,
      }
    case types.SET_ASIDE:
      return {
        ...state,
        aside: action.data,
      }
    case types.CLEAR_EVENT_DATA:
      return {
        ...state,
        event: null,
        odds: null,
        leagueEvents: null,
      }
    case types.SET_EVENT_ERROR:
      return {
        ...state,
        error: true,
      }
    default:
      return state
  }
}
