import React from 'react'
import { Nullable } from 'helpers/commonInterfaces/interfaces'
import { useAside } from '../hooks/useAside'

type ContextType = {
  small: boolean
  collapse: () => void
  position: any
  closeLeague: (id: number) => void
  isOpenSport: (id: number) => boolean
  isOpenLeague: (id: number) => boolean
  leaguesEvents: any
  onMouseLeave: () => void
  addOpenedSport: (id: number) => void
  addOpenedLeague: (id: number) => void
  dataForAsideSmall: Nullable<{ id: number }>
  sportCategoryHandler: (e: any, data: any) => void
  leagueCategoryHandler: (id: number) => void
}

export const AsideContext = React.createContext<Partial<ContextType>>({})

const AsideLeftContext: React.FC = ({ children }) => {
  const data = useAside()

  return <AsideContext.Provider value={{ ...data }}> {children} </AsideContext.Provider>
}

export default AsideLeftContext
