import { IRootState } from '../reducers/rootReducer'
import { ILeague, IMatch, ITournament, Nullable } from '../../helpers/commonInterfaces/interfaces'
import { IPrematchState as PrematchLeagueState } from '../reducers/prematchLeagueReducer'

export const getPrematchLeagueOdds = (state: IRootState): PrematchLeagueState => {
  return state.prematchLeague
}

export const getPrematchLeague = (state: IRootState): Nullable<ILeague> => {
  return state.prematchLeague.league
}

export const getPrematchEvents = (state: IRootState): Nullable<Array<IMatch | ITournament>> => {
  return state.prematchLeague.leagueEvents
}

// export const getPrematchOdds = (state: IRootState): Nullable<Obj<Odd>> => {
// 	return state.prematchLeague.odds
// }
// export const getPrematchCompetitors = (state: IRootState): ICompetitors[] => {
// 	if (state.prematchLeague.event?.eventType === 'Match') {
// 		return state.prematchLeague.event.competitors
// 	}
// 	return []
// }
