import React, { useState } from 'react'
import DropDownTop from './DropDownTop'
import DropDownItem from './DropDownItem'
import DropDownBottomButtons from './DropDownBottomButtons'
import { inplayWatchers, inplayWorkers } from '../../../../../../redux/actions/inplayActions'
import { useDispatch } from 'react-redux'
import { ILeague } from 'helpers/commonInterfaces/interfaces'

interface Props {
  leagues: ILeague[]
  showSelect: (e: any) => void
  chosenLeagues: ILeague[]
}
const CompetitionSelectLeague: React.FC<Props> = ({ leagues, showSelect, chosenLeagues }) => {
  const addSportLeague = (item) => (dispatch, getState) => {
    const { inplay } = getState()

    let { chosenLeagues } = inplay.currentView

    if (!chosenLeagues) {
      chosenLeagues = []
    }

    const leagues = [...chosenLeagues, item]

    dispatch(inplayWatchers.setChosenLeague(leagues))
  }

  const removeSportLeague = (item) => (dispatch, getState) => {
    const { inplay } = getState()

    const { chosenLeagues } = inplay.currentView

    const leagues = chosenLeagues.filter((league) => league.id !== item.id)

    dispatch(inplayWatchers.setChosenLeague(leagues))
  }

  const dispatch = useDispatch()

  const changeInput = (e, inputData) => {
    e.target.checked
      ? dispatch(inplayWorkers.toggleChosenLeague(inputData))
      : dispatch(inplayWorkers.toggleChosenLeague(inputData))
  }

  const [leaguesToDisplay, setLeagues] = useState(leagues)

  const checkAll = () => dispatch(inplayWorkers.setChosenLeagues(null))

  const isChecked = (id) => (chosenLeagues ? !!chosenLeagues.find((item) => item === id) : false)

  return (
    <div className="ip-classification-header__dropdown">
      <DropDownTop
        checked={!chosenLeagues}
        onChange={checkAll}
        setLeagues={setLeagues}
        allLeagues={leagues}
      />
      <hr className="ip-classification-header__hr" />
      <div className="flex flex-wrap">
        {leaguesToDisplay?.map(({ id, name, sportId }) => (
          <DropDownItem
            key={id}
            onChange={changeInput}
            checked={isChecked(id)}
            item={{
              id,
              name,
              sportId,
            }}
          />
        ))}
      </div>
      <DropDownBottomButtons close={showSelect} />
    </div>
  )
}

export default React.memo(CompetitionSelectLeague)
