import React, { useState } from 'react'
import ScoreboardHeader from './scoreboardHeader/ScoreboardHeader'
import ScoreboardContent from './scoreboardBody/ScoreboardContent'
import { scoreboardBackgrounds } from '../../../helpers/scoreboardBackgrounds'
import { CSSTransition } from 'react-transition-group'

import '../../../assets/styles/scoreboard.css'

const ScoreboardWrapper = ({ event }) => {
  const [content, setContent] = useState('table')
  const [open, setOpen] = useState(true)

  // const showTable = id => setContent(id)

  const hideScoreboard = () => {
    setOpen(!open)
  }

  const {
    id,
    additionalData,
    scoreboard,
    competitors,
    sportId,
    eventType,
    name,
    providerStatus,
    startDate,
  } = event

  const scoreboardBanner = scoreboardBackgrounds.get(sportId) ?? scoreboardBackgrounds.get(1)

  return (
    <div className="ip-scoreboard" style={{ backgroundImage: `url(${scoreboardBanner})` }}>
      <ScoreboardHeader
        handler={hideScoreboard}
        open={open}
        name={name}
        competitors={competitors}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CSSTransition
          in={open}
          timeout={300}
          mountOnEnter
          unmountOnExit
          classNames="ip-scoreboard__body--wrapper"
        >
          <ScoreboardContent
            competitors={competitors}
            additionalData={additionalData}
            content={content}
            scoreboard={scoreboard}
            sportId={sportId}
            eventType={eventType}
            providerStatus={providerStatus}
            name={name}
            startDate={startDate}
          />
        </CSSTransition>
      </div>
    </div>
  )
}

export default React.memo(ScoreboardWrapper)
