import { types } from '../types/types'

export type IAction = {
	type: string,
	payload: any
}

type IState = {
	data: Array<any>
}

const initialState: IState = {
	data: [] as Array<any>,
}

export const topGames = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case types.SET_TOP_GAMES:
			return {
				...state,
				data: action.payload,
			}
		default:
			return state
	}
}
