import React from 'react'
import { transformScoreNew } from '../../../../../../helpers/transform/transform'

const EventScore = ({ score, sportId }) => {
	const transformedScore = transformScoreNew(score, true, sportId)

	const newScore = transformedScore.reduce(
		(acc, item) => {
			acc[0].push(item[0])
			acc[1].push(item[1])
			return acc
		},
		[[], []]
	)

	return (
		<div className="text-right m-l-5">
			<table>
				<tbody>
					{newScore?.map((item, i) => (
						<tr key={i}>
							{item?.map((num, i) => (
								<td key={i} className="text-center p-b-5">
									<p
										className={`font font--13 font--red ${
											i === 0 && 'font--semibold'
										}`}>
										{num}
									</p>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default React.memo(EventScore)
