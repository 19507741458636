import React from 'react'
import { getTrackBackground, Range } from 'react-range'
import thumbImage from '../../../../assets/img/icon/thumb.svg'

interface ICashoutRange {
  min: number
  max: number
  rangeValues: number
  setRangeValues: (Array) => void
}

const CashoutRange: React.FC<ICashoutRange> = ({ min, max, rangeValues, setRangeValues }) => {
  // useEffect(() => {
  // 	return () => {
  // 		setRangeValues([max])
  // 	}
  // }, [])

  return (
    <Range
      step={0.01}
      min={min}
      max={max}
      values={[rangeValues]}
      onChange={(values) => {
        setRangeValues(values)
      }}
      renderTrack={({ props, children }) => (
        <div
          role="button"
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          onKeyPress={() => 0}
          tabIndex={0}
          style={{
            ...props.style,
            height: '36px',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '6px',
              width: '95%',
              margin: '0 auto',
              borderRadius: '2px',
              background: getTrackBackground({
                values: [rangeValues],
                colors: ['#15B93A', '#E1E5EE'],
                min: min,
                max: max,
              }),
              alignSelf: 'center',
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '18px',
            width: '20px',
            borderRadius: '2px',
            backgroundColor: '#15B93A',
            backgroundImage: `url(${thumbImage})`,
          }}
        />
      )}
    />
  )
}

export default CashoutRange
