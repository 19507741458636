import React, { useState } from 'react'
import ControlBar from './controlBar/ControlBar'
import ClassificationWrap from './classification/ClassificationWrap'

const EventAsideWrapper = ({ liveTranslation, setLiveTranslation }) => {

  return (
    <>
      <ControlBar {...{ liveTranslation }} {...{ setLiveTranslation }} />
      <ClassificationWrap {...{ liveTranslation }} />
    </>
  )
}

export default EventAsideWrapper
