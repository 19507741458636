import React from 'react'

interface IMarketRaw {
  children: any
  style?: any
  isMulti: boolean
}

const MarketRow: React.FC<IMarketRaw> = ({ children, isMulti, style }) => {
  // console.log(typeof children)
  return (
    <div
      className="gll-market-group__row"
      style={isMulti ? { display: 'grid' } : { ...style, display: 'flex' }}>
      {children}
    </div>
  )
}

export default MarketRow
