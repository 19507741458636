import { types } from '../types/types'
import { InferType } from '../../helpers/commonInterfaces/interfaces'

export const iconsWatchers = {}
export const iconsWorkers = {
	addSports: (icons: any[]) =>
		({
			type: types.ADD_ICONS,
			payload: icons,
		} as const),
}

export type iconsWorkersTypes = ReturnType<InferType<typeof iconsWorkers>>
