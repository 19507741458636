import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInplay } from 'redux/selectors/inplaySelectors'
import arsenal from '../../../../assets/img/teams/Arsenal.png'
import chelsea from '../../../../assets/img/teams/Chelsea.png'
import { IMatch, Nullable } from 'helpers/commonInterfaces/interfaces'
import { getOddsType, isLoggedIn } from 'redux/selectors/configSelectors'
import { getBetslip } from 'redux/selectors/betslipSelectors'
import { addStake, placeBet, removeStake } from 'redux/actions/betslipActions'
import { transformOddName } from 'helpers/transform/transform'
import BetLockButton from 'components/common/buttons/BetLockButton'
import BetButton from 'components/common/buttons/BetButton'
import { shouldOutcomesUpdate } from '../threeWayBanner/ThreeWayBanner'
import EventTime from 'components/common/competition/competitionContent/event/actionsCell/EventTime'
import { NavLink } from 'react-router-dom'

const BetVS = ({ eventId, inSlider = false }) => {
  const dispatch = useDispatch()
  const inplay = useSelector(getInplay)
  const LOGGED_IN = useSelector(isLoggedIn)
  const ODDS_TYPE = useSelector(getOddsType)
  const { quickBet, bt } = useSelector(getBetslip)

  const [event, setEvent] = useState<Nullable<IMatch>>(null)
  const [outcomes, setOutcomes] = useState<any[]>([])
  const [names, setNames] = useState({
    sport: 'Football',
    category: 'England',
    league: 'Championship',
    competitors: [
      {
        name: 'Chelsea',
      },
      {
        name: 'Arsenal',
      },
    ],
  })

  const createBet = (eventId, id, od, ha, check, qb): void => {
    check
      ? dispatch(removeStake(id))
      : qb && LOGGED_IN
        ? dispatch(placeBet({ FI: eventId, ID: id, OD: od, CL: event?.sportId ?? 1, HA: ha }))
        : dispatch(addStake(eventId, id, od, event?.sportId ?? 1, ha))
  }


  useEffect(() => {
    if (names.league !== '' && names.league !== 'Championship') {
      return
    }
    let sportName = '',
      categoryName = '',
      leagueName = '',
      sportId = 0,
      leagueId = 0,
      categoryId = 0,
      competitors = names.competitors
    // finding event
    Object.values(inplay.leaguesEvents).forEach((events) => {
      events.forEach((event) => {
        if (event.id === Number(eventId)) {
          const ev = event as IMatch
          setEvent(ev)
          const market = event.odds?.find((odd) => odd.id === 1 || odd.id === 175 || odd.id === 206)
            ?? event.odds?.[0]
          console.log(`marketId = `, market)
          console.log(event.odds?.filter(odd => odd.id === market?.id) ?? [])
          const item = event.odds?.filter(odd => odd.id === market?.id)[0]
          setOutcomes(transformOddName((item?.outcomes), {
            ...item?.specifiers,
            competitor1: competitors[0].name,
            competitor2: competitors[1].name,
          }) ?? [])
          sportId = event.sportId
          leagueId = event.leagueId
          categoryId = event.categoryId
          competitors = ev.competitors
        }
      })
    })
    if (!inplay.data) {
      return
    }
    // finding sport, category and league names
    inplay.data.forEach((sport) => {
      if (sport.id === sportId) {
        sportName = sport.name
        sport.categories.forEach((category) => {
          if (category.id === categoryId) {
            categoryName = category.name
            category.leagues.forEach((league) => {
              if (league.id === leagueId) {
                leagueName = league.name
              }
            })
          }
        })
      }
    })
    if (competitors[0].name === '') {
      return
    }
    setNames({ sport: sportName, category: categoryName, league: leagueName, competitors })
  }, [inplay, eventId])

  // updating odds
  useEffect(() => {
    const update = () => {
      Object.values(inplay.leaguesEvents).forEach((events) => {
        events.forEach((event) => {
          if (event.id === Number(eventId)) {
            const ev = event as IMatch
            if (shouldOutcomesUpdate(outcomes, ev.odds, names.competitors)) {
              setEvent(ev)
              const market = event.odds?.find((odd) => odd.id === 1 || odd.id === 175 || odd.id === 206)
                ?? event.odds?.[0]
              const item = event.odds?.filter(odd => odd.id === market?.id)[0]
              setOutcomes(transformOddName((item?.outcomes), {
                ...item?.specifiers,
                competitor1: names.competitors[0].name,
                competitor2: names.competitors[1].name,
              }) ?? [])
            }
          }
        })
      })
    }
    update()
  }, [eventId, names, outcomes, inplay.leaguesEvents])

  return (
    <div className={inSlider ? 'wn-banner wn-banner--shadow' : 'b-vs'}>
      <NavLink to={`/live/event/${event?.sportId}/${event?.leagueId}/${event?.id}`}
        className={
          inSlider
            ? 'm-t-15 flex flex-column b-vs__wrap full-width full-height flex-normal'
            : 'flex flex-column full-height b-vs__wrap'
        }
      >
        <div className="b-vs__title">
          <div className="text-center">
            <p className="font font--white font--15">
              {names.sport + '. ' + names.category + '. ' + names.league}
            </p>
          </div>
        </div>
        <div className="flex align-middle align-justify b-vs__info">
          <div className="b-vs__team">
            <img className="b-vs__img m-b-10" src={chelsea} alt="home" />
            <p className="font font--white font--semibold text-ellipsis">
              {names.competitors[0].name}
            </p>
          </div>
          <div className="b-vs__center">
            <EventTime
              color="#f1cb62"
              startDate={event?.startDate}
              providerStatus={event?.providerStatus}
              clock={event?.additionalData?.eventClock}
              periodId={event?.additionalData?.periodId}
              eventType={event?.eventType}
              sportId={event?.sportId}
              scoreboard={event?.additionalData?.scoreboard} />
            {/*<div className="font b-vs__placeholder">vs </div>
          <div className="text-center b-vs__time">
            <p className="font font--white-alpha-05">Today</p>
            <p className="font font--white-alpha-05">19:00</p>
          </div>
        </div> */}
          </div>
          <div className="b-vs__team">
            <img className="b-vs__img m-b-10" src={arsenal} alt="away" />
            <p className="font font--white font--semibold text-ellipsis">
              {names.competitors[1].name}
            </p>
          </div>
        </div>
        <div
          className={
            inSlider
              ? 'flex align-middle align-justify m-t-5'
              : 'flex align-middle align-justify b-vs__footer'
          }
        >
          {outcomes.length > 0
            ? <>
              {
                outcomes[0] &&
                <React.Fragment key={outcomes[0].id}>
                  {outcomes[0].status === '1' ? (
                    <BetLockButton />
                  ) : (
                    <BetButton
                      item={outcomes[0]}
                      onCreateBet={createBet.bind(null, eventId)}
                      panel={quickBet}
                      allStakes={bt}
                      oddsType={ODDS_TYPE}
                    />
                  )}
                </React.Fragment>
              }

              < button className="btn btn--h-42 btn--blue b-vs__bet">
                <span className="font font--white font--11 font--semibold text-uppercase">
                  Place a bet
                </span>
              </button>
              {outcomes[outcomes.length - 1] &&
                <React.Fragment key={outcomes[outcomes.length - 1].id}>
                  {outcomes[outcomes.length - 1].status === '1' ? (
                    <BetLockButton />
                  ) : (
                    <BetButton
                      item={outcomes[outcomes.length - 1]}
                      onCreateBet={createBet.bind(null, eventId)}
                      panel={quickBet}
                      allStakes={bt}
                      oddsType={ODDS_TYPE}
                    />
                  )}
                </React.Fragment>
              }</>
            : <></>
          }
        </div>
      </NavLink>
    </div >
  )
}

export default BetVS