import { IState } from '../reducers/configReducer'
import { eventChannel } from 'redux-saga'
import { Connection } from '../../connection/Connection'
import { linkTransform } from '../../helpers/transform/linkTransform'

export const createSubscriptionChannel = (
  path: string,
  data: object,
  topic: string,
  listeners: Array<(emitter, res) => void>,
  loop: number,
  config: IState
) =>
  eventChannel((emitter) => {
    // console.log("CREATE CHANNEL WITH TOPIC: ", topic)
    let connection
    const tryConnect = () => {
      if (config?.SITE_CONFIG?.CONNECTION_DETAILS) {
        connection = new Connection(null)
        // console.log('CONFIG FROM SAGAS', config)
        connection.send({
          cmd: 'Subscribe',
          path: linkTransform(path, config),
          data: {
            ...data,
            token: config?.CUSTOMER_CONFIG?.TOKEN,
          },
          topic,
          listeners: listeners?.map((listener) => (response) => {
            response?.forEach((res) => {
              listener(emitter, res)
            })
          }),
          loop,
        })
      } else {
        setTimeout(tryConnect, 50)
      }
    }
    tryConnect()
    return () => null
  })

export const unsubscribe = (path: string, topic: string, loop: number, config: IState): void => {
  let connection
  const tryConnect = () => {
    if (config?.SITE_CONFIG?.CONNECTION_DETAILS) {
      connection = new Connection(null)

      connection?.send({
        cmd: 'Unsubscribe',
        path: linkTransform(path, config),
        data: {
          token: config?.CUSTOMER_CONFIG?.TOKEN,
        },
        topic,
        listeners: [],
        loop,
      })
    } else {
      setTimeout(tryConnect, 50)
    }
  }
  tryConnect()
}
