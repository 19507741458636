import React from 'react'
import BonusOnDeposit from './bonusOnDeposit/BonusOnDeposit'
import BetVS from './betVS/BetVS'

const AccountAside = () => {
  return (
    <>
      <BonusOnDeposit key="1" />
      <BetVS key="2" eventId={111111} />
    </>
  )
}

export default AccountAside
