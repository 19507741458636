import React, { useState, useEffect } from 'react'
import { loadCountryIcon } from '../../helpers/utils'

const TopLeagueItem = ({ flag, name, eventsCount }) => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    loadCountryIcon(flag)
      .then((res) => setImage(res))
      .catch((err) => console.log('some problem', err))
  }, [flag])

  return (
    <>
      <div className="flex align-middle top-league__item">
        <div className="top-league__flag">
          <img src={image ?? ''} className="ip-competition__country m-r-10" alt="iconCode"></img>
        </div>
        <p className="font font--semibold text-ellipsis top-league__name">{name}</p>
        <div className="top-league__count">
          <p className="font font--semibold">{eventsCount}</p>
        </div>
      </div>
    </>
  )
}

export default TopLeagueItem
