import React, { useEffect, useRef, useState } from 'react'
import Center from '../../components/containers/main/Center'
import Footer from '../../components/containers/footer/Footer'
import EventContextWrapper from '../../context/eventContext'
import PrematchEventContentCenter from '../../components/forSportsPageComponents/PrematchEventContentCenter'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { prematchWatchers } from '../../redux/actions/prematchActions'
import { getAllSports } from 'redux/selectors/prematchSelectors'
import { getPrematchEvent } from 'redux/selectors/prematchEventSelectors'
import { prematchLeagueWatchers } from 'redux/actions/prematchLeagueActions'
import { prematchEventWatchers } from 'redux/actions/prematchEventActions'
import ErrorPopup from '../../components/common/elements/ErrorPopup'
// import WebApiEmitter from '../../connection/webApi'
// import { useContext } from 'react'
// import { ConfigContext } from '../../context/globalConfig'
type IParams = {
  sportId: string
  leagueId: string
  id: string
}

const PrematchEvent = () => {
  const params: IParams = useParams()
  const dispatch = useDispatch()

  const sports = useSelector(getAllSports)
  sessionStorage.setItem('prematchSports', JSON.stringify(sports))
  const currentEvent = useSelector(getPrematchEvent)

  const [isLoaded, setIsLoaded] = useState(currentEvent !== null)

  const eventRef = useRef(currentEvent)

  if (currentEvent && currentEvent.id !== Number(params.id) && isLoaded) {
    setIsLoaded(false)
  }

  useEffect(() => {
    dispatch(prematchWatchers.getPrematchAll())
  }, [])

  useEffect(() => {
    if (params && sports) {
      dispatch(prematchWatchers.getCurrentSport(params.sportId))
      dispatch(prematchLeagueWatchers.getLeague(params.sportId, params.leagueId))
      dispatch(prematchEventWatchers.getEvent(params))
    }
    return () => {
      //console.log('Params: ', window.location.href.split('/'))
      if (window.location.href.split('/')[6] !== params.id) {
        dispatch({ type: `REMOVE_EVENT_BY_ID_${params.id}` })
        dispatch(prematchEventWatchers.removeEvent())
      }
    }
  }, [sports])

  useEffect(() => {
    if (currentEvent?.id && !isLoaded) {
      setIsLoaded(true)
    }
    if (currentEvent?.id) {
      eventRef.current = currentEvent
      //sessionStorage.setItem('eventPrematch', JSON.stringify(currentEvent))
    }
  }, [currentEvent])

  /*if (!eventRef.current) {
    return <ErrorPopup />
  }*/
  return (
    <Center className="prematch-event">
      <EventContextWrapper type="prematch">
        {!eventRef.current ? (
          <ErrorPopup />
        ) : (
          <PrematchEventContentCenter currentEvent={eventRef.current} />
        )}
      </EventContextWrapper>
      <Footer />
    </Center>
  )
}

export default React.memo(PrematchEvent)
