import { types } from '../types/types'
import { InferType, ISport } from '../../helpers/commonInterfaces/interfaces'

export const prematchWorkers = {
  setPrematchAll: (data: ISport[]) =>
  ({
    type: types.SET_PREMATCH_ALL,
    payload: data,
  } as const),
  setCurrentSport: (data: ISport | null) =>
  ({
    type: types.SET_PREMATCH_SPORT,
    payload: data,
  } as const),
  clearCurrentSport: () =>
  ({
    type: types.CLEAR_CURRENT_SPORT,
  } as const),
}

export const prematchWatchers = {
  getPrematchAll: () =>
  ({
    type: types.GET_PREMATCH_ALL_WATCHER,
  } as const),
  getCurrentSport: (id: string | null) =>
  ({
    type: types.GET_PREMATCH_CURRENT_SPORT,
    id,
  } as const),
  clearCurrentSport: () =>
  ({
    type: types.CLEAR_CURRENT_SPORT_WATCHER,
  } as const),
}

export type PrematchWorkers = ReturnType<InferType<typeof prematchWorkers>>
export type PrematchWatchers = ReturnType<InferType<typeof prematchWatchers>>
