import { ISport } from 'helpers/commonInterfaces/interfaces'
import React, { useEffect, useState } from 'react'
import { lastOpenedT } from '../ClassificationBody'
import SportBody from './SportBody'
import SportHeader from './SportHeader'

type SportProps = {
  sport: ISport
  lastOpened: lastOpenedT,
  openedHandler: (key: string, value: number) => void
}
const Sport = React.forwardRef(function Sport(
  { sport, lastOpened, openedHandler }: SportProps,
  ref
) {
  const refer = ref as React.MutableRefObject<HTMLInputElement>
  const { categories = [] } = sport
  const [active, setActive] = useState(sport.id === lastOpened.sport)

  const createSportName = (name) => 'ipn-classification--' + name.split(' ').join('_').toLowerCase()

  const showBody = (e) => {
    e.stopPropagation()
    if (lastOpened.sport !== sport.id) {
      openedHandler('sport', sport.id)
      sessionStorage.setItem('lastOpened', sport.id.toString())
    }
    setActive(!active)
  }

  useEffect(() => {
    if (lastOpened.sport === sport.id) {
      setActive(true)
    }
  }, [lastOpened])
  return (
    <div
      ref={refer}
      className={`wn-classification-sport
        ${active ? ' wn-classification-sport--active' : ''}
        ${createSportName(sport.name)}`
      }
      onClick={showBody}
    >
      <SportHeader sport={sport} />
      {active &&
        <SportBody
          categories={categories}
          {...{ lastOpened }}
          {...{ openedHandler }} />
      }
    </div>
  )
})

export default Sport
