import React, { useState } from 'react'
import LoaderLogo from './LoaderLogo'
import LoaderProgress from './LoaderProgress'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { getProgress } from 'redux/selectors/preloaderSelectors'

const Loader = () => {
  const progress = useSelector(getProgress)

  const [state, setState] = useState(true)

  return (
    <>
      <CSSTransition
        in={state}
        timeout={1500}
        classNames="logo-loader"
        appear
        enter
        unmountOnExit
        onEntered={() => setTimeout(() => setState(false), 1000)}
      >
        <LoaderLogo />
      </CSSTransition>

      {<LoaderProgress {...{ progress }} />}
    </>
  )
}

export default Loader
