import React from 'react'

type IStyle = {
  style?: any
  className?: string
  children: any
}
const ScoreboardColumn: React.FC<IStyle> = ({
  children,
  className = ''
}) => {
  return (
    <div className={`column ${className}`}>
      {children}
    </div>
  )
}

export default React.memo(ScoreboardColumn)
