export function pushOrUpdate(arr: Array<any>, value: any, it: string): void {
  if (arr) {
    const index = arr.findIndex((value) => value.it === it)
    index === -1 ? arr.push({ ...value, it }) : (arr[index] = { ...value, it })
  }
}

export function removeByPath(obj: any, path: string): void {
  const stack: any[] = path.split('/')

  while (stack.length > 1) {
    obj = obj[0] /*obj[stack.shift()]*/
  }

  if (stack[0].length === 32) {
    const index = obj.findIndex((value) => value.it === stack[0])
    if (index !== -1) {
      for (let i = index; i < obj.length - 1; i++) {
        obj[i] = obj[i + 1]
      }
    }
  } else {
    delete obj[stack[0]]
  }
}

export function updateByPath(obj: any, newValue: any, path: string): void {
  const stack: any[] = path.split('/')

  while (stack.length > 1) {
    obj = obj[0]
  }

  stack[0].length === 32 ? pushOrUpdate(obj, newValue, stack[0]) : (obj[stack[0]] = newValue)
}
