import { store } from '../store'
import { types } from '../types/types'

//json for development

import { InferType } from '../../helpers/commonInterfaces/interfaces'

/* const useLocales = () => {
  const fromStore = store.getState().config.locales
  if (Object.entries(fromStore).length > 10) {
    return fromStore
  } else {
    return {}
  }
} */

export const contextWorkers = {
  t: (path) => {
    return path
  },
  authJoinToggle: (val?: boolean) => {
    if (typeof val !== 'undefined') {
      return {
        type: types.SET_CONTEXT,
        payload: {
          betslip: false,
          authJoin: val,
        },
      }
    } else {
      const authJoin = store.getState().context.authJoin
      return {
        betslip: false,
        authJoin: !authJoin,
      }
    }
  },

  leftSideToggle: (bs?: boolean) => {
    const betslip = store.getState().context.betslip
    const leftAside = store.getState().context.leftAside
    return {
      type: types.SET_CONTEXT,
      payload: {
        betslip: bs ? bs : betslip,
        rightAside: false,
        leftAside: !leftAside,
        search: leftAside,
      },
    }
  },

  rightSideToggle: (bs?: boolean) => {
    const betslip = store.getState().context.betslip
    const rightAside = store.getState().context.rightAside
    return {
      type: types.SET_CONTEXT,
      payload: {
        betslip: bs ? bs : betslip,
        leftAside: false,
        rightAside: !rightAside,
      },
    }
  },

  toggleBetslip: () => {
    const betslip = store.getState().context.betslip
    if (!betslip) {
      //document.body.style.overflow = 'auto'
    }
    return {
      type: types.SET_CONTEXT,
      payload: { betslip: !betslip },
    }
  },

  setLeftAsideActiveLink: (key: string) => ({
    type: types.SET_CONTEXT,
    payload: { leftAsideActiveLink: key },
  }),

  onFocus: () => ({
    type: types.SET_CONTEXT,
    payload: { search: true },
  }),

  onFocusOut: () => ({
    type: types.SET_CONTEXT,
    payload: { search: false },
  }),

  onClickSearchItem: () => ({
    type: types.SET_CONTEXT,
    payload: {
      leftAside: true,
      search: true,
    },
  }),
}

export type ContextWorkersType = ReturnType<InferType<typeof contextWorkers>>

// export type ContextWatchersType = ReturnType<InferType<typeof contextWatchers>>
