import {
  takeLatest,
  call,
  put,
  select,
  takeEvery,
  fork,
  take,
  cancel,
  delay,
} from 'redux-saga/effects'
import { END, eventChannel } from 'redux-saga'
import { types } from '../../types/types'
import { post } from '../../../helpers/asyncRequests'
import { modifyBets } from '../../../helpers/modifyBets'
import { setBetslip, setPopup } from '../../actions/betslipActions'
import { links } from '../../../connection/links'
import { getBetslip } from '../../selectors/betslipSelectors'
import { Connection } from '../../../connection/Connection'
import { IState } from '../../reducers/configReducer'
import { linkTransform } from '../../../helpers/transform/linkTransform'
import { isIterable } from '../../../helpers/utils/utils'

interface ISingle {
  bt: number
  cl: string
  fb: number
  fd: string
  fi: string
  mr: false
  mt: number
  ob: any[]
  od: number
  pf: string
  pt: [{ pi: string; md: string; bd: string }]
  so: string
  sa: string
  su?: number | null
  st?: number | null
  stake?: string
  growth?: string
  lm?: number
}

export interface IBetslip {
  at?: number
  bg: string
  bs: any[]
  bt: Array<ISingle>
  placedBt: Array<ISingle>
  dm?: any
  mo?: any[]
  cs: number
  ir: boolean
  mr: false
  sr: number
  vr: string
  quickBet?: boolean
  betsPlaced: boolean
}

const reqsInProgress = {
  refreshslip: false,
  acceptChanges: false,
  removeAll: false,
  removeStake: false,
  addBet: false,
  placeBet: false,
}

/* form string for request body helper */

function formString(
  FI: number,
  ID: number,
  OD: number,
  CL: number,
  // SA: string,
  HA = ''
) {
  if (HA === '')
    // #so=#sa=${SA}
    return `pt=N#o=${OD}#f=${FI}#fp=${ID}#so=#sa=#c=${CL}#mt=1#id=${FI}-${ID}Y#|TP=BS${FI}-${ID}#pbc=0||`
  else
    return `pt=N#o=${OD}#f=${FI}#fp=${ID}#so=#sa=#c=${CL}#ln=${HA || ''
      }#mt=1#id=${FI}-${ID}Y#|TP=BS${FI}-${ID}#pbc=0||`
}

function* initBetslipWorker() {
  const prevns = JSON.parse(sessionStorage.getItem('ns') || '{}')
  const ns = Object.values(prevns).join('')
  const ms = sessionStorage.getItem('ms') || ''
  const reqData = {
    ns: ns,
    ms: ms,
  }

  try {
    if (window.location.href.includes('casino')) {
      console.log('Casino opened')
    } else {
      const data = yield call(post(links.refreshslip, reqData))

      if (data && data !== '') {
        data.betsPlaced = false
      }

      let ms = ''
      if (typeof data?.dm !== 'undefined') {
        if (sessionStorage.getItem('ms') === '') {
          ms += 'id=' + data.dm.bt.toString() + '#bc=' + data.dm.bc.toString() + '#||'
        } else {
          ms = sessionStorage.getItem('ms') ?? ''
        }
        data.mo.forEach((mo: any) => {
          ms += 'id=' + mo.bt + '#bc=' + mo.bc + '#||'
        })
        if (data.bt && data.bt.filter((bet) => bet !== null).length === 0) {
          ms = ''
          data.bt = []
          data.mo = []
        }
        sessionStorage.setItem('ms', ms)
      }

      // replace odds and set if its raised or decrease
      if (data !== '') {
        if (isIterable(data.bt)) {
          data.bt = data.bt.map((item: ISingle) => {
            // replacing coeficients
            const oldBet = modifyBets(
              prevns[item.pt[0].pi.toString()].match(/#o=(.*)#f=/gi)[0].slice(3, -3),
              '2'
            )
            const newBet = modifyBets(item.od, '2')
            const replaceBet = () => {
              prevns[item.pt[0].pi.toString()] = prevns[item.pt[0].pi.toString()].replace(
                /#o=(.*)#f=/gi,
                '#o=' + item.od + '#f='
              )
              sessionStorage.setItem('ns', JSON.stringify(prevns))
            }

            // replacing so, sa
            const { so, sa } = item
            if (so && sa) {
              prevns[item.pt[0].pi.toString()] = prevns[item.pt[0].pi.toString()]
                .replace(/#so=(.*)#sa=/gi, '#so=' + so + '#sa=')
                .replace(/#sa=(.*)#c=/gi, '#sa=' + sa + '#c=')
              sessionStorage.setItem('ns', JSON.stringify(prevns))
            }

            if (Number(newBet).toFixed(2) > Number(oldBet).toFixed(2)) {
              replaceBet()
              return {
                ...item,
                growth: 'up',
              }
            } else {
              if (Number(newBet).toFixed(2) > Number(oldBet).toFixed(2)) {
                replaceBet()
                return {
                  ...item,
                  growth: 'down',
                }
              } else {
                return {
                  ...item,
                }
              }
            }
          })
          sessionStorage.setItem('ns', JSON.stringify(prevns))
        } else {
          data.bt = []
          sessionStorage.setItem('ns', '{}')
        }
      }
      yield put(setBetslip(data))
      yield recreateSubscribtion()
    }
  } catch (e) {
    console.error('Error ', e)
  }
}

function* recreateSubscribtion() {
  let disabled = false
  yield fork(function* () {
    yield take(types.DISABLE_BS_UPDATES)
    console.log(`DISABLING UPDATES`)
    disabled = true
  })
  // const updateChannel = yield call(countdown)
  try {
    while (true) {
      yield delay(150)
      // const one = yield take(updateChannel)
      if (!disabled && !reqsInProgress.refreshslip) {
        yield delay(4000)
        console.log('call refreshslip ')
        reqsInProgress.refreshslip = true
        yield refreshslipWorker()
      } else {
        if (disabled) {
          break
        }
      }
    }
  } catch (e) {
    console.log(`ERROR in BS UPDATES`, e)
    // updateChannel.close()
  }
}

function* refreshslipWorker() {
  console.log(`%cIn refreshslip worker`, 'color: pink')
  const prevns = JSON.parse(sessionStorage.getItem('ns') || '{}')
  const ns = Object.values(prevns).join('')
  const ms = sessionStorage.getItem('ms') || ''
  const reqData = {
    ns: ns,
    ms: ms,
  }

  const betslipState = yield select((state) => state.betslip)
  if (betslipState.placedBt.length > 0) {
    reqsInProgress.refreshslip = false
    return
  }
  try {
    const data = yield call(post(links.refreshslip, reqData))
    if (
      reqsInProgress.placeBet ||
      reqsInProgress.acceptChanges ||
      reqsInProgress.addBet ||
      reqsInProgress.removeStake ||
      reqsInProgress.removeAll
    ) {
      reqsInProgress.refreshslip = false
      console.log(`something is in progress: `, reqsInProgress)
      return
    }

    if (data && data !== '') {
      data.betsPlaced = false
    }

    // save multistakes
    let ms = ''
    if (typeof data?.dm !== 'undefined') {
      if (sessionStorage.getItem('ms') === '') {
        ms += 'id=' + data.dm.bt.toString() + '#bc=' + data.dm.bc.toString() + '||'
      } else {
        ms = sessionStorage.getItem('ms') ?? ''
      }

      if (ms.split('||')[1].length === 0) {
        data.mo.forEach((mo: any) => {
          ms += 'id=' + mo.bt + '#bc=' + mo.bc + '||'
        })
      }

      if (data.bt && data.bt.filter((bet) => bet !== null).length === 0) {
        ms = ''
        data.bt = {}
        data.mo = []
      }
      sessionStorage.setItem('ms', ms)
    }

    // replace odds and set if its raised or decrease

    if (data !== '') {
      const state = yield select()
      data.bt = data.bt
        .filter((bet) => bet)
        .map((item: ISingle) => {
          if (!data.er) {
            data.er = ''
          }
          if (item.su === 1) {
            prevns[item.pt[0].pi.toString()] = undefined
            return item
          }

          // replacing coeficients
          const oldBet = prevns[item.pt[0].pi.toString()].match(/#o=(.*)#f=/gi)[0].slice(3, -3)
          const newBet = item.od
          const replaceBet = (): void => {
            // console.log(`prevns[${item.pt[0].pi}]: `, prevns[item.pt[0].pi.toString()])
            prevns[item.pt[0].pi.toString()] = prevns[item.pt[0].pi.toString()].replace(
              /#o=(.*)#f=/gi,
              '#o=' + item.od + '#f='
            )
            if (prevns[item.pt[0].pi.toString()].includes('#st')) {
              const newSt = prevns[item.pt[0].pi.toString()].match(/#st=(.*)#tr=/gi)?.[0]
              const newTr = prevns[item.pt[0].pi.toString()].match(/#tr=(.*)#ust=/gi)?.[0]
              const newUst = prevns[item.pt[0].pi.toString()].match(/#ust=(.*)\|\|/gi)?.[0]
              // console.log(`%cnew St`, 'color: pink', newSt)
              // console.log(`%cnew Tr`, 'color: pink', newTr)
              // console.log(`%cnew newUst`, 'color: pink', newUst)
              prevns[item.pt[0].pi.toString()] = prevns[item.pt[0].pi.toString()]
                .replace(/#st=(.*)#tr=/gi, newSt)
                .replace(/#tr=(.*)#ust=/gi, newTr)
                .replace(/#ust=(.*)\|\|/gi, newUst)
            }
            // console.log(`new prevns[${item.pt[0].pi}]: `, prevns[item.pt[0].pi.toString()])
            sessionStorage.setItem('ns', JSON.stringify(prevns))
          }

          // replacing so, sa
          const { so, sa } = item
          if (so && sa) {
            prevns[item.pt[0].pi.toString()] = prevns[item.pt[0].pi.toString()]
              .replace(/#so=(.*)#sa=/gi, '#so=' + so + '#sa=')
              .replace(/#sa=(.*)#c=/gi, '#sa=' + sa + '#c=')
            sessionStorage.setItem('ns', JSON.stringify(prevns))
          }
          // getting growth from previous state
          const stateStake = state.betslip.bt.find((bt) => bt.pt[0].pi === item.pt[0].pi)
          replaceBet()
          item.growth = stateStake.growth
          if (Number(newBet).toFixed(2) > Number(oldBet).toFixed(2)) {
            return {
              ...item,
              growth: 'up',
            }
          } else {
            if (Number(newBet).toFixed(2) < Number(oldBet).toFixed(2)) {
              return {
                ...item,
                growth: 'down',
              }
            } else {
              return {
                ...item,
              }
            }
          }
        })
      sessionStorage.setItem('ns', JSON.stringify(prevns))
    }

    if (data.bt?.length === 0) {
      data.dm = {}
      data.mo = []
      sessionStorage.setItem('ms', '')
    }

    yield put(setBetslip(data))
  } catch (e) {
    console.log(`errored::: `, e)
  } finally {
    reqsInProgress.refreshslip = false
  }
}

const refreshslipShortPull = (config: IState) =>
  eventChannel((emitter) => {
    let connection
    const tryConnect = () => {
      if (config?.SITE_CONFIG?.CONNECTION_DETAILS) {
        const prevns = JSON.parse(sessionStorage.getItem('ns') || '{}')
        const ns = Object.values(prevns).join('')
        const ms = sessionStorage.getItem('ms') || ''
        const reqData = {
          ns: ns,
          ms: ms,
        }
        connection = new Connection(null)
        connection.send({
          cmd: 'Subscribe',
          path: linkTransform('/bets/refreshslip', config),
          data: reqData,
          topic: 'refreshslip',
          listeners: [
            (res) => {
              if (res) {
                console.log('LISTENED')
                emitter({ res, prevns })
              }
            },
          ],
          loop: 30000,
        })
      } else {
        setTimeout(tryConnect, 50)
      }
    }

    tryConnect()
    return () => {
      emitter(END)
      connection?.send({
        cmd: 'Unsubscribe',
        path: linkTransform('/bets/refreshslip', config),
        data: {},
        topic: '/bets/refreshslip',
        listeners: [],
        loop: 1000,
      })
    }
  })

function countdown() {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter(1)
    }, 6000)
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv)
    }
  })
}

function* acceptChangesWorker() {
  reqsInProgress.acceptChanges = true
  const prevns = JSON.parse(sessionStorage.getItem('ns') || '{}')
  const ns = Object.values(prevns).join('')
  const ms = sessionStorage.getItem('ms') || ''
  const reqData = {
    ns: ns,
    ms: ms,
  }

  try {
    const data = yield call(post(links.refreshslip, reqData))
    if (data && data !== '') {
      data.betsPlaced = false
    }
    // save multistakes
    let ms = ''
    if (typeof data?.dm !== 'undefined') {
      if (sessionStorage.getItem('ms') === '') {
        ms += 'id=' + data.dm.bt.toString() + '#bc=' + data.dm.bc.toString() + '#||'
      } else {
        ms = sessionStorage.getItem('ms') ?? ''
      }

      if (ms.split('||')[1].length === 0) {
        data.mo.forEach((mo: any) => {
          ms += 'id=' + mo.bt + '#bc=' + mo.bc + '#||'
        })
      }

      if (data.bt && data.bt.filter((bet) => bet !== null).length === 0) {
        ms = ''
        data.bt = {}
        data.mo = []
      }
      sessionStorage.setItem('ms', ms)
    }

    // replace odds and set if its raised or decrease

    if (data !== '') {
      if (!data.er) {
        data.er = ''
      }
      // clear null stakes
      for (const item in prevns) {
        const find = data.bt?.find((bet: ISingle) => {
          if (bet !== null) {
            return bet.pt[0].pi === prevns[item].match(/#fp=(.*)#so=/gi)[0].slice(4, -4)
          }
        })

        if (!find) {
          delete prevns[item]
        }
      }

      // filtering nulls
      if (!data.bt) {
        data.bt = []
      }
      data.bt = data.bt.filter((el: ISingle) => el)
      data.bt = data.bt.map((item: ISingle) => {
        if (item.su === 1) {
          prevns[item.pt[0].pi.toString()] = undefined
          return item
        }
        const oldBet = prevns[item.pt[0].pi.toString()]?.match(/#o=(.*)#f=/gi)[0].slice(3, -3)
        const newBet = item.od
        const replaceBet = () => {
          prevns[item.pt[0].pi.toString()] = prevns[item.pt[0].pi.toString()].replace(
            /#o=(.*)#f=/gi,
            '#o=' + item.od + '#f='
          )
          sessionStorage.setItem('ns', JSON.stringify(prevns))
          if (item.su) {
            delete prevns[item.pt[0].pi.toString()]
            sessionStorage.setItem('ns', JSON.stringify(prevns))
          }
        }

        if (Number(newBet).toFixed(2) > Number(oldBet).toFixed(2)) {
          replaceBet()
          if (item.su) {
            delete prevns[item.pt[0].pi.toString()]
            sessionStorage.setItem('ns', JSON.stringify(prevns))
          }
          return {
            ...item,
            growth: 'up',
          }
        } else {
          if (Number(newBet).toFixed(2) < Number(oldBet).toFixed(2)) {
            replaceBet()
            return {
              ...item,
              growth: 'down',
            }
          } else {
            return {
              ...item,
            }
          }
        }
      })
      sessionStorage.setItem('ns', JSON.stringify(prevns))
    }

    yield put(setBetslip(data))

  } catch (e) {
    console.error(e)
  }
  reqsInProgress.acceptChanges = false
}

function* addStakeWorker(data: {
  type: string
  payload: {
    FI: number
    ID: number
    OD: number
    CL: number
    HA: string
    qb?: boolean
  }
}) {
  reqsInProgress.addBet = true
  const betslip = yield select((state) => state.betslip)
  const { FI, ID, OD, CL, HA, qb } = data.payload
  // form date
  // const date = new Date()
  // const timestamp = date.getTime()
  // timestamp = Math.round(timestamp / 1000)
  // const tsToHex = timestamp.toString(16)
  // const SA = tsToHex + '-' + '60D0DCE9'
  if (betslip.bt.length > 14) {
    yield put(setPopup(true))
  } else {
    let string = formString(FI, ID, OD, CL, HA)
    if (qb) {
      string = string.slice(0, -2)
      const st = '#st=' + sessionStorage.getItem('quickbet')
      const tr = '#tr=' + (Number(sessionStorage.getItem('quickbet')) * OD).toFixed(3)
      string += st + tr + '||'
      console.log('string', string)
    }
    const prevns = JSON.parse(sessionStorage.getItem('ns') || '{}')
    prevns[ID] = string
    const ns = Object.values(prevns).join('')
    if (prevns.length > 14) {
      yield put(setPopup(true))
      return
    }

    sessionStorage.setItem('ns', JSON.stringify(prevns))
    const ms = sessionStorage.getItem('ms') ?? ''
    const reqData = {
      ns: ns,
      ms: ms,
    }

    try {
      const data = yield call(post(links.addBet, reqData))
      if (data && data !== '') {
        data.betsPlaced = false
      }
      data.placedBt = []

      // save multistakes
      let ms = ''
      if (typeof data.dm !== 'undefined') {
        ms += 'id=' + data.dm.bt.toString() + '#bc=' + data.dm.bc.toString() + '#||'
        data.mo.forEach((mo: any) => {
          ms += 'id=' + mo.bt + '#bc=' + mo.bc + '#||'
        })
        sessionStorage.setItem('ms', ms)
      }

      // replace odds and set if its raised or decrease
      if (data !== '') {
        // clear null stakes
        for (const item in prevns) {
          const find = data.bt.find((bet: ISingle) => {
            if (bet !== null) {
              return bet.pt[0].pi === prevns[item].match(/#fp=(.*)#so=/gi)[0].slice(4, -4)
            }
          })
          if (!find) {
            delete prevns[item]
          }
        }

        // filtering nulls
        data.bt = data.bt.filter((el: ISingle) => {
          return el != null
        })
        data.bt = data.bt.map((item: ISingle) => {
          if (item.su === 1) {
            prevns[item.pt[0].pi.toString()] = undefined
            return item
          }
          const oldBet = modifyBets(
            prevns[item.pt[0].pi.toString()].match(/#o=(.*)#f=/gi)[0].slice(3, -3),
            '2'
          )

          const newBet = modifyBets(item.od, '2')
          const replaceBet = () => {
            prevns[item.pt[0].pi.toString()] = prevns[item.pt[0].pi.toString()].replace(
              /#o=(.*)#f=/gi,
              '#o=' + item.od + '#f='
            )
            sessionStorage.setItem('ns', JSON.stringify(prevns))
          }

          if (Number(newBet).toFixed(2) > Number(oldBet).toFixed(2)) {
            replaceBet()
            return {
              ...item,
              growth: 'up',
            }
          } else {
            if (Number(newBet).toFixed(2) < Number(oldBet).toFixed(2)) {
              replaceBet()
              return {
                ...item,
                growth: 'down',
              }
            } else {
              return {
                ...item,
              }
            }
          }
        })
        sessionStorage.setItem('ns', JSON.stringify(prevns))
      }
      yield put(setBetslip(data))
    } catch (e) {
      console.error(e)
    }
  }
  reqsInProgress.addBet = false
}

function* removeStakeWorker(data: {
  type: string
  payload: { FI: number; ID: number; OD: number; CL: number; HA: string }
}) {
  reqsInProgress.removeStake = true
  const ID = Number(data.payload.ID)
  const prevns = JSON.parse(sessionStorage.getItem('ns') || '{}')
  delete prevns[ID]
  const ns = Object.values(prevns).join('')
  sessionStorage.setItem('ns', JSON.stringify(prevns))
  const ms = sessionStorage.getItem('ms') || ''
  sessionStorage.setItem('ms', '')
  const reqData = {
    ns: ns,
    ms: ms,
  }

  try {
    const data = yield call(post(links.removeBet, reqData))
    if (data && data !== '') {
      data.betsPlaced = false
    }
    let ms = ''
    if (typeof data.dm !== 'undefined') {
      ms += 'id=' + data.dm.bt.toString() + '#bc=' + data.dm.bc.toString() + '#||'
      data.mo.forEach((mo: any) => {
        ms += 'id=' + mo.bt + '#bc=' + mo.bc + '#||'
      })
      sessionStorage.setItem('ms', ms)
    }

    // replace odds and set if its raised or decrease
    if (data !== '') {
      data.bt = data.bt.map((item: ISingle) => {
        if (item !== null) {
          const oldBet = modifyBets(
            prevns[item.pt[0].pi.toString()].match(/#o=(.*)#f=/gi)[0].slice(3, -3),
            '2'
          )
          const newBet = modifyBets(item.od, '2')
          const replaceBet = () => {
            prevns[item.pt[0].pi.toString()] = prevns[item.pt[0].pi.toString()].replace(
              /#o=(.*)#f=/gi,
              '#o=' + item.od + '#f='
            )
            sessionStorage.setItem('ns', JSON.stringify(prevns))
          }

          if (Number(newBet).toFixed(2) > Number(oldBet).toFixed(2)) {
            replaceBet()
            return {
              ...item,
              growth: 'up',
            }
          } else {
            if (Number(newBet).toFixed(2) < Number(oldBet).toFixed(2)) {
              replaceBet()
              return {
                ...item,
                growth: 'down',
              }
            } else {
              return {
                ...item,
              }
            }
          }
        }
      })
    }

    yield put(setBetslip(data))
  } catch (e) {
    console.error(e)
  }
  reqsInProgress.removeStake = false
}

function* removeAllWorker() {
  reqsInProgress.removeAll = true
  const prevBetslipData = yield select(getBetslip)
  const reqData = {
    ns: '',
    ms: '',
  }
  sessionStorage.setItem('ns', '')
  sessionStorage.setItem('ms', '')
  try {
    let data = yield call(post(links.removeAll, reqData))

    if (data && data !== '') {
      data.popup = false
      data.betsPlaced = false
      data.placedBt = []
      data.dm = {}
      data.mo = []
    } else {
      data = {
        ...prevBetslipData,
        bt: [],
        dm: {},
        mo: [],
        placedBt: [],
        betsPlaced: false,
      }
    }

    yield put(setBetslip(data))
  } catch (e) {
    console.error(e)
  } finally {
    reqsInProgress.removeAll = false
  }
}

setInterval(() => {
  console.log(`reqs in pro`, reqsInProgress)
}, 2000)
function* placeBetWorker(data: {
  type: string
  payload: false | { FI: number; ID: number; OD: number; CL: number; HA: string }
}) {
  reqsInProgress.placeBet = true
  const prevBetslipData = yield select(getBetslip)
  const prevns = JSON.parse(sessionStorage.getItem('ns') || '{}')
  let ns = Object.values(prevns).join('')
  const ms = sessionStorage.getItem('ms') ?? ''
  if (data.payload) {
    const { FI, ID, OD, CL, HA } = data.payload

    // form date
    const date = new Date()
    let timestamp = date.getTime()
    timestamp = Math.round(timestamp / 1000)
    const tsToHex = timestamp.toString(16)
    const SA = tsToHex + '-' + '60D0DCE9'
    let string = formString(FI, ID, OD, CL, /*  SA, */ HA)
    string = string.slice(0, -2)
    console.log(`payload`, data.payload)
    const st = '#st=' + sessionStorage.getItem('quickbet')
    const tr = '#tr=' + (Number(sessionStorage.getItem('quickbet')) * OD).toFixed(3)
    string += st + tr + '||'
    prevns[ID] = string
    ns = Object.values(prevns).join('')
    sessionStorage.setItem('ns', JSON.stringify(prevns))
    sessionStorage.setItem('ms', '')
    ns = string
    console.log(`ns for quickbet: `, ns)
  }

  const reqData = {
    ns: ns,
    ms: ms,
  }

  sessionStorage.setItem('ns', '')
  sessionStorage.setItem('ms', '')

  try {
    const data = yield call(post(links.placeBet, reqData))
    if (data && data !== '') {
      data.popup = false
      if (data.bt.filter((bet) => bet).length === 0) {
        data.updatesEnabled = true
        data.er = 'Bets changed or locked'
        data.bt = [
          {
            bs: 'BS829965-192880948',
            bt: 1,
            cl: 111,
            ct: 8488,
            fb: 0,
            fd: 'Live TO Win v Team Empire',
            fi: '829965',
            mr: false,
            mt: 11,
            ob: [],
            od: 1.16,
            pf: 'N',
            ps: 'not_started',
            pt: [
              {
                pi: '192880948',
                md: '2 Way',
                mi: 175,
                outcomeId: '175001',
                bd: '{$competitor1}',
              },
            ],
            sa: '601d625f-722CEF5B',
            so: '601d625f-722CEF5B',
            sr: 0,
            su: 1,
          },
        ]
        data.dm = {}
        data.mo = []
        data.quickBet = false
        yield put(setBetslip(data))
      } else {
        // stake limits...
        if (data.bt.some((bet) => bet?.lm) || data.dm?.lm || data.er) {
          data.updatesEnabled = true
          data.bt = data.bt.filter((bet) => bet)
          // 	.map(bet => ({ ...bet, pt: [{ ...bet.pt[0], lm: 2 }] }))
          // console.log('new bt:', data.bt)
          data.quickBet = false
        } else {
          data.betsPlaced = true
          data.placedBt = data.bt
          data.dm = prevBetslipData.dm
          data.bt = []
          if (data.placedBt < 2) {
            data.dm = {}
          }
        }
        /* if (data.bt.some((bet: ISingle) => bet.st)) {
          data.placedBt = data.bt.filter((bet: ISingle) => bet.st)
        } */
        data.mo = []
        yield put(setBetslip(data))
      }
    }
  } catch (e) {
    console.log('Errored', e)
    return new Error('Problem')
  }
  reqsInProgress.placeBet = false
}

export const betslipSagas = [
  takeLatest(types.INIT_BETSLIP, initBetslipWorker),
  takeLatest(types.RESTART_BS_SUB, recreateSubscribtion),
  takeLatest(types.REFRESHSLIP, refreshslipWorker),
  takeLatest(types.ADD_STAKE, addStakeWorker),
  takeEvery(types.REMOVE_STAKE, removeStakeWorker),
  takeLatest(types.ACCEPT_CHANGES, acceptChangesWorker),
  takeLatest(types.REMOVE_ALL, removeAllWorker),
  takeLatest(types.PLACE_BET, placeBetWorker),
]
