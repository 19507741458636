import { call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { configWorkers } from '../../actions/configActions'
import { get, post } from '../../../helpers/asyncRequests'
import { types } from '../../types/types'
import { links } from '../../../connection/links'
import { Connection } from '../../../connection/Connection'
import { END, eventChannel } from 'redux-saga'
import { linkTransform } from '../../../helpers/transform/linkTransform'
import { isIterable } from '../../../helpers/utils/utils'
import { IState } from '../../reducers/configReducer'
import { createSubscriptionChannel } from '../subscriptionHandler'
import _ from 'lodash'
import { store } from '../../store'
import { emitterByCmd } from '../../utils/emitter'
import i18n from '../../../helpers/i18n'
import en from '../../../locales/en.json'
import { bannersWatchers } from '../../actions/bannersActions'
import { setCookie } from 'helpers/utils'

export const langIDs = new Map([
  [1, 'en'],
  [2, 'ru'],
  [3, 'ua'],
])

function* getConfig() {
  try {
    const config = yield call(post(links.config))
    yield put(configWorkers.setConfig(config))
    new Connection({
      webApi: links.webApi,
      socketConfig: {
        privateWS: config.SITE_CONFIG.CONNECTION_PRIVATE.isUsingWS,
        feedWS: config.SITE_CONFIG.CONNECTION_DETAILS.isUsingWS,
      },
      webSocketPrivate: config.SITE_CONFIG.CONNECTION_PRIVATE[0].Host,
      webSocket: config.SITE_CONFIG.CONNECTION_DETAILS[0].Host,
      longPoll: config.SITE_CONFIG.CONNECTION_DETAILS[1].Host,
    })

    yield fork(enableBalanceShortPull)
    yield loadLanguage(config)
    yield put(bannersWatchers.getInlineBanners())
    yield getOddTypes()
  } catch (e) {
    console.log(e)
  }
}

function* changeLanguage(action) {
  yield call(post(links.settings, { Language: action.payload }))
}

function* loadLanguage(config) {
  try {
    const ln = langIDs.get(config?.CUSTOMER_CONFIG?.LANGUAGE_ID) ?? 'en'
    const locales = yield call(
      get(
        //`https://api.absolute.bet/config/locales/${
        `https://dev.absolute.bet/config/locales/${ln}.json`,
        [],
        false
      )
    )
    i18n.store.data[ln] = { translation: locales }
    setTimeout(() => i18n.changeLanguage(ln), 200)
    yield put(configWorkers.setLocales(locales ?? en))
  } catch (e) {
    yield put(configWorkers.setLocales(en))
    i18n.store.data['en'] = { translation: en }
    setTimeout(() => i18n.changeLanguage('en'), 200)
    console.log('language loading failed', e)
  }
}

function* enableBalanceShortPull() {
  const config = yield select((state) => state.config)
  if (!config.CUSTOMER_CONFIG.LOGGED_IN) {
    return
  }
  const subscriptionChannel = yield call(
    //@ts-ignore
    createSubscriptionChannel,
    '/balance/get',
    {
      token: config?.CURRENT_CUSTOMER?.token,
    },
    'balance',
    [
      (emitter, rs) => {
        const oldState = _.cloneDeep(store.getState().config)
        emitterByCmd(oldState, rs, emitter)
      },
    ],
    10000,
    config,
    true
  )

  try {
    while (true) {
      const data = yield take(subscriptionChannel)
      yield put(configWorkers.setBalance(data.balance || null))
    }
  } catch (e) {
    subscriptionChannel.close()
  }
}

function* getBalance() {
  try {
    window.location.href.includes('casino')
      ? console.log('Casino opened')
      : yield fork(enableBalanceShortPull)
  } catch (e) {
    console.log(e)
  }
}

const oddTypesShortPool = (config: IState) =>
  eventChannel((emitter) => {
    let connection
    const tryConnect = () => {
      if (config?.SITE_CONFIG?.CONNECTION_DETAILS) {
        connection = new Connection(null)
        connection.send({
          cmd: 'Request',
          path: linkTransform('/feedApi/?key=odd-types', config),
          data: {},
          topic: 'Odds types',
          listeners: [
            (res) => {
              console.log('ODD TYPES', res)

              if (isIterable(res)) {
                emitter(res)
              }
            },
          ],
          loop: 0,
        })
      } else {
        setTimeout(tryConnect, 50)
      }
    }
    tryConnect()
    return () => {
      emitter(END)
      connection?.send({
        cmd: 'Unsubscribe',
        path: linkTransform('/feedApi/?key=odd-types', config),
        data: {},
        topic: 'Odd types',
        listeners: [],
        loop: 0,
      })
    }
  })

function* enableOddTypesShortPull() {
  const config = yield select((state) => state.config)
  const updateChannel = yield call(oddTypesShortPool.bind(null, config))

  yield fork(function* () {
    /* yield take(types.DISABLE_INPLAY_UPDATE_WATCHER)
		updateChannel.close() */
  })

  try {
    while (true) {
      const data = yield take(updateChannel)
      console.log('ODD Types Pull', data || null)
      yield put(configWorkers.setOddTypes(data || null))
    }
  } catch (e) {
    console.log('CANCEL')
    yield cancel()
    updateChannel.close()
  }
}

function* getOddTypes() {
  try {
    window.location.href.includes('casino')
      ? yield console.log('Casino opened')
      : yield fork(enableOddTypesShortPull)
  } catch (e) {
    console.log(e)
  }
}

export const configSagas = [
  takeEvery(types.CHANGE_LANGUAGE, changeLanguage),
  takeEvery(types.GET_CONFIG_WATCHER, getConfig),
  takeEvery(types.GET_BALANCE_WATCHER, getBalance),
  takeEvery(types.GET_ODD_TYPES, getOddTypes),
]
