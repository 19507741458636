import React, { useContext, useEffect, useRef } from 'react'
import RegistrationInner from './registrationInner'
import RegistrationSuccessed from './messages/registrationSuccessed'
import Overlay from './overlay'
import { RegisterContext } from '../../context/registrationContext'

const Registration = () => {
  //Header context (show or hide Registration Form)
  const { showRegistration, done } = useContext(RegisterContext)

  //Overlay Ref hide registration form to click on Overlay Component
  const ref = useRef()

  //hide Form document handler
  const hideRegistration = (e) => {
    if (ref.current === e.target && showRegistration) {
      showRegistration()
    }
  }

  useEffect(() => {
    document.addEventListener('click', hideRegistration)
    return () => document.removeEventListener('click', hideRegistration)
  }, [ref.current])

  return (
    <>
      <Overlay node={ref}>{done ? <RegistrationSuccessed /> : <RegistrationInner />}</Overlay>
    </>
  )
}

export default Registration
