import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Center from '../../components/containers/main/Center'
import BannerSlider from '../../components/forSportsPageComponents/banner/BannerSlider'
import ClassificationBar from '../../components/forSportsPageComponents/classificationBar/ClassificationBar'
import TopMatches from '../../components/forSportsPageComponents/TopMatches'
import SportCompetitions from '../../components/common/competition/SportCompetitions'
import Footer from '../../components/containers/footer/Footer'
import Video from '../../components/forSportsPageComponents/Video'
import DepositBanner from '../../components/forSportsPageComponents/DepositBanner'
import TopLeague from '../../components/forSportsPageComponents/TopLeague'
import Coupons from '../../components/forSportsPageComponents/Coupons'
import { getCurrentActive } from '../../redux/selectors/inplaySelectors'

const SportsMain = () => {
  const dispatch = useDispatch()
  const active = useSelector(getCurrentActive)

  // Here was load current sport

  return (
    <Center onScroll={() => null}>
      <BannerSlider />
      <ClassificationBar />
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div className="ip-sports" style={{ width: '50%' }}>
          <SportCompetitions />
          <TopMatches />
        </div>
        <div className="ip-sports" style={{ width: '50%', marginLeft: '2px' }}>
          <Video />
          <DepositBanner />
          <TopLeague />
          <Coupons />
        </div>
      </div>
      <Footer />
    </Center>
  )
}

export default SportsMain
