import React from 'react'
import { useTranslation } from 'react-i18next'

const Tab = ({ item, active = false, handler, activeCl, defaultCl }) => {
  const { t } = useTranslation()
  return (
    <a
      className={defaultCl + (active ? ' ' + activeCl : '')}
      onClick={(e) => {
        e.stopPropagation()
        handler(item.id)
      }}
    >
      <span className="font font--12">{t(item.title)}</span>
    </a>
  )
}

export default Tab
