import React from 'react'
import ScoreboardCompetitors from './header/ScoreboardCompetitors'
import ScoreboardTableWrapper from './ScoreboardTableWrapper'
import ScoreboardEvents from './matchEvents/ScoreboardEvents'

const MatchScoreboard = ({ additionalData, scoreboard, competitors, content, sportId }) => {
  return (
    <>
      <ScoreboardCompetitors
        additionalData={additionalData}
        // scoreboard={scoreboard}
        competitors={competitors}
      />
      {typeof additionalData !== 'undefined' ? (
        content === 'table' ? (
          <ScoreboardTableWrapper
            additionalData={additionalData}
            competitors={competitors}
            sportId={sportId}
          />
        ) : (
          <ScoreboardEvents />
        )
      ) : null}
    </>
  )
}

export default React.memo(MatchScoreboard)
