import React from 'react'
import ScoreboardCell from './ScoreboardCell'
import Text from '../../../../common/elements/Text'

const ColumnTitle = ({ title }) => (
  <ScoreboardCell>
    <Text title={title} className="font font--11 font--white-alpha-05" />
  </ScoreboardCell>
)

export default React.memo(ColumnTitle)
