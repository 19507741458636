import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Calendar from 'react-calendar'
import controlbar from '../../../forAsideRight/betslipAside/myBets/myBetsControlBar.json'

export type periodT = '2 hours' | '24 hours' | '3 days' | '7 days' | '1 month' | '6 months'
const periods: Array<periodT> = ['2 hours', '24 hours', '3 days', '7 days', '1 month', '6 months']
const BetHistoryHeader = ({
  active,
  setActive,
  date,
  setDate,
  period,
  setPeriod,
  dateBeingSet,
  setDateBeingSet,
}) => {
  const { t } = useTranslation()
  const [openedCalendar, setOpenedCalendar] = useState(false)
  const [time, setTime] = React.useState({
    activeFilter: '24 Hours',
    dropdown: false,
  })

  const switchTabHandler = (newTab) => {
    return () => {
      setActive(newTab)
    }
  }
  const dropdownToggleHandler = () => {
    setTime({
      ...time,
      dropdown: !time.dropdown,
    })
  }
  const setFilterHandler = (value) => {
    return () => {
      if (!value) {
        value = time.activeFilter
      }
      setTime({
        activeFilter: value,
        dropdown: false,
      })
    }
  }

  return (
    <div className="container__header">
      <div className="cb-tab">
        {controlbar.map((item) => (
          <div
            key={item.title.text}
            role="button"
            className={
              active === item.title.text ? 'cb-tab__item cb-tab__item--active' : 'cb-tab__item'
            }
            onClick={switchTabHandler(item.title.text)}
            onKeyPress={() => 1}
            tabIndex={0}
          >
            <p className="font">{item.title.lang ? t(item.title.text) : item.title.text}</p>
          </div>
        ))}
      </div>
      {time.dropdown && (
        <div
          role="button"
          className="h-popup__overlay"
          style={{ zIndex: 1 }}
          onClick={dropdownToggleHandler}
          onKeyPress={() => 1}
          tabIndex={0}
        ></div>
      )}
      <div className="dropdown">
        <span role="button" className="font dropdown__toggle" onClick={dropdownToggleHandler}>
          {time.activeFilter === 'All time' ? time.activeFilter : 'Last ' + period}
        </span>
        <div className={time.dropdown ? 'dropdown__menu active' : 'dropdown__menu'}>
          {periods.map((period) => (
            <span
              className="font dropdown__item"
              key={period}
              onClick={() => {
                setFilterHandler(period)()
                setDateBeingSet(false)
                setPeriod(period)
              }}
              role="button"
              onKeyPress={() => 1}
              tabIndex={0}
            >
              <p className="font">{period}</p>
            </span>
          ))}
        </div>
      </div>
      <hr className="hr" />
      <div className="calendar">
        <div
          role="button"
          className="calendar__item"
          onClick={() => {
            setOpenedCalendar(!openedCalendar)
          }}
          onKeyPress={() => 1}
          tabIndex={0}
        >
          <i className="fal fa-calendar-alt"></i>
        </div>
        {openedCalendar && (
          <>
            <div
              role="button"
              className="calendar-overlay"
              onClick={() => {
                setOpenedCalendar(false)
              }}
              onKeyPress={() => 1}
              tabIndex={0}
            />
            <div className="calendar-wrapper">
              <Calendar
                onChange={(e) => {
                  setDate(e)
                  setDateBeingSet(true)
                }}
                value={new Date(date)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default BetHistoryHeader
