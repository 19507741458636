import React from 'react'
import { Link } from 'react-router-dom'

const FooterColumnItem = ({ link, title }) => {
	return (
		<li className="footer__item">
			<Link className="font font--grey-400 footer__link" to={link}>
				{title}
			</Link>
		</li>
	)
}

export default FooterColumnItem
