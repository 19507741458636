import React from 'react'
import { useFormik } from 'formik'
import { useAccount } from '../../../../hooks/useAccount'

const Replenishment = ({ click }) => {
	const { schemaReplenishment, replenishment } = useAccount()
	const formik = useFormik({
		initialValues: replenishment.initialValues,
		validationSchema: schemaReplenishment,
		onSubmit: (values, methods) => {
			click()
		}
	})
	const {
		values,
		handleChange,
		handleSubmit,
		errors,
		touched
	} = formik
	return (
		<div className="overlay overlay--additionally overlay--block" onClick={click}>
			<div className="cb-replenishment cb-replenishment--block" onClick={(e) => { e.stopPropagation() }}>
				<div className="cb-replenishment__wrap">
					<form onSubmit={handleSubmit}>
						<div className="cb-replenishment__header">
							<div className="flex align-center-middle full-height">
								<img className="cb-replenishment__img" src="https://via.placeholder.com/170x24/F4F4F4" alt="170x24" />
							</div>
						</div>
						<div className="cb-replenishment__body">
							<div className={errors.amount
								? 'cb-replenishment__amount cb-replenishment__amount--error'
								: 'cb-replenishment__amount'}>
								<div className="flex align-middle align-justify">
									<p className="font">Amount (Min. 1.00 Bit)</p>
									<input
										className="field"
										type="text"
										name="amount"
										value={values.amount}
										onChange={handleChange} />
								</div>
								<div className="message">
									<div className="text-center">
										<p className="font font--red font-10">Minimum amount 10 Bin</p>
									</div>
								</div>
							</div>
							<div className="flex align-middle align-justify m-b-20">
								<p className="font font--white">Wallet number</p>
								<div className="cb-replenishment__wallet">
									<input className="field" type="text" value="MQtb$5NVSvlH&amp;SMJes&amp;Q" />
									<div className="copy"><i className="fal fa-copy"></i></div>
								</div>
							</div>
							<div className="text-center">
								<p className="font font--white">QR Code</p>
								<div className="cb-replenishment__qr"></div>
								<p className="font font--white font--10">Copy to clipboard</p>
							</div>
						</div>
						<div className="cb-replenishment__footer">
							<button
								className="btn btn--green btn--h-30"
								type="submit">
								<span className="font font--white">Deposit</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Replenishment