import React from 'react'

export const BetslipOverlay: React.FC = () => {
  return (
    <div className="betslip__overlay">
      <div className="spinner__outer">
        <div className="betslip__overlay-spinner" />
      </div>
    </div>
  )
}
