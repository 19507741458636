import React from 'react'
import EventParticipant from './EventParticipant'
import EventScore from './EventScore'

const ParticipantInfoCell = ({ competitors, score, sportId, toEvent, point }) => {
  return (
    <td
      className="td td--border"
      onClick={toEvent}
      style={{
        cursor: 'pointer',
        paddingLeft: '15px',
      }}
    >
      <div className="flex align-middle align-justify">
        <EventParticipant point={point} competitors={competitors} />
        {/*<Dollar />*/}
        {score && <EventScore score={score} sportId={sportId} />}
      </div>
    </td>
  )
}

export default React.memo(ParticipantInfoCell)
