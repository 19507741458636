import React from 'react'
import SearchIconLeague from './SearchIconLeague'
import { useHistory } from 'react-router-dom'

type ILeagueSearchItem = {
  NA: string
  EI: string
}

const SearchItemLeague: React.FC<{ q: ILeagueSearchItem }> = React.memo(function SearchItemLeague({
  q,
}) {
  const history = useHistory()
  const handleClick = () => {
    history.push(`/live/event/${q.EI.split('-')[4]}/${q.EI.split('-')[5]}/${q.EI.split('-')[3]}`)
  }

  return (
    <a
      className="h-search__link"
      onClick={() => {
        handleClick()
      }}
    >
      <SearchIconLeague />
      <p className="font font--13 font--white h-search__text">{q.NA}</p>
    </a>
  )
})

export default SearchItemLeague
