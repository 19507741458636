import React, { useContext } from 'react'
import Menu from '../../headerComponents/menus/Menu'
import AccountWrapper from '../../headerComponents/headerAccount/AccountWrapper'
import Registration from '../../registration/registration'
import Search from '../../headerComponents/search/Search'
import Submenu from '../../headerComponents/submenu/Submenu'
import LostCredentials from '../../registration/lostCredentials/lostCredentials'
import BreadCrambsWrapper from '../../headerComponents/breadCrumbs/BreadCrambsWrapper'
import { RegisterContext } from '../../../context/registrationContext'
import { NavLink } from 'react-router-dom'
import '../../../assets/styles/header.css'

const Header = () => {
  const { registration, lostCredentials } = useContext(RegisterContext)

  return (
    <>
      {registration ? <Registration /> : null}
      {lostCredentials?.show ? <LostCredentials /> : null}
      <header className="header">
        <nav className="h-nav">
          <div className="flex align-middle align-justify h-nav__up">
            <NavLink className="h-logo" to="/" />
            <Menu />
            <AccountWrapper />
          </div>
          <div className="flex align-middle align-justify h-nav__down">
            <BreadCrambsWrapper />
            <Search />
            <Submenu />
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
