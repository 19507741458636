import React, { useState } from 'react'
import Multiple from './Multiple'
import MultDropUp from './MultDropUp'
import { CSSTransition } from 'react-transition-group'

const Multiples = ({ className, multistakes, change, blur, focus }) => {
	const [multiState, setMultiState] = useState({ arrow: 'down' })

	const dropDownHandler = state => {
		multiState.arrow === 'down'
			? setMultiState({ arrow: 'up' })
			: setMultiState({ arrow: 'down' })
	}

	let multiStakeValue = 0
	if (typeof multistakes.dm.stake !== 'undefined') {
		multiStakeValue = multistakes.dm.stake
	}

	return (
		<div className={className}>
			<>
				{typeof multistakes.dm !== 'undefined' ? (
					<Multiple
						key={multistakes.dm.bt.toString()}
						inputid={multistakes.dm.bt}
						value={multiStakeValue}
						item={multistakes.dm}
						change={change}
						blur={blur}
						focus={focus}
					/>
				) : null}
				<MultDropUp click={dropDownHandler} arrow={multiState.arrow} />
				{/* typeof multistakes.mo !== 'undefined' && multiState.arrow === 'down' 
					?*/ ( 
						<CSSTransition in={multiState.arrow !== 'down'} classNames="mult_slide" timeout={250}>
							<div className="wrrrap">
								{
									multistakes.mo?.map((item, idx) => {
										let multiStakeValue = 0
										if (typeof item.stake !== 'undefined') {
											multiStakeValue = item.stake
										}
										return (
											<Multiple
												key={item.bt.toString()}
												inputid={item.bt}
												value={multiStakeValue}
												item={item}
												change={change}
												blur={blur}
												focus={focus}
											/>
										)
					  })}
							</div>
						</CSSTransition>
					)
					/* : null */}
			</>
		</div>
	)
}

export default Multiples
