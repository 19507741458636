import React from 'react'
import SearchIconMatch from './SearchIconMatch'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { eventWatchers } from '../../../redux/actions/eventActions'
import {
  prematchEventWatchers,
  prematchEventWorkers,
} from '../../../redux/actions/prematchEventActions'
import { getBetslip } from 'redux/selectors/betslipSelectors'
import { setPath } from 'redux/actions/betslipActions'

type ISearchItem = {
  NA: string
  EI: string
}

const SearchItemMatch: React.FC<{ q: ISearchItem; showSearch: (value: boolean) => any }> =
  React.memo(function SearchItemMatch({ q, showSearch }) {
    const dispatch = useDispatch()
    const { path } = useSelector(getBetslip)
    const history = useHistory()
    const params: {
      id?: string
      leagueId?: string
    } = useParams()

    const handleClick = () => {
      dispatch({ type: `REMOVE_EVENT_BY_ID_${params?.id}` })
      dispatch(
        eventWatchers.removeEventById({
          id: params.id,
          leagueId: params.leagueId,
        })
      )
      dispatch(prematchEventWatchers.removeEvent())
      dispatch(prematchEventWorkers.clearEventData())
      setTimeout(() => {
        showSearch(false)
        history.push(
          `/live/event/${q.EI.split('-')[4]}/${q.EI.split('-')[5]}/${q.EI.split('-')[3]}`
        )
      }, 10)
      if (path) {
        const route = path.split('->')
        if (route.pop() === 'search') {
          return
        }
        if (route.length >= 3) {
          const last = route.pop()
          const prev = route.pop()
          dispatch(setPath(prev + '->' + last + '->' + 'search'))
        } else {
          dispatch(setPath(path + '->' + 'search'))
        }
      } else {
        dispatch(setPath('search'))
      }
    }

    return (
      <a
        className="h-search__link"
        onClick={() => {
          handleClick()
        }}
      >
        <SearchIconMatch id={q.EI.split('-')[4]} />
        <p className="font font--13 font--white h-search__text">{q.NA}</p>
      </a>
    )
  })

export default SearchItemMatch
