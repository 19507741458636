import React from 'react'
import SimpleBar from 'simplebar-react'

const Center = ({ children, className = '', ...rest }) => {
  return (
    <SimpleBar
      className={`${className} center`}
      style={{
        maxWidth: '100%',
      }}
    >
      <div {...rest}>{children}</div>
    </SimpleBar>
  )
}

export default Center
