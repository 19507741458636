import React, { useCallback } from 'react'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { useLocation } from 'react-router-dom'
import BreadCrumbsItem from './BreadCrumbsItem'
import { useSelector } from 'react-redux'
import { getInplay, getInplayTree } from '../../../redux/selectors/inplaySelectors'
import { getLeagueEvents } from '../../../redux/selectors/eventSelectors'

const BreadCrumbs = ({ breadcrumbs }) => {
  const breadcrumbsFilter = breadcrumbs.filter(
    (crumb, idx) => crumb.key !== '/live/event' && idx !== 2 && idx !== 3
  )
  const { pathname } = useLocation()

  const inplay = useSelector(getInplay)
  const inplayTree = useSelector(getInplayTree)

  const getEventName = (eventId, inplay) => {
    const leagueId = Number(breadcrumbsFilter[2].breadcrumb.props.children)
    const league = inplay.leaguesEvents[leagueId] || null
    if (league !== null) {
      const eventData = league.find((event) => event.id.toString() === eventId)
      if (eventData) {
        return eventData.competitors[0].name + ' vs ' + eventData.competitors[1].name
      }
      else {
        return ' Event view'
      }
    } else {
      return breadcrumbsFilter[3].breadcrumb.props.children
    }
  }

  const getLeagueName = (leagueID, sportID, tree) => {
    if (tree.length >= 1) {
      return tree
        .find((sport) => sport.id.toString() === sportID)
        ?.categories.find((category) =>
          category.leagues?.find((league) => league.id.toString() === leagueID)
        )
        ?.leagues.find((league) => league.id.toString() === leagueID).name
    } else {
      return breadcrumbsFilter[2].breadcrumb.props.children
    }
  }

  const checkString = (string, arr) => {
    let is = false
    arr?.forEach((substr) => {
      if (string.includes(substr)) {
        is = true
      }
    })
    return is
  }

  const manageCrumbsTitle = (idx, breadcrumb) => {
    if (idx === 3 && window.location.href.includes('live/event')) {
      return getEventName(breadcrumb.props.children, inplay)
    } else if (idx === 2 && window.location.href.includes('live/event')) {
      return getLeagueName(
        breadcrumb.props.children,
        window.location.href.split('/')[5],
        inplayTree
      )
    } else {
      return breadcrumb.props.children
    }
  }

  return (
    <>
      {breadcrumbsFilter?.map(({ breadcrumb, key }, idx) => {
        if (checkString(key, ['/league/event', '/league/event/', '/sports/'])) return null

        return (
          <BreadCrumbsItem
            key={key}
            title={manageCrumbsTitle(idx, breadcrumb)}
            link={key}
            active={pathname === key}
          />
        )
      })}
    </>
  )
}

export default withBreadcrumbs()(BreadCrumbs)
