import React from 'react'

type ContentProps = {
	className?: string,
	refContainer?: any
}
const Content:React.FC<ContentProps> = ({ children, className = '', refContainer }) => {
	return (
		<div ref={refContainer} className={`content ${className}`}>
			{children}
		</div>
	)
}

export default Content
