import Text from 'components/common/elements/Text'
import React from 'react'

const FixtureEmpty = () => {
  return (
    <div className="ipn-fixture__header">
      <div className="flex align-middle align-justify full-height">
        <Text
          className="font font--grey-300"
          title={"There are no events with live translation"}
        />
      </div>
    </div>
  )
}

export default FixtureEmpty
