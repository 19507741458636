import React from 'react'

type ButtonProps = {
  title?: string
  type?: 'button' | 'submit' | 'reset'
  className?: string
  onClick?: (e) => void
}
const Button: React.FC<ButtonProps> = ({
  title = '',
  type = 'button',
  className = '',
  children,
  ...props
}) => {
  return (
    <button
      // @ts-ignore
      type={type}
      {...{ className }}
      {...(props as React.HTMLProps<HTMLButtonElement>)}
    >
      {title} {children}
    </button>
  )
}

Button.defaultProps = {
  title: '',
}

export default Button
