import React from 'react'
import { IBetPa } from '../../../../helpers/commonInterfaces/interfaces'

type IStatuses = {
  item: IBetPa
  ST: number
  RE: string
}

const Statuses: React.FC<IStatuses> = ({ item, ST, RE }) => {
  const { NA, MA, OD, SL, WS } = item
  const name = item.ST ?? ''
  return (
    <>
      {WS === 0 && <div className="bspa bspa__open">{name ?? 'Open'}</div>}
      {WS === 1 && <div className="bspa bspa__win">{name ?? 'Win'}</div>}
      {WS === 2 && (
        <div className="bspa bspa__loose">{name ?? 'Loose'}</div>
      )}
      {WS === 3 && <div className="bspa bspa__push">{name ?? 'Push'}</div>}
      {WS === 4 && <div className="bspa bspa__void">{name ?? 'Void'}</div>}
      {WS === 5 && (
        <div className="bspa bspa__halfwin">
          ½ {name.split('½')[1]} <span>½ {name.split('½')[2]}</span>
        </div>
      )}
      {WS === 6 && (
        <div className="bspa bspa__halfloose">
          <span>½ {name.split('½')[1]} </span> ½ {name.split('½')[2]}
        </div>
      )}
      {WS === 9 && <div className="bspa bspa__cashedout">Cashed out</div>}
    </>
  )
}

export default Statuses
