import Inbox from '../../components/forAccontPageComponents/messages/Inbox'
import Sent from '../../components/forAccontPageComponents/messages/Sent'
import OpenMessage from '../../components/forAccontPageComponents/messages/OpenMessage'
import MessagesRedirection from 'components/Routing/accountRedirection/messagesRedirection'

export const messagesRouting = [
	{
		path: '/account/messages/inbox',
		exact: true,
		component: Inbox,
	},
	{
		path: '/account/messages/sent',
		exact: true,
		component: Sent,
	},
	{
		path: '/account/messages/open',
		exact: true,
		component: OpenMessage,
	},
	{
		component: MessagesRedirection
	},
]
