import soccer from '../img/svgIcons/Soccer.svg'
import basketball from '../img/svgIcons/Basketball.svg'
import baseball from '../img/svgIcons/Baseball.svg'
import tennis from '../img/svgIcons/Tennis.svg'
import tableTennis from '../img/svgIcons/TableTennis.svg'
import pool from '../img/svgIcons/Pool.svg'
import esports from '../img/svgIcons/Esports.svg'
import speedway from '../img/svgIcons/Speedway.svg'
import americanFootball from '../img/svgIcons/AmericanFootball.svg'
import ufc from '../img/svgIcons/UFC.svg'
import snooker from '../img/svgIcons/Snooker.svg'
import volleyball from '../img/svgIcons/Volleyball.svg'
import beachVolleyball from '../img/svgIcons/BeachVolleyball.svg'
import rugby from '../img/svgIcons/Rugby.svg'
import motorbikes from '../img/svgIcons/Motorbikes.svg'
import iceHockey from '../img/svgIcons/IceHockey.svg'
import handball from '../img/svgIcons/Handball.svg'
import golf from '../img/svgIcons/Golf.svg'
import futsal from '../img/svgIcons/Futsal.svg'
import formula from '../img/svgIcons/Formula.svg'
import floorball from '../img/svgIcons/Floorball.svg'
import darts from '../img/svgIcons/Darts.svg'
import cycling from '../img/svgIcons/Cycling.svg'
import cricket from '../img/svgIcons/Cricket.svg'
import bows from '../img/svgIcons/Bowls.svg'
import bandy from '../img/svgIcons/Bandy.svg'
import horse from '../img/svgIcons/Horseracing.svg'
import badminton from '../img/svgIcons/Badminton.svg'
import dog from '../img/svgIcons/Greyhounds.svg'
import supercars from '../img/svgIcons/Supercars.svg'
import favourites from '../img/svgIcons/favourites.svg'

export const icons = {
	0: favourites,
	1: soccer,
	2: basketball,
	3: baseball,
	4: iceHockey,
	5: tennis,
	7: golf,
	8: rugby,
	10: formula,
	12: rugby,
	13: tennis,
	14: snooker,
	15: darts,
	16: baseball,
	17: iceHockey,
	19: snooker,
	18: basketball,
	20: tableTennis,
	22: darts,
	23: volleyball,
	24: speedway,
	27: motorbikes,
	31: badminton,
	34: beachVolleyball,
	35: pool,
	38: cycling,
	66: bows,
	78: handball,
	83: futsal,
	89: bandy,
	90: floorball,
	91: volleyball,
	92: tableTennis,
	94: badminton,
	95: beachVolleyball,
	114: supercars,
	151: esports,
}
