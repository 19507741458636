import React from 'react'
import MarketRow from '../MarketRow'
import { OddOutcome } from '../../../../../../helpers/commonInterfaces/interfaces'
import { useSelector } from 'react-redux'
import { getBetslip } from '../../../../../../redux/selectors/betslipSelectors'
import { getOddsType } from '../../../../../../redux/selectors/configSelectors'
import ColumnTitle from '../ColumnTitle'
import BetButton from 'components/common/buttons/BetButton'

interface IExactBody {
  outcomes: Array<OddOutcome>
  sportId: number
  eventId: number
  createBet: (
    eventId: number,
    id: number,
    od: number,
    ha: string,
    check: boolean,
    qb: boolean
  ) => void
}

const ExactBody: React.FC<IExactBody> = ({
  outcomes,
  eventId,
  sportId,
  createBet,
}) => {
  const { quickBet, bt } = useSelector(getBetslip)
  const ODDS_TYPE = useSelector(getOddsType)
  outcomes.forEach(outcome => {
    const cloneOutcome = { ...outcome }
  })

  const half = Math.ceil(outcomes.length / 2)

  const renderRow = (item, idx) => (
    <MarketRow
      key={item.id + item.outcomeId}
      isMulti={false}
      style={{
        display: 'flex',
        flex: '1 1 50%',
        borderRight: '1px solid rgba(223, 223, 223, 0.8)',
      }}>
      {/* <div
        className="gll-market-group__cell"
        style={{
          display: 'flex',
          flex: '1 1 50%',
          borderRight: '1px solid rgba(223, 223, 223, 0.8)',
        }}>
        <div className="gll-market-group__coff">
          <span className="font font--grey-300 text-ellipsis">
            {item.outcome}
          </span>
        </div>
      </div> */}
      <div
        className="gll-market-group__cell"
        style={{
          display: 'flex',
          flex: '1 1 25%',
        }}>
        <BetButton
          item={item}
          key={item.id}
          heightNotFixed={true}
          onCreateBet={createBet.bind(null, eventId)}
          panel={quickBet}
          allStakes={bt}
          oddsType={ODDS_TYPE}
        />
      </div>
    </MarketRow>
  )

  return (
    <div className="gll-market-group__body">
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          maxHeight: `${50 * half}px`,
        }}>
        {outcomes.splice(0, half).map(renderRow)}
        {outcomes.splice(-half).map(renderRow)}
      </div>
    </div>
  )
}

export default React.memo(ExactBody)
