import { types } from '../types/types'
import {
  IMatch,
  InferType,
  ITournament,
  Nullable,
  Odd,
} from '../../helpers/commonInterfaces/interfaces'

type IGetEvent = {
  id: string | undefined
  leagueId: string | undefined
}

export const eventWorkers = {
  setEvent: (event: Nullable<IMatch | ITournament>) =>
  ({
    type: types.SET_EVENT,
    payload: event,
  } as const),
  setEventError: () =>
  ({
    type: types.SET_EVENT_ERROR,
  } as const),
  setEvents: (data: Nullable<Array<IMatch | ITournament>>) =>
  ({
    type: types.SET_LEAGUE_EVENTS,
    data,
  } as const),
  setMarkets: (data: { [key: string]: Odd }) =>
  ({
    type: types.SET_MARKETS,
    data,
  } as const),
  setAside: (data: { openedSports: Array<number>; openedLeagues: Array<number> }) =>
  ({
    type: types.SET_ASIDE,
    data,
  } as const),
  clearEventData: () =>
  ({
    type: types.CLEAR_EVENT_DATA,
  } as const),
}

export const eventWatchers = {
  getEvent: ({ id, leagueId }: IGetEvent) =>
  ({
    type: types.SET_EVENT_WATCHER,
    ids: {
      id,
      leagueId,
    },
  } as const),
  removeEvent: () =>
  ({
    type: types.REMOVE_EVENT,
  } as const),

  removeEventById: ({ id, leagueId }: IGetEvent) =>
  ({
    type: types.REMOVE_EVENT_BY_ID,
    ids: {
      leagueId,
      id,
    },
  } as const),

  getLeagueEvents: (id: number) =>
  ({
    type: types.SET_LEAGUE_EVENTS_WATCHER,
    id,
  } as const),
  getLeaguesEvents: () =>
  ({
    type: types.SET_LEAGUES_EVENTS_WATCHER,
  } as const),
}

export type EventWorkersTypes = ReturnType<InferType<typeof eventWorkers>>
export type EventWatchersTypes = ReturnType<InferType<typeof eventWatchers>>
