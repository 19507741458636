import React from 'react'
import ReactDOM from 'react-dom'
import SimpleBar from 'simplebar-react'
import App from './components/App'
import './helpers/i18n.ts'
import './assets/styles/index.css'
import './assets/styles/react-transition-group.css'
import 'simplebar/dist/simplebar.min.css'

ReactDOM.render(
  <SimpleBar>
    <App />
  </SimpleBar>,
  document.getElementById('root')
)
