import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { MatchTimer } from '../../helpers/timer'

const areEqual = (prev, next) => {
  return _.isEqual(prev, next)
}

const EventTimer = ({ color, periodId, clock, sportId, scoreboard }) => {
  const timer = new MatchTimer()

  const timerId = useRef<NodeJS.Timeout | null>(null)

  const [timerValue, setTimerValue] = useState('')

  const halfTimeOrEnd = () => {
    if (periodId?.data === 31) {
      return (
        <span
          className="font font--13"
          style={{ color: '#fc3164', fontWeight: 700, marginRight: '5px' }}
        >
          Half time
        </span>
      )
    } else if (periodId?.data === 100) {
      return (
        <span
          className="font font--13"
          style={{ color: '#fc3164', fontWeight: 700, marginRight: '5px' }}
        >
          Match End
        </span>
      )
    } else {
      return null
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const timerVal = () => {
    if (halfTimeOrEnd() !== null) {
      return halfTimeOrEnd()
    } else {
      return timerValue
    }
  }

  const hookExpressionClock = () => {
    if (typeof clock !== 'undefined' && clock.hasOwnProperty('data') && clock?.data) {
      return clock.data
    } else {
      return []
    }
  }

  const start = () => {
    timerId.current = setTimeout(() => {
      setTimerValue(timer.start({ periodId, clock }))
      start()
    }, 1000)
  }

  useEffect(() => {
    if (typeof clock !== 'undefined' && clock.hasOwnProperty('data') && clock?.data) {
      start()

      return () => {
        timer.clearRemainingTime()
        clearTimeout(Number(timerId.current))
      }
    }
  }, [hookExpressionClock()])

  return (
    <span
      style={{

        justifyContent: 'center',
        flexDirection: 'row',
      }}
    >
      {sportId === 1 && clock?.data ? (
        periodId.data !== 31 && periodId.data !== 100 ? (
          periodId.data !== 7 ? (
            <span className="font font--13" style={{ color: color ? color : 'black', marginRight: '5px' }}>
              1H
            </span>
          ) : (
            <span className="font font--13" style={{ color: color ? color : 'black', marginRight: '5px' }}>
              2H
            </span>
          )
        ) : null
      ) : null}
      {
        <>
          {sportId === 5 || sportId === 20 || sportId === 23 || sportId === 2 ? (
            <span
              className="font font--13 font--red font--semibold"
              style={{ marginRight: '5px', padding: '5px 0' }}
            >
              {scoreboard?.data.periodScores?.length
                ? (sportId === 5 ? 'Set ' : sportId === 2 ? 'Q' : 'Game ') +
                scoreboard?.data.periodScores?.length
                : 'Ended'}
            </span>
          ) : null}
        </>
      }
      <span>{timerVal()}</span>
    </span >
  )
}

export default React.memo(EventTimer, areEqual)
