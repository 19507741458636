import React from 'react'
import { modifyBets } from '../../../helpers/modifyBets'

const ButtonValueWithText = ({ oddValue, oddsType, highlight, outcome, outcomeId }) => {
  const transform = (outcome) => {
    if (outcome === ' ') {
      if (
        outcomeId === '1001' ||
        outcomeId === '206001' ||
        outcomeId === '232001' ||
        outcomeId === '175001'
      ) {
        return '1'
      } else {
        return '2'
      }
    } else {
      if (outcome === ' X') {
        return '1 or Draw'
      }
      if (outcome === 'X ') {
        return '2 or Draw'
      }
      if (outcome === '  ') {
        return '1 or 2'
      }
      if (outcome === 'X') {
        return 'Draw'
      }
      return outcome
    }
  }
  return (
    <span className="nowrap flex flex-1-1">
      <span className="font font--11 font--default-alpha-05 flex-1-1">{transform(outcome)}</span>
      <span className={'font font--12 flex-1-1 ' + highlight.className}>
        {modifyBets(oddValue, oddsType)}
      </span>
    </span>
  )
}

export default React.memo(ButtonValueWithText)
