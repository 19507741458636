import React from 'react'
import SoccerScoreboard from '../SoccerScoreboard'
import {
  sportsScoreboardTitles,
  transformPeriodScores,
} from '../../../../../helpers/transform/scoreboardTransform'
import ColumnCompetitorScores from '../../common/tableComponents/ColumnCompetitorsScores'

const ScoreboardCompetitorsScores = ({ scoreboard, sportId, soccerStatistics }) => {
  if (typeof scoreboard === 'undefined') {
    return <></>
  } else {
    const { periodScores, currentGamePoints, currentHomeScore, currentAwayScore } = scoreboard?.data

    if (sportId === 1) {
      return (
        <SoccerScoreboard
          currentAwayScore={currentAwayScore}
          currentHomeScore={currentHomeScore}
          soccerStatistics={soccerStatistics}
        />
      )
    }

    const titles = sportsScoreboardTitles[sportId] || sportsScoreboardTitles.defaultTitles

    const scores = transformPeriodScores({
      periodScores,
      currentGamePoints,
      currentAwayScore,
      currentHomeScore,
      sportId,
    })

    return (
      <>
        {titles?.map((title, i) => {
          const { homeScore, awayScore } = scores[i]

          return (
            <ColumnCompetitorScores
              key={i}
              title={title}
              homeScore={homeScore}
              awayScore={awayScore}
            />
          )
        })}
      </>
    )
  }
}

export default React.memo(ScoreboardCompetitorsScores)
