import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { favoritesWorkers } from 'redux/actions/favoritesActions'
import { getFavorites } from 'redux/selectors/favoritesSelectors'

const League = ({ league, openedHandler, lastOpened }) => {
  const dispatch = useDispatch()
  const favorites = useSelector(getFavorites)
  const toggleFavLeague = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (favorites.leagues[league.sportId]?.some(lg => lg === league.id.toString())) {
      dispatch(
        favoritesWorkers.removeLeague(
          league.id.toString(),
          league.sportId.toString()
        )
      )
    } else {
      dispatch(
        favoritesWorkers.addLeague(league.id.toString(), league.sportId.toString())
      )
    }
  }

  const clickHandler = () => {
    if (lastOpened.league !== league.id) {
      openedHandler('league', league.id)
    }
  }

  return (
    <NavLink
      className="wn-classification-league p-l-5"
      activeClassName="wn-classification-league--active"
      to={`/sports/${league.sportId}/${league.id}`}
      onClick={clickHandler}
    >
      <div className="flex align-middle wn-classification-league__header">
        <p className="font font--grey-300 text text-ellipsis">{league.name}</p>
        <div className={"ipn-competition__favourite"
          + (Object.values(favorites.leagues).some((sport: any[]) => sport.some(lg => lg === league.id.toString())) ? ' active' : '')}
          onClick={toggleFavLeague}
        />
        {/* <p className="font font--grey-300 count">{league.matchCount}</p>
        <div className="wn-classification-league__favourite" /> */}
      </div>
    </NavLink>
  )
}

export default League
