import React, { useState } from 'react'
import Wrap from '../../registration/elements/wrap'
import Input from '../../common/elements/Input'
import Text from '../../common/elements/Text'
import Button from '../../registration/elements/button'
import EyeIcon from '../../common/elements/EyeIcon'
import { useFormik } from 'formik'
import { useAccount } from '../../../hooks/useAccount'
import PopupSucceed from '../PopupSucceed'
import { Nullable } from 'helpers/commonInterfaces/interfaces'

const ChangePassword = () => {
  const [pw, setPw] = useState({
    cur: false,
    new: false,
    confirm: false,
  })
  const [popup, setPopup] = useState(false)
  const { sumbit, changePassword, schemaChangePassword } = useAccount()
  const closePopupHandler = () => {
    window.clearTimeout(window.closePopupTimeout)
    setPopup(false)
  }
  const showPasswordHandler = (prop) => {
    return () => {
      setPw({ ...pw, [prop]: !pw[prop] })
    }
  }
  const formik = useFormik({
    initialValues: changePassword.initialValues,
    validationSchema: schemaChangePassword,
    onSubmit: (values, methods) => {
      setPopup(true)
      window.clearTimeout(window.closePopupTimeout)
      window.closePopupTimeout = setTimeout(() => {
        window.clearTimeout(window.closePopupTimeout)
        setPopup(false)
      }, 3000)
      sumbit('changePassword', values)
    },
  })
  let curPasswordType: Nullable<string> = null,
    newPasswordType: Nullable<string> = null,
    confirmPasswordType: Nullable<string> = null
  if (pw.cur) {
    curPasswordType = 'text'
  } else {
    curPasswordType = 'password'
  }
  if (pw.new) {
    newPasswordType = 'text'
  } else {
    newPasswordType = 'password'
  }
  if (pw.confirm) {
    confirmPasswordType = 'text'
  } else {
    confirmPasswordType = 'password'
  }
  const { values, handleChange, handleSubmit, errors, touched } = formik
  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="container__head">
          <p className="font font--semibold">Change Password</p>
        </div>
        <div className="container__wrap">
          <div className="account">
            <div className="left">
              <Wrap
                className={
                  errors.currentPassword && touched.currentPassword && 'required required--error'
                }
              >
                <Text
                  title={'Current password'}
                  className="font font--10 font--default-alpha-05 m-t-5"
                />
                <Input
                  className="field field--main"
                  type={curPasswordType}
                  placeholder="Password*"
                  name="currentPassword"
                  value={values.currentPassword}
                  onChange={handleChange}
                  autoComplete="on"
                />
                <EyeIcon showPasswordHandler={showPasswordHandler('cur')} type={pw.cur} />
                {errors.currentPassword && touched.currentPassword && (
                  <Text
                    title={errors.currentPassword ?? ''}
                    className="font font--10 font--default-alpha-05 m-t-5"
                  />
                )}
              </Wrap>
              <Wrap
                className={errors.newPassword && touched.newPassword && 'required required--error'}
              >
                <Text
                  title={'New password'}
                  className="font font--10 font--default-alpha-05 m-t-5"
                />
                <Input
                  className="field field--main"
                  type={newPasswordType}
                  placeholder="New password*"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  autoComplete="on"
                />
                <EyeIcon showPasswordHandler={showPasswordHandler('new')} type={pw.new} />
                {errors.newPassword && touched.newPassword && (
                  <Text
                    title={errors.newPassword}
                    className="font font--10 font--default-alpha-05 m-t-5"
                  />
                )}
              </Wrap>
              <Wrap
                className={
                  errors.confirmPassword && touched.confirmPassword && 'required required--error'
                }
              >
                <Text
                  title={'Confirm password'}
                  className="font font--10 font--default-alpha-05 m-t-5"
                />
                <Input
                  className="field field--main"
                  type={confirmPasswordType}
                  placeholder="Confirm password*"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  autoComplete="on"
                />
                <EyeIcon showPasswordHandler={showPasswordHandler('confirm')} type={pw.confirm} />
                {errors.confirmPassword && touched.confirmPassword && (
                  <Text
                    title={errors.confirmPassword}
                    className="font font--10 font--default-alpha-05 m-t-5"
                  />
                )}
              </Wrap>
              <Wrap>
                <Button type="submit" title="Save" />
              </Wrap>
            </div>
            <div className="right">
              <div className="wrap">
                <p className="font font--semibold m-b-10">Password requirements</p>
                <ul className="list">
                  <li className="list__item m-b-10">
                    <p className="font m-b-5">Must contain uppercase characters</p>
                    <p className="font font--default-alpha-05">
                      The password must contain at least one uppercase character
                    </p>
                  </li>
                  <li className="list__item m-b-10">
                    <p className="font m-b-5">Must contain lowercase characters</p>
                    <p className="font font--default-alpha-05">
                      Password must contain at least one lowercase character
                    </p>
                  </li>
                  <li className="list__item m-b-10">
                    <p className="font m-b-5">Must contain numbers</p>
                    <p className="font font--default-alpha-05">
                      the password must contain at least one numeric character. Numeric characters
                      are 1 2 3 4 5 6 7 8 9 0
                    </p>
                  </li>
                  <li className="list__item m-b-10">
                    <p className="font m-b-5">Must contain a special character</p>
                    <p className="font font--default-alpha-05">
                      The password must contain at least one special character. Supported special
                      characters:! @ # $% ^ &amp; * () - _ = + \ | [] {}; : /? . &gt; &lt;
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
      {popup && (
        <PopupSucceed
          close={closePopupHandler}
          message={'Account settings has changed successfully'}
        />
      )}
    </div>
  )
}

export default ChangePassword
