import React from 'react';

const TableRow = ({ img, name, min, max, comission, processing, click, buttonName = "Deposit" }) => {
  return (
    <tr className="cb-table__tr" role="row">
      <td className="cb-table__td cb-table__td--border">
        <img className="img" src={img} alt="bank" />
      </td>
      <td className="cb-table__td cb-table__td--border">
        <p className="font">{name}</p>
      </td>
      <td className="cb-table__td cb-table__td--border">
        <p className="font">{min}</p>
      </td>
      <td className="cb-table__td cb-table__td--border">
        <p className="font">{max}</p>
      </td>
      <td className="cb-table__td cb-table__td--border">
        <p className="font">{comission}</p>
      </td>
      <td className="cb-table__td cb-table__td--border">
        <p className="font">{processing}</p>
      </td>
      <td className="cb-table__td cb-table__td--border">
        <button className="btn btn--blue btn--h-30" onClick={click}>
          <span className="font font--white">{buttonName}</span>
        </button>
      </td>
    </tr>
  );
};

export default TableRow;