import React from 'react'
import img from '../../../../../assets/img/team.svg'

const ScoreboardTeam = () => {
  return (
    <div className="m-r-5 ip-scoreboard__team">
      <img src={img} alt="team" />
    </div>
  )
}

export default React.memo(ScoreboardTeam)
