import React from 'react'
import { getCurrentTime } from '../../../../helpers/transform/transformTime'

const TournamentScoreboard = ({ name, startDate }) => {
  const { day, weekDay, hours, minutes, month } = getCurrentTime(startDate)

  return (
    <div className="ip-scoreboard__score">
      <div className="flex align-center full-height">
        <div className="flex align-center-middle ip-scoreboard__wrap">
          <p
            className="font font--white font--17 font--semibold text-ellipsis"
            style={{
              lineHeight: '17px',
            }}
          >
            {name}
          </p>
        </div>
      </div>
      <div
        className="flex align-center full-height"
        style={{
          padding: '20px 0px',
        }}
      >
        <p className="font font--white font--17 font--semibold text-ellipsis">
          {weekDay} {day} {month} {hours}:{minutes}
        </p>
      </div>
    </div>
  )
}

export default React.memo(TournamentScoreboard)
