import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getConfig, getCurrency, getTotalBalance } from 'redux/selectors/configSelectors'
import { ConfigContext } from '../../../../../context/globalConfig'
import { floatToCurrency } from '../../../../../helpers/floatToCurrency'
import NotEnoughFunds from '../../popups/NotEnoughFunds'
import PlacedSuccess from '../../popups/PlacedSuccess'

const BetslipFooter = ({
  changes,
  acceptAllOddsChanges,
  total,
  placed,
  notLoggedIn,
  notEnoughFunds,
  onAcceptChangesHandler,
  placeBetHandler,
  popupAccept,
}) => {
  const { CUSTOMER_CONFIG } = useSelector(getConfig)
  const currencyConfig = useSelector(getCurrency)
  const { balance } = useSelector(getConfig)
  return (
    <div className="betslip__footer">
      {/* placed && <PlacedSuccess {...{ popupAccept }} /> */}
      {(notEnoughFunds || notLoggedIn) && <NotEnoughFunds {...{ popupAccept }} {...{ notLoggedIn }} />}
      {changes.accept || changes.checkbox ? (
        <button
          className="btn btn--h-40 btn--blue betslip__placebet"
          onClick={() => {
            placeBetHandler(CUSTOMER_CONFIG.LOGGED_IN, balance?.total ?? 0)
          }}
        >
          <span className="font font--12 font--white font--semibold m-r-5">Place bet</span>
          <span
            className="font font--12 font--white font--semibold"
            dangerouslySetInnerHTML={{
              __html: floatToCurrency(total, currencyConfig, true, true),
            }}
          ></span>
        </button>
      ) : (
        <button
          className={
            changes.checkbox
              ? 'btn btn--h-40 btn--gold betslip__apply btn--disabled'
              : 'btn btn--h-40 btn--gold betslip__apply'
          }
          onClick={onAcceptChangesHandler}
        >
          <span className="font font--12 font--semibold">Accept Changes</span>
        </button>
      )}
    </div>
  )
}

export default BetslipFooter
