import React, { useEffect, useState } from 'react'
import { loadCountryIcon } from '../../helpers/utils'

export const Flag = ({ iconCode, style, className }) => {
	const [image, setImage] = useState(undefined)

	useEffect(() => {
		loadCountryIcon(iconCode)
			.then(res => setImage(res))
			.catch(err => console.log('some problem', err))
	}, [iconCode])

	if (!image) {
		return (
			<div
				style={{
					width: '30px',
					height: '30px',
					background: 'transparent',
				}}
			/>
		)
	}

	return <img src={image} style={style} className={className} alt="iconCode" />
}

Flag.defaultProps = {
	style: {},
	className: '',
}
