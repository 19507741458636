import {
	IMatch,
	ITournament,
	Nullable,
	Odd,
} from '../../helpers/commonInterfaces/interfaces'
import { types } from '../types/types'
import { PrematchEventWorkersTypes } from '../actions/prematchEventActions'

export const initialState = {
	event: null as Nullable<IMatch | ITournament>,
	leagueEvents: null as Nullable<Array<IMatch | ITournament>>,
	odds: null as Nullable<{ [key: string]: Odd }>,
	markets: null as Nullable<{ [key: string]: Odd; }>,
	error: false,
}

export type IState = typeof initialState

export const prematchEvent = (
	state = initialState,
	action: PrematchEventWorkersTypes
): IState => {
	switch (action.type) {
		case types.SET_PREMATCH_EVENT:
			return {
				...state,
				event: action.payload,
			}
		case types.SET_PREMATCH_MARKETS:
			return {
				...state,
				odds: action.data,
			}
		case types.SET_PREMATCH_SAME_LEAGUE_EVENTS:
			return {
				...state,
				leagueEvents: action.data,
			}
		case types.SET_PREMATCH_EVENT_ERROR:
			return {
				...state,
				error: true,
			}
		case types.CLEAR_PREMATCH_EVENT_DATA:
			return {
				...state,
				leagueEvents: null,
				event: null,
				odds: null,
				error: false,
			}
		default:
			return state
	}
}
