import { call, put, takeEvery } from '@redux-saga/core/effects'
import { links } from '../../../connection/links'
import { post } from '../../../helpers/asyncRequests'
import { bannersWorkers } from '../../actions/bannersActions'
import { types } from '../../types/types'

function* getInlineBanners() {
  try {
    const banners = yield call(post(links.banners))
    yield put(bannersWorkers.setInlineBanners(banners))
  } catch (e) {
    console.log(`Error `, e)
  }
}

function* getSlides() {
  try {
    console.log(`getting slides :(`)
    const banners = yield call(post(links.slides))
    yield put(bannersWorkers.setSlides(banners))
  } catch (e) {
    console.log(`Error `, e)
  }
}

export const bannersSagas = [
  takeEvery(types.GET_INLINE_BANNERS, getInlineBanners),
  takeEvery(types.GET_SLIDES, getSlides),
]
