import React from 'react'
import Navigate from '../../common/routing/Navigate'
import { messagesRouting } from '../../../routing/accountRouting/messagesRouting'

const Messages = () => {
	return (
		<div className="container">
			<Navigate routes={messagesRouting} />
		</div>
	)
}

export default Messages
