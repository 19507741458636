export const modifyBets = (bet: number | string | undefined, type = '1') => {
  if (!bet) {
    bet = 1.0
  }
  bet = bet.toString()
  if (bet) {
    switch (type) {
      case '1':
        return transformBetAsDecimal(bet)
      case '2':
        return transformBetAsFraction(bet)
      case '3':
        return transformBetAsAmerican(bet)
      default:
        return transformBetAsDecimal(bet)
    }
  }
  return ''
}
//.
function transformBetAsFraction(bet: string) {
  if (bet.includes('/')) {
    const btArr: any[] = bet.split('/')
    const res = (btArr[0] / btArr[1] + 1).toFixed(3)
    return res
  }

  if (bet.includes('+') || bet.includes('-')) {
    return bet.includes('+') ? positiveAmericanBets(bet) : negativeAmericanBets(bet)
  }

  return Number(bet).toFixed(3)
}
// --/--
function transformBetAsDecimal(bet: string) {
  if (bet.includes('.')) {
    return decimalToFraction(Number(bet))
  }

  if (bet.includes('+') || bet.includes('-')) {
    const fractionBet = bet.includes('+') ? positiveAmericanBets(bet) : negativeAmericanBets(bet)
    return decimalToFraction(Number(fractionBet))
  }

  return bet
}
//+-
function transformBetAsAmerican(bet: string) {
  if (bet.includes('/')) {
    const fractionBet = transformBetAsFraction(bet)
    return fromFractionToAmerican(fractionBet)
  }

  if (bet.includes('.')) {
    return fromFractionToAmerican(bet)
  }

  return bet
}

function fromFractionToAmerican(bet: string) {
  if (Number(bet) >= 2) {
    return `+${((Number(bet) - 1) * 100).toFixed(0)}`
  } else {
    return `-${(100 / (Number(bet) - 1)).toFixed(0)}`
  }
}

function positiveAmericanBets(bet: string) {
  const newStr = bet.replace('+', '')
  return (Number(newStr) / 100 + 1).toFixed(3)
}

function negativeAmericanBets(bet: string) {
  const newStr = bet.replace('-', '')
  return (100 / Number(newStr) + 1).toFixed(3)
}

function gcd(a: number, b: number): number {
  return b ? gcd(b, a % b) : a
}

function decimalToFraction(_decimal: number) {
  const top: string = _decimal.toString().replace(/\d+[.]/, '')
  const bottom = Math.pow(10, top.length)
  let topNum = 0
  if (_decimal > 1) {
    topNum = +top + Math.floor(_decimal) * bottom
  } else {
    topNum = Number(+top)
  }

  const x = gcd(topNum, bottom)
  return `${topNum / x + '/' + bottom / x}`
}
