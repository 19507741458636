import { types } from '../types/types'
import {
	InferType,
	IMatch,
	Nullable,
	ITournament,
	Odd,
} from '../../helpers/commonInterfaces/interfaces'

export type IGetEvent = {
	id: string
	leagueId: string
	sportId: string
}

export const prematchEventWorkers = {
	setEvent: (event: Nullable<IMatch | ITournament>) =>
		({
			type: types.SET_PREMATCH_EVENT,
			payload: event,
		} as const),
	setEventError: () =>
		({
			type: types.SET_PREMATCH_EVENT_ERROR,
		} as const),
	setEvents: (data: Nullable<Array<IMatch | ITournament>>) =>
		({
			type: types.SET_PREMATCH_SAME_LEAGUE_EVENTS,
			data,
		} as const),
	setMarkets: (data: { [key: string]: Odd }) =>
		({
			type: types.SET_PREMATCH_MARKETS,
			data,
		} as const),
	clearEventData: () =>
		({
			type: types.CLEAR_PREMATCH_EVENT_DATA,
		} as const),
}

export const prematchEventWatchers = {
	getEvent: (ids: IGetEvent) =>
		({
			type: types.SET_PREMATCH_EVENT_WATCHER,
			ids,
		} as const),
	removeEvent: () =>
		({
			type: types.REMOVE_PREMATCH_EVENT,
		} as const),
	getLeagueEvents: (id: number) =>
		({
			type: types.SET_PREMATCH_SAME_LEAGUE_EVENTS_WATCHER,
			id,
		} as const),
	getLeaguesEvents: () =>
		({
			type: types.SET_PREMATCH_SAME_LEAGUES_EVENTS_WATCHER,
		} as const),
}

export type PrematchEventWorkersTypes = ReturnType<
	InferType<typeof prematchEventWorkers>
>
export type PrematchEventWatchersTypes = ReturnType<
	InferType<typeof prematchEventWatchers>
>
