import _ from 'lodash'
import { ILeague, ISport, ITournament, IMatch } from '../commonInterfaces/interfaces'
import { get } from '../asyncRequests'

type IObjArr = Record<string, any> | Array<any>

type IFindLeague = {
  data: ISport[]
  sportId: string
  id: string
}

export const isEqualOutcomes = (base: IObjArr, other: IObjArr): boolean => {
  return _.isEqual(base, other)
}

export const isEmpty = (obj: IObjArr): boolean => {
  return _.isEmpty(obj)
}

export const getAllLeagues = (sport: ISport): ILeague[] => {
  if (!sport?.categories) {
    return []
  }

  return sport.categories.reduce((acc: ILeague[], { leagues, iconCode }) => {
    if (!leagues) {
      return acc
    }

    leagues?.forEach((league) => (league.iconCode = iconCode))

    acc = [...acc, ...leagues]
    return acc
  }, [])
}

export const loadAllEvents = async (
  id: number,
  link: string
): Promise<{ events: Array<IMatch | ITournament> }> => {
  try {
    return await get(link + id)()
  } catch (e) {
    return {
      events: [],
    }
    console.log(e)
  }
}

export const loadCasino = async (): Promise<any> => {
  let data: Array<any> = []
  try {
    data = await get('https://games.oddsbit.io/api.json')()
    return data
  } catch (e) {
    console.log(e)
    return {
      data: data,
    }
  }
}

export const getSportIcon = (id: number): string => {
  /* if (id.toLowerCase().includes('es')) {
    id = '0'
  } */

  return `http://bestline.bet/icon/sport/${id.toString()}`

  /* `https://bestline.bet/media/icons/sports/png/${name
    .split(' ')
    .join('-')
    .toLowerCase()}.png` */
}

export const getFirstLeagueId = (data: ISport[]): number => {

  const sortedData = sortInplayData(data);
  const leagues = getAllLeagues(sortedData[0])

  return leagues[0].id
}

export const sortInplayData = (data: ISport[]): ISport[] => {
  if (data) {
    data = data.filter((sport) => {
      if (sport.inPlayMatchCount > 0) {
        return (sport.categories = sport.categories.filter((category) => {
          if (category.inPlayMatchCount > 0) {
            category.leagues = category.leagues.filter((league) => league.inPlayMatchCount > 0)
            return category
          } else {
            return false
          }
        }))
      } else {
        return false
      }
    })

    data.forEach(sport => {
      if (sport.priority === 0) {
        sport.priority = 200
      }
    })

    return data.sort((a, b) => a.priority - b.priority)
  }

  return []
}

export const getSportName = (
  data: ISport[],
  sportID: number,
  leagueID: number
): { sportName: string; leagueName: string; countyName: string } => {
  const result = {
    sportName: '',
    leagueName: '',
    countyName: '',
  }

  data?.forEach(({ id, name, categories }) => {
    if (id === sportID) {
      result.sportName = name
      categories?.forEach(({ leagues, name }) => {
        leagues?.forEach((league) => {
          if (league.id === leagueID) {
            result.countyName = name
            result.leagueName = league.name
          }
        })
      })
    }
  })

  return result
}

export const findLeague = ({ data, sportId, id }: IFindLeague): ILeague | null => {
  const currentSport = data?.find((sport) => sport.id.toString() === sportId)

  console.log(currentSport)

  if (currentSport) {
    return getAllLeagues(currentSport).find((league) => league.id.toString() === id) || null
  }
  return null
}

export const getBetradarId = (betRadar: string): string => betRadar?.split(':')[2] ?? ''

export function isIterable(obj: any) {
  // checks for null and undefined
  if (obj == null) {
    return false
  }
  return typeof obj['filter'] === 'function'
}

export const sortSportsData = (data: ISport[]): ISport[] => {
  if (data && isIterable(data)) {
    data.forEach(sport => {
      if (sport.priority === 0) {
        sport.priority = 200
      }
    })
    data.sort((a, b) => a.priority - b.priority)

    return data
  }

  return []
}