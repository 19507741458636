import React from 'react'
import Option from './Option'
interface SelectI {
  className?: string
  name: string
  value: string
  onChange: (e) => void
  optionsArray?: Array<{
    title: string
    value: string
    deflt?: boolean
  }>
}
const Select: React.FC<SelectI> = ({ optionsArray = [], onChange, name = '', value = '' }) => {
  return (
    <select {...{ name }} {...{ value }} onBlur={onChange}>
      {optionsArray?.map((item, idx) => (
        <Option key={idx * Math.random() - Math.random()} {...{ item }} />
      ))}
    </select>
  )
}

export default Select
