import React, { useContext, useState } from 'react'
import { AsideContext } from '../../../../context/asideLeftContext'
import ControlBarItemText from './ControlBarItemText'
import ControlBarItemIcon from './ControlBarItemIcon'

const data = [
  {
    text: 'All',
    id: 'all',
  },
  {
    text: 'Top',
    id: 'top',
  },
  {
    text: null,
    icon: 'play',
    id: 'play',
  },
  {
    text: null,
    icon: 'menu',
    id: 'menu',
  },
]

const ControlBar = ({ liveTranslation, setLiveTranslation }) => {
  const [active, setActive] = useState('All')
  const { collapse } = useContext(AsideContext)

  const play = {
    innerStyle: 'fal fa-play',
    active: liveTranslation,
    onClick: () => {
      setLiveTranslation(!liveTranslation)
      setActive('play')
    },
  }

  const menu = {
    parentStyle: 'ipn-control-bar__item--menu',
    collapse,
  }

  return (
    <div className="ipn-control-bar">
      <div className="flex align-middle align-justify full-height">
        {data?.map((item) => {
          return item.text ? (
            <ControlBarItemText
              key={item.id}
              text={item.text}
              onClick={() => { setActive(item.text); setLiveTranslation(false) }}
              active={active === item.text} />
          ) : (
            <ControlBarItemIcon
              key={item.id}
              props={item.icon === 'play' ? play : menu} />
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(ControlBar)
