import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import NotLogon from './NotLogon'
import { ConfigContext } from '../../../../../context/globalConfig'
import { floatToCurrency } from '../../../../../helpers/floatToCurrency'
import { getBetslip } from 'redux/selectors/betslipSelectors'
import { getConfig } from 'redux/selectors/configSelectors'

const QuickbetPanelMobile = (props) => {
  const { CUSTOMER_CONFIG } = useSelector(getConfig)
  const btnClickHandler = (type: string) => {
    const st = props.quickbet.stake === '' ? 0 : props.quickbet.st
    if (type === 'plus') {
      props.change(
        undefined,
        'quickbet',
        floatToCurrency(st + 1, CUSTOMER_CONFIG.currencyConfig, true, false),
        true
      )
      /* setQuickbetStake(
        quickbet.st + 1,
        floatToCurrency(quickbet.st + 1, currencyConfig, true, false)
      ) */
    } else {
      props.change(
        undefined,
        'quickbet',
        floatToCurrency(st - 1, CUSTOMER_CONFIG.currencyConfig, true, false),
        true
      )
      if (props.quickbet.st > 0) {
        /* setQuickbetStake(
          quickbet.st - 1,
          floatToCurrency(quickbet.st - 1, currencyConfig, true, false)
        ) */
      }
    }
  }
  const betslipReduxData = useSelector(getBetslip)
  const [bets, setBets] = React.useState(betslipReduxData.bt)
  useEffect(() => {
    setBets(betslipReduxData.bt)
  }, [betslipReduxData])
  return (
    <div className="betslip__quickbet-panel">
      {!CUSTOMER_CONFIG.LOGGED_IN && bets.length > 0 && <NotLogon />}
      <div className="quickbet-panel">
        <p>Quick stake</p>
        <div className="input-group plus-minus-input">
          <div className="input-group-button">
            <button
              type="button"
              className="button hollow"
              data-quantity="minus"
              data-field="quantity"
              onClick={(e) => {
                btnClickHandler('minus')
              }}
            >
              <i className="fa fa-minus" aria-hidden="true"></i>
            </button>
          </div>
          {/* <input
						className="input-group-field"
						type="number"
						name="quantity"
						value="0"
					/> */}
          <input
            className={'betslip__field betslip__field__ext'}
            type="string"
            data-key="quickbet"
            name="quickbet"
            placeholder="Stake"
            maxLength={12}
            value={props.quickbet.stake === '0' ? '' : props.quickbet.stake}
            onChange={props.change}
          />
          <div className="input-group-button">
            <button
              type="button"
              className="button hollow"
              data-quantity="plus"
              data-field="quantity"
              onClick={(e) => {
                btnClickHandler('plus')
              }}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuickbetPanelMobile
