import React from 'react'
import ScoreboardPoint from '../../../../../forLivePageComponents/scoreboard/common/tableComponents/ScoreboardPoint'

const EventParticipant = ({ competitors, point }) => {
	const curServer = ['HOME', 'AWAY']

	return (
		<div className="text-left overflow-hidden m-r-5">
			{competitors?.map(({ id, name }, idx) => (
				<div className="flex align-middle m-b-5" key={id}>
					{point && <ScoreboardPoint active={point === curServer[idx]} />}
					<p className="font text-ellipsis">{name}</p>
				</div>
			))}
		</div>
	)
}

export default React.memo(EventParticipant)
