import React from 'react'
import SimpleBar from 'simplebar-react'

const SearchScrollWrapper = ({ children, className = '', display, ...rest }) => {
  return (
    <SimpleBar
      className={`${className}`}
      style={display ? { maxWidth: '100%', top: '31px' } : { maxWidth: '100%', top: '31px' }}
    >
      <div {...rest}>{children}</div>
    </SimpleBar>
  )
}

export default SearchScrollWrapper
