import axios from 'axios'
import { links } from './links'

type urls = {
  webSocket: string
  socketConfig: {
    feedWS: boolean
    privateWS: boolean
  }
  webSocketPrivate: string
  longPoll: string
  webApi: string
}

type sendParams = {
  cmd: 'Subscribe' | 'Unsubscribe' | 'Request'
  path: string
  data: any
  topic: string
  listeners: any[]
  loop: number
  isPrivate?: boolean
}

let instance = null

export class Connection {
  static OPENED = 0
  static CLOSED = 1
  urls: urls
  socket: WebSocket | null
  privateSocket: WebSocket | null
  socketPingInterval: NodeJS.Timeout | null
  privateSocketPingInterval: NodeJS.Timeout | null
  listeners: any[]
  queue: any[]
  privateQueue: any[]
  subscribtions: any
  status: number
  isUsingWS: boolean
  isUsingPrivateWS: boolean
  canUseLP: boolean

  constructor(urls: urls | null) {
    //@ts-ignore
    if (instance) return instance

    this.socket = null
    this.privateSocket = null
    this.socketPingInterval = null
    this.privateSocketPingInterval = null
    this.listeners = []
    this.subscribtions = {}
    this.queue = [] // wait until socket is opened
    this.privateQueue = [] // wait until socket is opened
    this.status = Connection.CLOSED
    this.isUsingWS = true
    this.isUsingPrivateWS = true
    this.canUseLP = false
    //@ts-ignore
    instance = this

    if (urls) {
      this.urls = urls
      this.isUsingWS = urls.socketConfig.feedWS
      this.isUsingPrivateWS = urls.socketConfig.privateWS
    }
  }

  send(params: sendParams): void {
    const { cmd, path, data, topic, listeners, loop, isPrivate } = params
    if (cmd === 'Subscribe' && this.subscribtions[topic]) {
      //@ts-ignore
      return null
    }

    if (cmd === 'Unsubscribe') {
      clearInterval(this.subscribtions[topic])
      delete this.subscribtions[topic]
    }

    this.addListener(topic, listeners)

    if (isPrivate && this.isUsingPrivateWS && cmd !== 'Request') {
      console.log('Using PRIVATE websocket: ', path)

      this.websocket(cmd, path, data, topic, listeners, loop, true)
    } else if (this.isUsingWS && !isPrivate && cmd !== 'Request') {
      // try use websocket
      console.log('Using websocket: ', path)
      this.websocket(cmd, path, data, topic, listeners, loop, false)
    } else if (this.canUseLP && cmd !== 'Request') {
      // if websocket failure - try use long polling
      console.log('Using Long Polling')
      this.longPoll(cmd, path, data, topic, listeners, loop)
    } else if (cmd !== 'Unsubscribe') {
      // if websocket and long polling failure - tru use web api
      console.log(`Using Web Api for topic ${topic} ${loop > 0 ? `with loop: ${loop}` : ''}`)
      this.webApi(cmd, path, data, topic, listeners, loop)
    }
  }

  websocket(
    cmd: string,
    path: string,
    data: object,
    topic: string,
    listeners: any[],
    loop: number,
    isPrivate = false
  ) {
    const getConnection = (socket: WebSocket) => {
      socket = new WebSocket(isPrivate ? this.urls.webSocketPrivate : this.urls.webSocket)
      if (socket) {
        socket.onopen = () => {
          const queue = isPrivate ? this.privateQueue : this.queue
          if (queue.length === 0) queue.push({ cmd, data, path, topic } as sendParams)
          queue.forEach((data) => {
            socket?.send(JSON.stringify(data))
          })

          if (isPrivate) this.privateQueue = []
          else this.queue = []

          this.status = Connection.OPENED
          const interval = setInterval(() => {
            socket?.send(JSON.stringify({ cmd: 'ping' }))
          }, 29000)

          if (isPrivate) this.privateSocketPingInterval = interval
          else this.socketPingInterval = interval
        }

        socket.onmessage = (msg) => {
          const obj = JSON.parse(msg.data)

          if (obj) {
            obj.forEach((message) => {
              const topic = message.topic

              if (!this.listeners.hasOwnProperty(topic)) return

              this.listeners[topic].forEach((listener: any) => {
                isGenerator(listener)
                  ? toYield(listener, message.response).next()
                  : listener(message.response)
              })
            })
          } else {
            //@ts-ignore
            socket.onerror(new Event('error'))
          }
        }
        socket.onerror = socket.onclose = (err) => {
          console.log('Websocket error: ', err.type)
          this.closeWebsocketWithError({
            cmd,
            path,
            data,
            topic,
            listeners,
            loop,
            isPrivate,
          } as sendParams)
        }
      }
      return socket
    }

    if (isPrivate) {
      if (!this.privateSocket) {
        //@ts-ignore
        this.privateSocket = getConnection(this.privateSocket)
        console.log('NEW PRIVATE SOCKET')
      } else {
        this.privateSocket.readyState === WebSocket.OPEN
          ? this.privateSocket.send(JSON.stringify({ cmd, path, data, topic }))
          : this.privateQueue.push({ cmd, data, path, topic })
      }
    } else {
      if (!this.socket) {
        //@ts-ignore
        this.socket = getConnection(this.socket)
      } else {
        this.socket.readyState === WebSocket.OPEN
          ? this.socket.send(JSON.stringify({ cmd, path, data, topic }))
          : this.queue.push({ cmd, data, path, topic })
      }
    }
  }

  request(
    method: 'POST' | 'GET',
    url: string,
    cmd: string,
    path: string,
    topic: string,
    data: any,
    error: any,
    callback: any
  ): void {
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200 && xhr.responseText && xhr.responseText[0] !== '<') {
        callback(xhr.response.text)
      } else {
        error()
        console.log(`longpoll request for ${topic} failed`)
      }
    }
    xhr.onerror = () => {
      error()
      console.log(`longpoll request for ${topic} failed`)
    }
    xhr.open(method, url, true)
    // custom headers here
    xhr.send(
      JSON.stringify({
        cmd,
        path,
        topic,
        data,
      })
    )
  }

  addListener(topic: string, listener): void {
    Object.prototype.hasOwnProperty.call(this.listeners, topic)
      ? this.listeners[topic].concat(listener)
      : (this.listeners[topic] = listener)
  }

  removeListener(topic: string, listener): void {
    Object.prototype.hasOwnProperty.call(this.listeners, topic)
      ? this.listeners[topic].push(listener)
      : (this.listeners[topic] = [listener])
  }

  longPoll(
    cmd: string,
    path: string,
    data: any,
    topic: string,
    listeners: any[],
    loop: number
  ): void {
    const useWebApi = () => {
      this.canUseLP = false
      console.log(`Using Web Api for topic ${topic} ${loop > 0 ? `with loop: ${loop}` : ''}`)
      this.webApi(cmd, path, data, topic, listeners, loop)
    }

    this.request('POST', this.urls?.longPoll + path, cmd, path, topic, data, useWebApi, (data) => {
      listeners.forEach((listener) => listener(data))
      this.longPoll(cmd, path, data, topic, listeners, loop)
    })
  }

  async post(
    url: string,
    payload: { cmd: string; topic: string; path: string; data: any },
    listeners: any[]
  ): Promise<void | Error> {
    try {
      const res = await axios.post(url, payload, {
        withCredentials: true,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'text/plain',
          // ...headers,
        },
      })

      if (this.subscribtions[payload.topic] || payload.cmd === 'Request') {
        res.data.forEach((rs) => {
          listeners.forEach((listener) => {
            if (isGenerator(listener)) {
              toYield(listener, rs.response).next()
            } else {
              listener(rs.response)
            }
          })
        })
      }
    } catch (e) {
      return new Error('Some problem')
    }
  }

  webApi(
    cmd: string,
    path: string,
    data: any,
    topic: string,
    listeners: any[],
    loop: number
  ): void {
    const iter = () => {
      if (path) {
        const url = (this.urls?.webApi ? this.urls.webApi : links.webApi) + path
        this.post(
          url,
          {
            cmd,
            path,
            topic,
            data,
          },
          listeners
        )
      }
    }
    iter()
    if (cmd === 'Subscribe' && loop > 0) {
      const interval = window.setInterval(iter, loop)
      clearInterval(this.subscribtions[topic])
      delete this.subscribtions[topic]
      this.subscribtions[topic] = interval
    }
  }

  close(): void {
    this.status = Connection.CLOSED
    if (this.isUsingWS && this.socket !== null) {
      this.socket.close()
      this.socket = null
    }
  }

  closeWebsocketWithError(params: sendParams) {
    if (params.isPrivate) {
      //@ts-ignore
      clearInterval(this.privateSocketPingInterval)
      this.isUsingPrivateWS = false
      //@ts-ignore
      this.privateSocket.close()
      console.log('CLOSE PRIVATE WEBSOCKET')
    } else {
      //@ts-ignore
      clearInterval(this.socketPingInterval)
      this.isUsingWS = false
      //@ts-ignore
      this.socket.close()
      console.log('CLOSE WEBSOCKET')
    }
    this.send(params)
  }
}

function isGenerator(fn): boolean {
  return fn.constructor.name === 'GeneratorFunction'
}

function* toYield(listener, data) {
  yield* listener(data)
}
