import React from 'react'
import Tabs from '../../../../common/tabs/Tabs'

const tabs = [
  {
    id: '1',
    title: 'Slider',
  },
  {
    id: '2',
    title: 'All',
  },
]

const MarketHeaderTabs = ({ onSetActiveTab, activeTab }) => {
  return (
    <Tabs
      tabs={tabs}
      activeTab={activeTab}
      handler={onSetActiveTab}
      className="gll-market-group__tab"
      activeCl="gll-market-group__link--active"
      defaultCl="gll-market-group__link"
    />
  )
}

export default React.memo(MarketHeaderTabs)
