import { call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { prematchWorkers } from '../../actions/prematchActions'
import { types } from '../../types/types'
import { getAllSports, getCurrentSport } from '../../selectors/prematchSelectors'
import { isEmpty, isIterable } from '../../../helpers/utils/utils'
import { createSubscriptionChannel, unsubscribe } from '../subscriptionHandler'
import { links } from '../../../connection/links'
import { get } from '../../../helpers/asyncRequests'
import _ from 'lodash'
import { store } from '../../store'
import { emitterByCmd } from '../../utils/emitter'

function* enablePrematchShortPull() {
  const config = yield select((state) => state.config)
  const updateChannel = yield call(
    createSubscriptionChannel,
    '/feedapi/?key=sports',
    {},
    'sports-tree',
    [
      (emitter, rs) => {
        if (isIterable(rs.data)) {
          const oldState = _.cloneDeep(store.getState().prematch)
          emitterByCmd(
            oldState,
            {
              ...rs,
              data: rs.data.filter(
                (sport) => !config.SITE_CONFIG.SITE_EXCLUDED_SPORTS.some((id) => id === sport.id)
              ),
            },
            emitter
          )
        }
      },
    ],
    20000,
    config
  )

  yield fork(function* () {
    yield take(types.DISABLE_INPLAY_UPDATE_WATCHER)
    unsubscribe('/feed/?key=sports', 'sports-tree', 20000, config)
  })

  try {
    while (true) {
      const data = yield take(updateChannel)
      yield put(prematchWorkers.setPrematchAll(data))
    }
  } catch (e) {
    yield cancel()
    updateChannel.close()
  }
}

function* getPrematchAll() {
  try {
    yield fork(enablePrematchShortPull)
  } catch (e) {
    console.log(e)
  }
}

function* getPrematchCurrentSport({ id }: { type: string; id: string | null }) {
  if (id) {
    let prematchData = yield select(getAllSports)

    if (isEmpty(prematchData)) {
      prematchData = yield call(get(links.prematchAll))
      prematchData = prematchData.flatMap((i) => i.response.flatMap((r) => r.data))
    }

    const currentSport = getCurrentSport(prematchData, id)
    yield put(prematchWorkers.setCurrentSport(currentSport || null))
  }
}

function* clearCurrentSport() {
  yield put(prematchWorkers.clearCurrentSport())
}

export const prematchSagas = [
  takeEvery(types.GET_PREMATCH_ALL_WATCHER, getPrematchAll),
  takeEvery(types.CLEAR_CURRENT_SPORT_WATCHER, clearCurrentSport),
  takeEvery(types.GET_PREMATCH_CURRENT_SPORT, getPrematchCurrentSport),
]
