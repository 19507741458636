import React from 'react'

const FooterColumnTitle = ({ title }) => {
	return (
		<li className="footer__item">
			<p className="font font--semibold font--white">{title}</p>
		</li>
	)
}

export default FooterColumnTitle
