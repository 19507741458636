import React, { useEffect } from 'react'
import ClassificationBar from './clasificationBar/ClassificationBar'
import SportCompetitions from '../../common/competition/SportCompetitions'
import Footer from '../../containers/footer/Footer'
import SimpleBar from 'simplebar-react'
import { useDispatch, useSelector } from 'react-redux'
import { inplayWatchers } from '../../../redux/actions/inplayActions'
import { getInplay } from 'redux/selectors/inplaySelectors'
import { useHistory, useParams } from 'react-router'

interface IParams {
  sportId: string
}
const OverviewWrapper = () => {
  const refContainer = React.useRef(null)
  const inplay = useSelector(getInplay)
  const params: IParams = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    data,
    active,
    currentView: { current },
  } = useSelector(getInplay)

  useEffect(() => {
    if (params.sportId) {
      const neededSport = inplay.data?.find((sport) => sport.id === Number(params.sportId))
      if (neededSport) {
        dispatch(inplayWatchers.setCurrentView(neededSport))
      }
    }
  })

  const setView = ({ id }) => {
    const newSport = data?.find((sport) => sport.id === id)
    /* Add Sport to path:
          history.push('/live/overview/' + id + '-' + newSport?.name ?? '') 
    */
    if (newSport) {
      dispatch(inplayWatchers.setCurrentView(newSport))
    }
  }

  return (
    <SimpleBar ref={refContainer} className="height-scroll">
      <div className="ip-sports center">
        <ClassificationBar data={data} active={active} setView={setView} />
        {current?.id && <SportCompetitions />}
      </div>
      <Footer />
    </SimpleBar>
  )
}

export default OverviewWrapper
