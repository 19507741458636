import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOddTypes } from 'redux/selectors/configSelectors'
import { updateOdd } from '../../../redux/actions/betslipActions'
import Spinner from '../loaders/Spinner'
import ButtonValue from './ButtonValue'
import ButtonValueWithText from './ButtonValueWithText'

declare global {
  interface Window {
    snakeTimeout: ReturnType<typeof setTimeout>
  }
}

type IBetButton = {
  inInplay?: boolean
  item: any
  name?: string
  onCreateBet?: any
  panel?: any
  allStakes?: any
  oddsType: any
  heightNotFixed?: boolean
  isMatchBet?: boolean
  isCurScore?: boolean
  br?: boolean
  className?: string
}

const BetButton: React.FC<IBetButton> = ({ item, className = '', inInplay = false, heightNotFixed = false, onCreateBet, panel, allStakes, oddsType }) => {
  const dispatch = useDispatch()
  const oddTypes = useSelector(getOddTypes)
  const [itemFromProps, setItemFropProps] = useState(item)

  const [snake, setSnake] = React.useState(false)
  const [highlight, setHighlight] = React.useState({
    oldOdd: itemFromProps.oddValue,
    className: '',
  })

  const mark = useCallback(() => {
    return !!allStakes.find((bet) => Number(bet?.pt[0].pi) === Number(itemFromProps.id))
  }, [allStakes])

  useEffect(() => {
    const translated = item
    const market = oddTypes.find((odd) => item.id === odd.id)
    const oc = market?.outcomes?.find((outcome) => outcome.id === itemFromProps.id)
    if (oc) {
      translated.outcome = oc.name
    }
    setItemFropProps(translated)
  }, [oddTypes, item])

  useEffect(() => {
    if (mark()) {
      dispatch(updateOdd(itemFromProps.id, itemFromProps.oddValue))
    }
  }, [itemFromProps.oddValue])

  useEffect(() => {
    if (itemFromProps.oddValue > highlight.oldOdd) {
      setHighlight({
        oldOdd: itemFromProps.oddValue,
        className: 'highlight-up',
      })
    }
    if (itemFromProps.oddValue < highlight.oldOdd) {
      setHighlight({
        oldOdd: itemFromProps.oddValue,
        className: 'highlight-down',
      })
    }
    const timerId = setTimeout(() => {
      setHighlight({
        ...highlight,
        className: '',
      })
    }, 1500)

    return () => clearTimeout(timerId)
  }, [itemFromProps.oddValue])

  const onMouseUpHandler = () => {
    setSnake(false)
    window.clearTimeout(window.snakeTimeout)
  }
  const onMouseDownHandler = (event) => {
    if (!snake) {
      setSnake(true)
    }
    const root: HTMLElement | null = document.querySelector(':root')
    if (root) {
      root.style.setProperty('--tr-X', `${event.currentTarget.offsetWidth}px`)
      root.style.setProperty('--tr-Y', `${event.currentTarget.offsetHeight}px`)
    }
    window.snakeTimeout = setTimeout(() => {
      setSnake(false)
      onCreate(true)
    }, 2000)
  }

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation() // to not turn off over/under mini-popup in inplay/sports
    if (!panel) {
      onCreate(false)
    }
  }

  const onMouseDown = (event) => {
    panel ? onMouseDownHandler(event) : setSnake(false)
  }

  const onCreate = (qb) => {
    const specifiers = itemFromProps.specifiers
    let ha = null
    if (specifiers) {
      if (specifiers.hcp) {
        ha = specifiers.hcp
      }
      if (specifiers.total) {
        ha = specifiers.total
      }
    }
    console.log(`speci`, specifiers)
    console.log(`HA`, ha)
    onCreateBet(itemFromProps.id, itemFromProps.oddValue, ha, mark(), qb)
  }

  const isLobby = () => {
    if (window.location.href.includes('/sports/main')) {
      if (item.outcome !== ' ' && item.outcome !== 'X' && item.outcome !== 'draw') {
        return false
      } else {
        return true
      }
    } else {
      if (item.outcome !== ' ' && item.outcome !== 'X' && item.outcome !== 'draw') {
        return false
      } else {
        return true
      }
    }
  }

  return (
    <button
      className={`btn btn--coff ip-competition__coff ip-competition__coff--big
      ${className ? ` ${className}` : ''}
      ${heightNotFixed
          ? ' align-center gll-market-group__btn'
          : inInplay
            ? ''
            : ' btn--h-40'
        }
			${mark() ? ' active' : ''}
      ${' ' + highlight.className}
			${panel && snake ? ' qb' : ''}`}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUpHandler}
    >
      {isLobby() ? (
        <ButtonValue oddsType={oddsType} oddValue={itemFromProps.oddValue} highlight={highlight} />
      ) : (
        <ButtonValueWithText
          outcomeId={item.outcomeId}
          oddsType={oddsType}
          oddValue={itemFromProps.oddValue}
          highlight={highlight}
          outcome={itemFromProps.outcome}
        />
      )}
      <Spinner />
    </button>
  )
}

export default React.memo(BetButton)
