import Axios from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getWithouCredentials } from '../../helpers/asyncRequests'
import { configWatchers } from '../../redux/actions/configActions'
import { iconsWorkers } from '../../redux/actions/iconsActions'
import { inplayWatchers } from '../../redux/actions/inplayActions'
import { prematchWatchers } from '../../redux/actions/prematchActions'
import { getConfig } from '../../redux/selectors/configSelectors'
import { getIcons } from '../../redux/selectors/iconsSelectors'
import { getAllSports } from '../../redux/selectors/prematchSelectors'
import { getInplay } from '../../redux/selectors/inplaySelectors'

export const Wrapper: FC = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const config = useSelector(getConfig)
  const icons = useSelector(getIcons)
  const prematch = useSelector(getAllSports) || []
  const inplay = useSelector(getInplay)
  const [prevPrematchLength, setPrevPrematchLegth] = useState(0)

  useEffect(() => {
    dispatch(configWatchers.getConfig())
  }, [])

  useEffect(() => {
    if (config.CUSTOMER_CONFIG) {
      dispatch(configWatchers.getOddTypes())
    }
  }, [config])

  // start preloader and load inplay
  useEffect(() => {
    if (config.CUSTOMER_CONFIG) {
      dispatch(
        inplayWatchers.loadInplayTree(location.pathname.includes('main') ? 'main' : 'inplay')
      )
      dispatch(prematchWatchers.getPrematchAll())
    }
  }, [config])

  // icons
  useEffect(() => {
    if (prematch && prematch?.length !== prevPrematchLength) {
      console.log(`trying to collect icons`)
      const iconsFromStorage = JSON.parse(localStorage.getItem('icons') ?? '{}')
      if (iconsFromStorage && iconsFromStorage.length === prematch.length) {
        dispatch(iconsWorkers.addSports(iconsFromStorage))
      } else {
        const ids: number[] = []
        const iconsRequest: Array<Promise<any>> = []
        for (const sport of prematch) {
          if (!Object.values(icons).some((ic) => Number(ic.id) === sport.id)) {
            ids.push(sport.id)
            iconsRequest.push(
              getWithouCredentials(`https://bestline.bet/icon/sport/${sport.id.toString()}`, [], {
                responseType: 'blob',
              })()
            )
          }
        }
        const newIcons: Array<{ id: number; icon: string | ArrayBuffer | null }> = []
        Axios.all(iconsRequest).then((res) => {
          let i = 0
          for (const response of res) {
            if (response && response instanceof Blob) {
              const reader = new FileReader()
              reader.onloadend = () => {
                newIcons.push({
                  id: ids[i],
                  icon: reader.result,
                })
                i++
              }
              reader.readAsDataURL(response)
            }
          }

          setTimeout(() => {
            for (const sport of prematch) {
              const icon = Object.values(icons).find((ic) => Number(ic.id) === sport.id)
              if (icon) {
                newIcons.push(icon)
              }
            }
            dispatch(iconsWorkers.addSports(newIcons))
          }, 500)
        })
      }

      setPrevPrematchLegth(prematch?.length ?? 0)
    }
  }, [prematch, prevPrematchLength])

  useEffect(() => {
    dispatch(inplayWatchers.getStreamingEvents())
  }, [])

  return <div className="page">{props.children}</div>
}
