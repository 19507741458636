import { types } from '../types/types'
import { PreloaderTypes } from '../actions/preloaderActions'

export interface IPreloader {
  bootstrapPreloader: boolean
  progress: number
}

export const initialState: IPreloader = {
  bootstrapPreloader: true,
  progress: 0,
}

export type IState = typeof initialState

export const preloader = (state = initialState, action: PreloaderTypes): IState => {
  switch (action.type) {
    case types.START_PRELOADER:
      return {
        ...state,
        bootstrapPreloader: true,
      }
    case types.FINISH_PRELOADER:
      return {
        ...state,
        bootstrapPreloader: false,
      }
    default:
      return state
  }
}
