import { types } from '../types/types'
import {
  IMatch,
  InferType,
  ISport,
  IStreaming,
  ITournament,
  Nullable,
  Obj,
} from '../../helpers/commonInterfaces/interfaces'

export const inplayWatchers = {
  disableUpdate: () =>
  ({
    type: types.DISABLE_INPLAY_UPDATE_WATCHER,
  } as const),
  disableStreamingUpdate: () =>
  ({
    type: types.DISABLE_STREAMING_EVENTS_UPDATE,
  } as const),
  loadInplayTree: (type: 'main' | 'inplay') =>
  ({
    type: types.GET_INPLAY_TREE,
    data: type,
  } as const),

  setCurrentView: (item: ISport) =>
  ({
    type: types.SET_CURRENT_VIEW_WATCHER,
    payload: item,
  } as const),
  loadLeague: (id: number) =>
  ({
    type: types.LOAD_LEAGUE_EVENTS_WATCHER,
    id,
  } as const),
  destroyLeague: (id: number) =>
  ({
    type: types.DESTROY_LEAGUE_WATCHER,
    id,
  } as const),
  setChosenLeague: (ids: Nullable<number[]>) =>
  ({
    type: types.SET_CHOSEN_LEAGUES_WATCHER,
    ids,
  } as const),
  getStreamingEvents: () =>
  ({
    type: types.GET_STREAMING_EVENTS,
  } as const),
  changeColumnMarket: (marketName: string) =>
  ({
    type: types.CHANGE_COLUMN_MARKET,
    payload: marketName,
  } as const),
}

export const inplayWorkers = {
  setCurrentView: (item: ISport) =>
  ({
    type: types.SET_CURRENT_VIEW,
    payload: item,
  } as const),
  setInplayData: (data: Nullable<Array<ISport>>) =>
  ({
    type: types.SET_INPLAY_DATA,
    payload: data,
  } as const),
  setLeague: ({ id, data }: { id: number; data: ISport }) =>
  ({
    type: types.LOAD_LEAGUE_EVENTS,
    payload: { id, data },
  } as const),
  destroyLeague: (data: Obj<Array<IMatch | ITournament>>) =>
  ({
    type: types.DESTROY_LEAGUE,
    data,
  } as const),
  setLeaguesEvents: (payload: Record<string, Array<IMatch | ITournament>>) =>
  ({
    type: types.INPLAY_ALL,
    payload,
  } as const),
  setChosenLeagues: (data: Nullable<number[]>) =>
  ({
    type: types.SET_CHOSEN_LEAGUES,
    payload: data,
  } as const),
  toggleChosenLeague: (id: number) => ({
    type: types.TOGGLE_CHOSEN_LEAGUE,
    payload: id,
  }),
  setStreamingEvents: (payload: Array<IStreaming>) =>
  ({
    type: types.SET_STREAMING_EVENTS,
    payload,
  } as const),
  changeColumnMarket: (marketName: string) =>
  ({
    type: types.CHANGE_COLUMN_MARKET,
    payload: marketName,
  } as const),
  setCurrentOddName: (data) => ({
    type: types.CURRENT_ODD_NAME,
    payload: data,
  }),
  setCurrentOddId: (data) => ({
    type: types.CURRENT_ODD_ID,
    payload: data,
  }),
  setAllOddsNames: (data) => ({
    type: types.SET_ALL_ODDS_NAME,
    payload: data,
  }),
  setAllOddsIds: (data) => ({
    type: types.SET_ALL_ODDS_ID,
    payload: data,
  }),
  setActiveTracker: (id: number) => ({
    type: types.SET_ACTIVE_TRACKER,
    payload: id,
  }),
  setActiveTranslation: (id: number) => ({
    type: types.SET_ACTIVE_TRANSLATION,
    payload: id,
  }),
  setPinnedMatch: (id: boolean) => ({
    type: types.SET_PINNED_MATCH,
    payload: id,
  }),
}

export const topGamesWatcher = {
  setTopGames: (data: any) =>
  ({
    type: types.SET_TOP_GAMES,
    payload: data,
  } as const),
}

export type InplayActionsWorkers = ReturnType<InferType<typeof inplayWorkers>>

// export const updateCurrentView = (data: any[]) => {
// 	return {
// 		type: types.UPDATE_CURRENT_VIEW,
// 		payload: data,
// 	}
// }

// export const setCurrentViewActive = (item: object) => {
// 	return {
// 		type: types.SET_INPLAY_VIEW,
// 		payload: item,
// 	}
// }

// export const setCurrentView = (data: object) => {
// 	return {
// 		type: types.SET_CURRENT_VIEW,
// 		payload: data,
// 	}
// }

// export const loadInplayTree = () => {
// 	return {
// 		type: types.LOAD_INPLAY_TREE,
// 	}
// }

// export const setLeaguesInCurrentView = (responses: any) =>
// 	({
// 		type: types.SET_LEAGUES_IN_CURRENT_VIEW,
// 		responses: responses,
// 	} as const)
//
// export const setLeaguesInInplay = () =>
// 	({
// 		type: types.SET_LEAGUES_IN_INPLAY,
// 	} as const)
//
// export const clearAdditionalData = () => ({
// 	type: types.CLEAR_ADDITIONAL_DATA,
// })
//
// export const setInplayLeagues = (data: any) => ({
// 	type: types.SET_INPLAY_LEAGUES,
// 	payload: data,
// })
//
// export const deleteInplayLeagues = (id: any) => ({
// 	type: types.DELETE_INPLAY_LEAGUES,
// 	payload: id,
// })
//
// export const setCurrentLeagues = (data: any) => ({
// 	type: types.SET_CURRENT_LEAGUES,
// 	payload: data,
// })
//
// export const deleteCurrentLeagues = (id: any) => ({
// 	type: types.DELETE_CURRENT_LEAGUES,
// 	payload: id,
// })
