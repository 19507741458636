import { ICompetitors, Nullable, Obj, Odd, OddOutcome } from '../commonInterfaces/interfaces'
import marketTypes from '../../assets/json/marketsType.json'

export const getRangeInputsValues = (
  outcomes: OddOutcome[]
): { first: Array<string>; second: Array<string> } => {
  const first: Set<string> = new Set()
  const second: Set<string> = new Set()

  outcomes.forEach(item => {
    const [one, two] = item.outcome.split(':')

    first.add(one)
    second.add(two)
  })

  const sortValues = (a: string, b: string) => (a > b ? 1 : -1)

  return {
    first: [...first.values()].sort(sortValues),
    second: [...second.values()].sort(sortValues),
  }
}

export const transformMarkets = (
  odds: Odd[],
  competitors: Nullable<ICompetitors[]>,
  status: 'prematch' | 'live'
): Obj<Odd> => {
  const result: Obj<Odd> = {}
  const clonedOdds = [...odds]

  clonedOdds?.forEach((odd) => {
    const singles = (odd.name?.includes('!') || odd.name?.includes('{')) && !odd.name?.includes('$')

    addMarketType(odd)

    odd.providerStatus = status

    if (singles) {
      odd.id = odd.id = createId(odd.id, odd.specifiers || {})
    }

    odd.name = oddName(odd.name, odd.specifiers || {}, competitors)

    odd.outcomes = odd.outcomes?.map((item) => {
      item.outcome = oddName(item.outcome, odd.specifiers || {}, competitors)

      if (odd.specifiers) {
        item.specifiers = odd.specifiers
      }

      item.outcome = item.outcome.replace('({', '')
      item.outcome = item.outcome.replace('})', '')

      return item
    })

    if (singles) {
      result[odd.id] = odd
    } else {
      if (!result[odd.id]) {
        result[odd.id] = {
          ...odd,
          outcomes: [],
        }
      }
      result[odd.id].outcomes = [...result[odd.id].outcomes, ...odd.outcomes]
    }
  })
  return result
}

const oddName = (
  name: string,
  specifiers: Obj<any>,
  competitors: Nullable<ICompetitors[]>
): string => {
  const withBrackets = /{(.*?)}/gi
  const withoutBrackets = /\{.+\?|\w+(?=\})/gi

  const defaultData: Obj<string> = {
    ...specifiers,
    competitor1: competitors?.[0].name || '',
    competitor2: competitors?.[1].name || '',
  }

  const wordsInBreckets = name?.match(withBrackets)

  if (wordsInBreckets && wordsInBreckets.length) {
    name = wordsInBreckets.reduce((acc, word) => {
      const cleanWord = word.match(withoutBrackets)

      if (defaultData.hcp && word.match(/hcp/)) {
        name = transformHandicapName(name, word, cleanWord?.[0] || '', defaultData)
        name = name.replace(competitors?.[0].name ?? 'Arsenal', '')
        name = name.replace(competitors?.[1].name ?? 'Arsenal', '')
      }
      if (word.includes('competitor1')) {
        name = name.replace(word, defaultData[cleanWord?.[0] ?? '1'] || '1')
      }
      if (word.includes('competitor2')) {
        name = name.replace(word, defaultData[cleanWord?.[0] ?? '1'] || '2')
      }
      name = name.replace(word, defaultData[cleanWord?.[0] ?? ''] || '')
      name = name.replace(competitors?.[0].name ?? 'Arsenal', '1')
      name = name.replace(competitors?.[1].name ?? 'Arsenal', '2')
      /* name = name.replace('({', '')
      name = name.replace('})', '') */
      return name
    }, name)
  }

  return uppercaseFirstLetter(name)
}

export const uppercaseFirstLetter = (str: string): string =>
  str?.replace(str[0], str[0]?.toUpperCase())

const createId = (id: number | string, specifiers: Obj<any>): string =>
  id + Object.values(specifiers).join('')

const addMarketType = (odd: Odd): Odd => {
  if (odd.templateId) {
    switch (odd.templateId) {
      case 1:
        odd.type = 'default'
        break
      case 2:
        odd.type = 'handicap'
        break
      case 3:
        odd.type = 'overUnder'
        break
      case 4:
        odd.type = 'range'
        break
      case 5:
        odd.type = 'anytime'
        break
      case 6:
        odd.type = 'alternative'
        break
      case 7:
        odd.type = 'teams'
        break
      case 8:
        odd.type = 'exact'
        break
    }
    return odd
  }
  if (marketTypes.handicap.includes(Number(odd.id))) {
    odd.type = 'handicap'
    return odd
  }
  if (marketTypes.withRangeMarkets.includes(Number(odd.id))) {
    odd.type = 'range'
    return odd
  }
  if (marketTypes.overUnder.includes(Number(odd.id))) {
    odd.type = 'overUnder'
    return odd
  }
  if (marketTypes.anytime.includes(Number(odd.id))) {
    odd.type = 'anytime'
    return odd
  }
  if (marketTypes.alternative.includes(Number(odd.id))) {
    odd.type = 'alternative'
    return odd
  }
  if (marketTypes.teams.includes(Number(odd.id))) {
    odd.type = 'teams'
    return odd
  }
  if (marketTypes.exact.includes(Number(odd.id))) {
    odd.type = 'exact'
    return odd
  }
  if (marketTypes.column.includes(Number(odd.id))) {
    odd.type = 'column'
    return odd
  }
  return odd
}

const transformHandicapName = (
  name: string,
  word: string,
  cleanWord: string,
  defaultData: Obj<string>
): string => {
  const hcpMinus = defaultData.hcp.match(/-/)
  const hcpScore = defaultData.hcp.match(/:/)

  if (hcpScore) {
    return name.replace(word, `{${defaultData[cleanWord]}}`)
  }

  if (hcpMinus) {
    if (word.includes('+')) {
      const noBrackets = name.replace(word, `{${defaultData[cleanWord]}}`)
      /* noBrackets = name.replace('({', '')
      noBrackets = noBrackets.replace('})', '') */
      return noBrackets
    }
    const noBrackets = name.replace(word, `{+${defaultData[cleanWord].replace('-', '')}}`)
    /* noBrackets = name.replace('({', '')
    noBrackets = noBrackets.replace('})', '') */
    return noBrackets
  }

  if (word.includes('-')) {
    const noBrackets = name.replace(word, `{-${defaultData[cleanWord]}}`)
    /* noBrackets = name.replace('({', '')
    noBrackets = noBrackets.replace('})', '') */
    return noBrackets
  }
  const noBrackets = name.replace(word, `{+${defaultData[cleanWord].replace('-', '')}}`)
  /* noBrackets = name.replace('({', '')
  noBrackets = noBrackets.replace('})', '') */
  return noBrackets
}

export const getHandicapValue = (name: string): string => {
  //console.log(name)
  const withBreckets = /{(.*?)}/gi

  const value = name.match(withBreckets) || name

  //console.log(value)
  if (value?.[0]) {
    //console.log(value?.[0].match(/\{(.*)\}/)?.[1] || '')
    //return value?.[0].match(/\{(.*)\}/)?.[1] || ''
    //value?.[0].match(/\{(.*)\}/)?.[1] || '' == ""
    if (value?.[0].match(/\{(.*)\}/)?.[1] || '' == '') {
      //console.log(name.substring(1))
      return name.substring(1).replace('{', '').replace('}', '').replace('raw', '')
    } else {
      //console.log(value?.[0].match(/\{(.*)\}/)?.[1] || '')
      return (
        value?.[0]
          .match(/\{(.*)\}/)?.[1]
          .replace('{', '')
          .replace('}', '')
          .replace('raw', '') || ''
      )
    }
  }
  return ''
}
