import React, { useEffect } from 'react'
import Main from '../../components/containers/main/Main'
import AsideLeft from '../../components/containers/main/AsideLeft'
import UserCabinet from '../../components/forAccontPageComponents/UserCabinet'
import UserAsideNavBar from '../../components/forAccontPageComponents/UserAsideNavBar'
import Content from '../../components/containers/main/Content'
import AsideRight from '../../components/containers/main/AsideRight'
import Footer from '../../components/containers/footer/Footer'
import Navigate from '../../components/common/routing/Navigate'
import AsideLeftContext from '../../context/asideLeftContext'
import AsideRightContext from '../../context/asideRightContext'
import { accountRouting } from '../../routing/accountRouting/accountRouting'
import '../../assets/styles/account.css'
import { useDispatch } from 'react-redux'
import { finish } from '../../redux/actions/preloaderActions'
import SimpleBar from 'simplebar-react'

const Account = () => {
  const dispatch = useDispatch()
  const refContainer = React.useRef(null)

  // TODO remove
  useEffect(() => {
    dispatch(finish())
  }, [])

  // useEffect(() => {
  // 	$(refContainer.current).niceScroll({
  // 		cursorcolor: '#2F3E46',
  // 		cursorborder: '0px solid #1A1EA0',
  // 		cursorborderradius: '0px',
  // 		cursorwidth: '5px',
  // 	})
  // }, [])

  return (
    <SimpleBar className="cabinet">
      <Navigate routes={accountRouting} />
      <Footer />
    </SimpleBar>

  )
}

export default Account
