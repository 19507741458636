import React from 'react'

const ControlBarItem = ({ day, date, active, click }) => {
  return (
    <div
      role="button"
      className={
        active ? 'ips-control-bar__item ips-control-bar__item--active' : 'ips-control-bar__item'
      }
      onClick={click}
      onKeyPress={() => 0}
      tabIndex={0}
    >
      <p className="font font--11 font--semibold text-uppercase ips-control-bar__top">{day}</p>
      <p className="font font--11 font--default-alpha-05 text-uppercase ips-control-bar__bottom">
        {date}
      </p>
    </div>
  )
}

export default ControlBarItem
