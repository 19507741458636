export const links = {
  webApi: 'https://api.absolute.bet',
  inplayTree: 'https://api.absolute.bet/feedapi/?key=inplay-tree',
  event: 'https://api.absolute.bet/feed/?key=event&eventId=',
  oddTypes: 'https://api.absolute.bet/feed/?key=odd-types&ln=',
  slides: 'https://api.absolute.bet/config/bannerSlider/',
  banners: 'https://api.absolute.bet/config/banners/',
  league: 'https://api.absolute.bet/feed/?key=inplay-league&leagueId=',
  prematchLeague: 'https://api.absolute.bet/feed/?key=league&leagueId=',
  prematchAll: 'https://api.absolute.bet/feed/?key=sports',
  refreshslip: 'https://api.absolute.bet/bets/refreshslip',
  addBet: 'https://api.absolute.bet/bets/addbet',
  placeBet: 'https://api.absolute.bet/bets/placebet',
  removeBet: 'https://api.absolute.bet/bets/removebet',
  removeAll: 'https://api.absolute.bet/bets/removeall',
  config: 'https://api.absolute.bet/account/config',
  account: 'https://members.absolute.bet/balance/deposit/',
  /* 	account:
		'https://absolute.bet/accountIframe/pages/balance/deposit/index.php' ,*/
  accountPages: 'https://members.absolute.bet/',
  /* accountPages: 'https://absolute.bet/accountIframe/pages/' ,*/
  registration: 'https://api.absolute.bet/account/create',
  regConfirm: 'https://api.absolute.bet/account/regConfirm',
  resetPassword: 'https://api.absolute.bet/account/resetPassword',
  resetPasswordConfirm: 'https://api.absolute.bet/account/resetConfirm',
  newPassword: 'https://api.absolute.bet/account/newPassword',
  login: 'https://api.absolute.bet/account/login',
  logout: 'https://api.absolute.bet/account/logout',
  balance: 'https://api.absolute.bet/balance/get/',
  settings: 'https://api.absolute.bet/account/setting',
  all: 'https://api.absolute.bet/mybets/?type=3',
  settled: 'https://api.absolute.bet/mybets/?type=2',
  unsettled: 'https://api.absolute.bet/mybets/?type=1',
  mybets: 'https://api.absolute.bet/mybets',
  topGames: 'http://bestline.bet/api2/?key=top-games',
  matchLiveAnimation:
    'https://href.li/?https://cs.betradar.com/ls/widgets/?/favbet/ru/page/widgets_lmts#matchId=',
  matchLiveStreaming: 'https://api.absolute.bet/video/',
  accountMenu: 'https://api.absolute.bet/config/accountMenu.php',
  // accountMenu: 'http://46.101.202.52/appMobile/config/accountMenu.json'
}
