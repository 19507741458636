import React from 'react'
import Tabs from '../common/tabs/Tabs'

const tabs: Array<{ title: string; id: number; type: 'Match' | 'Tournament' | 'Table' }> = [
  { title: 'Matches', id: 0, type: 'Match' },
  { title: 'Outright', id: 1, type: 'Tournament' },
  { title: 'Tables', id: 2, type: 'Table' },
]

interface IPrematchTabsProps {
  tab: 'Match' | 'Tournament' | 'Table'
  setTab: (type: 'Match' | 'Tournament' | 'Table') => void
}

const PrematchTabs: React.FC<IPrematchTabsProps> = ({ tab, setTab }) => {
  const handler = (id) => {
    setTab(tabs[id].type)
  }

  return (
    <div className="ip-control-bar wn-control-bar">
      <Tabs
        activeTab={tab}
        handler={handler}
        tabs={tabs}
        className="flex align-middle full-height ip-control-bar__wrap"
        activeCl="font ip-control-bar__link--active"
        defaultCl="font ip-control-bar__link"
      />
    </div>
  )
}

export default PrematchTabs
