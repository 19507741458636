import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { types } from '../../types/types'
import { createSubscriptionChannel, unsubscribe } from '../subscriptionHandler'
import { topGamesWatcher } from '../../actions/inplayActions'
import _ from 'lodash'
import { store } from '../../store'
import { emitterByCmd } from '../../utils/emitter'

function* getTopGamesWorker() {
	const config = yield select(state => state.config)
	const channel = yield call(createSubscriptionChannel,
		'/feedapi/?key=top-games',
		{},
		'top-games',
		[
			(emitter, rs) => {
				const oldState = _.cloneDeep(store.getState().topGames)
				emitterByCmd(oldState, rs, emitter)
			},
		],
		2500
		, config)


	yield fork(function* () {
		yield take(types.DISABLE_TOP_GAMES_WATCHER)
		unsubscribe('/feed/?key=top-games', 'top-games', 2500, config)
	})

	try {
		while (true) {
			const data = yield take(channel)
			yield put(topGamesWatcher.setTopGames(data))
		}
	} catch (e) {
		channel.close()
	}
}


export const topGamesSagas = [
	takeEvery(types.GET_TOP_GAMES_WATCHER, getTopGamesWorker),
]
