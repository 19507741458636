import React, { useEffect, useState } from 'react'
import SportHeader from './SportHeader'
import LeagueHeader from './LeagueHeader'
import PrematchTabs from './PrematchTabs'
import { useDispatch, useSelector } from 'react-redux'
import LeagueEvents from './LeagueEvents'
import { getAllSports, getCurrent } from 'redux/selectors/prematchSelectors'
import { getPrematchLeague } from 'redux/selectors/prematchLeagueSelectors'
import { prematchLeagueWorkers } from 'redux/actions/prematchLeagueActions'
import { prematchWorkers } from 'redux/actions/prematchActions'
import { useParams } from 'react-router'
import { ILeague, Nullable } from 'helpers/commonInterfaces/interfaces'

const PrematchLeagueWrapper = () => {
  const dispatch = useDispatch()
  const params: { leagueId?: string; sportId?: string } = useParams()
  const sports = useSelector(getAllSports)
  const currentSport = useSelector(getCurrent)
  const currentLeague = useSelector(getPrematchLeague)

  const [tab, setTab] = useState<'Match' | 'Tournament' | 'Table'>('Match')

  useEffect(() => {
    const getDataIfReloaded = () => {
      if (sports?.length && sports.length > 0) {
        if (currentSport === null || currentLeague === null) {
          const sport = sports.find((sport) => sport.id.toString() === params.sportId) ?? null
          dispatch(prematchWorkers.setCurrentSport(sport))
          let league: Nullable<ILeague> = null
          if (sport) {
            for (const category of sport.categories) {
              for (const lg of category.leagues) {
                if (lg.id.toString() === params.leagueId) {
                  league = lg
                }
              }
            }
          }
          dispatch(prematchLeagueWorkers.setLeague(league))
        }
      } else {
        setTimeout(getDataIfReloaded, 50)
      }
    }
    getDataIfReloaded()
  }, [params, sports])

  if (!currentSport || !currentLeague) {
    return null
  }

  return (
    <>
      <SportHeader sport={currentSport} />
      <PrematchTabs {...{ tab }} {...{ setTab }} />
      <LeagueHeader league={currentLeague} />
      <LeagueEvents type={tab} />
    </>
  )
}

export default PrematchLeagueWrapper
