import React from 'react'

const TitleMarketCell = ({ odd }) => {
  if (typeof odd === 'undefined') {
    return <></>
  }
  return (
    <div className="gll-market-group__cell">
      <div className="gll-market-group__coff">
        <span className="font font--grey-300">{odd.outcome}</span>
      </div>
    </div>
  )
}

export default React.memo(TitleMarketCell)
