import React from 'react'

const ScoreboardScore = ({ scoreboard }) => {
  //console.log('Scoreboard: ', scoreboard)

  if (typeof scoreboard === 'undefined' || scoreboard === undefined) {
    return <></>
  } else {
    const { currentHomeScore, currentAwayScore } = scoreboard?.data

    return (
      <div className="text-center ip-scoreboard__wrap--small">
        <div className="flex align-center-middle">
          <p className="font font--white font--20 font--semibold">{currentHomeScore}</p>
          <p className="font font--white font--20 font--semibold">:</p>
          <p className="font font--white font--20 font--semibold">{currentAwayScore}</p>
        </div>
      </div>
    )
  }
}

export default React.memo(ScoreboardScore)
