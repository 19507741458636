import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { favoritesWorkers } from 'redux/actions/favoritesActions'
import { getFavorites } from 'redux/selectors/favoritesSelectors'

const CompetitionHeader = ({ refProp, title, id, sportId, onToggleHandler }) => {
  const dispatch = useDispatch()
  const favorites = useSelector(getFavorites)
  const toggleFavLeague = (e) => {
    e.stopPropagation()
    if (favorites.leagues[sportId]?.some(lg => lg === id.toString())) {
      dispatch(
        favoritesWorkers.removeLeague(
          id.toString(),
          sportId.toString()
        )
      )
    } else {
      dispatch(
        favoritesWorkers.addLeague(id.toString(), sportId.toString())
      )
    }
  }

  return (
    <div
      ref={refProp}
      className="flex align-middle align-justify ipn-competition__header"
      onClick={onToggleHandler}
    >
      <p className="font font--grey-300 text-ellipsis ipn-competition__text">{title}</p>
      <div
        className={"ipn-competition__favourite"
          + (Object.values(favorites.leagues).some((sport: any[]) => sport.some(league => league === id.toString())) ? ' active' : '')}
        onClick={toggleFavLeague}
      />
    </div>
  )
}

export default React.memo(CompetitionHeader)
