export interface ICurrencyConfig {
  CURRENCY_SYMBOL: string
  CURRENCY_DECIMAL_SEPARATOR: string
  CURRENCY_GROUP_SEPARATOR: string
  CURRENCY_PREFIX_SYMBOL: boolean
  CURRENCY_MIN_SEP_VALUE: string
  CURRENCY_SPACE_REQUIRED: boolean
  CURRENCY_PLURAL_SYMBOL: string
  CURRENCY_EXCHANGE_RATE: string
  CURRENCY_PRECISION?: number
}

export function floatToCurrency(
  moneyParam: number | string | undefined,
  config: ICurrencyConfig,
  wrap = false,
  symbol = false
) {
  let money = moneyParam?.toString() ?? ''
  if (money === '0') {
    return '0'
  }
  const groupSep = config.CURRENCY_GROUP_SEPARATOR
  const decimalSep = config.CURRENCY_DECIMAL_SEPARATOR
  const startMoneyValue = money.toString()
  money = Number(money.split(groupSep).join('').replace(decimalSep, '.')).toFixed(
    config.CURRENCY_PRECISION ?? 2
  )
  money = money.toString()
  if (money === 'NaN') {
    money = '0'
  }
  money = money.split(groupSep).reduce((acc: string, cur: string) => acc + cur)
  // check if input is correct
  if (money.includes(decimalSep)) {
    // remove extra decimal separators
    if (money.split(decimalSep).length - 1 > 1) {
      return money.slice(0, -1)
    }
    // remove extra coins
    if (money.split(decimalSep)[1].length > 2) {
      money = money.slice(0, -1)
    }
    if (money.split(decimalSep)[1] !== '') {
      if (
        !(
          /^\d+$/.test(money.split(decimalSep)[0].split(groupSep).join()) &&
          /^\d+$/.test(money.split(decimalSep)[1])
        )
      ) {
        return '0.'
      }
    }
  } else {
    if (!/^\d+$,/.test(money)) {
      return '0'
    }
  }


  if (typeof money.split(decimalSep)[1] === 'undefined') {
    money += `${decimalSep}00`
  } else if (money.split(decimalSep)[1].length === 1) {
    money += '0'
    money = money.replace('.', decimalSep)
  } else if (money.split(decimalSep)[1].length === 0) {
    money += '00'
    money = money.replace('.', decimalSep)
  } else {
    money = money.replace('.', decimalSep)
  }

  // Add currency group and decimal separators from the user's config
  const lg = money.length
  let count = 0
  for (let i = 0; i < lg && lg > 6; i++) {
    count += 1
    const item = money.charAt(i)
    if (item === decimalSep) {
      break
    }
    if (count === 3) {
      count = 0
      money = money.slice(0, -(i - 1) - 5) + groupSep + money.slice(-(i - 1) - 5, money.length)
      i++
    }
    if (money.charAt(0) === groupSep) {
      money = money.slice(1)
    }
  }
  if (
    money.charAt(money.length - 1) === '0' &&
    startMoneyValue.charAt(startMoneyValue.length - 1) !== '0'
  ) {
    money = money.slice(0, -1)
    if (money.charAt(money.length - 1) === '0') {
      money = money.slice(0, -2)
    }
  }

  if (startMoneyValue.includes(decimalSep)) {
    if (
      startMoneyValue.split(decimalSep)[1].length === 1 &&
      startMoneyValue.charAt(startMoneyValue.length - 1) === '0'
    ) {
      money = money.slice(0, -1)
    }
    if (startMoneyValue.split(decimalSep)[1].length === 0) {
      money += '.'
    }
  } else if (startMoneyValue.charAt(startMoneyValue.length - 1) === '0') {
    money = money.slice(0, -3)
  }
  // wrapping
  if (wrap) {
    if (!startMoneyValue.includes(decimalSep)) {
      money += `${decimalSep}00`
    } else {
      const lengthDiff = startMoneyValue.split(decimalSep)[1].length
      if (wrap && lengthDiff > 0) {
        if (lengthDiff === 1) {
          money += '0'
        } else if (lengthDiff === 0) {
          money += `${decimalSep}00`
        }
      }
    }
  }
  // currency symbol
  if (symbol) {
    if (config.CURRENCY_PREFIX_SYMBOL) {
      if (config.CURRENCY_SPACE_REQUIRED) {
        return `${config.CURRENCY_SYMBOL} ${money}`
      }
      return config.CURRENCY_SYMBOL + money
    }
    if (config.CURRENCY_SPACE_REQUIRED) {
      return `${money} ${config.CURRENCY_SYMBOL}`
    }
    return money + config.CURRENCY_SYMBOL
  }
  // if (money === '0,00') return ''
  return money
}
