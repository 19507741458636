import React, { useState } from 'react'
import LiveTranslationHeader from './header/LiveTranslationHeader'
import LiveTranslationBody from './body/LiveTranslationBody'
import { useStorage } from '../../../../hooks/useStorage'
import { useDispatch, useSelector } from 'react-redux'
import { getTrackerAndTranslation } from 'redux/selectors/inplaySelectors'
import { inplayWorkers } from 'redux/actions/inplayActions'

interface LiveTranslationWrapperProps {
  wide: boolean
  removeWideHandler: () => void
}
const LiveTranslationWrapper: React.FC<LiveTranslationWrapperProps> = ({
  wide,
  removeWideHandler,
}) => {
  const dispatch = useDispatch()
  const { getSession, setSession } = useStorage()

  const showTranslation = getSession('showTranslation')
  const volumeStorage = getSession('volumeSettings') || {}
  const pinnedStorage = getSession('pinnedTranslation') ?? false
  const { pinnedMatch } = useSelector(getTrackerAndTranslation)

  const [active, setActive] = useState('live')
  const [showBody, setShowBody] = useState(showTranslation === 'false' ? false : true)
  const [soundRangeValue, setSoundRangeValue] = useState(volumeStorage.soundRangeValue || 50)

  const pinToggle = () => {
    dispatch(inplayWorkers.setPinnedMatch(!pinnedMatch))
  }

  const activateBody = () => {
    setSession('showTranslation', !showBody)
    setShowBody(!showBody)
  }

  const setActiveLink = (title) => setActive(title)

  return (
    <div className="lv-translation m-b-10">
      <LiveTranslationHeader
        wide={wide}
        pinned={pinnedMatch}
        pinToggle={pinToggle}
        active={active}
        showBody={showBody}
        activateBody={activateBody}
        setActiveLink={setActiveLink}
        removeWideHandler={removeWideHandler}
        soundRangeValue={soundRangeValue}
        setSoundRangeValue={setSoundRangeValue}
      />
      {showBody && <LiveTranslationBody active={active} soundRangeValue={soundRangeValue} />}
    </div>
  )
}

export default LiveTranslationWrapper
