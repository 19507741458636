import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import CompetitionHeader from './header/CompetitionHeader'
import CompetitionBody from './body/CompetitionBody'
import { CSSTransition } from 'react-transition-group'
import { AsideContext } from '../../../../context/asideLeftContext'
import { useParams } from 'react-router-dom'

type CompHeaderRef = {
  current
}

const Competition = ({ data, liveTranslation }) => {
  const { name, id, sportId } = data

  const { leagueId }: { leagueId: string } = useParams()
  const ref: CompHeaderRef = useRef()

  const { leagueCategoryHandler, isOpenLeague, addOpenedLeague, leaguesEvents, closeLeague } =
    useContext(AsideContext)

  const showToggleHandler = (event) => {
    event.stopPropagation()
    if (leagueCategoryHandler) {
      leagueCategoryHandler(id)
    }
  }

  useLayoutEffect(() => {
    if (Number(leagueId) === id && addOpenedLeague) {
      addOpenedLeague(id)
    }

    return () => {
      if (closeLeague) {
        closeLeague(id)
      }
    }
  }, [])

  useEffect(() => {
    if (window.location.href.split('/')[6] === id.toString()) {
      ref.current.click()
    }
  }, [])

  if (!isOpenLeague) {
    return <></>
  }

  const OpenL = isOpenLeague(id)

  return (
    <div className={OpenL ? 'ipn-competition ipn-competition--down' : 'ipn-competition'}>
      <CompetitionHeader refProp={ref} title={name} {...{ id }} {...{ sportId }} onToggleHandler={showToggleHandler} />
      <CSSTransition in={OpenL} timeout={300} appear={true} unmountOnExit mountOnEnter>
        <CompetitionBody
          data={leaguesEvents[id]}
          leagueId={id}
          {...{ liveTranslation }} />
      </CSSTransition>
    </div>
  )
}

export default React.memo(Competition)
