import React from 'react'
import SportsTableElem from './sportsTableElem'
import ScheduleHeader from './scheduleHeader'
import { v4 as uuidv4 } from 'uuid'
import ScheduleEmpty from './scheduleEmpty'

const ScheduleTableWrapper = ({ data, init }) => {
  if (data.length === 0) {
    return <ScheduleEmpty {...{ init }} />
  }

  return (
    <div>
      <ScheduleHeader />
      {data?.map((item) => (
        <SportsTableElem {...{ item }} key={uuidv4()} />
      ))}
    </div>
  )
}

export default ScheduleTableWrapper
