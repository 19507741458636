import React from 'react'

const TableRow = ({ children, className }) => {
	return (
		<tbody>
			<tr {...{ className }}>{children}</tr>
		</tbody>
	)
}

export default TableRow
