import React from 'react'

const ControlBarItemText = ({ text, onClick, active }) => {
  return (
    <a
      className={
        active ? 'ipn-control-bar__item ipn-control-bar__item--active' : 'ipn-control-bar__item'
      }
      {...{ onClick }}
    >
      <p className="font font--12 font--grey-300">{text}</p>
    </a>
  )
}

export default React.memo(ControlBarItemText)
