import { floatToCurrency } from 'helpers/floatToCurrency'
import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrency } from 'redux/selectors/configSelectors'

const BetlipTitle = ({ click, id, name, amount }) => {
  const currencyConfig = useSelector(getCurrency)
  return (
    <div className="betslip__tr betslip__tr--title" onClick={click}>
      <div className="betslip__td">
        <div className="betslip__id">
          <p className="font font--11 font--white">
            {floatToCurrency(amount, currencyConfig, true, true)}
          </p>
        </div>
        <p className="font font--11 font--dark">{name}</p>
        {/* <p className="font font--defaul-alpha-05">21.09 23:10</p> */}
      </div>
    </div>
  )
}

export default BetlipTitle
