import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStreaming, getTrackerAndTranslation } from 'redux/selectors/inplaySelectors'
import { isStreaming, isAnimation } from '../../../../../../helpers/utils'
import { inplayWorkers } from '../../../../../../redux/actions/inplayActions'

const EventActions = ({ id, enrichment, externalIds, sportID }) => {
  const dispatch = useDispatch()
  const streamingData = useSelector(getStreaming)
  const { tracker, translation } = useSelector(getTrackerAndTranslation)

  // deprecated code was: externalIds.betradar.split(':')[2]
  const onSetTracker = () => {
    if (isAnimation(enrichment)) {
      dispatch(inplayWorkers.setActiveTracker(id))
    }
  }

  const onSetTranslation = () => {
    if (isStreaming(streamingData, externalIds)) {
      dispatch(inplayWorkers.setActiveTranslation(id))
    }
  }

  return (
    <div className="flex align-middle align-center">
      <a className="c-icon c-icon--active ip-competition__action">
        <svg width="13" height="13" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="3" height="11" fill="#6c90fc"></rect>
          <rect x="4" y="4" width="3" height="7" fill="#6c90fc"></rect>
          <rect x="8" y="2" width="3" height="9" fill="#6c90fc"></rect>
        </svg>
      </a>

      <div
        className={"c-icon  ip-competition__action"
          + (isStreaming(streamingData, externalIds)
            ? " c-icon--video" + (translation === id && isStreaming(streamingData, externalIds)
              ? ""
              : " c-icon--video not-active")
            : " c-icon--no-play")
        }
        onClick={onSetTranslation}
      />
      {/* {
        !isStreaming(streamingData, externalIds) &&
        (<div
          className="c-icon c-icon--rejected"
        />)
      } */}

      <div
        className={"c-icon ip-competition__action"
          + (isAnimation(enrichment)
            ? (" c-icon--" + sportID + " c-icon--1")
            : (" c-icon--" + sportID + " c-icon--1" + " c-icon--no-anim"))
          + (tracker === id && isAnimation(enrichment)
            ? ""
            : " not-active")}
        onClick={onSetTracker}
      >
        {/* {getAnimation(sportID)} */}
      </div>
      {/*<div className="c-icon c-icon--stats ip-competition__action" />*/}
    </div >
  )
}

export default React.memo(EventActions)

const getAnimation = (sportID) => {
  switch (sportID) {
    // soccer
    case 1:
      return (
        <svg width="19" height="14" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.5 0.5V3.5" stroke="#B5B7C6" strokeLinecap="square"></path>
          <path d="M8.5 8C9.60457 8 10.5 7.10457 10.5 6C10.5 4.89543 9.60457 4 8.5 4C7.39543 4 6.5 4.89543 6.5 6C6.5 7.10457 7.39543 8 8.5 8Z" stroke="#383a4a"></path>
          <rect x="0.5" y="0.5" width="16" height="11" stroke="#383a4a"></rect>
          <rect x="0.5" y="3.5" width="3" height="5" stroke="#383a4a"></rect>
          <rect x="13.5" y="3.5" width="3" height="5" stroke="#383a4a"></rect>
          <path d="M8.5 8.5V11.5" stroke="#B5B7C6" strokeLinecap="square"></path>
        </svg>

      )
    // tennis
    case 5:
      return (
        <svg width="19" height="14" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="16" height="11" stroke="#383a4a"></rect>
          <rect x="2" y="2" width="13" height="8" fill="#587a5a"></rect>
          <path d="M8.5 2.30005V9.80005" stroke="#383a4a" strokeWidth="0.5" strokeLinecap="square"></path>
          <path d="M10 1V11" stroke="#383a4a" strokeWidth="0.5" strokeLinecap="square"></path>
          <path d="M7 1V11" stroke="#383a4a" strokeWidth="0.5" strokeLinecap="square"></path>
        </svg>
      )
    // table tennis
    case 20:
      return (
        <svg width="19" height="14" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="16" height="11" stroke="#383a4a"></rect>
          <rect x="2" y="2" width="13" height="8" fill="#587a5a"></rect>
          <path d="M8.5 2.30005V9.80005" stroke="#383a4a" strokeWidth="0.5" strokeLinecap="square"></path>
          <path d="M10 1V11" stroke="#383a4a" strokeWidth="0.5" strokeLinecap="square"></path>
          <path d="M7 1V11" stroke="#383a4a" strokeWidth="0.5" strokeLinecap="square"></path>
        </svg>
      )
    case 21:
      return (
        <svg width="19" height="14" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="16" height="11" rx="5.5" stroke="#383a4a"></rect>
          <rect x="2.5" y="2.5" width="12" height="7" rx="3.5" stroke="#383a4a"></rect>
          <rect x="5" y="4" width="7" height="4" fill="#383a4a"></rect>
        </svg>
      )
    // volleyball
    case 23:
      return (
        <svg width="19" height="14" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="16" height="11" stroke="#B5B7C6"></rect>
          <path d="M1 3L2.63246 3.54415C3.44914 3.81638 4 4.58066 4 5.44152V6.55848C4 7.41934 3.44914 8.18362 2.63246 8.45585L1 9V3Z" fill="#B5B7C6"></path>
          <path d="M16.75 10.6711V1.32891L12.5316 2.50068C11.7742 2.71108 11.25 3.40072 11.25 4.18684V7.81316C11.25 8.59928 11.7742 9.28892 12.5316 9.49932L16.75 10.6711Z" stroke="#B5B7C6" strokeWidth="0.5"></path>
          <path d="M0.25 10.6711V1.32891L4.46838 2.50068C5.22581 2.71108 5.75 3.40072 5.75 4.18684V7.81316C5.75 8.59928 5.22581 9.28892 4.46838 9.49932L0.25 10.6711Z" stroke="#B5B7C6" strokeWidth="0.5"></path>
          <path d="M16 3L14.3675 3.54415C13.5509 3.81638 13 4.58066 13 5.44152V6.55848C13 7.41934 13.5509 8.18362 14.3675 8.45585L16 9V3Z" fill="#B5B7C6"></path>
        </svg>
      )
    default:
      return (
        <svg width="19" height="14" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.5 0.5V3.5" stroke="#B5B7C6" strokeLinecap="square"></path>
          <path d="M8.5 8C9.60457 8 10.5 7.10457 10.5 6C10.5 4.89543 9.60457 4 8.5 4C7.39543 4 6.5 4.89543 6.5 6C6.5 7.10457 7.39543 8 8.5 8Z" stroke="#383a4a"></path>
          <rect x="0.5" y="0.5" width="16" height="11" stroke="#383a4a"></rect>
          <rect x="0.5" y="3.5" width="3" height="5" stroke="#383a4a"></rect>
          <rect x="13.5" y="3.5" width="3" height="5" stroke="#383a4a"></rect>
          <path d="M8.5 8.5V11.5" stroke="#B5B7C6" strokeLinecap="square"></path>
        </svg>

      )
  }
}
