import React, { useState } from 'react'
import Wrap from '../../registration/elements/wrap'
import Select from '../../common/elements/Select'
import Text from '../../common/elements/Text'
import Button from '../../registration/elements/button'
import { useFormik } from 'formik'
import { useAccount } from '../../../hooks/useAccount'
import PopupSucceed from '../PopupSucceed'

const AccountSettings = () => {
  const [popup, setPopup] = useState(false)
  const { sumbit, accountSettings, schemaAccountSettins } = useAccount()
  const closePopupHandler = () => {
    window.clearTimeout(window.closePopupTimeout)
    setPopup(false)
  }

  const formik = useFormik({
    initialValues: accountSettings.initialValues,
    validationSchema: schemaAccountSettins,
    onSubmit: (values, methods) => {
      setPopup(true)
      window.clearTimeout(window.closePopupTimeout)
      window.closePopupTimeout = setTimeout(() => {
        window.clearTimeout(window.closePopupTimeout)
        setPopup(false)
      }, 3000)
      sumbit('accountSettings', values)
    },
  })
  const { values, handleChange, handleSubmit, errors, touched } = formik
  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="container__head">
          <p className="font font--semibold">Account Settings</p>
        </div>
        <div className="container__wrap">
          <div className="account">
            <div className="left">
              <Wrap className={errors.language && touched.language && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10" title="Language"></Text>
                <Select
                  className="select select--main"
                  optionsArray={[
                    {
                      value: 'English',
                      title: 'English',
                      deflt: true,
                    },
                    {
                      value: 'Русский',
                      title: 'Русский',
                    },
                    {
                      value: 'Українська',
                      title: 'Українська',
                    },
                  ]}
                  name="language"
                  value={values.language}
                  onChange={handleChange}
                />
              </Wrap>
              <Wrap className={errors.odds && touched.odds && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10" title="Odds type"></Text>
                <Select
                  className="select select--main"
                  optionsArray={[
                    {
                      value: 'Decimal',
                      title: 'Decimal',
                      deflt: true,
                    },
                    {
                      value: 'Float',
                      title: 'Float',
                    },
                    {
                      value: 'American',
                      title: 'American',
                    },
                  ]}
                  name="odds"
                  value={values.odds}
                  onChange={handleChange}
                />
              </Wrap>
              <Wrap>
                <Button type="submit" title="Save" />
              </Wrap>
            </div>
            <div className="right">
              <Wrap className={errors.language && touched.language && 'required required--error'}>
                <Text className="font font--default-alpha-05 m-b-10" title="Time zone"></Text>
                <Select
                  className="select select--main"
                  optionsArray={[
                    {
                      value: 'GMT +3',
                      title: 'GMT +3',
                      deflt: true,
                    },
                    {
                      value: 'GMT +2',
                      title: 'GMT +2',
                    },
                  ]}
                  name="language"
                  value={values.language}
                  onChange={handleChange}
                />
              </Wrap>
              <Wrap className={errors.language && touched.language && 'required required--error'}>
                <Text
                  className="font font--default-alpha-05 m-b-10"
                  title="Max inactivity time"
                ></Text>
                <Select
                  className="select select--main"
                  optionsArray={[
                    {
                      value: '10 minutes',
                      title: '10 minutes',
                      deflt: true,
                    },
                    {
                      value: '1 Hour',
                      title: '1 Hour',
                    },
                    {
                      value: '2 Hours',
                      title: '2 Hours',
                    },
                  ]}
                  name="language"
                  value={values.language}
                  onChange={handleChange}
                />
              </Wrap>
            </div>
          </div>
        </div>
      </form>
      {popup && (
        <PopupSucceed
          close={closePopupHandler}
          message={'Account settings has changed successfully'}
        />
      )}
    </div>
  )
}

export default AccountSettings
