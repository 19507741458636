import React, { useContext, useState } from 'react'
import { Collapse } from 'react-collapse'
import WithRangeMarkets from './rangeMarkets/WithRangeMarkets'
import WithSpecifiersTitleMarkets from './specifiersTitle/WithSpecifiersTitleMarkets'
import DefaultMarketsTemplate from './defaultMarketsTemplate/DefaultMarketsTemplate'
import { EventContext } from '../../../../../context/eventContext'
import '../../../../../assets/styles/markets.css'
import { IMatch, ITournament, Nullable, OddOutcome } from 'helpers/commonInterfaces/interfaces'
import MarketHeader from '../marketHeader/MarketHeader'
import { transformOutcomeName } from 'helpers/transform/transform'
import { scrollToBottom } from 'helpers/scrollTo'
import MarketBody from '../marketBody/MarketBody'

const withRangeMarkets = [889, 913, 962, 1050, 1051, 187, 194, 201]
const specifiersMarkets = [
  478, 189, 197, 268, 248, 247, 693, 694, 695, 686, 692, 7, 366, 368, 507, 510, 511, 190, 194, 197,
  199, 201, 205, 1057, 1130, 1129, 902, 305, 231, 228,
]
type Props = {
  odd: any
  idx: number
  marketsLn: number
}
const MarketGroup = React.forwardRef(function MarketGroup(
  { odd, idx, marketsLn }: Props,
  ref
) {
  const refer = ref as React.MutableRefObject<HTMLInputElement>

  const { id, name, outcomes } = odd
  const { event }: { event: Nullable<IMatch | ITournament> } = useContext(EventContext)
  const [open, setOpen] = useState<boolean>(idx <= 10)


  let match: Nullable<IMatch> = null
  if (event && event['competitors'] !== null) {
    match = event as IMatch
  } else {
    return null
  }

  const newName = transformOutcomeName(name, outcomes[0]?.specifiers, match.competitors)

  // if (specifiersMarkets.includes(id)) {
  //   return <WithSpecifiersTitleMarkets odd={odd} competitors={match ? match.competitors : []} />
  // }

  // if (withRangeMarkets.includes(id)) {
  //   return <WithRangeMarkets odd={odd} competitors={match?.competitors} />
  // }

  const smoothScrollToBottom = () => {
    const ph = document.querySelector('.flipmove-placeholder')
    if (ph) {
      setTimeout(() => {
        const height = ph.getBoundingClientRect().height
        console.log('height will be then', height)
        scrollToBottom(document.body, height, 350)
      }, 50)
    }
  }
  const onToggleOpen = () => {
    if (idx > marketsLn - 4) {
      smoothScrollToBottom()
    }
    setOpen(!open)
  }

  return (
    <div
      ref={refer}
      className={
        open
          ? 'gll-market-group gll-market-group--open'
          : 'gll-market-group gll-market-group--closed'
      }
    >
      <MarketHeader
        id={id}
        title={newName}
        rangeData={[]}
        onToggleHandler={onToggleOpen}
      />
      <Collapse
        theme={{ collapse: 'ReactCollapse' }}
        isOpened={open}
        initialStyle={{ height: '0px', overflow: 'hidden' }}
        checkTimeout={350}>
        <>
          {/* <div>
            Odd type: {odd.type} odd id: {odd.id}
          </div> */}
          <MarketBody
            outcomes={outcomes}
            groupId={id}
            type={odd.type}
            eventId={match.id}
            sportId={match.sportId}
            competitors={match.competitors}
          />
        </>
      </Collapse>
    </div>
  )
})

export default MarketGroup
