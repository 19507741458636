import React from 'react'
import img from '../../../assets/img/offer.png'

const Archieve = () => {
	return (
		<div className="container">
			<div className="container__head">
				<p className="font font--semibold">Archive</p>
			</div>
			<div className="container__wrap">
				<div className="offers">
					<div className="offer" style={{ backgroundImage: `url(${img})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									0. Избавь деревню от оборотней и получи 45 фриспинов в слоте
								</p>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-right">
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more offer__btn--not-more">
										<span className="font font--white">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="offer" style={{ backgroundImage: `url(${img})` }}>
						<div className="flex align-middle align-justify full-height offer__wrap">
							<div className="offer__left">
								<p className="font font--15 font--white">
									1. Избавь деревню от оборотней и получи 45 фриспинов в слоте
								</p>
							</div>
							<div className="offer__right">
								<div className="flex align-middle align-right">
									<button className="btn btn--h-36 btn--outline offer__btn offer__btn--more offer__btn--not-more">
										<span className="font font--white">More</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Archieve
