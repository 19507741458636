import React from 'react'
import { Flag } from '../../../common/Flag'

const LanguageItem = ({ item, active, onChangeLanguage }) => {
  return (
    <div
      className={
        active
          ? 'dropdown__item h-submenu__lang h-submenu__lang--active'
          : 'dropdown__item h-submenu__lang'
      }
      onClick={() => onChangeLanguage(item)}
    >
      <Flag iconCode={item.key} className="lang__country" />
      <p className="font font--grey-300 font--12 text-uppercase m-l-5">{item.key}</p>
    </div>
  )
}

export default LanguageItem
