import React from 'react'
import { useTranslation } from 'react-i18next'
import img from '../../../assets/img/banners/BannerFigma.png'
// import img from "../../../img/scoreboardBackgrounds/golf.png";

type IItem = {
	titles: {
		title1: string,
		title2: string,
		title3: string,
	},
	buttonTitle: string
}

type IBannerProps = {
	click: () => void,
	item: IItem,
}

const BannerSlide = ({ click, item: { titles, buttonTitle } }: IBannerProps) => {
	const { t } = useTranslation();
	return (
		<div
			style={{ backgroundImage: `url(${img})` }}
			className="wn-banner wn-banner--shadow">
			<div className="wn-banner__wrap">
				<div className="text-left full-width">
					<p className="font font--gold m-b-10">{t(titles.title1)}</p>
					<p className="font font--white font--20 m-b-10">{t(titles.title2)}</p>
					<p className="font font--white-alpha-05 m-b-10">{t(titles.title3)}</p>
				</div>
				<button
					className="btn btn--outline btn--h-24 wn-banner__btn"
					onClick={click}>
					<span className="font font--gold font--11">{t(buttonTitle)}</span>
				</button>
			</div>
		</div>
	)
}

export default BannerSlide
