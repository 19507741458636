import React, { useEffect } from 'react'

import ScoreboardWrapper from '../scoreboard/ScoreboardWrapper'
import EventHeader from './eventHeader/EventHeader'
import Markets from './markets/Markets'
import ErrorPopup from '../../common/elements/ErrorPopup'
import { useSelector } from 'react-redux'
import { getEvent, getMarkets } from 'redux/selectors/eventSelectors'
import { transformMarkets } from '../../../helpers/transform/eventTransform'

const EventCenterContent = () => {
  const currentEvent = useSelector(getEvent)
  const markets = useSelector(getMarkets)
  const eventRef = React.useRef(currentEvent)
  const marketsRef = React.useRef(markets)

  useEffect(() => {
    if (currentEvent?.id) {
      eventRef.current = currentEvent
    }
  }, [currentEvent])

  useEffect(() => {
    if (markets !== null) {
      marketsRef.current = markets
      //sessionStorage.setItem('event', JSON.stringify(markets))
    }
  }, [markets])

  /*if (sessionStorage.getItem('event') === null) {
    marketsRef.current = markets
  } else {
    marketsRef.current = JSON.parse(`${sessionStorage.getItem('event')}`)
  }*/

  if (!currentEvent) {
    return <ErrorPopup />
  }

  const marketsNotLoaded = () => {
    if (eventRef.current && eventRef.current.odds) {
      return transformMarkets(eventRef.current?.odds, null, 'live')
    } else {
      return []
    }
  }

  return (
    <>
      <ScoreboardWrapper event={eventRef.current} />
      <EventHeader />
      <Markets
        markets={
          marketsRef.current === null || !marketsRef.current.length
            ? marketsNotLoaded()
            : marketsRef.current
        }
      />
    </>
  )
}

export default EventCenterContent
